<template>
    <g transform="translate(2.86) scale(.36575)">
        <path :fill="iconColor"
              d="M39.2 16.57c0 .47 1.12 1.05-.06 4.65-.27.83-.35.75-.7 1.39-.41.76-.61 2.2-.96 3.05-1.84 4.58-5.47 9.56-11 9.56-2.05 0-3.77-1.22-4.98-2.16-1.1-.86-2.65-2.75-3.33-3.99-2.35-4.26-1.91-5.43-2.46-6.43-.69-1.25-1.25-2.4-1.25-4.32 0-2 1.06-.75.69-3.12-.87-5.64-.5-9.69 4.54-13.26C20.87 1.1 22.46.08 24.4.01c2.14-.08 3.14.25 5.38.9 3.36.98 6.32 1.94 8.44 4.81 2.92 3.96.99 9.04.99 10.86z"/>
        <path :fill="iconColor"
              d="M19.17 34c1.75 1.17 3.32 3.41 7.35 3.6 2.17.1 4.56-.95 6.2-2.07.58-.4 1.06-.82 1.6-1.19l2.46 1.54c.38.27.38.31.84.55l7.82 3.16c4.82 1.8 5.39 5.19 6.72 9.83.6 2.1 1.49 7.4 1.49 9.48l-5.3 3.59c-2.73 1.66-6.27 3.09-9.4 4.02-3.15.94-4.4 1.12-7.5 1.56-6.4.92-14.7-.54-20.02-2.67l-2.24-.9c-1.72-.78-4.99-2.65-6.56-3.72-.91-.62-1.7-1.38-2.65-1.88 0-2.12.92-7.35 1.46-9.51.4-1.59.77-2.78 1.3-4.1 2.11-5.24 3.68-5.02 9.46-7.1 1.53-.55 4.13-1.86 5.48-2.88.6-.45.87-.93 1.46-1.32z"/>
    </g>
</template>

<script>
export default {
    name: "PersonIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
