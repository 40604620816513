<template>
    <svg :aria-labelledby="iconName"
         :height="height"
         :width="width"
         role="presentation"
         style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
         viewBox="0 0 25 30"
         xml:space="preserve"
         xmlns="http://www.w3.org/2000/svg"
    >
        <title
            :id="iconName"
            lang="ru"
        >{{iconName}}</title>
        <g>
            <component
                :is="icon"
                :iconColor="iconColor"
            ></component>
        </g>
    </svg>
</template>

<script>
import ObjectCardListIcon from "../Icons/NavBar/ObjectCardListIcon";
import TasksListIcon from "../Icons/NavBar/TasksListIcon";
import NewTasksIcon from "../Icons/NavBar/NewTasksIcon";
import ReportsIcon from "../Icons/NavBar/ReportsIcon";
import WarehouseIcon from "../Icons/NavBar/WarehouseIcon";
import AdminIcon from "../Icons/NavBar/AdminIcon";
import StatusMonitorIcon from "../Icons/NavBar/StatusMonitorIcon";
import ObjectMapIcon from "../Icons/NavBar/ObjectMapIcon";
import CalendarIcon from "../Icons/NavBar/CalendarIcon";
import ObjectGroupIcon from "../Icons/NavBar/ObjectGroupIcon";
import PlanIcon from "../Icons/NavBar/PlanIcon";
import PersonIcon from "../Icons/NavBar/AdminIcon/PersonIcon";
import ObjectsIcon from "../Icons/NavBar/AdminIcon/ObjectsIcon";
import PerformersIcon from "../Icons/NavBar/AdminIcon/PerformersIcon";
import UsersIcon from "../Icons/NavBar/AdminIcon/UsersIcon";
import CustomersIcon from "../Icons/NavBar/AdminIcon/CustomersIcon";
import ContractsIcon from "../Icons/NavBar/AdminIcon/ContractsIcon";
import SystemsIcon from "../Icons/NavBar/AdminIcon/SystemsIcon";
import HierarchyIcon from "../Icons/NavBar/AdminIcon/HierarchyIcon";
import DirectoryIcon from "../Icons/NavBar/AdminIcon/DirectoryIcon";
import TasksTimetableIcon from "../Icons/NavBar/AdminIcon/TasksTimetableIcon";
import CustomDataIcon from "../Icons/NavBar/AdminIcon/CustomDataIcon";
import ConfigIcon from "../Icons/NavBar/AdminIcon/ConfigIcon";
import DepartmentIcon from "../Icons/NavBar/AdminIcon/DepartmentIcon";
import TransportIcon from "../Icons/NavBar/TransportIcon";
import AutomobileIcon from "../Icons/NavBar/AutomobileIcon";
import WaybillIcon from "../Icons/NavBar/WaybillIcon";

export default {
    name: "BaseNavbarIcon",
    components: {
        ObjectCardListIcon,
        TasksListIcon,
        NewTasksIcon,
        ReportsIcon,
        WarehouseIcon,
        AdminIcon,
        StatusMonitorIcon,
        ObjectMapIcon,
        CalendarIcon,
        ObjectGroupIcon,
        PlanIcon,
        TransportIcon,
        AutomobileIcon,
        WaybillIcon,


        PersonIcon,
        ObjectsIcon,
        PerformersIcon,
        UsersIcon,
        CustomersIcon,
        ContractsIcon,
        SystemsIcon,
        HierarchyIcon,
        DirectoryIcon,
        TasksTimetableIcon,
        CustomDataIcon,
        ConfigIcon,
        DepartmentIcon
    },
    props: {
        icon: {
            required: true,
            type: String
        },
        iconName: {
            type: String,
            default: 'icon'
        },
        iconColor: {
            type: String,
            default: '#285473'
        },
        width: {
            type: [Number, String],
            default: 25
        },
        height: {
            type: [Number, String],
            default: 30
        },
    }
}
</script>

<style scoped>

</style>
