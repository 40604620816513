<template>
    <g transform="matrix(12.162162,0,0,12.225705,5,11)">
        <path
            class="fil0"
            d="M 0.09,4.45 4.45,0.09 c 0.12,-0.12 0.32,-0.12 0.44,0 l 2.42,2.42 c 0.12,0.12 0.12,0.32 0,0.44 L 3.99,6.27 C 3.89,6.36 3.8,6.37 3.7,6.38 H 1.69 C 1.62,6.37 1.53,6.34 1.46,6.26 L 0.09,4.89 c -0.12,-0.12 -0.12,-0.32 0,-0.44 z"/>
        <path
            class="fil1"
            d="M 0.39,4.56 2.48,2.47 4.93,4.92 3.85,6 C 3.8,6.04 3.74,6.07 3.66,6.07 H 1.85 C 1.76,6.07 1.69,6.04 1.61,5.98 L 0.39,4.76 C 0.33,4.7 0.33,4.61 0.39,4.55 Z"/>
        <g transform="rotate(45,485.14119,-239.56963)">
            <text
                class="fil1 fnt0"
                x="314.92999"
                y="272.01999">DEL
            </text>
        </g>
    </g>
</template>

<script>
export default {
    name: "RemoteStatus"
}
</script>

<style scoped>
.fil1 {
    fill: #fefefe
}

.fil0 {
    fill: #285473
}

.fnt0 {
    font-weight: bold;
    font-size: 2.28px;
    font-family: 'Calibri'
}
</style>
