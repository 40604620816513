<template>
    <v-app>
        <main-errors/>
        <main-info/>
        <confirm-dialog ref="confirmDialog"/>
        <div v-if="isLogged === true">
            <main-app-bar/>
            <main-view/>
        </div>
        <div v-if="isLogged === false">
            <login/>
        </div>
    </v-app>
</template>

<script>
import MainAppBar from './Navbar/AppBar'
import MainFooter from './MainComponents/Footer'
import MainView from './MainComponents/View'
import MainErrors from "./MainComponents/MainErrors"
import MainInfo from "./MainComponents/MainInfo"
import Login from "./MainComponents/Login.vue";
import ConfirmDialog from "./MainComponents/ConfirmDialog.vue";

export default {
    name: "app",
    components: {
        ConfirmDialog,
        MainAppBar,
        MainFooter,
        MainView,
        MainErrors,
        MainInfo,
        Login
    },
    data() {
        return {
            isLogged: null,
        };
    },
    async beforeCreate() {
        await this.$store.dispatch('UserData/getUserData');
        this.isLogged = !!this.$store.getters['UserData/userId']
    },
    mounted() {
        this.$store.commit('setConfirmDialog', this.$refs.confirmDialog)
    },

    watch: {
        isLogged: function (value) {
            if (value) {
                this.$store.dispatch('UserData/getUserSettings');
            }
        },
    },

}
</script>

