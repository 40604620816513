<template>
    <base-modal
        v-model="value"
        max-width="900px"
    >
        <v-card>
            <v-container>
                <v-card-title>
                    <span class="headline">История действий по задаче</span>
                </v-card-title>
                <v-card-text>
                    <div class="scrol-div">
                        <v-simple-table
                            dense
                        >
                            <template v-slot:default>
                                <tbody>
                                <tr v-for="item in log" :key="item.name">
                                    <td><b>{{ formatDate(item.created_at) }}</b> {{ item.user_fio }} {{ item.action }}
                                        {{ item.data }}
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" text v-on:click="close()">Закрыть</v-btn>
                </v-card-actions>
            </v-container>
        </v-card>

    </base-modal>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";
import {shortDateWithTime} from "../../../../mixins/commonMethod";

export default {
    name: "TaskLogs",
    components: {
        BaseModal
    },
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        log: {
            type: Array,
            default: [],
            required: true
        }
    },
    methods: {
        close() {
            this.$emit("input", false)
        },
        formatDate(date) {
            return shortDateWithTime(date)
        }
    }
}
</script>

<style scoped>
.scrol-div {
    overflow: auto;
    overflow-x: hidden;
    height: 590px;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 6px; /* высота для горизонтального скролла */
    background-color: #ffffff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #3273a7;
}

</style>
