<template>
    <svg :height="height"
         :width="width"
         clip-rule="evenodd"
         fill-rule="evenodd"
         image-rendering="optimizeQuality"
         shape-rendering="geometricPrecision"
         text-rendering="geometricPrecision"
         viewBox="0 0 30 30"
         xml:space="preserve"
         xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(.15795 0 0 .16165 7.5 0)">
        <path :fill="iconColor"
              d="M50.65 185.59C34.62 145.27 15.68 99.83 3.76 69.83l-.05-.13A50.462 50.462 0 010 50.66C0 22.69 22.68.01 50.65.01s50.65 22.68 50.65 50.65c0 6.74-1.32 13.17-3.71 19.04l-.05.13c-11.92 30-30.87 75.44-46.89 115.76z"/>
        <path :fill="iconColor"
              d="M50.65 0v185.59c16.03-40.32 34.97-85.76 46.89-115.76l.05-.13a50.462 50.462 0 003.71-19.04C101.3 22.69 78.62.01 50.65.01z"/>
        <circle cx="50.65" cy="50.65" fill="#fefefe" r="37.26"/>
        <text :fill="iconColor" font-family="Arial" font-size="49.44" font-weight="700" text-anchor="middle" x="50"
              y="67.25">{{ text }}</text>
    </g>
</svg>
</template>

<script>
export default {
    name: "UserIcon",
    props: {
        iconColor: {
            type: String,
            // default: '#9d9e9e',
            default: '#285473'
        },
        width: {
            type: [Number, String],
            default: 40
        },
        height: {
            type: [Number, String],
            default: 40
        },
        text: 0
    }
}
</script>

<style scoped>

</style>
