<template>
    <div v-if="value" class="pdf-block">
        <iframe
            :src="src+'#toolbar=0&view=Fit'"
            :title="alt">

        </iframe>
    </div>
</template>

<script>
//По ссылке есть немного информации о настройки отображения просмотрщика pdf
//https://stackoverflow.com/questions/7126089/how-to-specify-parameters-to-google-chrome-adobe-pdf-viewer
export default {
    name: "BasePdfViewer",
    props: {
        src: {
            type: String
        },
        alt: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style scoped>
.pdf-block {
    display: flex;
    justify-content: center;
    height: 100%;
}

.pdf-block iframe {
    position: fixed;
    border: 1px solid #666CCC;
    margin-top: -60px;
    z-index: 10003;
    height: 95%;
    width: 100%;
}
</style>