<template>
    <div class="d-inline-flex justify-center align-center">
        <v-checkbox
            v-model="checkbox"
            @change="selectAll"
        ></v-checkbox>

        <v-menu
            v-if="value.length"
            bottom
            right
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    small
                    tile
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>{{ $store.state.Icon.svgMdiDotsVertical }}</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item
                    v-if="canSetToClosed"
                    :disabled="! closableTasks.length"
                    @click="closeCompletedTasks"
                >
                    <v-list-item-title>Закрыть выполненные задачи</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="canDelete"
                    :disabled="! deletebleTasks.length"
                    @click="deleteTask"
                >
                    <v-list-item-title>Удалить задачи</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import {VBtn, VCheckbox, VIcon} from "vuetify/lib";
import NumberMood from "../../Helpers/NumberMood";
import {DoneStatus} from "../../Constants/TaskStatusConst";

export default {
    name: "TaskListItemSelection",
    components: {VBtn, VIcon, VCheckbox},
    props: {
        value: {
            default: []
        },
        dataList: {
            default: []
        },
    },

    data: function () {
        return {
            checkbox: false
        }
    },
    computed: {
        canDelete: function () {
            return this.$store.getters['UserRights/canAllTasksDelete'] ||
                this.$store.getters['UserRights/canDepTasksDelete'] ||
                this.$store.getters['UserRights/canUsersTasksDelete']
        },

        deletebleTasks: function () {
            return this.value
                .filter((item) => item.can_delete)
                .map((item) => item.id)
        },

        canSetToClosed: function () {
            return this.$store.getters['UserRights/canAllTasksSetToClosed'] ||
                this.$store.getters['UserRights/canDepTasksSetToClosed'] ||
                this.$store.getters['UserRights/canUsersTasksSetToClosed']
        },

        closableTasks: function () {
            return this.value
                .filter((item) => item.task_status_id === DoneStatus)
                .map((item) => item.id);
        }
    },

    methods: {
        numberMoodTask(length) {
            return NumberMood(length, 'задачу', 'задачи', 'задач')
        },

        selectAll(e) {
            if (e) {
                this.$emit('input', this.dataList)
            } else {
                this.$emit('input', [])
            }
        },

        setDefault() {
            this.checkbox = false
            this.$emit('input', [])
            this.$emit('need-update')
        },

        deleteTask() {
            if (this.deletebleTasks.length) {
                this.$store.getters['getConfirmDialog'].show({
                    title: 'Удалить несколько задач?',
                    question: `Вы уверены, что хотите удалить ${this.deletebleTasks.length} ${this.numberMoodTask(this.deletebleTasks.length)}?`,
                    okButton: 'Удалить',
                    cancelButton: 'Отменить'
                }).then(() => {
                    axios.post('/api/task/delete-multiple-tasks', this.deletebleTasks)
                        .then(() => {
                            this.setDefault()
                        })
                        .catch(error => {
                            this.showError(error)
                        });
                })
            }
        },
        closeCompletedTasks() {
            if (this.closableTasks.length) {
                this.$store.getters['getConfirmDialog'].show({
                    title: 'Закрыть несколько задач?',
                    question: `Вы уверены, что хотите закрыть ${this.closableTasks.length} ${this.numberMoodTask(this.closableTasks.length)}?`,
                    okButton: 'Закрыть',
                    cancelButton: 'Отменить'
                }).then(() => {
                    axios.post('/api/taskstatus/close-multiple-tasks', this.closableTasks)
                        .then(() => {
                            this.setDefault()
                        })
                        .catch(error => {
                            this.showError(error)
                        });
                })
            }
        },

    }
}
</script>
