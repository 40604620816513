<template>
    <v-col v-if="value.planned_works.length" class="px-0 pb-1">
        <PlannedWorks
            :disabled="disabled"
            :task-id="taskId"
            v-bind:value="value"
        />
    </v-col>
</template>

<script>
import PlannedWorks from "./PlannedWorks";

export default {
    name: "TaskPlannedWorks",
    components: {PlannedWorks},
    props: {
        value: {
            default: {
                planned_works: [],
            }
        },
        title: {
            type: String,
            default: "Плановые работы"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: Number,
            required: true
        },
    },
}
</script>

<style scoped>
div.TaskPlannedWorksTreeview :deep(div.v-treeview-node__root) {
    min-height: 30px;
}

div.filter {
    background: green;
    position: relative;

}
</style>
