<template>
    <v-icon
        :color="icon.color"
    >
        {{ icon.type }}
    </v-icon>
</template>

<script>
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";

export default {
    name: "SystemDefectStatusIcon",
    props: {
        value: {
            default: SystemDefectDefaultItem(),
            required: true
        },
        taskId: {
            default: null,
            required: false
        },
        systemId: {
            default: null,
            required: false
        }
    },
    computed: {

        isFixed: function () {
            return !!(this.value.fixed_at && this.value.fixed_by)
        },

        fixInThisTask: function () {
            return this.isFixed && this.value.changed_by_task === this.taskId
        },

        createdInThisTaskAndFixed: function () {
            return (this.taskId && this.value.created_by_task === this.taskId && this.isFixed)
        },

        createdInThisTaskAndNotFixed: function () {
            return (this.taskId && this.value.created_by_task === this.taskId && !this.isFixed)
        },

        createdInAnotherTask: function () {
            return (this.taskId && this.value.created_by_task !== this.taskId)
        },

        createdNotInTaskAndFixed: function () {
            return this.value.created_by_task === null && this.isFixed
        },

        createdNotInTaskAndNotFixed: function () {
            return this.value.created_by_task && !this.isFixed
        },

        icon: function () {

            if (this.systemId) {
                return {
                    type: this.isFixed ? this.$store.state.Icon.svgMdiCheck : this.$store.state.Icon.svgMdiExclamation,
                    color: this.isFixed ? 'green' : 'red'
                }
            }


            if (this.fixInThisTask) {
                return {
                    type: this.$store.state.Icon.svgMdiCheck,
                    color: 'green'
                }
            }

            if (this.createdInThisTaskAndNotFixed) {
                return {
                    type: this.$store.state.Icon.svgMdiDubleExclamation,
                    color: 'red'
                }
            }

            return {
                type: this.$store.state.Icon.svgMdiExclamation,
                color: 'red'
            }
        }
    }

}
</script>


<style scoped>

</style>
