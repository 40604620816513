<template>
    <g transform="matrix(12.110727,0,0,12.006861,15,15)">
        <path
            class="fil0"
            d="m 0.61,1.48 h 0.52 v -1 C 1.13,0.22 1.35,0 1.61,0 h 2.56 c 0.26,0 0.48,0.22 0.48,0.48 v 1 h 0.52 c 0.34,0 0.61,0.28 0.61,0.61 V 5.22 C 5.78,5.56 5.5,5.83 5.17,5.83 H 0.61 C 0.27,5.83 0,5.55 0,5.22 V 2.09 C 0,1.75 0.28,1.48 0.61,1.48 Z m 2.28,0.81 c 0.4,0 0.72,0.32 0.72,0.72 0,0.19 -0.08,0.37 -0.2,0.5 l 0.2,1.46 H 2.89 2.17 L 2.37,3.51 C 2.25,3.38 2.17,3.21 2.17,3.01 2.17,2.61 2.49,2.29 2.89,2.29 Z M 1.76,1.48 H 4.03 V 0.78 H 1.76 Z"
        />
    </g>
</template>

<script>
export default {
    name: "CloseStatus"
}
</script>

<style scoped>
.fil0 {
    fill: #285473
}

.fil1 {
    fill: #fefefe
}
</style>
