import { render, staticRenderFns } from "./PersonForm.vue?vue&type=template&id=44968de4&scoped=true"
import script from "./PersonForm.vue?vue&type=script&lang=js"
export * from "./PersonForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44968de4",
  null
  
)

export default component.exports