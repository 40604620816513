<template>
    <v-sheet
        id="draggable-item-sheet"
        class="px-3"
        color="grey lighten-3"
        elevation="1"
        height="38"
    >
        {{ text }}
    </v-sheet>
</template>

<script>
export default {
    name: "TaskListDraggableItem",
    props: {
        item: {
            requred: true,
        },
    },
    data: function () {
        return {
            text: '',
        }
    },
    watch: {
        item: function (val) {
            if (val) {
                this.text = 'Задача №' + val.id + ' ' + val?.task_types_name
            }
        }
    }
}
</script>

<style scoped>
#draggable-item-sheet {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
    width: max-content;
}
</style>