<template>

    <v-row v-if="value.planned_works.length" no-gutters>
        <v-col :title="title" class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">
            {{ title }}
        </v-col>
        <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">
            <template v-if="model.length">
                <template v-for="item of model">
                    <v-checkbox
                        :id="item.id.toString()"
                        v-model="item.status"
                        :disabled="disabled"
                        :label="item.group_of_planned_work.name"
                        class="mt-1"
                        dense
                        hide-details
                        @click="dataChange(item.id, item.status)"
                    ></v-checkbox>
                </template>
            </template>
            <div class="w-100 pb-3"></div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "PlannedWorks",
    props: {
        value: {
            default: {
                planned_works: {},
            }
        },
        title: {
            type: String,
            default: "Плановые работы"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: Number,
            required: true
        },
    },
    data: function () {
        return {
            model: [],
            disabledModel: [],
            settings: [],
        }
    },
    computed: {
        //Вычисляем цвет дерева
        color: function () {
            return this.disabled ? 'gray' : 'corpColor'
        }
    },
    watch: {
        value: {
            handler: function (val, oldVal) {
                if (val.planned_works) {
                    this.model = Object.assign([], val.planned_works)
                }
            },
            deep: true,
            immediate: true
        },
        model: {
            handler: function (val, oldVal) {
                let data = {
                    planned_works: val
                }
                // this.dataChange(val)
                this.$emit('input', data)
            },
            deep: true,
            immediate: true
        },

        // disabled: {
        //     handler: function (val) {
        //         if (val){
        //             this.settings = this.settings.map(function(item) {
        //                 item.disabled = true
        //                 return item
        //             })
        //         } else {
        //             this.settings = this.settings.map(function(item) {
        //                 item.disabled = false
        //                 return item
        //             })
        //         }
        //     },
        //     immediate: true
        // },

    },
    methods: {
        /**
         * При изменении данных, если у задачи disabled, то модели присваиваем старые данные,
         * если не disabled то присваиваем новые данные и отправляем их на сервер
         * @param plannedWorkID
         * @param status
         */
        dataChange(plannedWorkID, status) {
            if (!this.disabled && plannedWorkID) {
                axios.put('/api/task/plannedworks/' + this.taskId, {plannedWorkID: plannedWorkID, status: status})
                    .then(() => {
                        // this.$emit('input', this.model)
                    })
                    .catch(error => {
                        if (error.response) {
                            this.Error = true
                            this.ErrorText = error.response.data.message
                        }
                    });
            }
        }
    }
}
</script>

<style scoped>
:deep(.v-label) {
    margin-bottom: 0;
}
</style>
