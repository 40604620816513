<template>
    <v-card
        :height="heightComputed"
        :loading="loading"
        class="mx-auto overflow-y-auto"
    >
        <v-card-title class="pa-3 " style="justify-content: space-between">
            <div>
                {{ title }}
            </div>
            <div>
                <base-left-menu-settings/>
            </div>
        </v-card-title>
        <v-col v-if="withSearch" class="search-col">
            <v-text-field
                v-model="search"
                :append-icon="$store.state.Icon.svgMdiMagnify"
                clearable
                hide-details
                label="Найти"
                single-line
            />
        </v-col>

        <v-treeview
            ref="leftMenu"
            :activatable="activatable"
            :active.sync="activeMenuPoint"
            :items="allNodes"
            :open.sync="open"
            :search="search"
            :selectable="selectable"
            class="base-left-menu"
            color="primary"
            hoverable
            return-object
            transition
            v-on:input="$emit('input', $event)"

        >
            <template v-slot:label="{ item }">
                <span :title="item.name">{{ item.name }}</span>
            </template>
            <template v-slot:append="{ item }">
                <div v-if="showSystemCount" class="smoll-text">
                    {{ getAppendData(item) }}
                </div>
            </template>
        </v-treeview>
    </v-card>
</template>

<script>
import BaseLeftMenuSettings from "./BaseLeftMenuSettings";

export default {
    name: "BaseLeftMenu",
    components: {BaseLeftMenuSettings},
    props: {
        updateAll: null,
        selectable: false,
        objectOnly: { //если труе, то иерархию загружаем только до объекта, если фалсе то до системы
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "Список объектов"
        },
        height: {
            default: null
        },
        withSearch: {
            type: Boolean,
            default: false
        },
        heightCorrection: {
            default: 95
        },
        onlyOneItem: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            allNodes: [],
            activeMenuPoint: [],
            open: [],
            search: null,
            loading: false
        }
    },
    computed: {
        showSystemCount: function () {
            const settings = this.$store.getters["UserData/getLeftMenuSettings"]
            return settings.showSystemCount
        },
        selectedSchema: function () {
            const settings = this.$store.getters["UserData/getLeftMenuSettings"]
            return settings.schema
        },
        heightComputed: function () {
            if (this.height) {
                return this.height
            }
            if (this.$vuetify.breakpoint.width < 992) {
                return "100%"
            } else {
                return this.$vuetify.breakpoint.height - this.heightCorrection
            }
        },
        activatable: function () {
            return !this.selectable;
        },
    },
    created() {
        this.getMainNode()
    },
    watch: {
        activeMenuPoint: function (val) {
            if (val.length && this.onlyOneItem) {
                if (this.activeMenuPoint[0].hasOwnProperty('children') && this.activeMenuPoint[0]?.data?.systems_count !== 1) {
                    this.activeMenuPoint = []
                }
            }
            this.$emit("input", val)
        },
        updateAll: function (val) {
            if (val !== null) {
                this.$refs.leftMenu.updateAll(val)
            }
        },
        selectedSchema: function (val) {
            this.getMainNode()
        }
    },
    methods: {
        getAppendData(item) {
            if (item && item.data && item.data.systems_count) {
                return item.data.systems_count
            }
            return null
        },

        async getMainNode() {
            this.loading = true
            let node = this.objectOnly ? "object" : "node"
            return axios.get('/api/hierarchy/' + node)
                .then(response => {
                    this.allNodes = response.data
                    this.open = this.allNodes
                })
                .catch((error) => {
                    this.showError(error)
                })
                .finally(() => {
                    this.loading = false
                });
        },
    }
}
</script>

<style scoped>

.base-left-menu :deep(.v-treeview-node__root) {
    min-height: 35px;
}

.search-col {
    padding-top: 0;
}

.search-col :deep(.v-input) {
    padding-top: 0;
    margin-top: 0;
}

.base-left-menu :deep(.v-treeview-node__label) {
    cursor: pointer;
}

.smoll-text {
    font-size: 0.75rem;
}
</style>
