import taskStatusSettingsDataType from "../../mixins/taskStatusSettingsDataType";

const state = {
    settings: new taskStatusSettingsDataType()
}

const getters = {
    requireCommentWhenSetCanceled: (state) => !!state.settings.requireCommentWhenSetCanceled,
    requireCommentWhenSetClose: (state) => !!state.settings.requireCommentWhenSetClose,
    requireCommentWhenSetDone: (state) => !!state.settings.requireCommentWhenSetDone,
    requireCommentWhenSetInWork: (state) => !!state.settings.requireCommentWhenSetInWork,
    requireCommentWhenSetSuspended: (state) => !!state.settings.requireCommentWhenSetSuspended,

    CanceledCommentLength: (state) => state.settings.CanceledCommentLength,
    CloseCommentLength: (state) => state.settings.CloseCommentLength,
    DoneCommentLength: (state) => state.settings.DoneCommentLength,
    InWorkCommentLength: (state) => state.settings.InWorkCommentLength,
    SuspendedCommentLength: (state) => state.settings.SuspendedCommentLength,
}

const mutations = {
    setSettings(state, data) {
        state.settings = data
    },
}

const actions = {
    taskStatusSattings({commit}) {
        axios.get('/api/taskstatus/settings')
            .then(response => {
                commit('setSettings', response.data)
            })
            .catch(error => {
                if (error.response) {
                    commit('setErrors', error.response, {root: true})
                }
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
