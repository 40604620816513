const Objects = () => import(/* webpackChunkName: "Objects" */ '../components/Admin/Objects/Objects.vue')
const ObjectCreate = () => import(/* webpackChunkName: "Objects" */ '../components/Admin/Objects/ObjectCreate.vue')
const ObjectDelete = () => import(/* webpackChunkName: "Objects" */ '../components/Admin/Objects/ObjectDelete.vue')
const ObjectUpdate = () => import(/* webpackChunkName: "Objects" */ '../components/Admin/Objects/ObjectUpdate.vue')
const Systems = () => import(/* webpackChunkName: "Systems" */ '../components/Admin/Systems/Systems.vue')
const SystemCreate = () => import(/* webpackChunkName: "Systems" */ '../components/Admin/Systems/SystemCreate.vue')
const SystemDelete = () => import(/* webpackChunkName: "Systems" */ '../components/Admin/Systems/SystemDelete.vue')
const SystemUpdate = () => import(/* webpackChunkName: "Systems" */ '../components/Admin/Systems/SystemUpdate.vue')

export const objectRoutes = [
    {
        path: '/object', name: "object.index", component: Objects,
        children: [
            {path: "create", component: ObjectCreate, name: "object.create"},
            {path: "delete/:id", component: ObjectDelete, name: "object.delete", props: true},
            {path: ":id", component: ObjectUpdate, name: "object.update", props: true},

            {path: "system/create/:objectId", component: SystemCreate, name: "object.system.create", props: true},
            {path: "system/delete/:id", component: SystemDelete, name: "object.system.delete", props: true},
            {path: "system/:id", component: SystemUpdate, name: "object.system.update", props: true},
        ]
    },
    {
        path: '/systems', name: "system.index", component: Systems,
        children: [
            {path: "create", component: SystemCreate, name: "system.create"},
            {path: "delete/:id", component: SystemDelete, name: "system.delete", props: true},
            {path: ":id", component: SystemUpdate, name: "system.update", props: true},
        ]
    },
]
