<template>
    <base-full-screen-modal
        v-model="dialog"
        style="z-index: 19999">
        <task/>
    </base-full-screen-modal>
</template>

<script>
import BaseFullScreenModal from "../BaseComponents/BaseFullScreenModal";
import Task from "./Task";

export default {
    name: "ModalTask",
    components: {BaseFullScreenModal, Task},
    data: function () {
        return {
            dialog: false,
        }
    },
    mounted() {
        this.dialog = true
    },
}
</script>

<style scoped>

</style>