<template>
    <div ref="observedElement">
        <template v-if="show">
            <slot
                :openCreateDialog="openCreateDialog"
                :openEditDialog="openEditDialog"
                :openDeleteDialog="openDeleteDialog"
                :openUploadDialog="openUploadDialog"
                :openCommentDialog="openCommentDialog"
                :openStateDialog="openStateDialog"
                :openInfoDialog="openInfoDialog"
                :changeIsPrintable="changeIsPrintable"
            />

            <system-defect-form
                v-if="!disabled"
                :task-id="taskId"
                :system-id="systemId"
                :value="editableDefect"
                v-on:close="closeEditDialog()"
                v-on:need-update="needUpdate()"
            />

            <system-defect-delete-form
                v-if="!disabled"
                :task-id="taskId"
                :system-id="systemId"
                :value="daletebleDefect"
                v-on:close="closeDeleteDialog()"
                v-on:need-update="needUpdate()"
            />

            <system-defect-comment-form
                v-if="!disabled"
                :task-id="taskId"
                :system-id="systemId"
                :value="commentedDefect"
                v-on:close="closeCommentDialog()"
                v-on:need-update="needUpdate()"
            />

            <system-defect-upload-file
                v-if="!disabled"
                :task-id="taskId"
                :system-id="systemId"
                :value="uploadebleDefect"
                v-on:close="closeUploadDialog()"
                v-on:need-update="needUpdate()"
            />

            <system-defect-state-form
                v-if="!disabled"
                :task-id="taskId"
                :system-id="systemId"
                :value="changeableDefect"
                v-on:close="closeStateDialog()"
                v-on:need-update="needUpdate()"
            />

            <system-defect-info
                :value="infoDefect"
                :task-id="taskId"
                :system-id="systemId"
                v-on:close="closeInfoDialog()"
                v-on:need-update="needUpdate()"
            />
        </template>
    </div>
</template>

<script>
import BaseFileUpload from "../BaseComponents/BaseFileUpload/BaseFileUpload.vue";
import BaseModal from "../BaseComponents/BaseModal.vue";
import requiredRules from "../../Rules/requiredRules";
import SystemDefectCommentForm from "./SystemDefectCommentForm.vue";
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";
import SystemDefectDeleteForm from "./SystemDefectDeleteForm.vue";
import SystemDefectForm from "./SystemDefectForm.vue";
import SystemDefectInfo from "./SystemDefectInfo.vue";
import SystemDefectItemInTask from "./SystemDefectItemInTask.vue";
import SystemDefectStateForm from "./SystemDefectStateForm.vue";
import SystemDefectUploadFile from "./SystemDefectUploadFile.vue";
import shownWhenIntersectionComponent from "../../mixins/shownWhenIntersectionComponent";

export default {
    name: "BaseSystemDefects",
    mixins: [shownWhenIntersectionComponent],
    components: {
        BaseFileUpload,
        BaseModal,
        SystemDefectCommentForm,
        SystemDefectDeleteForm,
        SystemDefectForm,
        SystemDefectInfo,
        SystemDefectItemInTask,
        SystemDefectStateForm,
        SystemDefectUploadFile,
    },

    props: {
        disabled: {
            default: false
        },
        taskId: {
            type: Number,
            nullable: true,
            default: null
        },
        systemId: {
            type: Number,
            nullable: true,
            default: null
        }
    },

    data: function () {
        return {
            editableDefect: null,
            changeableDefect: null,
            commentedDefect: null,
            daletebleDefect: null,
            infoDefect: null,
            uploadebleDefect: null,
        }
    },

    computed: {
        requiredRules() {
            return requiredRules
        },
    },
    methods: {

        openCreateDialog() {
            this.editableDefect = SystemDefectDefaultItem()
        },

        canEdit(event) {
            if (!event.fixed_at && !event.fixed_by) {
                if (this.$store.getters["UserData/getStatusDefectSettings"].edit_only_in_task_where_created) {
                    return event.created_by_task === this.taskId
                }
                return true
            }
        },

        canDelete(event) {
            if (this.$store.getters["UserData/getStatusDefectSettings"].delete_only_in_task_where_created) {
                return event.created_by_task === this.taskId
            } else {
                return true
            }
        },

        openEditDialog(event) {
            if (event && this.canEdit(event)) {
                this.editableDefect = event
            }
        },

        closeEditDialog() {
            this.editableDefect = null
        },

        openDeleteDialog(event) {
            if (event && this.canDelete(event)) {
                this.daletebleDefect = event
            }
        },

        closeDeleteDialog() {
            this.daletebleDefect = null
        },

        openUploadDialog(event) {
            this.uploadebleDefect = event
        },

        closeUploadDialog() {
            this.uploadebleDefect = null
        },

        openCommentDialog(event) {
            this.commentedDefect = event
        },

        closeCommentDialog() {
            this.commentedDefect = null
        },

        openStateDialog(event) {
            this.changeableDefect = event
        },

        closeStateDialog() {
            this.changeableDefect = null
        },

        openInfoDialog(event) {
            if (event?.id) {
                axios.get('/api/system-defect/' + event?.id)
                    .then(response => (
                        this.infoDefect = response.data
                    ))
                    .catch(error => {
                        this.showError(error)
                    });
            }

        },

        closeInfoDialog() {
            this.infoDefect = null
        },

        needUpdate() {
            this.$emit('need-update')
        },

        changeIsPrintable(event) {
            if (event?.id) {
                axios.post('/api/system-defect/printable/defect/' + event?.id, {is_printable: event.is_printable})
                    .catch(error => {
                        this.showError(error)
                    });
            }
        }
    },
}
</script>


<style scoped>

</style>
