const Person = () => import(/* webpackChunkName: "Person" */ '../components/Admin/Person/Person.vue')
const PersonCreate = () => import(/* webpackChunkName: "Person" */ '../components/Admin/Person/PersonCreate.vue')
const PersonDelete = () => import(/* webpackChunkName: "Person" */ '../components/Admin/Person/PersonDelete.vue')
const PersonUpdate = () => import(/* webpackChunkName: "Person" */ '../components/Admin/Person/PersonUpdate.vue')
export const personRoutes = [
    {
        path: '/person', name: "person.index", component: Person,
        children: [
            {path: "create", component: PersonCreate, name: "person.create"},
            {path: "delete/:id", component: PersonDelete, name: "person.delete", props: true},
            {path: ":id", component: PersonUpdate, name: "person.update", props: true},
        ]
    },
]