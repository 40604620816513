<template>
    <v-tabs
        v-if="navbar"
        v-model="tab"
        class="hidden-sm-and-down"
        optional
        show-arrows
        style="width: 90%"
    >
        <v-tabs-slider></v-tabs-slider>

        <template v-for="point in navbar">
            <v-tab
                v-if="!point.children && point.show"
                :key="point.key"
                :to="point.path"
                active-class="corporate-color"
                class="font-weight-bold"
                min-width="96"
                text
                v-on:click="activPoint=point.key"
            >
                <v-flex class="mr-1">
                    <BaseNavbarIcon
                        :icon="point.img"
                        :icon-color="getIconColor(point.key)"
                        :icon-name="point.name"
                    />
                </v-flex>
                <v-flex>
                    {{ point.name }}
                </v-flex>
            </v-tab>
            <v-menu
                v-if="point.children && point.show"
                bottom
                offset-y
                right
            >
                <template v-slot:activator="{ on }">
                    <v-tab
                        :key="point.key"
                        active-class="corporate-color"
                        class="font-weight-bold"
                        min-width="96"
                        text
                        v-on="on"
                        v-on:change="activPoint=point.key"

                    >
                        <v-flex class="mr-1">
                            <BaseNavbarIcon
                                :icon="point.img"
                                :icon-color="getIconColor(point.key)"
                                :icon-name="point.name"
                            />
                        </v-flex>
                        <v-flex class="">
                            {{ point.name }}
                            <v-icon right>{{ $store.state.Icon.svgMdiMenuDown }}</v-icon>
                        </v-flex>
                    </v-tab>
                </template>

                <v-list class="">
                    <v-list-item
                        v-for="subpoint in point.children"
                        :key="subpoint.key"
                        :to="subpoint.path"
                        dense
                        fixed
                    >
                        <v-list-item-avatar
                            height="25"
                            tile
                            width="25"
                        >
                            <BaseNavbarIcon
                                :height="25"
                                :icon="subpoint.img"
                                :icon-name="subpoint.name"
                                :width="25"
                                icon-color="#285473"
                            />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-text="subpoint.name"/>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

    </v-tabs>
</template>

<script>
import BaseNavbarIcon from "./BaseNavbarIcon";

export default {
    name: "AppBarTabs",
    components: {BaseNavbarIcon},
    props: {
        navbar: {}
    },
    data: () => ({
        activPoint: String,
        tab: null,
    }),
    methods: {
        getIconColor(key) {
            if (key === this.activPoint) {
                return "#285473"
            } else {
                return "#757575"
            }
        },
    }
}
</script>

<style scoped>

</style>