<template>
    <base-modal v-model="value">
        <v-card
            :loading="filePreparation"
        >
            <v-card-title>
                <span class="headline">Отчетные документы</span>
            </v-card-title>
            <v-card-text class="py-0">
                <template v-if="availableReportingDocs && availableReportingDocs.length">
                    <v-checkbox
                        v-for="item in availableReportingDocs"
                        :key="item.id"
                        v-model="selectedReportingDocs"
                        :error="!!item.error"
                        :error-messages="item.error"
                        :input-value="!item.error"
                        :label="item.name"
                        :multiple=true
                        :readonly="!!item.error"
                        :value="item.id"
                        dense
                    ></v-checkbox>
                </template>

                <v-text-field
                    v-model="email"
                    :rules="nullableEmailRules"
                    autocomplete="off"
                    clearable
                    label="Адрес электронной почты"
                >
                </v-text-field>
            </v-card-text>
            <v-expansion-panels
                flat
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>Дополнительные параметры</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-text-field
                            v-model="datetime"
                            clearable
                            label="Дата документа"
                            no-resize
                            type="datetime-local"
                        ></v-text-field>

                        <v-select
                            v-model="customerRepresentative"
                            :items="customerRepresentativeList"
                            label="Представитель заказчика"
                            no-resize
                        ></v-select>

                        <template v-if="customerRepresentative === 'Указать вручную'">
                            <v-text-field
                                v-model="customerRepPosition"
                                clearable
                                label="Должность представителя заказчика"
                                no-resize
                            ></v-text-field>
                            <v-text-field
                                v-model="customerRepFullName"
                                clearable
                                label="ФИО представителя заказчика"
                                no-resize
                            ></v-text-field>
                        </template>

                        <v-select
                            v-model="performerRepresentative"
                            :items="performerRepresentativeList"
                            label="Представитель исполнителя"
                            no-resize
                        ></v-select>

                        <template v-if="performerRepresentative === 'Указать вручную'">
                            <v-text-field
                                v-model="performerRepPosition"
                                clearable
                                label="Должность представителя исполнителя"
                                no-resize
                            ></v-text-field>
                            <v-text-field
                                v-model="performerRepFullName"
                                clearable
                                label="ФИО представителя исполнителя"
                                no-resize
                            ></v-text-field>
                        </template>
                        <v-checkbox
                            v-model="addAssistants"
                            label="Добавлять помощников в подписанты"
                        ></v-checkbox>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-card-actions>
                <v-row>
                    <v-col class="text-center" cols="12" lg="4" md="4" sm="4" xl="4">
                        <v-btn :disabled="!selectedReportingDocs.length" class="px-1 mx-a" color="black" text
                               @click="downloadReportingDocs"
                        >
                            <v-icon
                                dark
                                left
                            >
                                {{ $store.state.Icon.svgMdiDownload }}
                            </v-icon>
                            Скачать
                        </v-btn>
                    </v-col>
                    <v-col class="text-center" cols="12" lg="4" md="4" sm="4" xl="4">
                        <v-btn
                            :disabled="!email || !selectedReportingDocs.length"
                            class="px-1"
                            color="black"
                            text
                            @click="sendReportingDocs"

                        >
                            <v-icon
                                dark
                                left
                            >
                                {{ $store.state.Icon.svgMdiFileSend }}
                            </v-icon>
                            Отправить
                        </v-btn>
                    </v-col>
                    <v-col class="text-center" cols="12" lg="4" md="4" sm="4" xl="4">
                        <v-btn class="px-1" color="black" text @click="close()">
                            <v-icon
                                dark
                                left
                            >
                                {{ $store.state.Icon.svgMdiClose }}
                            </v-icon>
                            Закрыть
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card-actions>
        </v-card>
    </base-modal>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";
import {shortDateWithTimeAndT} from "../../../../mixins/commonMethod";
import nullableEmailRules from "../../../../Rules/nullableEmailRules";

export default {
    name: "TaskReportingDocs",
    components: {
        BaseModal,
    },
    props: {
        value: {
            default: false
        },
    },
    data: () => {
        return {
            availableReportingDocs: null,
            selectedReportingDocs: [],
            email: null,
            datetime: shortDateWithTimeAndT(new Date()),
            filePreparation: false,
            customerRepresentativeList: [
                "Ответственный на объекте",
                "Руководитель заказчика",
                "Указать вручную"
            ],
            customerRepresentative: "Ответственный на объекте",
            customerRepPosition: null,
            customerRepFullName: null,

            performerRepresentativeList: [
                "Текущий пользователь",
                "Исполнитель задачи",
                "Ответственный инженер",
                "Руководитель исполнителя",
                "Указать вручную"
            ],
            performerRepresentative: "Текущий пользователь",
            performerRepPosition: null,
            performerRepFullName: null,
            addAssistants: true,
        }
    },

    watch: {
        value: function (val) {

            if (val) {
                axios.post('/api/reporting_docs/reportingDocsForTask/' + this.taskId, this.getData())
                    .then(response => {
                        this.availableReportingDocs = response.data
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                    .finally(() => {
                        //Перебераем данные с отчетными документами и отмечаем те документы, в которых нет ошибок
                        this.selectedReportingDocs = []
                        if (this.availableReportingDocs && this.availableReportingDocs.length) {
                            this.availableReportingDocs.forEach((element) => {
                                if (!element.error) {
                                    this.selectedReportingDocs.push(element.id)
                                }
                            })
                        }
                    })
                ;
            }
        },
    },

    computed: {
        taskId: function () {
            return this.$store.getters["TaskData/getTaskId"]
        },
        disabled: function () {
            return this.$store.getters["TaskData/getDisabled"]
        },
        nullableEmailRules() {
            return nullableEmailRules
        }
    },
    methods: {
        getData() {
            return {
                email: this.email,
                datetime: this.datetime,
                customerRepresentative: this.customerRepresentative,
                customerRepPosition: this.customerRepPosition,
                customerRepFullName: this.customerRepFullName,
                performerRepresentative: this.performerRepresentative,
                performerRepPosition: this.performerRepPosition,
                performerRepFullName: this.performerRepFullName,
                addAssistants: this.addAssistants,
                selectedReportingDocs: this.selectedReportingDocs,
            }
        },
        viewReportingDocs() {
            window.open("/reporting_docs/view/" + this.taskId, '_blank')
            this.close()
        },
        // downloadReportingDocs() {
        //     window.open("/reporting_docs/download/"+this.taskId, '_blank')
        //     this.close()
        // },


        downloadReportingDocs() {
            axios({
                url: '/api/reporting_docs/download/' + this.taskId,
                method: 'post',
                data: this.getData(),
                responseType: 'blob', // important
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "Отчетные документы по задаче " + this.taskId + '.docx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    if (error.response) {
                        this.validationErrors = ["Не удалось скачать шаблон."]
                    }
                })
                .finally(() => (this.close()));
        },

        sendReportingDocs() {
            if (this.email) {
                this.filePreparation = true
                axios.post('/api/reporting_docs/send/' + this.taskId, this.getData())
                    .then(response => {

                    })
                    .catch(console.log.bind(console))
                    .finally(() => (
                            this.close()
                        )
                    )
                ;
            }
        },
        close() {
            this.filePreparation = false
            this.$emit("input", false)
            this.$store.dispatch('TaskData/updateTaskData')
        },
    }
}
</script>

<style scoped>

</style>
