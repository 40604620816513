<template>
    <div :style="panStyle" class="overflow-y-auto">
        <splitpanes
            v-if="!mobile"
            class="default-theme"
            @resized="onResize($event)"
        >
            <pane
                v-if="show_left"
                :max-size="leftPaneMaxSize"
                :min-size="leftPaneMinSize"
                :size="leftPaneSize"
            >
                <slot name="left"></slot>
            </pane>
            <pane
                v-if="show_right"
                :max-size="rightPaneMaxSize"
                :min-size="rightPaneMinSize"
                :size="rightPanSize"
            >
                <slot name="right"></slot>
            </pane>
        </splitpanes>

        <v-col v-if="mobile" class="pb-0 px-1" cols="12">
            <slot name="left"></slot>
        </v-col>
        <v-col v-if="mobile" class="pb-0 px-1" cols="12">
            <slot name="right"></slot>
        </v-col>
    </div>

</template>

<script>
import {VCol} from 'vuetify/lib'
import {Pane, Splitpanes} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

export default {
    name: "BaseResizePanel",
    components: {Splitpanes, Pane, VCol},
    props: {
        name: {
            default: null,
        },
        mobile: {
            required: true,
            default: false,
            type: Boolean
        },
        show_left: {
            default: true,
            type: Boolean
        },
        show_right: {
            default: true,
            type: Boolean
        }

    },
    data: function () {
        return {
            rightPanSize: 85,
            rightPaneMinSize: 70,
            rightPaneMaxSize: 90,
            leftPaneSize: 15,
            leftPaneMinSize: 10,
            leftPaneMaxSize: 30,
        }
    },

    mounted() {
        if (!this.show_left) {
            this.leftPaneSize = 0
            this.rightPanSize = 100
        }

        if (!this.show_right) {
            this.leftPaneSize = 100
            this.rightPanSize = 0
        }

        if (this.show_left && this.show_right && this.getLeftPanSize()) {
            this.leftPaneSize = this.getLeftPanSize()
        }
        if (this.show_left && this.show_right && this.getRightPanSize()) {
            this.rightPanSize = this.getRightPanSize()
        }
    },

    computed: {
        panStyle: function () {
            return {
                width: '100%',
                height: (this.$vuetify.breakpoint.height - 64).toString() + 'px',
            }
        }
    },

    methods: {
        getName(panName) {
            return this.name ? this.name + '_' + panName : panName
        },

        getLeftPanSize() {
            return localStorage[this.getName('leftpansize')] ? localStorage[this.getName('leftpansize')] : null
        },

        getRightPanSize() {
            return localStorage[this.getName('rightpansize')] ? localStorage[this.getName('rightpansize')] : null
        },


        setLeftPanSize(val) {
            localStorage[this.getName('leftpansize')] = val
        },

        setRightPanSize(val) {
            localStorage[this.getName('rightpansize')] = val
        },

        onResize(event) {
            if (event[0] !== undefined && event[0].size) {
                this.setLeftPanSize(event[0].size)
            }
            if (event[1] !== undefined && event[1].size) {
                this.setRightPanSize(event[1].size)
            }
        },

    },
}
</script>

<style scoped>

</style>