<template>
    <g transform="matrix(0.21199016,0,0,0.23753619,0,-5.8679287e-4)">
        <path :fill="iconColor"
              d="m 49.51,21.9 c 0,0.59 1.41,1.33 -0.07,5.87 -0.34,1.05 -0.44,0.95 -0.88,1.76 -0.52,0.96 -0.78,2.77 -1.21,3.85 -2.33,5.78 -6.91,12.07 -13.9,12.07 -2.59,0 -4.76,-1.54 -6.29,-2.73 -1.39,-1.08 -3.34,-3.48 -4.2,-5.04 -2.97,-5.39 -2.41,-6.86 -3.11,-8.12 -0.87,-1.58 -1.58,-3.03 -1.58,-5.46 0,-2.53 1.34,-0.94 0.87,-3.94 -1.1,-7.13 -0.63,-12.24 5.73,-16.74 1.49,-1.06 3.5,-2.34 5.95,-2.43 2.71,-0.1 3.96,0.31 6.79,1.13 4.24,1.24 7.99,2.46 10.65,6.07 3.69,5 1.25,11.42 1.25,13.71 z"/>
        <path :fill="iconColor"
              d="m 24.21,43.91 c 2.21,1.48 4.2,4.31 9.29,4.55 2.75,0.13 5.76,-1.2 7.84,-2.61 0.74,-0.5 1.33,-1.04 2.02,-1.5 l 3.11,1.95 c 0.49,0.34 0.48,0.39 1.06,0.7 l 9.88,3.99 c 1.11,0.41 2.04,0.9 2.83,1.44 -1.99,1.36 -5.03,2.87 -7.11,3.62 -8.8,3.17 -12.5,3.16 -16.3,12.61 -0.89,2.2 -1.55,4.4 -2.13,6.7 -0.61,2.41 -1.06,4.96 -1.46,7.42 -0.23,1.42 -0.48,2.97 -0.66,4.51 C 26.12,87.04 19.31,85.55 14.46,83.6 L 11.63,82.47 C 9.45,81.49 5.33,79.12 3.34,77.78 2.18,77 1.19,76.03 0,75.4 0,72.73 1.16,66.11 1.85,63.38 c 0.5,-2.01 0.97,-3.52 1.64,-5.18 2.66,-6.62 4.65,-6.34 11.94,-8.96 1.93,-0.7 5.21,-2.35 6.93,-3.64 0.76,-0.57 1.1,-1.17 1.85,-1.67 z"/>
        <path :fill="iconColor"
              d="m 95.68,25.52 c 0,0.72 1.72,1.62 -0.09,7.16 -0.42,1.27 -0.54,1.15 -1.08,2.14 -0.63,1.17 -0.94,3.38 -1.47,4.7 -2.84,7.05 -8.43,14.71 -16.94,14.71 -3.15,0 -5.8,-1.87 -7.67,-3.33 -1.69,-1.32 -4.07,-4.24 -5.13,-6.14 -3.62,-6.57 -2.94,-8.36 -3.79,-9.9 -1.06,-1.93 -1.93,-3.69 -1.93,-6.66 0,-3.08 1.63,-1.15 1.07,-4.81 C 57.31,14.7 57.88,8.47 65.63,2.98 67.45,1.69 69.9,0.12 72.88,0.02 c 3.3,-0.12 4.83,0.38 8.28,1.38 5.17,1.51 9.74,2.99 12.99,7.4 4.5,6.09 1.53,13.92 1.53,16.72 z"/>
        <path :fill="iconColor"
              d="m 64.82,52.35 c 2.69,1.8 5.12,5.25 11.32,5.55 3.35,0.16 7.02,-1.46 9.55,-3.19 0.9,-0.61 1.62,-1.27 2.46,-1.83 l 3.79,2.38 c 0.59,0.41 0.59,0.48 1.3,0.85 l 12.04,4.86 c 7.42,2.77 8.3,7.99 10.35,15.14 0.93,3.24 2.3,11.4 2.3,14.6 l -8.16,5.53 c -4.21,2.56 -9.66,4.75 -14.47,6.18 -4.85,1.44 -6.77,1.72 -11.55,2.4 -9.86,1.41 -22.64,-0.83 -30.82,-4.11 l -3.45,-1.38 c -2.65,-1.2 -7.69,-4.08 -10.11,-5.72 -1.41,-0.96 -2.62,-2.13 -4.07,-2.9 0,-3.26 1.42,-11.32 2.25,-14.65 0.61,-2.44 1.19,-4.29 2,-6.32 3.25,-8.07 5.67,-7.73 14.56,-10.93 2.36,-0.85 6.36,-2.87 8.44,-4.43 0.93,-0.7 1.34,-1.43 2.25,-2.04 z"/>
    </g>
</template>

<script>
export default {
    name: "DepartmentIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>