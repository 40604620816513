<template>
    <v-data-table
        v-if="UserCan.DirectoryItem.Read"
        :footer-props="{
                showCurrentPage: true,
                itemsPerPageOptions: [15,20,30,50,-1],
                showFirstLastPage: true,
                firstIcon: $store.state.Icon.svgMdiArrowCollapseLeft,
                lastIcon: $store.state.Icon.svgMdiArrowCollapseRight
            }"
        :headers="headers"
        :items="dataList"
        :items-per-page="20"
        :loading="dataTableLoading"
        :search="search"
        class="elevation-1"
        dense
        loading-text="Загрузка данных... Пожалуйста подождите"
    >
        <template v-slot:top>
            <v-toolbar color="white" flat>
                <v-toolbar-title>{{ directoryData.name }}</v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>
                <v-text-field
                    v-model="search"
                    autocomplete="off"
                    clearable
                    dense
                    hide-details
                    outlined
                    placeholder="Найти"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <base-modal v-model="editDialog">
                    <directory-item-form
                        v-if="editDialog"
                        :dirid="dirid"
                        :props-edited-index="editedIndex"
                        :props-edited-item="editedItem"
                        :props-fields="directoryData.fields"
                        v-on:close="onCloseEditForm"
                    ></directory-item-form>
                </base-modal>
                <base-error-snackbar
                    v-model="Error"
                    v-bind:error-text="ErrorText"
                />
                <v-btn
                    :disabled="!UserCan.DirectoryItem.Edit"
                    class="mb-2"
                    color="normal"
                    raised
                    small
                    @click="newItem()"
                >
                    Создать
                </v-btn>

            </v-toolbar>
        </template>

        <template
            v-for="field in directoryData.fields"
            v-slot:[getSlotName(field)]="{ item }"
        >

            <template v-if="field.type.key === 'Money'">
                {{ item.data[field.name] / 100 }}
            </template>

            <template v-else-if="field.type.key === 'DateTime'">
                {{ dateTimeFormatter(item.data[field.name]) }}
            </template>

            <template v-else-if="field.type.key === 'Date'">
                {{ dateFormatter(item.data[field.name]) }}
            </template>

            <template v-else>
                {{ item.data[field.name] }}
            </template>

        </template>


        <template v-slot:item.actions="{ item }">
            <v-icon
                class="mr-2"
                small
                v-on:click.stop="editItem(item)"
            >
                <template v-if="UserCan.DirectoryItem.Edit">
                    {{ $store.state.Icon.svgMdiPencil }}
                </template>
                <template v-else>
                    {{ $store.state.Icon.svgMdiEye }}
                </template>
            </v-icon>
            <v-icon v-if="UserCan.DirectoryItem.Edit"
                    small
                    v-on:click.stop="deleteItem(item)"
            >
                {{ $store.state.Icon.svgMdiDelete }}
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";
import BaseErrorSnackbar from "../../../BaseComponents/BaseErrorSnackbar";
import DirectoryItemForm from "./DirectoryItemForm";

export default {
    name: "DirectoryItem",
    components: {BaseModal, BaseErrorSnackbar, DirectoryItemForm},
    props: {
        dirid: { //Идентификатор справочника, передается из маршрута
            required: true
        },
    },
    data: () => ({
        search: '',
        directoryData: {
            fields: null
        },
        dataList: [],
        dataTableLoading: true,
        editDialog: false,
        Error: false,
        ErrorText: null,
        editedIndex: -1,
        editedItem: null,
        headers: [
            {text: 'ИД', value: 'id', align: 'start', cellClass: 'directoryItemCell', sortable: true}
        ]
    }),
    computed: {},

    created() {
        this.getDirectoryData(this.dirid)
        this.getData(this.dirid)
    },

    methods: {

        dateTimeFormatter(date) {
            if (date) {
                const val = new Date(date)
                return val.toLocaleDateString('ru-RU', {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                })

            } else return ""
        },

        dateFormatter(date) {
            if (date) {
                const val = new Date(date)
                return val.toLocaleDateString('ru-RU', {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })

            } else return ""
        },

        getSlotName: function (field) {
            // console.log(field)
            return "item.data." + field.name
        },

        onCloseEditForm: function () {
            this.editDialog = false
            this.editedIndex = 0
            this.getData(this.dirid)
        },

        prepareHeaders(fields) {
            fields.sort((a, b) => a.following > b.following ? 1 : -1);
            fields.forEach(
                element => this.headers.push({
                    text: element.name,
                    value: 'data.' + element.name,
                    cellClass: 'directoryItemCell',
                    align: 'start',
                    sortable: true,
                })
            )
            this.headers.push({text: 'Дейстивя', value: 'actions', sortable: false, align: 'start', cellClass: '',})
        },

        unchangedFormat(value) {
            return value
        },

        dateTimeFormat(value) {
            return 'DT' + value
        },

        getDirectoryData(directoryID) {
            axios.get('/api/directory/dir/' + directoryID)
                .then(response => {
                    this.directoryData = response.data
                })
                .then(() => {
                    this.prepareHeaders(this.directoryData.fields)
                })
                .catch(error => {
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = "Не удалось загрузить данные справочника."
                    }
                })
                .finally(() => (this.dataTableLoading = false));
        },

        getData(directoryID) {
            axios.get('/api/directoryitem/index/' + directoryID)
                .then(response => {
                    // this.columnFlag = response.data.columnFlag
                    this.dataList = response.data
                })
                .catch(error => {
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = "Не удалось загрузить данные справочника."
                    }
                })
                .finally(() => (this.dataTableLoading = false));
        },

        newItem() {
            // console.log(this.directoryData.fields)
            this.directoryData.fields.sort((a, b) => a.following > b.following ? 1 : -1);
            this.editedIndex = -1
            this.editedItem = {
                directory_id: this.dirid,
                data: {},
            }
            this.directoryData.fields.forEach((element) => {
                this.editedItem.data[element.name] = null
            })
            // console.log(this.editedItem )
            this.editDialog = true
        },
        editItem(item) {
            if (typeof (item) === "object") {
                this.editedIndex = item.id
                this.editedItem = item
            } else {
                this.editedIndex = -1
                this.editedItem = {}
            }
            this.editDialog = true
        },
        deleteItem(item) {
            if (this.UserCan.DirectoryItem.Edit) {
                if (item && item.id) {
                    if (confirm("Удалить элемент справочника?")) {
                        axios.delete('/api/directoryitem/' + item.id)
                            .then()
                            //если есть ошибки, то выводим текст ошибки
                            .catch(error => {
                                //console.log(error.request.status)
                                if (error) {
                                    this.Error = true
                                    this.ErrorText = "Не удалось удалить элемент справочника."
                                }
                            })
                            .finally(() => {
                                this.getData(this.dirid)
                            });
                    }
                }
            }
        },
    }
}
</script>

<style scoped>
.v-data-table :deep(.directoryItemCell) {
    padding-left: 5px;
    padding-right: 2px;
}


</style>
