<template>
    <base-priority-icon
        v-if="id"
        :height="height"
        :icon="icon"
        :icon-name="iconName"
        :width="width"
    />
</template>

<script>
import BasePriorityIcon from "../components/BaseComponents/BasePriorityIcon";

export default {
    name: "PriorityIcon",
    components: {
        BasePriorityIcon
    },
    props: {
        id: {
            required: true,
        },
        width: {
            type: [Number, String],
            default: 25
        },
        height: {
            type: [Number, String],
            default: 25
        },
    },

    data: function () {
        return {
            icons: [
                {
                    id: 1,
                    name: 'Низкий',
                    icon: 'LowPriority'
                },
                {
                    id: 2,
                    name: 'Средний',
                    icon: 'MediumPriority'
                },
                {
                    id: 3,
                    name: 'Высокий',
                    icon: 'HighPriority'
                },
                {
                    id: 4,
                    name: 'Просрочена',
                    icon: 'ExpiredPriority'
                },
            ]
        }
    },

    computed: {
        item: function () {
            return this.id ? this.icons.find(item => item.id === this.id) : null
        },
        icon: function () {
            return this.item ? this.item.icon : null
        },
        iconName() {
            return this.item ? this.item.name : null
        }
    }
}
</script>

<style scoped>

</style>