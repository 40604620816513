<template>
    <g id="Слой_x0020_1">
        <g transform="scale(.60208 .59855)">
            <path class="fil0" d="M13.16 14 26.18 0l3.52 23.14 3.72 23.1-16.68-9.35L0 27.78z"/>
            <path class="fil0"
                  d="m28.06 30.83-1.82-16.85c46.19-2.2 67.59 23.17 71.91 67.54.36 4.18-15.8 6.78-17.19 1.8-2.6-31.77-17.96-50.42-52.9-52.5Z"/>
            <rect class="fil1" height="15.88" rx="1.66" ry="1.66" width="15.88" x="118.49" y="88.24"/>
            <rect class="fil1" height="15.88" rx="1.66" ry="1.66" width="15.88" x="118.49" y="112.87"/>
            <rect class="fil1" height="15.88" rx="1.66" ry="1.66" width="15.88" x="62.8" y="112.87"/>
            <rect class="fil1" height="15.88" rx="1.66" ry="1.66" width="15.88" x="90.65" y="112.87"/>
        </g>
        <path class="fil1"
              d="M95.52 100H25.397c-2.463 0-4.48-2.005-4.48-4.453V33.62a2.61 2.61 0 0 1 2.613-2.598h2.866c1.44 0 2.613 1.173 2.613 2.598v56.7c0 .898.74 1.634 1.644 1.634H90.27c.903 0 1.644-.736 1.644-1.634V43.604H73.249a1.87 1.87 0 0 1-1.866-1.855v-2.023c0-1.024.843-1.856 1.866-1.856h18.665V25.935c0-.898-.74-1.634-1.644-1.634H59.317a2.61 2.61 0 0 1-2.613-2.598v-2.849a2.61 2.61 0 0 1 2.613-2.597h14.107V8.015c0-1.275 1.054-2.323 2.336-2.323h5.636c1.282 0 2.336 1.048 2.336 2.323v8.242H95.52c2.462 0 4.479 2.005 4.479 4.453v74.83c0 2.449-2.017 4.454-4.48 4.454z"
              style="stroke-width:.600315"/>
    </g>
</template>

<script>
export default {
    name: "NotCreated"
}
</script>


<style scoped>
.fil0 {
    fill: #285473
}

.fil1 {
    fill: #9d9e9e
}
</style>
