<template>
    <v-select
        :value="localData"
        :clearable="!disabled && !read_only"
        :disabled="disabled"
        :items="extra_attributes"
        :readonly="read_only"
        class="task-text-field pt-0 mt-0"
        hide-details
        multiple
        no-resize
        spellcheck="false"
        v-on:change="$emit('change', $event)"
    ></v-select>
</template>

<script>
export default {
    name: "MultiSelect",
    props: {
        value: null,
        extra_attributes: null,
        read_only: false,
        disabled: false,
    },
    computed: {
        localData: function () {
            return Array.isArray(this.value) ? this.value : []
        }
    },
}
</script>

<style scoped>
div.task-text-field {
    font-size: .85rem;
}

div.task-text-field :deep(input[type="text"]) {
    padding: 3px 0 3px;
}

div.task-text-field :deep(input[disabled="disabled"]) {
    color: rgba(0, 0, 0, 0.87);
}
</style>
