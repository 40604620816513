<template>
    <g transform="matrix(5.3475934,0,0,5.3025153,0,11)">
        <rect
            id="rect7"
            class="fil0"
            height="14.71"
            rx="1.89"
            ry="1.89"
            width="18.700001"
            x="0"
            y="0"/>
        <path
            class="fil1"
            d="M 9.35,9.31 C 8.48,9.25 6.91,7.76 4.96,6.36 3.58,5.37 2.51,4.56 1.91,3.71 1.32,2.87 1.2,2.01 1.4,1.47 1.45,1.39 1.53,1.28 1.71,1.28 h 7.64 7.64 c 0.18,0 0.27,0.1 0.31,0.19 0.2,0.53 0.08,1.4 -0.51,2.24 -0.59,0.84 -1.67,1.66 -3.05,2.65 -1.95,1.4 -3.53,2.89 -4.39,2.95 z"/>
        <polygon
            class="fil1"
            points="2.96,10.5 1.53,10.5 9.35,4.17 17.17,10.5 15.74,10.5 9.35,5.68 "/>
    </g>
</template>

<script>
export default {
    name: "NotReadStatus"
}
</script>

<style scoped>
.fil0 {
    fill: #285473
}

.fil1 {
    fill: #fefefe
}
</style>
