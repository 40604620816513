<template>
    <g transform="matrix(18.074087,0,0,20.070425,0.09342933,0)">
        <g>
            <path
                class="fil0"
                d="M 5.29,0.15 C 5.28,0.16 5.27,0.18 5.26,0.19 3.57,2.54 3.08,4.42 2.92,4.87 2.89,4.99 3.14,5.03 3.18,4.91 3.36,4.52 3.78,2.63 5.52,0.21 5.57,0.1 5.36,0.04 5.29,0.15 Z"/>
            <path
                class="fil0"
                d="M 4.88,0.19 C 4.9,0.16 4.89,0.07 4.84,0.03 4.78,-0.01 4.71,-0.01 4.65,0.03 3.57,0.74 2.39,2.57 2.04,3.24 1.61,2.78 0.57,2.16 0.19,2.07 0.08,2.05 -0.05,2.14 0.01,2.27 0.06,2.4 0.71,2.86 1.21,3.41 1.75,4.01 2.19,4.76 2.29,4.93 2.35,5.01 2.48,4.99 2.52,4.91 2.7,4.52 3.13,2.62 4.87,0.19 Z"/>
        </g>
    </g>
</template>

<script>
export default {
    name: "DoneStatus"
}
</script>

<style scoped>
.fil0 {
    fill: #285473
}

.fil1 {
    fill: #fefefe
}
</style>
