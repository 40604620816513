<template>
    <g transform="matrix(3.15985 0 0 3.06748 4 0)">
        <path :fill="iconColor"
              d="M.23 0h4.91c.13 0 .24.1.24.23v7.68c0 .13-.11.24-.24.24H.23C.1 8.15 0 8.04 0 7.91V.23C0 .1.1 0 .23 0z"/>
        <path :fill="whiteColor" d="M1.13.96h3.12v.82H1.13z"/>
        <path :fill="whiteColor" d="M1.13 2.48h3.12v.82H1.13v-.82z"/>
        <path :fill="whiteColor" d="M1.13 4h3.12v.82H1.13z"/>
        <path :fill="whiteColor"
              d="M.6 8.15v-.16c0-.09.07-.17.17-.17h.46c.04-.18.11-.34.21-.48l-.33-.33a.175.175 0 010-.25l.21-.2c.06-.07.18-.07.24 0l.33.33c.15-.1.31-.16.49-.2v-.46c0-.1.07-.18.17-.18h.29c.1 0 .17.08.17.18v.46c.18.04.34.11.49.21l.32-.33c.07-.07.18-.07.25 0l.2.2c.07.07.07.18 0 .25l-.32.33c.09.15.16.31.19.48h.46c.1 0 .18.08.18.18v.14H3.5c0-.45-.36-.82-.81-.82-.45 0-.82.37-.82.82z"/>
    </g>
</template>

<script>
export default {
    name: "CustomDataIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
