// initial state
const state = {
    status: false,
    emailVerified: false,
}

// getters
const getters = {
    isLogged: state => {
        return state.status
    }
}


// mutations
const mutations = {

    setUserStatus(state, data) {
        state.status = data
    },

    setEmailErified(state, data) {
        state.emailVerified = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
