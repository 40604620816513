<template>
    <div ref="observedElement">
        <template v-if="show">
            <v-sheet
                v-if="attachment.length"
                class="mx-auto p-0"
                width="100%"
            >
                <v-slide-group
                    ref="slideGroup"
                    v-model="model"
                    center-active
                    class="pa-0"
                    mandatory
                    show-arrows
                    value="id"
                >
                    <v-slide-item
                        v-for="attach in localAttachment"
                        :key="attach.uuid"
                        v-slot:default="{ active, toggle }"

                    >
                        <v-card
                            :color="active ? 'grey lighten-1' : ''"
                            class="ma-1"
                            height="200"
                            width="240"
                            v-on:click="toggle();clickSlider();"
                        >
                            <v-img
                                :src="getImgSrc(attach)"
                                :srcset="getImgThumbnail(attach)+' 240w'"
                                contain
                                height="200"
                                width="240"
                            >
                                <div class="img-slot-wrapper">
                                    <div class="img-slot-download">
                                        <v-btn
                                            :replace="false"
                                            icon
                                            v-on:click.stop="downloadImg(attach.uuid)"
                                        >
                                            <v-icon>{{ $store.state.Icon.svgMdiDownload }}</v-icon>
                                        </v-btn>
                                    </div>
                                    <div class="img-slot-checkbox">
                                        <v-checkbox
                                            v-if="!disabled"
                                            :id="attach.uuid"
                                            :ripple="false"
                                            class="mt-0"
                                            color="corpColor"
                                            v-on:click.stop="onClickImgButton"
                                        ></v-checkbox>
                                    </div>
                                    <div class="img-slot-name">{{ attach.name }}</div>
                                </div>
                            </v-img>
                        </v-card>
                    </v-slide-item>
                </v-slide-group>

            </v-sheet>
            <base-full-screen-modal
                v-model="carouselDialog">
                <v-card>
                    <v-card-actions class="p-0">
                        <v-spacer></v-spacer>
                        <v-btn icon large style="z-index: 20001" @click="closeCarouselDialog">
                            <v-icon>
                                {{ $store.state.Icon.svgMdiClose }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-carousel
                        v-model="model"
                        continuous
                        height="90vh"
                        hide-delimiter-background
                        hide-delimiters
                        progress
                    >
                        <v-carousel-item
                            v-for="(item,i) in localAttachment"
                            v-if="item.mime_type.includes('image')
                            || item.mime_type.includes('video')
                            || item.mime_type.includes('application/pdf')
                            || item.mime_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')"
                            :key="i"
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                        >

                            <base-image-viewer
                                v-model="item.mime_type.includes('image')"
                                :src="item.original_url"
                            />


                            <base-pdf-viewer
                                v-model="item.mime_type.includes('application/pdf')"
                                :src="item.original_url"
                            />


                            <base-doc-viewer
                                v-model="item.mime_type.includes('openxmlformats-officedocument')"
                                :src="item.original_url"
                            />


                            <v-sheet
                                v-if="item.mime_type.includes('video')"
                                height="100%"
                                tile
                            >
                                <v-row
                                    align="center"
                                    class="fill-height"
                                    justify="center"
                                >
                                    <div class="player">
                                        <video autoplay controls height="320" muted>
                                            <source :src="item.original_url" :type="item.mime_type">
                                        </video>
                                    </div>
                                </v-row>
                            </v-sheet>


                        </v-carousel-item>
                    </v-carousel>
                </v-card>

            </base-full-screen-modal>
        </template>
    </div>
</template>

<script>
import BaseFullScreenModal from "./BaseFullScreenModal";
import BaseImageViewer from "./BaseImageViewer/BaseImageViewer";
import BaseDocViewer from "./BaseDocViewer/BaseDocViewer";
import BasePdfViewer from "./BasePdfViewer/BasePdfViewer";
import shownWhenIntersectionComponent from "../../mixins/shownWhenIntersectionComponent";

export default {
    name: "BaseImageList",
    mixins: [shownWhenIntersectionComponent],
    components: {
        BaseFullScreenModal, BaseImageViewer, BaseDocViewer, BasePdfViewer
    },
    props: {
        attachment: {
            type: Array,
            default: [],
            required: true
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    data: function () {
        return {
            model: null,
            carouselModel: null,
            carouselDialog: false,
            checkboxModel: null,
            localAttachment: [],
            selectedImg: [],
            maxScrollOffset: null,
        }
    },
    watch: {
        attachment: {
            deep: true,
            immediate: true,
            handler() {
                if (this.attachment.length) {
                    this.localAttachment = this.attachment
                }
            },
        },

    },
    methods: {
        getImgSrc(attach) {
            if (attach.mime_type.includes('image')) {
                return attach.original_url
            }
            if (attach.mime_type.includes('video')) {
                return this.getImgStubPath()
            }
            return this.getFileStubPath()
        },

        getImgThumbnail(attach) {
            if (attach.preview_url) {
                return attach.preview_url
            }

            return this.getImgSrc(attach)
        },


        clickSlider() {
            this.carouselDialog = !this.carouselDialog
        },

        downloadImg(id) {
            axios.get('/api/img/download/' + id)
                .then((response) => {
                    const link = document.createElement("a");
                    link.href = response.data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(error => {
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = error.response.data.message
                    }
                });
        },

        openCarouselDialog() {
            this.carouselDialog = true
        },
        closeCarouselDialog() {
            this.carouselDialog = false
        },

        getImgStubPath() {
            return this.getPath("img/play.svg")
        },

        getFileStubPath() {
            return this.getPath("img/file.svg")
        },

        getPath(path) {
            //хитрость для определения хоста текущей страницы. Взято вот отсюда https://gist.github.com/jlong/2428561
            let parser = document.createElement('a')
            parser.href = path
            return parser.protocol + '//' + parser.hostname + "/" + path
        },

        onClickImgButton(e) {
            if (e.target.id && e.target.ariaChecked === "false") {
                this.selectedImg.push(e.target.id)
            }
            if (e.target.id && e.target.ariaChecked === "true") {
                let index = this.selectedImg.indexOf(e.target.id)
                this.selectedImg.splice(index, 1)
            }
            this.$emit('selectFile', this.selectedImg)
        }
    }
}
</script>


<style scoped>

.img-slot-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto auto;
    height: 100%;
}

.img-slot-download {
    display: grid;
    justify-content: start;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

.img-slot-checkbox {
    display: grid;
    justify-content: end;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.img-slot-name {
    display: none;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    align-items: end;
    justify-content: center;
}

.grab-bing {
    cursor: grab;
}

.grab-bing:active {
    cursor: grabbing;
}

</style>
