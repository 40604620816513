<template>
    <v-snackbar
        v-if="validationErrors"
        v-model="errorSnackbar"
        :timeout="-1"
        color="error"
        multi-line
        style="z-index: 999999"
        top
    >
        <div>
            <ul>
                <li v-for="(value, key, index) in validationErrors">{{ value }}</li>
            </ul>
        </div>

        <template v-slot:action="{ attrs }">
            <v-btn
                text
                @click="closeError"
            >
                <v-icon>
                    {{ $store.state.Icon.svgMdiClose }}
                </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "MainErrors",
    data() {
        return {
            errorSnackbar: false,
        }
    },
    computed: {
        errors() {
            return this.$store.getters["getErrors"]
        },
        validationErrors() {
            if (this.errors) {
                let errors = Object.values(this.errors);
                errors = errors.flat();
                return errors;
            }
            return null
        }
    },
    watch: {
        errors: function (val) {
            this.errorSnackbar = !!val
        },
    },
    methods: {
        closeError() {
            this.errorSnackbar = false
            this.$store.commit('setNoErrors')
        },
    }
}
</script>

<style scoped>

</style>
