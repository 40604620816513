<template>
    <svg :aria-labelledby="iconName"
         :height="height"
         :width="width"
         role="presentation"
         style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
         viewBox="0 0 100 100"
         xml:space="preserve"
         xmlns="http://www.w3.org/2000/svg"
    >
        <title
            :id="iconName"
            lang="ru"
        >{{iconName}}</title>
        <g>
            <component :is="icon"></component>
        </g>
    </svg>
</template>

<script>
import LowPriority from "../Icons/TasksPriority/LowPriority";
import MediumPriority from "../Icons/TasksPriority/MediumPriority";
import HighPriority from "../Icons/TasksPriority/HighPriority";
import ExpiredPriority from "../Icons/TasksPriority/ExpiredPriority";

export default {
    name: "BasePriorityIcon",
    components: {
        LowPriority,
        MediumPriority,
        HighPriority,
        ExpiredPriority,
    },
    props: {
        icon: {
            required: true,
            type: String
        },
        iconName: {
            type: String,
            default: 'icon'
        },
        width: {
            type: [Number, String],
            default: 25
        },
        height: {
            type: [Number, String],
            default: 25
        },
    }
}
</script>

<style scoped>

</style>
