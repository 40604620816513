<template>
    <v-col v-if="value && value.length"
           class="px-1 py-0"
           cols="12"
    >
        <base-image-list
            v-if="value && value.length"
            :attachment="value"
            v-on:selectFile="$emit('selectFile', $event)"
        />
    </v-col>
</template>

<script>
import BaseImageList from "../BaseComponents/BaseImageList.vue";

export default {
    name: "SystemDefectMedia",
    components: {BaseImageList},
    props: {
        value: {
            required: true,
            type: Array,
            nullable: true,
        }
    },
    data: function () {
        return {
            selectedFile: null,
        }
    },
}
</script>

<style scoped>

</style>
