export async function getPreparedHeaders(type, addExpander = false) {
    let headers
    headers = await getHeaders(type)
    headers = prepareHeaders(headers)
    headers = removeHiddenTitles(headers)
    if (addExpander) {
        headers = addExpanderColumn(headers)
    }
    return headers
}

export async function getHeaders(type) {
    return axios.get('/api/header/' + type)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            throw error;
        });
}

function prepareHeaders(headers) {
    return headers.filter(header => {
        if (header.value === 'actions') {
            return true
        }
        return header.show === true
    });
}

function addExpanderColumn(headers) {
    let expandHead = {text: '', value: 'data-table-expand', sortable: false}
    headers.push(expandHead)
    return headers
}

function removeHiddenTitles(headers) {
    return headers.map(header => {
        if (header['showText'] !== undefined && header['showText'] === false) {
            header['text'] = ''
        }
        return header
    })

}

export function prepareFieldsData(headers, entityName) {
    if (headers) {
        let data = []
        Object.entries(headers).forEach(([key, value]) => {
            if (value
                && value.value !== 'data-table-expand'
                && value.value !== 'actions'
            ) {
                data.push(value.value)
            }
        });
        return `fields[${entityName}]=` + data.join(',')
    }
}

export function prepareFilterData(filter) {
    if (filter) {
        let data = []
        Object.entries(filter).forEach(([key, value]) => {
            if (value) {
                data.push(`filter[${key}]=${value.join(',')}`)
            }
        });
        return data.join('&')
    }
    return null
}
