<template>
    <g
        transform="matrix(0.04098764,0,0,0.04470606,0.01918025,0)">
        <path
            :fill="iconColor"
            d="m 72.59,615.18 69.06,-251.63 76.94,0.07 15.67,25.8 c 1.96,3.46 3.27,4.59 5.17,7.76 l 65.53,98.64 65.53,-98.64 c 1.9,-3.16 3.21,-4.29 5.17,-7.76 l 15.67,-25.8 76.94,-0.07 69.06,251.63 -232.38,0.02 -232.38,-0.02 z M 304.97,394.26 c -24.1,-28.41 -67.49,-97.28 -92.37,-158.77 -12.39,-30.62 -19.58,-63.63 -18.71,-80.51 6.4,-56.5 54.22,-99.03 111.07,-99.05 h 0.02 0.02 c 56.85,0.02 104.67,42.54 111.07,99.05 0.87,16.88 -6.32,49.89 -18.71,80.51 -24.88,61.49 -68.27,130.36 -92.37,158.77 z M 304.97,0 c -70.6,0.84 -128.35,42 -155.81,106.34 -24.65,57.76 -6.87,105.87 16.36,158.17 3.29,7.42 19.87,38.77 21.05,43.14 l -86.65,-0.02 -99.9,363.42 304.97,-0.07 304.97,0.07 -99.9,-363.42 -86.65,0.02 c 1.18,-4.38 17.76,-35.73 21.05,-43.14 23.22,-52.3 41.01,-100.41 16.36,-158.17 C 433.35,42 375.61,0.84 305.01,0 Z"/>
        <path
            :fill="iconColor"
            d="m 304.97,211.54 c -24.1,0 -43.64,-19.54 -43.64,-43.65 0,-24.1 19.54,-43.65 43.64,-43.65 24.1,0 43.64,19.54 43.64,43.65 0,24.1 -19.54,43.65 -43.64,43.65 z"/>
    </g>
</template>

<script>
export default {
    name: "ObjectMapIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
