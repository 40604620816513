<template>
    <g transform="matrix(2.05255 0 0 2.02128 0 3)">
        <path :fill="iconColor"
              d="M.62.02h8.16c.34 0 .62.28.62.62v8.12c0 .34-.28.62-.62.62H.62C.28 9.38 0 9.1 0 8.76V.64C0 .3.28.02.62.02z"/>
        <path :fill="whiteColor"
              d="M0 5.01v-.65c0-.22.18-.39.39-.39h1.05c.08-.4.24-.76.45-1.09l-.73-.73a.405.405 0 010-.56l.46-.45c.15-.16.4-.16.56 0l.73.73c.33-.2.7-.36 1.09-.44V.39c0-.21.18-.39.39-.39h.65c.22 0 .39.18.39.39v1.05c.4.08.76.24 1.09.45l.73-.73c.15-.15.4-.15.56 0l.45.46c.16.15.16.4 0 .56l-.73.73c.21.33.36.7.44 1.09h1.04c.21 0 .39.18.39.39v.65c0 .22-.18.39-.39.39H7.96c-.08.4-.24.76-.45 1.09l.73.73c.15.15.15.4 0 .56l-.46.45c-.15.16-.4.16-.55 0l-.74-.73c-.33.21-.7.36-1.09.44v1.04c0 .21-.18.39-.39.39h-.65a.39.39 0 01-.39-.39V7.96c-.4-.08-.76-.24-1.09-.45l-.73.73c-.15.15-.4.15-.56 0l-.45-.46a.375.375 0 010-.55l.73-.74c-.2-.33-.36-.7-.44-1.09H.39C.18 5.4 0 5.22 0 5.01z"/>
        <path :fill="iconColor"
              d="M4.7 2.11a2.59 2.59 0 11-.001 5.181A2.59 2.59 0 014.7 2.11zm0 .32c1.25 0 2.27 1.02 2.27 2.27S5.95 6.97 4.7 6.97 2.43 5.95 2.43 4.7 3.45 2.43 4.7 2.43z"/>
        <path :fill="iconColor" d="M9.41 4.39h2.39v.62H9.41z"/>
        <path :fill="iconColor" d="M0 0h2.39v.41H0z" transform="matrix(0 -1.60342 1.51172 0 11.179 4.854)"/>
        <circle :fill="iconColor" cx="4.7" cy="4.7" r=".94"/>
        <circle :fill="iconColor" cx="11.49" cy=".7" r=".69"/>
    </g>
</template>

<script>
export default {
    name: "TasksTimetableIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
