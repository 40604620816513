<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :disabled="readonly || disabled"
        :nudge-right="40"
        :return-value.sync="time"
        max-width="290px"
        min-width="290px"
        offset-y
        transition="scale-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="time"
                :class="classObject"
                :clearable="!disabled && !readonly"
                :disabled="disabled"
                :hide-details="hideDetails"
                :label="label"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-time-picker
            v-if="menu"
            v-model="time"
            format="24hr"
            full-width
            no-title
            @click:minute="$refs.menu.save(time)"
        ></v-time-picker>
    </v-menu>
</template>

<script>
export default {
    name: "BaseTimePicker",
    props: {
        value: null,
        label: '',
        hideDetails: false,
        readonly: false,
        disabled: false,
        classObject: {}
    },
    data() {
        return {
            time: null,
            menu: false,
        }
    },
    watch: {
        value: {
            handler: function (val) {
                this.time = val
            },
            immediate: true
        },
        time: function (val) {
            this.$emit('input', val)
            this.$emit('change', val)
        }
    },
}
</script>

<style scoped>
.font-size {
    font-size: .85rem;
}
</style>
