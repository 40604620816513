<template>
    <div>
        <base-modal
            v-model="BaseDialog"
            :fullscreen="fullscreen"
            :scrollable="false"
            max-width="1000px"
        >
            <task-list-view-form
                v-bind:props-edited-item="editedItem"
                v-on:close="onCloseForm"
            />
        </base-modal>

        <v-menu
            v-model="showMenu"
        >
            <template v-slot:activator="{on}">
                <base-icon-btn
                    :icon="$store.state.Icon.svgMdiFilterOutline"
                    :on="on"
                    title="Настройка списка задач"
                />
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in list"
                    :key="index"
                    @click="showDialog(item)"
                >
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="showDialog({ id: -1})"
                >
                    <v-list-item-title class="font-weight-black">Добавить отображение</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import {VList, VListItem, VListItemTitle, VMenu} from 'vuetify/lib'
import BaseModal from "../BaseComponents/BaseModal";
import BaseIconBtn from "../../BaseComponents/BaseIconBtn";
import TaskListViewForm from "./TaskListViewForm";

export default {
    name: "TaskListFilter",
    components: {
        BaseModal, BaseIconBtn, TaskListViewForm,
        VMenu, VList, VListItem, VListItemTitle
    },
    props: {
        filterList: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data: function () {
        return {
            showMenu: false,
            BaseDialog: false,
            editedItem: Object, //Данные редактируемого пункта, передаем их в модальное окно (в форму редактирования)
            list: () => {
                return []
            },
            fullscreenWidth: 700,
        }
    },
    computed: {
        fullscreen: function () {
            return this.$vuetify.breakpoint.width < this.fullscreenWidth
        },
    },
    mounted() {
        this.getView()
    },

    methods: {
        getView() {
            axios.get('/api/tasklist/views')
                .then(response => {
                    this.list = response.data
                    this.$emit('update:filterList', this.list)
                })
                .catch(error => {
                    this.showError(error)
                })
            ;
        },
        showDialog(item) {
            this.editedItem = item
            this.BaseDialog = true
        },
        onCloseForm() {
            this.BaseDialog = false
            this.editedItem = {}
            this.$emit('close')
            this.getView()
        },
    }
}
</script>

<style scoped>

</style>