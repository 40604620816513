<template>
    <v-text-field
        v-model.number="model"
        :clearable="clearable"
        :disabled="disabled"
        :hide-details="hideDetails"
        :label="label"
        :required="required"
        :rules="rules"
        no-resize
        type="number"
        v-on:blur="$emit('blur')"
    >
        <template v-slot:label>
            {{ label }}<sup v-if="required">*</sup>
        </template>
    </v-text-field>
</template>

<script>
import MainFieldsType from "./MainFieldsType";

export default {
    name: "Number",
    extends: MainFieldsType,

}
</script>

<style scoped>

</style>
