<template>
    <v-col v-if="value" class="pt-4" cols="12">
        <b>Комментарии:</b><br>
        <v-simple-table
            dense
        >
            <tbody>
            <tr v-for="item in value">
                <td>
                    <b>{{ item.created_at }}</b> -
                    <b>{{ item.user?.short_name }}</b> -
                    {{ item.body }}
                </td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-col>
</template>

<script>

export default {
    name: "SystemDefectInfoComments",
    props: {
        value: {
            default: null,
        }
    },
}
</script>


