<template>
    <div>
        <base-modal v-model="value">
            <v-card>
                <v-card-title>
                    <span class="headline">Плановое время выполнения</span>
                </v-card-title>
                <v-card-text>
                    <task-plan-items :value="currentPlan"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black" text @click="addDialog=true">Добавить</v-btn>
                    <v-btn color="blue" text @click="close()">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </base-modal>

        <base-modal v-if="value" v-model="addDialog">
            <v-card>
                <v-card-title>
                    <span class="headline">Добавить план</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="addValue.start"
                        :max="taskDeadline"
                        :min="taskCreatedAt"
                        class="pb-2"
                        hide-details
                        label="Начало" no-resize
                        type="datetime-local"
                    />

                    <v-text-field
                        v-model="addValue.end"
                        :disabled="!addValue.start"
                        :max="taskDeadline"
                        :min="addValue.start"
                        class="pb-2"
                        hide-details
                        label="Конец" no-resize
                        type="datetime-local"
                    />

                    <base-autocomplete-with-request
                        v-model="addValue.user"
                        :hide-details="true"
                        :multiple="false"
                        :return-object="true"
                        class="pb-2"
                        item-text="short_name"
                        item-value="id"
                        label="Исполнитель"
                        placeholder="Исполнитель"
                        url="user/simpleList"
                        v-on:loaded="executorLoaded"
                    />

                    <base-user-combobox
                        v-model="addValue.assistants"
                        :multiple="true"
                        label="Помощники"
                    />

                    <v-textarea
                        v-if="currentPlan.length"
                        v-model="addValue.reason"
                        auto-grow
                        class="pb-2"
                        hide-details
                        label="Причина переноса"
                        placeholder="Причина переноса"
                        rows="1"
                    />

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" text @click="closeAddDialog()">Отмена</v-btn>
                    <v-btn :disabled="!canSave" color="blue" text @click="saveNewPlan">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </base-modal>

    </div>

</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";
import TaskPlanItems from "./TaskPlanItems";
import BaseAutocompleteWithRequest from "../../../BaseComponents/BaseAutocompleteWithRequest";
import BaseUserCombobox from "../../../../BaseComponents/BaseUserCombobox";

function defaultAddValue() {
    return {
        start: null,
        end: null,
        user: null,
        reason: null,
        assistants: null
    }
}

export default {
    name: "TaskPlan",
    components: {
        BaseModal, TaskPlanItems, BaseAutocompleteWithRequest, BaseUserCombobox
    },
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
    },
    data: () => ({
        addDialog: false,
        addValue: defaultAddValue(),
        defaultTime: {
            default_start_time: '09:00',
            default_end_time: '18:00'
        }
    }),

    created() {
        this.getDefaultTime()
    },

    computed: {
        taskId: function () {
            return this.$store.getters["TaskData/getTaskId"]
        },

        disabled: function () {
            return this.$store.getters["TaskData/getDisabled"]
        },

        currentPlan: function () {
            return this.$store.getters["TaskData/getPlan"]
        },

        taskCreatedAt: function () {
            const date = this.$store.getters["TaskData/getTaskCreatedAt"]
            if (date) {
                return date.toString().substring(0, 16)
            }
            return null
        },
        taskDeadline: function () {
            const date = this.$store.getters["TaskData/getTaskDeadline"]
            if (date) {
                return date.toString().substring(0, 16)
            }
            return null
        },

        canSave: function () {
            let reason = false
            if (!this.currentPlan.length) {
                reason = true
            }
            if (this.currentPlan.length && this.addValue.reason) {
                reason = true
            }
            return this.addValue.start && this.addValue.end && this.addValue.user && this.addValue.user.id && reason
        }
    },
    methods: {

        executorLoaded() {
            this.addValue.user = this.$store.getters["TaskData/getTaskExecutor"]
        },

        close() {
            this.$emit("input", false)
            this.$store.dispatch('TaskData/updateTaskData')
        },

        closeAddDialog() {
            this.addDialog = false
            this.addValue = defaultAddValue()
            this.setTime()
        },

        setTime() {
            let date = new Date()
            this.addValue.start = date.toISOString().substring(0, 11) + this.defaultTime.default_start_time
            this.addValue.end = date.toISOString().substring(0, 11) + this.defaultTime.default_end_time
        },

        getDefaultTime() {
            axios.get('/api/plan/settings/defaultTime')
                .then(response => {
                    this.defaultTime = Object.assign({}, response.data)
                    this.setTime()
                })
                .catch(error => {
                    this.showError(error)
                })
            ;
        },

        prepareAssistants(assistants) {
            if (assistants && Array.isArray(assistants) && assistants.length) {
                let result = []
                assistants.forEach(function (item) {
                    result.push(item.id)
                })
                return result
            }
            return null
        },

        saveNewPlan() {
            if (this.canSave) {
                axios.post('/api/plan/newPlan/' + this.$store.getters["TaskData/getTaskId"], {
                    start: this.addValue.start,
                    end: this.addValue.end,
                    user_id: this.addValue.user.id,
                    reason: this.addValue.reason,
                    assistants: this.prepareAssistants(this.addValue.assistants)
                })
                    .then(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                        this.addDialog = false
                        this.addValue = defaultAddValue()
                        this.setTime()
                        this.addValue.user = this.$store.getters["TaskData/getTaskExecutor"]
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                ;
            }

        }
    }
}
</script>

<style scoped>

</style>