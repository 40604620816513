<template>
    <v-col class="px-1 py-0" cols="12">
        <v-text-field
            :clearable="clearable"
            :dense="dense"
            :disabled="disabled"
            :hide-details="hideDetails"
            :min="min"
            :readonly="readonly"
            :rules="rules"
            :type="type"
            :validate-on-blur="validateoOnBlur"
            :value="value"
            v-on:input="$emit('input', $event)"
        >
            <template v-slot:label>
                {{ label }}<sup v-if="required">*</sup>
            </template>
            <template v-if="append" v-slot:append>
                {{ append }}
            </template>
        </v-text-field>
    </v-col>
</template>

<script>
import {VCol, VTextField} from 'vuetify/lib'

export default {
    name: "EntityFormTextField",
    components: {
        VCol, VTextField
    },
    props: {
        value: {
            required: true
        },
        label: {
            required: true
        },
        rules: {
            required: false
        },
        required: {
            required: false
        },
        type: {
            default: 'text',
            required: false
        },
        append: {
            required: false
        },
        clearable: {
            required: false,
            default: false,
        },
        min: {
            required: false,
            default: null
        },
        hideDetails: {
            required: false,
            default: false,
        },
        validateoOnBlur: {
            required: false,
            default: true,
        },
        disabled: {
            required: false,
            default: false,
        },
        readonly: {
            required: false,
            default: false,
        },
        dense: {
            required: false,
            default: false,
        }
    },
}
</script>

<style scoped>

</style>