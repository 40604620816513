<template>
    <div>
        <base-icon-btn
            :icon="$store.state.Icon.svgMdiCogOutline"
            title="Настройка заголовков таблицы"
            @click="openDialog()"
        />

        <base-modal
            v-model="dialog"
            :maxWidth="'400px'"
            :scrollable="false"
        >
            <v-card v-if="dialog">
                <v-card-title>
                    <span class="headline">Настройка заголовков</span>
                </v-card-title>
                <v-card-text v-if="headers">
                    <draggable :list="headers" class="list-group draggable" handle=".handle" tag="ul">
                        <li
                            v-for="element in headers"
                            v-if="element.value !== 'actions'"
                            :key="element.value"
                            class="list-group-item draggable"
                        >

                            <v-checkbox
                                v-model="element.show"
                                :label="element.text"
                                :ripple="false"
                                dense
                                hide-details
                            >
                                <template v-slot:prepend>
                                    <v-icon class="handle">{{ $store.state.Icon.svgMdiDotsVertical }}</v-icon>
                                </template>

                            </v-checkbox>

                        </li>
                    </draggable>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" text @click="close()">
                        Отмена
                    </v-btn>
                    <v-btn color="blue" text @click="save()">
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </base-modal>

    </div>
</template>

<script>

import baseModal from "../BaseModal";
import draggable from 'vuedraggable';
import {getHeaders} from "../../../mixins/loadTableHeaders";
import BaseIconBtn from "../../../BaseComponents/BaseIconBtn";
import {VBtn, VCard, VCardActions, VCardText, VCardTitle, VCheckbox, VIcon, VSpacer} from 'vuetify/lib'

export default {
    name: "BaseTableHeadersSetting",
    components: {
        baseModal, draggable, BaseIconBtn,
        VCard, VCardTitle, VCardText, VCardActions, VCheckbox, VSpacer, VBtn, VIcon
    },
    props: {
        type: { //Название для хранения настроек заголовков
            requred: true,
            type: String
        },
    },
    data: () => ({
        dialog: false,
        headers: [],
    }),

    methods: {
        async getHeaders() {
            try {
                this.headers = await getHeaders(this.type)
            } catch (error) {
                this.showError(error)
            }
        },

        openDialog() {
            this.getHeaders().then(() => (this.dialog = true))
        },

        close() {
            this.$emit('close')
            this.dialog = false
        },

        save() {
            axios.post('/api/header/' + this.type, {'data': this.headers})
                .then(() => {
                    this.close()
                })
                .catch(error => {
                    this.showError(error)
                })
        },
    }
}
</script>

<style scoped>
ul.list-group.list-group {
    padding-left: 0;
}

li.list-group-item.draggable {
    list-style-type: none; /* Убираем маркеры */
}

li.list-group-item.draggable :deep(.v-icon.handle) {
    cursor: move;
}
</style>