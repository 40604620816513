<template>
    <v-card>
        <v-form
            ref="personForm"
            v-model="personValid"
            lazy-validation
        >
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col class="px-1 py-0" cols="12">
                            <v-text-field
                                ref="surname"
                                v-model="editedItem.surname"
                                :rules="requiredRules"
                                label="Фамилия"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col class="px-1 py-0" cols="12">
                            <v-text-field
                                ref="name"
                                v-model="editedItem.name"
                                :rules="requiredRules"
                                label="Имя"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col class="px-1 py-0" cols="12">
                            <v-text-field v-model="editedItem.patronymic" label="Отчество"></v-text-field>
                        </v-col>
                        <v-col class="px-1 py-0" cols="12">
                            <v-text-field v-model="editedItem.position" label="Должность"></v-text-field>
                        </v-col>
                        <v-col class="px-1 py-0" cols="12">
                            <v-text-field v-model="editedItem.tel" label="Номер телефона"></v-text-field>
                        </v-col>
                        <v-col class="px-1 py-0" cols="12">
                            <v-text-field
                                ref="email"
                                v-model="editedItem.email"
                                :rules="nullableEmailRules"
                                label="Электронная почта">
                            </v-text-field>
                        </v-col>
                        <v-col class="px-1 py-0" cols="12">
                            <v-textarea v-model="editedItem.comment" auto-grow label="Комментарий"
                                        no-resize></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
                <v-btn :disabled="!personValid" color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import requiredRules from "../../../Rules/requiredRules";
import nullableEmailRules from "../../../Rules/nullableEmailRules";

export default {
    name: "PersonForm",
    props: {
        propsEditedIndex: Number,
        propsEditedItem: {
            surname: '',
            name: '',
            patronymic: '',
            position: '',
            phone_number: '',
            email: null,
            birthday: null,
            comment: ''
        },
    },
    data: function () {
        return {
            datePickerMenu: false,
            editedItem: Object,
            defaultItem: {
                surname: '',
                name: '',
                patronymic: '',
                position: '',
                phone_number: '',
                email: null,
                birthday: null,
                comment: ''
            },
            personValid: true,
        }
    },
    created() {
        if (Object.keys(this.propsEditedItem).length) {
            this.editedItem = this.propsEditedItem
        } else {
            this.editedItem = this.defaultItem
        }
    },
    watch: {
        propsEditedItem: function (val) {
            if (Object.keys(val).length) {
                this.editedItem = val
            }
        },
    },
    computed: {
        editedIndex: {
            get: function () {
                return this.propsEditedIndex
            },
            set: function (newValue) {
                return newValue
            }
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Создать контакт' :
                this.UserCan.Person.Update ? 'Изменить контакт' : 'Данные контакта'
        },
        requiredRules() {
            return requiredRules
        },
        nullableEmailRules() {
            return nullableEmailRules
        }
    },
    methods: {
        resetValidation() {
            this.$refs.personForm.reset()
            this.$refs.personForm.resetValidation()
        },

        close() {
            this.resetValidation()
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            this.$emit('close')
        },

        save() {
            //console.log(this.editedIndex)
            let emitedData = Object.assign({}, this.editedItem)
            if (this.editedIndex !== -1) {
                axios.put('/api/person/' + emitedData.id, emitedData)
                    .then(response => {
                        this.$emit('return', response.data)
                        this.close()
                    })
                    //если есть ошибки, то выводим текст ошибки
                    .catch(console.log.bind(console));
            } else {
                axios.post('/api/person/new', emitedData)
                    .then(response => {
                        this.$emit('return', response.data)
                        this.close()
                    })
                    //если есть ошибки, то выводим текст ошибки
                    .catch(console.log.bind(console));
            }
        },
    },
}
</script>

<style scoped>

</style>
