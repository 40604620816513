<template>
    <base-modal
        v-if="UserCan.UsersTasks.Create || UserCan.DepTasks.Create || UserCan.AllTasks.Create"
        v-model="value"
        max-width="90%"
    >
        <v-card
            v-if="value"
            :loading="loading"
        >
            <v-container>
                <v-card-title>
                    Создание расписания
                </v-card-title>
                <v-card-text>
                    <v-form
                        ref="Form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model="expiration"
                                    :min="today"
                                    :rules="requredDateAfterTodayRules"
                                    type="date"
                                >
                                    <template v-slot:label>
                                        Действует до<sup>*</sup>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-checkbox
                                    v-model="dontCreateIfExists"
                                    label="Не создавать задачу, если есть не закрытая задача того же типа для данной системы"
                                />
                                <v-checkbox
                                    :disabled="!dontCreateIfExists"
                                    v-model="deleteIfNotCreated"
                                    label="Удалить задание, если не получилось создать задачу"
                                />
                            </v-col>
                        </v-row>
                        <VueCronEditor
                            v-on:expression="cronExpression = $event"
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="disableWhenSaving" color="blue"
                           text @click="close()">Отмена
                    </v-btn>
                    <v-btn :disabled="saveDisabled() || disableWhenSaving" color="blue"
                           text @click="save()">Сохранить
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </base-modal>
</template>

<script>
import BaseModal from "../BaseComponents/BaseModal";
import VueCronEditor from "../BaseComponents/VueCronEditor";
import Today from "../../Helpers/Today";
import requiredDateAfterTodayRules from "../../Rules/requiredDateAfterTodayRules";

export default {
    name: "SchedulingNewTasks",
    components: {
        BaseModal,
        VueCronEditor
    },
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        preparedData: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            valid: true,
            expiration: null,
            dontCreateIfExists: false,
            deleteIfNotCreated: false,
            cronExpression: null,
            disableWhenSaving: false,
            loading: false,
        }
    },
    computed: {
        today: function () {
            return Today()
        },
        requredDateAfterTodayRules: function () {
            return requiredDateAfterTodayRules
        }
    },
    methods: {
        setDefault() {
            this.expiration = null
            this.dontCreateIfExists = false
            this.deleteIfNotCreated = false
            this.cronExpression = null
        },

        saveDisabled() {
            return !(
                this.valid &&
                this.expiration &&
                this.preparedData &&
                this.preparedData.taskTypeId &&
                this.preparedData.systemsId.length &&
                this.cronExpression
            );
        },

        close() {
            this.$emit('close')
            this.setDefault()
        },
        save() {
            if (this.UserCan.UsersTasks.Create || this.UserCan.DepTasks.Create || this.UserCan.AllTasks.Create) {
                let data = Object.assign(
                    {},
                    this.preparedData,
                    {expiration: this.expiration},
                    {dontCreateIfExists: this.dontCreateIfExists},
                    {deleteIfNotCreated: this.deleteIfNotCreated},
                    {cronExpression: this.cronExpression}
                )
                if (confirm('Сохранить текущие данные как расписание?')) {
                    this.loading = true
                    this.disableWhenSaving = true
                    axios.post('/api/schedule/fromNewTask', data)
                        .then(response => {
                            this.$emit('return')
                            this.showInfo('Расписание успешно сохраненно.')
                            this.close()
                        })
                        //если есть ошибки, то выводим текст ошибки
                        .catch(error => {
                            this.showError(error)
                        })
                        .finally(() => {
                            this.loading = false
                            this.disableWhenSaving = false
                        })
                    ;
                }
            }
        },
    }
}
</script>

<style scoped>

</style>