export function TaskDataType() {
    this.attachment = []
    this.add_on_module = []
    this.id = null
    this.assistants = []
    this.contact_person = {
        id: null,
        name: null,
        patronymic: null,
        short_name: null,
        full_name: null,
        surname: null,
        tel: null,
    }
    this.created_at = null
    this.created_by = null
    this.creator = {
        department: null,
        disabled: null,
        email: null,
        id: null,
        login: null,
        name: null,
        patronymic: null,
        position: null,
        short_name: null,
        surname: null,
        tel: null,
    }
    this.custom_data = []
    this.comment = []
    this.deadline = null
    this.description = null
    this.executor = {
        department: null,
        disabled: null,
        email: null,
        id: null,
        login: null,
        name: null,
        patronymic: null,
        position: null,
        short_name: null,
        surname: null,
        tel: null,
    }
    this.fixed_one_time_works = null
    this.free_one_time_works = null
    this.fixed_equipment = null
    this.free_equipment = null
    this.warehouse_equipment = null
    this.intervals = []
    this.plan = []
    this.log = []
    this.priority = {
        id: null,
        name: null,
        icon: null,
    }
    this.reporting_docs_id = []
    this.status = {
        description: null,
        icon: null,
        id: null,
        name: null,
    }
    this.status_list = []
    this.system = {
        contract: {
            customer: {
                id: null,
                name: null,
            },
            customer_id: null,
            id: null,
            name: null,
            number: null,
            performer: {
                id: null,
                name: null,
            },
            performer_id: null,
            setting_equipment: null,
            setting_onetimework: null,
        },
        contract_id: null,
        id: null,
        lead_engineer: {
            department: null,
            disabled: null,
            email: null,
            id: null,
            login: null,
            name: null,
            patronymic: null,
            position: null,
            short_name: null,
            surname: null,
            tel: null,
        },
        object: {
            address: null,
            description: null,
            disabled: null,
            id: null,
            name: null,
            type: null,
        },
        object_id: null,
        observer: {
            birthday: null,
            comment: null,
            email: null,
            id: null,
            name: null,
            patronymic: null,
            position: null,
            short_name: null,
            full_name: null,
            surname: null,
            tel: null,
        },
        person_id: null,
        systems_type_id: null,
        type: {
            description: null,
            id: null,
            name: null,

        },
        user_id: null,
    }
    this.system_defect = []
    this.system_state = null
    this.systems_id = null
    this.task_priority_id = null
    this.task_status_id = null
    this.task_type_id = null
    this.type = {
        description: null,
        disabled: null,
        id: null,
        name: null,
        template: null,
    }
    this.updated_at = null
    this.whose_task = null
    this.description = null
    this.task_system_data = {}
    this.system_data = {}
    this.result_system_fields_settings = []
    this.seals = null
    this.media = null
}

