<template>
    <v-footer
        id="home-footer"
        color="grey darken-4"
        dark
    >
        <v-container class="m-0 p-0">
            <v-row>
                <v-col class="text-left  py-1">
                    Copyright &copy; 2019 Vuetify, LLC
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: 'HomeFooter',
}
</script>

<style lang="sass">
#home-footer a
    text-decoration: none
</style>
