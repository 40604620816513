<template>
    <v-expansion-panels
        accordion
        hover
        active-class="object-card-contract-expansion-panel-active"
        class="object-card-contract-expansion-panel"
    >
        <v-expansion-panel
            v-for="(item, i) in data"
            :key="i"
        >
            <v-expansion-panel-header class="pl-6">{{ item.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0 pl-4">
                <v-expansion-panels
                    accordion
                    active-class="object-card-task-expansion-panel-active"
                    class="object-card-task-expansion-panel"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header class="pl-6">Данные контракта
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="px-0 px-4">
                            <object-card-contract-description
                                :object-id="objectId"
                                :system-type-id="systemTypeId"
                                :contract-id="item.id"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header class="pl-6">Описание системы
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="px-0 px-8">
                            <object-card-system-description
                                :object-id="objectId"
                                :system-type-id="systemTypeId"
                                :contract-id="item.id"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <object-card-tasks
                        :object-id="objectId"
                        :system-type-id="systemTypeId"
                        :contract-id="item.id"
                    />

                </v-expansion-panels>

            </v-expansion-panel-content>
        </v-expansion-panel>

    </v-expansion-panels>
</template>

<script>

import ObjectCardContractDescription from "./ObjectCardContractDescription.vue";
import ObjectCardSystemDescription from "./ObjectCardSystemDescription.vue";
import ObjectCardTasks from "./ObjectCardTasks.vue";

export default {
    name: "ObjectCardContracts",
    components: {
        ObjectCardTasks, ObjectCardContractDescription, ObjectCardSystemDescription
    },
    props: {
        objectId: {
            required: true
        },
        systemTypeId: {
            required: true,
        }
    },
    data: function () {
        return {
            data: null
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            axios.get('/api/objectcard/' + this.objectId + '/' + this.systemTypeId)
                .then(response => {
                    this.data = response.data
                })
                .catch(error => {
                    this.showError(error)
                }).finally(() => {
                this.loading = false
            });
        },
    }
}
</script>

<style scoped>

.object-card-table {
    border: #99B1C6 1px solid;
    width: 100%;
    border-collapse: collapse;
}

.object-card-table > tr > td {

}


tr.simple-table-row > td {
    font-size: 13px !important;
}


.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px !important;
}


.object-card-expansion-panel :deep(.object-card-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 17px;
}

.object-card-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 17px !important;
}

.object-card-contract-expansion-panel :deep(.object-card-contract-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 15px;
}

.object-card-contract-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 15px !important;
}

.object-card-task-expansion-panel :deep(.object-card-task-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 15px;
}

.object-card-task-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 15px !important;
}

.object-card-task-expansion-panel-active > .v-expansion-panel-content {
    background-color: #ffffff;
    color: #000000;
}

.object-card-expansion-panel :deep(.v-expansion-panel-content__wrap) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

a.noDecor {
    color: rgba(0, 0, 0, 0.87) !important;
    text-decoration: none;
}

a.noDecor:link,
a.noDecor:hover,
a.noDecor:active {
    color: rgba(0, 0, 0, 0.87);
}

</style>