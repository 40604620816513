<template>
    <g transform="matrix(.0335 0 0 .03642 0 0)">
        <path :fill="iconColor"
              d="M716.65 821.36H29.71c-15.01 0-27.3-12.28-27.3-27.3V169.97c.49-4.7 2.15-8.67 5.21-11.76L161.63 9.96c5.48-4.35 10.45-7.15 17.49-7.56h538.01c15.01 0 26.81 12.3 26.81 27.29v511.18h-57.78V247.81L565.98 559.53c-8.53 15.61-24.49 15.55-35.78 7.96L379.66 369.13 220.54 683.97 94.45 767.41h591.71V540.86h57.78v253.19c0 15.01-12.3 27.3-27.3 27.3zm-30.49-674.99V56.34h-487.3v128.61c0 5-4.1 8.94-9.1 9.1l-129.83 4.3-1.48 535.95 125.38-84.99 171.36-334.56c9.76-17.86 27.73-17.95 39.98-2.72l144.98 190.4 146.01-356.05v0z"/>
        <path :stroke="iconColor" d="M177.55 22.82v744.61" stroke-miterlimit="22.9256" stroke-width="9.1"/>
        <path :stroke="iconColor" d="M373.18 22.82v744.61" stroke-miterlimit="22.9256" stroke-width="9.1"/>
        <path :stroke="iconColor" d="M568.8 22.82v744.61" stroke-miterlimit="22.9256" stroke-width="9.1"/>
        <path :stroke="iconColor" d="M63.24 594.89h619.87" stroke-miterlimit="22.9256" stroke-width="9.1"/>
        <path :stroke="iconColor" d="M63.24 416.3h619.87" stroke-miterlimit="22.9256" stroke-width="9.1"/>
        <path :stroke="iconColor" d="M63.24 237.71h619.87" stroke-miterlimit="22.9256" stroke-width="9.1"/>
    </g>
</template>

<script>
export default {
    name: "ReportsIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
