const Config = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/Config.vue")
const ConfigDirectoryDefault = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/ConfigDirectoryDefault.vue")
const ObjectFormConfig = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/FormConfig/ObjectFormConfig.vue")
const SystemFormConfig = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/FormConfig/SystemFormConfig/SystemFormConfig.vue")
const ConfigSystemsType = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/ConfigSystemsType.vue")
const ConfigTasksType = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/ConfigTasksType.vue")
const SystemFieldsInTask = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/FormConfig/TaskFormConfig/SystemFieldsInTask.vue")
const ConfigTaskCustomData = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/TaskCustomDataConfig/ConfigTaskCustomData.vue")
const ConfigTaskReportingDoc = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/TaskReportingDocConfig/ConfigTaskReportingDoc.vue")
const ConfigTaskAddonModule = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/TaskAddOnModulesConfig/ConfigTaskAddonModule.vue")
const WarehouseConfig = () => import (/* webpackChunkName: "Config" */ "../components/Admin/Config/WarehouseConfig/WarehouseConfig.vue")
const TransportConfig = () => import(/* webpackChunkName: "Config" */  "../components/Transport/TransportConfig/TransportConfig")
const TaskStatusConfig = () => import(/* webpackChunkName: "Config" */ '../components/Admin/Config/TaskStatus/TaskStatusConfig')
const PlanConfig = () => import(/* webpackChunkName: "Config" */ '../components/Admin/Config/PlanConfig/PlanConfig.vue')
const SystemDefectConfig = () => import(/* webpackChunkName: "Config" */ '../components/Admin/Config/SystemDefectConfig/SystemDefectConfig.vue')

export const adminConfigRoutes = [
    {
        path: '/admin/config', name: "config", component: Config, children: [
            {path: "config-directory-default", name: "ConfigDirectoryDefault", component: ConfigDirectoryDefault},
            {path: "object-form-config", name: "ObjectFormConfig", component: ObjectFormConfig},
            {path: "system-form-config", name: "SystemFormConfig", component: SystemFormConfig},
            {path: "config-systems-type", name: "ConfigSystemsType", component: ConfigSystemsType},
            {path: "config-tasks-type", name: "ConfigTasksType", component: ConfigTasksType},
            {path: "config-tasks", name: "ConfigTasks", component: SystemFieldsInTask},
            {path: "config-task-custom-data", name: "ConfigTaskCustomData", component: ConfigTaskCustomData},
            {path: "config-task-reporting-doc", name: "ConfigTaskReportingDoc", component: ConfigTaskReportingDoc},
            {path: "config-task-addon-module", name: "ConfigTaskAddonModule", component: ConfigTaskAddonModule},
            {path: "warehouse-config", name: "WarehouseConfig", component: WarehouseConfig},
            {path: "transport-config", name: "TransportConfig", component: TransportConfig},
            {path: "task-status-config", name: "TaskStatusConfig", component: TaskStatusConfig},
            {path: "plan-config", name: "PlanConfig", component: PlanConfig},
            {path: "system-defect-config", name: "SystemDefectConfig", component: SystemDefectConfig}
        ]
    }
]
