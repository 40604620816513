import { render, staticRenderFns } from "./SystemDefectMedia.vue?vue&type=template&id=2feb49aa&scoped=true"
import script from "./SystemDefectMedia.vue?vue&type=script&lang=js"
export * from "./SystemDefectMedia.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2feb49aa",
  null
  
)

export default component.exports