<template>
    <v-col v-if="value.fixed_at" class="py-1" cols="12">
        <b>Дефект исправлен:</b>
        {{ value.fixed_at }} - {{ value.repairer?.short_name }}
        <template v-if="value.changed_by_task">
            <router-link :to="{ name: 'task', params: { taskid: value.changed_by_task }}"
                         class="noDecor">
                из задачи №{{ value.changed_by_task }}
            </router-link>
        </template>
        <template v-else>
            из панели администрирования
        </template>
    </v-col>
</template>

<script>
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";

export default {
    name: "SystemDefectInfoFix",
    props: {
        value: {
            default: SystemDefectDefaultItem(),
        }
    },
}
</script>
