<template>
    <entity-form
        v-if="can"
        :can-save="true"
        title="Создать контакт"
        v-on:cancel="close"
        v-on:save="save"
    >
        <template v-slot:main-form>
            <entity-form-text-field
                v-model="item.surname"
                :required="true"
                :rules="requiredRules"
                label="Фамилия"
            />
            <entity-form-text-field
                v-model="item.name"
                :required="true"
                :rules="requiredRules"
                label="Имя"
            />
            <entity-form-text-field
                v-model="item.patronymic"
                label="Отчество"
            />
            <entity-form-text-field
                v-model="item.position"
                label="Должность"
            />
            <entity-form-text-field
                v-model="item.tel"
                label="Номер телефона"
            />
            <entity-form-text-field
                v-model="item.email"
                :rules="nullableEmailRules"
                label="Электронная почта"
            />
            <entity-form-text-field
                v-model="item.comment"
                label="Комментарий"
            />
        </template>
    </entity-form>
</template>

<script>
import EntityForm from "../../../BaseComponents/EntityForm";
import EntityFormTextField from "../../../BaseComponents/EntityFormTextField";
import requiredRules from "../../../Rules/requiredRules";
import nullableEmailRules from "../../../Rules/nullableEmailRules";
import PersonDefaultItem from "./PersonDefaultItem";

export default {
    name: "PersonCreate",
    components: {EntityForm, EntityFormTextField},

    data: function () {
        return {
            item: PersonDefaultItem()
        }
    },
    computed: {
        can() {
            return this.$store.getters["UserRights/canPersonCreate"]
        },

        requiredRules() {
            return requiredRules
        },

        nullableEmailRules() {
            return nullableEmailRules
        }
    },
    methods: {
        close() {
            if (this.$parent.$options.name === 'BasePersonCombobox') {
                this.$emit('close')
            } else {
                this.$router.back()
            }
        },

        save() {
            if (this.can) {
                axios.post('/api/person', this.item)
                    .then(response => {
                        this.$emit('return', response.data)
                        this.$emit('update')
                        this.showInfo('Контакт успешно создан')
                        this.close()
                    })
                    .catch(error => {
                        this.showError(error)
                    });
            }
        },
    }
}
</script>

<style scoped>

</style>
