export default function () {
    return {
        surname: null,
        name: null,
        patronymic: null,
        position: null,
        tel: null,
        email: null,
        comment: null
    }
}