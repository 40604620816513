<template>
    <div v-if="systemFieldsSettings.length">
        <template v-for="(value, index) in systemFieldsSettings">
            <template v-if="value.type_key  && value.type_key === customDataTypesName.Directory">
                <directory
                    :custom_data_settings="value"
                    :disabled="disabled"
                    :extra_attributes="value.extra_attributes"
                    :read_only="value.read_only"
                    :with-remove="false"
                    :value="selectValue(value.name, value.fill_primary_data)"
                    v-on:addAddedItem="addItem($event, value.name)"
                    v-on:deleteAddedItem="deleteItem($event, value.name)"
                    v-on:updateAddedItem="updateItem($event, value.name)"
                />
            </template>
        </template>
    </div>
</template>

<script>
import CustomDataTypesName from "../../../BaseCustomDataComponents/CustomDataFieldsType/CustomDataTypesName";
import Directory from "./FieldType/Directory";

export default {
    name: "TaskSystemDataDirectory",
    components: {Directory},
    data: function () {
        return {
            componentsList: Object.keys(this.$options.components),
        }
    },
    computed: {
        customDataTypesName() {
            return CustomDataTypesName
        },
        taskId() {
            return this.$store.getters["TaskData/getTaskId"]
        },
        disabled() {
            return this.$store.getters["TaskData/getDisabled"]
        },
        systemFieldsSettings() {
            return this.$store.getters["TaskData/getSystemFieldsSettings"]
        },

        taskSystemData() {
            return this.$store.getters["TaskData/getTaskSystemData"]
        },
        systemData() {
            return this.$store.getters["TaskData/getSystemData"]
        },

    },
    methods: {
        selectValue(key, fill_primary_data) {
            if (this.taskSystemData && this.taskSystemData.hasOwnProperty(key)) {
                return this.taskSystemData[key] ?? []
            }

            if (fill_primary_data && this.systemData[key]) {
                return this.systemData[key] ?? []
            }
            return null
        },

        addItem(data, key) {
            let settings = this.systemFieldsSettings.find((element) => {
                return element.name === key
            })

            let currentValue = this.selectValue(key, settings.fill_primary_data)

            if (!currentValue) {
                currentValue = []
            }

            const newData = currentValue.concat(data)

            this.updateData(newData, key)
        },

        updateItem(data, key) {
            let settings = this.systemFieldsSettings.find((element) => {
                return element.name === key
            })
            let newData = this.selectValue(key, settings.fill_primary_data).slice()
            newData[data.index] = data.item
            this.updateData(newData, key)
        },

        deleteItem(data, key) {
            let settings = this.systemFieldsSettings.find((element) => {
                return element.name === key
            })
            let newData = this.selectValue(key, settings.fill_primary_data).slice()
            newData.splice(data, 1)
            this.updateData(newData, key)
        },

        updateData(data, key) {
            if (this.taskSystemData && data === this.taskSystemData[key]) {
                return
            }

            const sendData = {
                key: key,
                data: data,
            }
            axios.post('/api/task/data/' + this.taskId, sendData)
                .then(() => {
                    this.$store.dispatch('TaskData/updateTaskData')
                })
                .catch(error => {
                    this.showError(error)
                });
        }
    }
}
</script>

<style scoped>

</style>
