<template>
    <base-modal
        v-model="openDialog"
        max-width="1000"
    >
        <v-card v-if="openDialog">
            <v-container>
                <v-card-title>
                    <span class="headline">Комментарий к дефекту</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="comment"
                                :rules="requiredRules"
                                auto-grow
                                hide-details
                                label="Комментарий"
                                rows="2"
                                validate-on-blur
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!comment" color="blue" text v-on:click="save">
                        Сохранить
                    </v-btn>
                    <v-btn color="blue" text v-on:click="close">Закрыть</v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </base-modal>
</template>

<script>
import BaseModal from "../BaseComponents/BaseModal.vue";
import requiredRules from "../../Rules/requiredRules";
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";

export default {
    name: "SystemDefectCommentForm",
    components: {BaseModal},
    props: {
        value: {
            default: SystemDefectDefaultItem(),
        },
        taskId: {
            type: Number,
            nullable: true,
            default: null
        },
        systemId: {
            type: Number,
            nullable: true,
            default: null
        }
    },
    data: function () {
        return {
            openDialog: false,
            comment: null
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if (val) {
                    this.openDialog = true
                }
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        requiredRules() {
            return requiredRules
        },
    },
    methods: {
        save() {
            if (this.comment && this.getPath()) {
                axios.post(this.getPath(), {comment: this.comment})
                    .then(() => {
                        this.$emit('need-update')
                        this.close()
                    })
                    .catch(error => {
                        this.showError(error)
                    });
            }
        },

        getPath() {
            if (this.taskId && this.value.id) {
                return '/api/system-defect/comment/task/' + this.taskId + '/defect/' + this.value.id
            }

            if (this.systemId && this.value.id) {
                return '/api/system-defect/comment/system/' + this.systemId + '/defect/' + this.value.id
            }
            return null
        },

        close() {
            this.openDialog = false
            this.comment = null
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>
