<template>
    <v-main>
        <main-errors/>
        <v-fade-transition mode="out-in">
            <router-view/>
        </v-fade-transition>
    </v-main>
</template>

<script>
import NotificationToast from "./NotificationToast";
import MainErrors from "./MainErrors";
import {Centrifuge} from "centrifuge"
import {getToken} from "./centrifugo";

export default {
    name: 'MainView',
    components: {
        MainErrors,
        NotificationToast
    },
    data() {
        return {
            UserId: null,
        };
    },
    beforeCreate: function () {
        axios.get('/api/user/getauthuser')
            .then(response => {
                if (response.data && response.data.id) {
                    this.UserId = response.data.id
                }
            })
    },


    watch: {
        UserId: function (userId) {
            if (userId && window.location.host !== 'localhost' && !window.location.host.includes('assistman-service.ru')) {
                const centrifuge = new Centrifuge('wss://centrifugo.assistman.ru:8000/connection/websocket', {
                    getToken: function () {
                        return getToken('/api/centrifugo/connection_token');
                    },
                });
                const privateChannel = 'notifications:personal#' + this.getDomain() + '_' + userId
                centrifuge.connect();

                const sub = centrifuge.newSubscription(privateChannel, {
                    getToken: function () {
                        return getToken('/api/centrifugo/subscription_token');
                    },
                });

                sub.on('publication', (notification) => {
                        const content = {
                            component: NotificationToast,
                            props: {
                                notificationData: notification.data,
                            },
                        };
                        this.showNotification(content)
                    }
                ).subscribe();
            }
        },
    },
    methods: {
        getDomain() {
            const hostArray = window.location.host.split('.')
            if (hostArray[1]) {
                if (hostArray[0] === 'www' && hostArray[2]) {
                    return hostArray[1]
                }
                return hostArray[0]
            }
            return false
        },

        showNotification(data) {
            if (data) {
                this.$toast(data, {
                    timeout: false
                })
            }
        },
        showToast(message) {
            this.$toast(message ? message : "I'm a toast!")
        }
    }
}
</script>
