const Departments = () => import(/* webpackChunkName: "Departments" */ '../components/Admin/Departments/Departments.vue')
const DepartmentCreate = () => import(/* webpackChunkName: "Departments" */ '../components/Admin/Departments/DepartmentCreate.vue')
const DepartmentUpdate = () => import(/* webpackChunkName: "Departments" */ '../components/Admin/Departments/DepartmentUpdate.vue')
const DepartmentDelete = () => import(/* webpackChunkName: "Departments" */ '../components/Admin/Departments/DepartmentDelete.vue')
export const departmentRoutes = [
    {
        path: '/department', name: "department.index", component: Departments,
        children: [
            {path: "create", component: DepartmentCreate, name: "department.create"},
            {path: "delete/:id", component: DepartmentDelete, name: "department.delete", props: true},
            {path: ":id", component: DepartmentUpdate, name: "department.update", props: true},
        ]
    },
]
