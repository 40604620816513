<template>
    <v-menu
        ref="menu"
        v-model="dateMenu"
        :close-on-content-click="false"
        :hide-details="hideDetails"
        :return-value.sync="date"
        min-width="290px"
        offset-y
        transition="scale-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                :hide-details="hideDetails"
                :label="label"
                :required="required"
                :rules="rules"
                readonly
                v-bind="attrs"
                v-on="on"
            >
                <template v-slot:label>
                    {{ label }}<sup v-if="required">*</sup>
                </template>
            </v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            :first-day-of-week="1"
            no-title
            range
            scrollable
        >
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dateMenu = false"
            >
                Отмена
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="$refs.menu.save(date)"
            >
                OK
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
import {VBtn, VDatePicker, VMenu, VSpacer, VTextField} from 'vuetify/lib'

export default {
    name: "DateRange",
    components: {
        VMenu, VTextField, VDatePicker, VSpacer, VBtn
    },
    props: {
        required: {
            type: Boolean,
            default: false,
            required: false
        },
        rules: {
            delete: []
        },
        label: {
            type: String,
            default: '',
            required: true
        },
        value: {
            required: true
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            dateMenu: false,
            date: null,
        }
    },
    computed: {
        dateRangeText() {
            if (this.date) {
                let end = this.date[1] ? " по " + this.date[1] : ""
                return "c " + this.date[0] + end
            }
            return null
        },
    },
    watch: {
        value: {
            handler: function (newVal) {
                this.date = newVal
            },
            immediate: true
        },
        date: {
            handler: function (newVal) {
                this.$emit('input', newVal)
            },
            immediate: false
        },
    },
}
</script>

<style scoped>

</style>