<template>
    <div v-if="UserCan.ObjectGroup.Read || UserCan.ObjectGroup.Edit" class="pb-0 overflow-y-hidden overflow-x-hidden">
        <base-error-snackbar
            v-model="Error"
            v-bind:error-text="ErrorText"
        />
        <v-row class="px-3">
            <v-col class="p-2" cols="12" lg="3" md="4" xl="2">
                <v-card
                    :height="height"
                    :loading="loadingUsersList"
                    class="mx-auto  overflow-y-auto"
                >
                    <v-card-title>
                        Пользователи
                    </v-card-title>
                    <draggable
                        id="mainUserList"
                        :group="{
                            name: 'users',
                            pull: UserCan.ObjectGroup.Edit ? 'clone' : false
                        }"
                        :list="userList"
                        :sort="false"
                        class="dragArea list-group"
                        handle=".noDisabled"
                        @end="moveUser"

                    >
                        <div
                            v-for="user in userList"
                            :id='"0-"+user.id+"-"+user.free_workload'
                            :key="user.id"
                            class="list-group-item"
                            v-bind:class="{ noDisabled: user.free_workload !== 0 }"
                        >
                            <object-group-user-card
                                :user-name="user.short_name"
                                :user-workload="user.free_workload"
                            />
                        </div>
                    </draggable>
                    <v-card-text>

                    </v-card-text>

                </v-card>
            </v-col>
            <v-col class="p-2" cols="12" lg="9" md="8" xl="10">
                <v-card
                    :height="height"
                    :loading="loadingObjectCardsList"
                    class="mx-auto  overflow-y-auto"
                >
                    <v-card-title>
                        Группы объектов
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <base-modal v-model="editDialog">
                            <ObjectGroupForm
                                :props-edited-index="edtitedIndex"
                                :props-edited-item="editedItem"
                                v-on:close="onCloseEditForm"
                            />
                        </base-modal>
                        <v-btn
                            :disabled="!UserCan.ObjectGroup.Edit"
                            class="mb-2"
                            color="normal"
                            raised
                            small
                            @click="newGroup()"
                        >
                            Создать
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-row>
                            <template v-for="(item) in objectGroupData">
                                <v-col cols="12">
                                    <v-hover v-slot="{ hover }">
                                        <v-card
                                            :class="{ 'on-hover': hover }"
                                            :elevation="hover ? 12 : 2"
                                        >
                                            <v-card-title>
                                                <div :style="{
                                                    backgroundColor: item.color,
                                                    height: '20px',
                                                    width: '20px',
                                                    borderRadius: '4px',
                                                    transition: 'border-radius 200ms ease-in-out'
                                                }" class="mr-4"/>
                                                {{ item.name }}

                                                <v-spacer></v-spacer>
                                                <v-icon
                                                    class="mr-2"
                                                    small
                                                    @click="editGroup(item)"
                                                >
                                                    <template v-if="UserCan.ObjectGroup.Edit">
                                                        {{ $store.state.Icon.svgMdiPencil }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $store.state.Icon.svgMdiEye }}
                                                    </template>
                                                </v-icon>
                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{ item.description }}
                                            </v-card-subtitle>
                                            <v-card-text style="min-height: 30px">
                                                <v-row>
                                                    <v-col cols="3">
                                                        <v-list
                                                            class="py-0 objects-group-list"
                                                            dense
                                                            disabled

                                                        >
                                                            <v-list-item
                                                                dense
                                                            >
                                                                <v-list-item-content class="py-1">
                                                                    <v-list-item-title>Всего объектов в группе:
                                                                        <b>{{ item.objects_count.toString() }}</b>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item
                                                                dense
                                                            >
                                                                <v-list-item-content class="py-1">
                                                                    <v-list-item-title>Всего систем в группе:
                                                                        <b>{{ item.systems_count.toString() }}</b>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item-group
                                                                v-if="item.system_type_count.length"
                                                                color="primary"
                                                            >
                                                                <v-list-item
                                                                    v-for="(systemType,i) in item.system_type_count"
                                                                    :key="i"
                                                                    dense
                                                                >
                                                                    <v-list-item-content class="py-1">
                                                                        <v-list-item-title>{{ systemType.name }}:
                                                                            <b>{{ systemType.count }}</b>
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list-item-group>
                                                        </v-list>

                                                    </v-col>
                                                    <v-col cols="9">
                                                        <draggable
                                                            :id='"user-list-"+item.id'
                                                            :group="{
                                                                name: 'users',
                                                                pull: UserCan.ObjectGroup.Edit ? 'clone' : false
                                                            }"
                                                            :list="item.users"
                                                            :sort="false"
                                                            class="list-group"
                                                            @end="moveUser"
                                                        >
                                                            <div
                                                                v-for="user in item.users"
                                                                :id='item.id+"-"+user.id+"-"+ (user.pivot && user.pivot.workload ? user.pivot.workload : 0)'
                                                                :key="user.id"
                                                                class="list-group-item"
                                                                v-bind:class="{ noDisabled: (user.pivot && user.pivot.workload ? user.pivot.workload : 0) !==0 }"
                                                            >
                                                                <object-group-user-card
                                                                    :user-name="user.short_name"
                                                                    :user-workload="user.pivot && user.pivot.workload ? user.pivot.workload : 0"
                                                                />
                                                            </div>
                                                        </draggable>
                                                    </v-col>
                                                </v-row>

                                            </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </template>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <base-modal
            v-if="UserCan.ObjectGroup.Edit"
            v-model="userMoveDialog">
            <v-card
                :loading="loading"
            >
                <v-container>
                    <v-card-title>
                        <span class="headline">Укажите выделяемое время</span>
                    </v-card-title>
                    <v-card-text class="mt-5 pb-1">
                        <v-slider
                            v-model="payload"
                            :max="maxPayload"
                            hide-details
                            min="0"
                            step="5"
                            thumb-label="always"
                        >
                            <template v-slot:append>
                                {{ maxPayload }}
                            </template>
                            <template v-slot:prepend>
                                {{ 0 }}
                            </template>
                        </v-slider>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="inProcess" color="blue" text @click="canselUserMove">Отмена</v-btn>
                        <v-btn v-if=" UserCan.ObjectGroup.Edit" :disabled="inProcess" color="blue" text
                               @click="saveUserMove">Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </base-modal>
    </div>
</template>

<script>
import {screenHeight} from "../../mixins/setScreenHeight";
import BaseErrorSnackbar from "../BaseComponents/BaseErrorSnackbar";
import ObjectGroupUserCard from "./ObjectGroupUserCard";
import draggable from 'vuedraggable';
import BaseModal from "../BaseComponents/BaseModal";
import ObjectGroupForm from "./ObjectGroupForm";

export default {
    name: "ObjectGroup",
    mixins: [screenHeight],
    components: {ObjectGroupForm, draggable, ObjectGroupUserCard, BaseErrorSnackbar, BaseModal},
    data: function () {
        return {
            ErrorText: '',
            Error: false,
            loadingObjectCardsList: true,
            loadingUsersList: true,
            activeMenuPoint: [],
            updateAll: null,
            userList: [],
            objectGroupData: null,
            userMoveDialog: false,
            payload: 0,
            maxPayload: 0,
            moveData: {},
            editDialog: false,
            edtitedIndex: -1,
            editedItem: null,
            inProcess: false,
            loading: false,
        }
    },


    created() {
        this.getObjectGroupData()
        this.getUserListData()
    },
    methods: {

        getObjectGroupData: function () {
            axios.get('/api/objectgroup/index')
                .then(response => {
                    this.objectGroupData = response.data
                })
                .then(() => {
                    this.loadingObjectCardsList = false
                })
                .catch(error => {
                    this.loadingObjectCardsList = false
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = "Произошла какая-то ошибка при загрузке групп объектов."
                    }
                });
        },
        getUserListData: function () {
            axios.get('/api/user/objectCardUserList')
                .then(response => {
                    this.userList = response.data
                })
                .then(() => {
                    this.loadingUsersList = false
                })
                .catch(error => {
                    this.loadingUsersList = false
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = "Произошла какая-то ошибка при загрузке данных о сотрудниках."
                    }
                });
        },

        log: function (evt) {
            // console.log(evt.item.id)
            // event.preventDefault ()
            // console.log(evt)
            // console.log(evt.to.id)
            // window.console.log(evt);
        },

        moveUser: function (evt) {
            if (this.UserCan.ObjectGroup.Edit) {
                this.moveData.objectGroupFrom = evt.from.id === "mainUserList" ? 0 : Number(evt.from.id.slice(10))
                this.moveData.objectGroupTo = evt.to.id === "mainUserList" ? 0 : Number(evt.to.id.slice(10))
                const items = evt.item.id.split("-")
                this.moveData.userID = Number(items[1])
                this.moveData.maxPayload = Number(items[2])
                // console.log(this.moveData)
                if (this.moveData.objectGroupFrom !== this.moveData.objectGroupTo) {
                    this.maxPayload = this.moveData.maxPayload
                    this.userMoveDialog = true
                } else {
                    this.canselUserMove()
                }
            }
        },


        canselUserMove: function () {
            this.getObjectGroupData()
            this.getUserListData()
            this.maxPayload = 0
            this.moveData = Object.assign({}, {})
            this.userMoveDialog = false
        },

        wait: function (act) {
            if (act === "start") {
                this.inProcess = true
                this.loading = true
            }
            if (act === "stop") {
                this.inProcess = false
                this.loading = false
            }
        },

        saveUserMove: function () {
            this.wait("start")
            this.moveData.payload = this.payload
            // console.log(this.moveData)
            const data = Object.assign({}, this.moveData)
            if (this.UserCan.ObjectGroup.Edit) {
                axios.put('/api/objectgroup/moveuser', data)
                    .then(() => {
                        this.canselUserMove()
                    })
                    .then(() => {
                        this.wait("stop")
                    })
                    //если есть ошибки, то выводим текст ошибки
                    .catch(error => {
                        if (error.response) {
                            this.Error = true
                            this.ErrorText = "Не удалось сохраниеть изменения."
                        }
                        this.canselUserMove()
                        this.wait("stop")
                    });
            }

        },

        newGroup: function () {
            this.edtitedIndex = -1
            this.editDialog = true
        },

        editGroup: function (item) {
            this.edtitedIndex = item.id
            this.editedItem = item
            this.editDialog = true
        },

        onCloseEditForm: function () {
            this.editDialog = false
            this.edtitedIndex = 0
            this.getObjectGroupData()
            this.getUserListData()
        },


        checkMove: function (evt) {
            window.console.log(evt);
        }
    }
}
</script>

<style scoped>
.v-card {
    transition: opacity .4s ease-in-out;
}

.objects-group-list :deep(.v-list-item) {
    min-height: 25px;
}

.objects-group-list :deep(.v-subheader) {
    height: 30px;
    color: #000000;
}


</style>
