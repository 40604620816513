<template>
    <g transform="matrix(.0012 0 0 .00127 -.01 0)">
        <path :fill="iconColor"
              d="M12001.88 21905.33h7288.44v-7116.79h1630.18v8079.55c0 367-300.47 667.47-667.52 667.47h-8251.1v-667.47z"/>
        <path :fill="iconColor" d="M6100.73 20236.14V8920.35h11408.68v1311.87H7522.23v10003.92z"/>
        <path :fill="iconColor"
              d="M667.47 0h19585.51c367.15 0 667.52 300.63 667.52 667.47v8252.88h-1630.18V1630.13H1630.17v20275.21h4470.55v1630.23H667.46c-366.79 0-667.47-300.37-667.47-667.47V667.47C-.01 300.37 300.36 0 667.46 0z"/>
        <path :fill="iconColor" d="M12001.85 11918.43h2697.39v8317.68h-2697.39z"/>
        <path :fill="iconColor" d="M0 0h2090.01v5995.03H0z" transform="matrix(0 1.29061 -1.38743 0 17509.5 14728.6)"/>
    </g>
</template>

<script>
export default {
    name: "NewTasksIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
