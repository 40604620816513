<template>
    <base-modal
        v-model="openDialog"
    >
        <base-file-upload
            :url="getPath()"
            @input="close"
        />
    </base-modal>
</template>

<script>
import BaseModal from "../BaseComponents/BaseModal.vue";
import BaseFileUpload from "../BaseComponents/BaseFileUpload/BaseFileUpload.vue";
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";

export default {
    name: "SystemDefectUploadFile",
    components: {BaseFileUpload, BaseModal},
    props: {
        value: {
            default: SystemDefectDefaultItem(),
        },
        taskId: {
            type: Number,
            nullable: true,
            default: null
        },
        systemId: {
            type: Number,
            nullable: true,
            default: null
        }
    },
    data: function () {
        return {
            openDialog: false,
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if (val) {
                    this.openDialog = true
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        getPath() {
            if (this.taskId && this.value?.id) {
                return '/api/system-defect/upload/task/' + this.taskId + '/defect/' + this.value.id
            }

            if (this.systemId && this.value?.id) {
                return '/api/system-defect/upload/system/' + this.systemId + '/defect/' + this.value.id
            }
            return null
        },

        close() {
            this.openDialog = false
            this.$emit('need-update')
            this.$emit('close')
        }
    }
}
</script>


<style scoped>

</style>
