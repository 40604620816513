<template>
    <!--    <base-date-time-picker-->
    <!--        v-model="localData"-->
    <!--        clearText="Очистить"-->
    <!--        okText="Применить"-->
    <!--        :datePickerProps="datePickerProps"-->
    <!--        :timePickerProps="timePickerProps"-->
    <!--        :hide-details="true"-->
    <!--        clearable-->
    <!--        no-resize-->
    <!--        :readonly="read_only"-->
    <!--        :disabled="disabled"-->
    <!--        v-on:input="$emit('change', $event)"-->
    <!--        :class-object="classObject"-->
    <!--    >-->
    <!--        <template v-slot:dateIcon>-->
    <!--            <v-icon>-->
    <!--                {{$store.state.Icon.svgMdiCalendarMonthOutline}}-->
    <!--            </v-icon>-->
    <!--        </template>-->
    <!--        <template v-slot:timeIcon>-->
    <!--            <v-icon>-->
    <!--                {{$store.state.Icon.svgMdiTimer}}-->
    <!--            </v-icon>-->
    <!--        </template>-->
    <!--    </base-date-time-picker>-->
    <v-text-field
        v-model="localData"
        :clearable="!disabled && !read_only"
        :disabled="disabled"
        :readonly="read_only"
        class="task-text-field pt-0 mt-0"
        hide-details
        no-resize
        type="datetime-local"
        v-on:change="$emit('change', $event)"
    >
    </v-text-field>
</template>

<script>
import BaseDateTimePicker from "../../../../BaseComponents/BaseDateTimePicker";

export default {
    name: "DateTime",
    components: {BaseDateTimePicker},
    props: {
        value: null,
        extra_attributes: null,
        read_only: false,
        disabled: false,
    },
    data: function () {
        return {
            localData: null,
            classObject: {
                'pa-0': true,
                'ma-0': true,
                'font-size': true,
                'cursor-pointer': true
            },
            datePickerProps: {
                'show-current': true,
                'no-title': true,
            },
            timePickerProps: {
                format: '24hr',
                'no-title': true,
            },
        }
    },
    created() {
        this.localData = this.value
    },
    watch: {
        value: function (val) {
            this.localData = val
        },

    }
}
</script>

<style scoped>
.task-text-field {
    font-size: .85rem;
}
</style>
