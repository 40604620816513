<template>
    <div>
        <template v-if="$store.getters['TaskData/canChangeExecutor']">
            <base-autocomplete-with-request
                v-model="executor"
                :disabled="$store.getters['TaskData/getDisabled']"
                :hide-details="true"
                :multiple="false"
                :return-object="true"
                class="task-executor-combobox"
                item-text="short_name"
                item-value="id"
                label=""
                placeholder="Исполнитель"
                title=""
                url="user/simpleList"
            />
        </template>
        <template v-else>
            {{ this.$store.getters["TaskData/getTaskExecutor"].short_name }}
        </template>
    </div>
</template>

<script>

import BaseAutocompleteWithRequest from "../../../BaseComponents/BaseAutocompleteWithRequest";

export default {
    name: "TaskExecutor",
    components: {
        BaseAutocompleteWithRequest,

    },


    computed: {
        executor: {
            get: function () {
                return this.$store.getters["TaskData/getTaskExecutor"]
            },
            set: function (newValue) {
                if (!_.isEqual(this.$store.getters["TaskData/getTaskExecutor"], newValue)) {
                    this.changeExecutor(newValue)
                }
            }
        }
    },

    methods: {

        changeExecutor(user) {
            if (confirm("Вы уверены, что хотите сменить исполнителя задачи?")) {
                axios.put('/api/task/executor/' + this.$store.getters["TaskData/getTaskId"], {executor_id: user.id})
                    .then(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                    .catch(error => {
                        this.showError(error)
                    });
            }

        }
    }
}
</script>

<style>
div.task-executor-combobox {
    padding-left: 16px;
}
</style>

<style scoped>
div.task-executor-combobox {
    text-align: center;
    font-size: 0.9rem;
    margin-top: 0;
    padding-top: 0;
}

div.task-executor-combobox div.v-select__slot {
    height: 25px;
    padding-left: 16px;
}

div.task-executor-combobox div.v-input__append-inner {
    margin-top: 0;
}

</style>
