<template>
    <div>
        <v-app-bar
            id="home-app-bar"
            app
            color="white"
            elevation="1"
            style="z-index: 9999"
        >
            <a class="navbar-brand" href="#" v-on:click.prevent="rout('/taskslist')">
                <new-logo/>
            </a>

            <app-bar-tabs :navbar="navbar"/>

            <v-spacer/>
            <v-menu
                bottom
                left
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <profile-icon/>
                    </v-btn>
                </template>

                <v-list dense>
                    <v-list-item fixed @click="toProfile">
                        <v-list-item-icon>
                            <v-icon>{{ $store.state.Icon.svgMdiAccount }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Профиль</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item fixed @click="toNotificationSattings">
                        <v-list-item-icon>
                            <v-icon>{{ $store.state.Icon.svgMdiCommentAlertOutline }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Оповещения</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item fixed @click="showUserNainMenuSetting=true">
                        <v-list-item-icon>
                            <v-icon>{{ $store.state.Icon.svgMdiViewStream }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Вид главного меню</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="false" fixed @click="toWorkTimetable">
                        <v-list-item-icon>
                            <v-icon>{{ $store.state.Icon.svgMdiTimetable }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Расписание работ</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="false" fixed @click="toHelp">
                        <v-list-item-icon>
                            <v-icon>{{ $store.state.Icon.svgMdiHelp }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Помощь</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider class="my-1"></v-divider>

                    <v-list-item fixed @click="exit">
                        <v-list-item-icon>
                            <v-icon>{{ $store.state.Icon.svgMdiExitToApp }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Выход</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>
            </v-menu>
            <v-app-bar-nav-icon
                class="hidden-md-and-up"
                @click="drawer = !drawer"
            />
        </v-app-bar>

        <home-drawer
            v-model="drawer"
            :navbar="navbar"
        />

        <users-main-menu-setting
            :show="showUserNainMenuSetting"
            v-on:close="closeUsersMainMenuSetting"
        />

    </div>
</template>

<script>
import HomeDrawer from './Drawer'
import NewLogo from "./NewLogo";
import ProfileIcon from "../Icons/NavBar/ProfileIcon";
import UsersMainMenuSetting from "../Profile/UsersMainMenuSetting";
import AppBarTabs from "./AppBarTabs";

export default {
    name: 'MainAppBar',
    components: {
        HomeDrawer,
        NewLogo,
        ProfileIcon,
        UsersMainMenuSetting,
        AppBarTabs
    },
    data: () => ({
        activPoint: String,
        drawer: null,
        // navbar: null,
        tab: null,
        showUserNainMenuSetting: false,
    }),
    beforeCreate: function () {
        this.$store.dispatch('UserRights/getUserRights');
    },

    computed: {
        navbar: function () {
            return this.$store.getters['Navbar/getNavbar']
        },
    },

    created: function () {
        // this.getNavbar()
        this.$store.dispatch('Navbar/getData')
    },

    methods: {
        // getNavbar(){
        //     this.navbar = null
        //     axios.get('/api/navbar')
        //         .then(response => {
        //             this.navbar = response.data
        //         })
        // },

        toProfile: function () {
            this.$router.push({name: 'profile'}).catch(err => {
            })
        },

        toNotificationSattings: function () {
            this.$router.push({name: 'notificationSettings'}).catch(err => {
            })
        },

        toHelp: function () {
            this.$router.push({name: 'help'}).catch(err => {
            })
        },

        toWorkTimetable: function () {
            this.$router.push({name: 'help'}).catch(err => {
            })
        },

        closeUsersMainMenuSetting() {
            this.showUserNainMenuSetting = false
            // this.getNavbar()
            this.$store.dispatch('Navbar/getData')
        },

        exit: function () {
            if (confirm("Вы уверены что хотите выйти?")) {
                axios.post('/logout')
                    .then(function (response) {
                        //console.log(response);
                        location.href = '/'
                    })
                    .catch(function (error) {
                        // console.log(error);
                    });
            }
        }
    }
}
</script>

<style lang="sass">
$tabs-bar-height: 40px
.corporate-color
    color: rgba(40, 84, 115, 1) !important

.gray-color
    color: #757575 !important

#home-app-bar
    .v-tabs-slider
        max-width: 100%
        margin: 0 auto

    .v-tab
        &::before
            display: none

    a.font-weight-bold.v-tab:hover
        text-decoration: none
</style>
