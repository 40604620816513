<template>
    <div style="width: 100%">
        <v-expansion-panel
            v-for="(task,i) in data"
            :key="task.id"
        >
            <v-expansion-panel-header class="pl-6">{{ dateFormattedShortDate(task.created_at) }} - Задача №{{ task.id }}
                {{ task.type.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0 px-8">
                <object-card-task
                    :object-id="objectId"
                    :system-type-id="systemTypeId"
                    :contract-id="contractId"
                    :task-id="task.id"
                />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </div>

</template>


<script>
import {shortDate} from "../../mixins/commonMethod";
import ObjectCardTask from "./ObjectCardTask/ObjectCardTask.vue";
import ObjectCardSystemDescription from "./ObjectCardSystemDescription.vue";

export default {
    name: "ObjectCardTasks",
    components: {ObjectCardSystemDescription, ObjectCardTask},
    props: {
        objectId: {
            required: true
        },
        systemTypeId: {
            required: true,
        },
        contractId: {
            required: true
        }
    },
    data: function () {
        return {
            data: null
        }
    },
    created() {
        this.getData()
    },
    methods: {
        dateFormattedShortDate(date) {
            return shortDate(date)
        },
        getData() {
            axios.get('/api/objectcard/taskslist/' + this.objectId + '/' + this.systemTypeId + '/' + this.contractId)
                .then(response => {
                    this.data = response.data
                })
                .catch(error => {
                    this.showError(error)
                }).finally(() => {
                this.loading = false
            });
        },
    }
}
</script>


<style scoped>

.object-card-table {
    border: #99B1C6 1px solid;
    width: 100%;
    border-collapse: collapse;
}

.object-card-table > tr > td {

}


tr.simple-table-row > td {
    font-size: 13px !important;
}


.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px !important;
}


.object-card-expansion-panel :deep(.object-card-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 17px;
}

.object-card-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 17px !important;
}

.object-card-contract-expansion-panel :deep(.object-card-contract-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 15px;
}

.object-card-contract-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 15px !important;
}

.object-card-task-expansion-panel :deep(.object-card-task-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 15px;
}

.object-card-task-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 15px !important;
}

.object-card-task-expansion-panel-active > .v-expansion-panel-content {
    background-color: #ffffff;
    color: #000000;
}

.object-card-expansion-panel :deep(.v-expansion-panel-content__wrap) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

a.noDecor {
    color: rgba(0, 0, 0, 0.87) !important;
    text-decoration: none;
}

a.noDecor:link,
a.noDecor:hover,
a.noDecor:active {
    color: rgba(0, 0, 0, 0.87);
}

</style>