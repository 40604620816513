<script>
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";

export default {
    name: "SystemDefectActionMenu",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            default: SystemDefectDefaultItem(),
            required: true
        },
        taskId: {
            default: null,
            required: false
        },
        systemId: {
            default: null,
            required: false
        },
    },
    computed: {
        settings() {
            return this.$store.getters["UserData/getStatusDefectSettings"]
        },
        canEdit() {
            if (this.systemId) {
                return true
            }

            if (!this.value.fixed_at && !this.value.fixed_by) {
                if (this.settings && this.settings.edit_only_in_task_where_created) {
                    return this.value.created_by_task === this.taskId
                }
                return true
            }
        },
        canDelete() {
            if (this.systemId) {
                return true
            }

            if (this.settings && this.settings.delete_only_in_task_where_created) {
                return this.value.created_by_task === this.taskId
            } else {
                return true
            }
        }
    },
}
</script>

<template>
    <div>
        <v-menu
            bottom
            left
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>{{ $store.state.Icon.svgMdiDotsVertical }}</v-icon>
                </v-btn>
            </template>

            <v-list dense>
                <v-list-item-group>
                    <v-list-item
                        key="info"
                        v-on:click="$emit('info', value)"
                    >
                        <v-list-item-title>Информация о дефекте</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        v-if="canEdit"
                        :disabled="disabled"
                        key="edit"
                        v-on:click="$emit('edit', value)"
                    >
                        <v-list-item-title>Изменить</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        key="status"
                        :disabled="disabled"
                        v-on:click="$emit('change-status', value)"
                    >
                        <v-list-item-title>Изменить состояние</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        key="file"
                        :disabled="disabled"
                        v-on:click="$emit('add-file', value)"
                    >
                        <v-list-item-title>Добавить файлы</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        key="comment"
                        :disabled="disabled"
                        v-on:click="$emit('add-comment', value)"
                    >
                        <v-list-item-title>Добавить комментарий</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        v-if="canDelete"
                        key="delete"
                        :disabled="disabled"
                        v-on:click="$emit('delete', value)"
                    >
                        <v-list-item-title>Удалить</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>
