import requiredRules from "../../../Rules/requiredRules";

export default {
    name: "MainFieldsTypeComponent",
    props: {
        id: {
            required: false,
        },
        value: {
            required: true,
            default: null
        },
        extra_attributes: {
            default: null
        },
        label: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: true
        },

        addedRules: null,
    },
    data: function () {
        return {
            model: null,
        }
    },
    created() {
        this.model = this.value
    },
    computed: {
        rules() {
            let result = this.required ? requiredRules : []
            if (this.addedRules && Array.isArray(this.addedRules)) {
                result = result.concat(this.addedRules)
            }
            return result;
        },
    },
    watch: {
        value: function (val) {
            this.model = val
        },
        model: function (val) {
            this.$emit('input', val)
        }
    },
}

