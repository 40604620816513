import Today from "../Helpers/Today";

export default [
    value => {
        return !!value || /^(20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/.test(value) || 'Это поле должно содержать дату'
    },
    value => {
        const today = new Date(Today())
        const valueDate = new Date(value)
        return valueDate > today || 'Это поле должно содержать дату больше сегодняшнего числа'
    },
]