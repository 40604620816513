<template>
    <v-text-field
        v-model="model"
        :disabled="componentDisabled"
        class="task-text-field pt-0 mt-0"
        clearable
        hide-details
        no-resize
        type="number"
        v-on:blur="$emit('blur')"
    >
    </v-text-field>
</template>

<script>
export default {
    name: "TaskCustomDataInteger",
    props: {
        value: null,
        componentDisabled: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            model: null
        }
    },
    created() {
        this.model = this.value
    },
    watch: {
        value: function (val) {
            this.model = val
        },
        model: function (val) {
            this.$emit('input', val)
        }
    }
}
</script>

<style scoped>
div.task-text-field {
    font-size: .85rem;
}

div.task-text-field :deep(input[type="text"]) {
    padding: 3px 0 3px;
}

div.task-text-field :deep(input[disabled="disabled"]) {
    color: rgba(0, 0, 0, 0.87);
}
</style>
