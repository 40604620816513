<template>
    <v-col cols="12">
        <v-row v-if="UserCan.UsersObjectMap.View || UserCan.DepObjectMap.View || UserCan.AllObjectMap.View">
            <v-col cols="12" lg="3" xl="2">
                <v-row>
                    <v-col class="py-0 pr-0" cols="12">
                        <base-left-menu
                            v-model="selectedPoint"
                            :height="(height/2)-20"
                            :selectable="true"
                            object-only

                        />
                    </v-col>
                    <v-col
                        class="pr-0"
                        cols="12"
                    >
                        <v-text-field
                            v-model="date"
                            :rules="requiredDateRules"
                            label="Дата"
                            type="date"
                            validate-on-blur
                        />
                        <base-users-checkbox-list
                            v-model="selectedUser"
                            :height="(height/2)-66"
                            :multiple="false"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="9" style="height: 100%" xl="10">
                <v-card
                    :height="height"
                    class="mx-auto overflow-y-auto"
                >

                    <v-card-text class="p-0" style="height: 100%">
                        <l-map
                            :center="center"
                            :options="mapOptions"
                            :zoom="zoom"
                            style="z-index: 100; height: 100%"
                            @update:center="centerUpdate"
                            @update:zoom="zoomUpdate"
                        >
                            <l-tile-layer
                                :attribution="attribution"
                                :url="url"
                            />
                            <template v-for="(item, index) in dataList">
                                <l-marker
                                    v-if="rebuildObjectPosition(item)"
                                    :lat-lng="rebuildObjectPosition(item)"
                                    :name="item.name"
                                >
                                    <l-icon
                                        :icon-anchor="[5,5]"
                                    >
                                        <object-icon
                                            :icon-color="item.color"
                                        />
                                    </l-icon>
                                    <l-popup>
                                        <div @click="innerClick">
                                            <b>{{ item.name }}</b> {{ item.description }}
                                            <p v-show="showParagraph">
                                                {{ getAddress(item) }}
                                            </p>
                                        </div>
                                    </l-popup>
                                </l-marker>
                            </template>
                            <template v-for="(usersItem, userIndex) in usersPositionList">
                                <template v-if="usersItem.length">
                                    <template v-for="(item, index) in usersItem">
                                        <l-marker
                                            :lat-lng="rebuildUsersPosition(item.lat, item.lon)"
                                            :name="item.id.toString()"
                                        >
                                            <l-icon
                                                :icon-anchor="[15,30]"
                                            >
                                                <user-icon
                                                    :icon-color="colors[userIndex]"
                                                    :text="index"
                                                />
                                            </l-icon>
                                            <l-popup>
                                                <div>
                                                    <b>{{ item.user.short_name }}</b> <br>
                                                    Установил статус "{{ item.task_status.name }}" для задачи
                                                    №{{ item.task_id }}
                                                </div>
                                            </l-popup>
                                        </l-marker>
                                    </template>
                                </template>
                            </template>
<!--                            <v-polyline-decorator :paths="[userGeoPolyline]" :patterns="patterns"></v-polyline-decorator>-->
                            <l-polyline
                                v-if="userGeoPolyline.length"
                                :lat-lngs="userGeoPolyline"
                                :color="polyline.color"
                            />

                        </l-map>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import L from "leaflet";
import {LIcon, LMap, LMarker, LPolyline, LPopup, LTileLayer, LTooltip} from "vue2-leaflet";
import BaseLeftMenu from "../BaseComponents/BaseLeftMenu";
import ObjectIcon from "./ObjectIcon";
import UserIcon from "./UserIcon";
import BaseUsersCheckboxList from "../BaseComponents/BaseUsersCheckboxList";
import requiredDateRules from "../../Rules/requiredDateRules";
// import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'
// import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

export default {
    name: "ObjectMap",
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LTooltip,
        BaseLeftMenu,
        LIcon,
        LPolyline,
        ObjectIcon,
        UserIcon,
        BaseUsersCheckboxList,
        // 'v-polyline-decorator': Vue2LeafletPolylineDecorator
        // 'v-marker-cluster': Vue2LeafletMarkerCluster
    },
    data() {
        return {
            colors: ["#7690C9", "#769897", "#D6E9D8", "#EC9C9C", "#B7CBD8", "#FCD5A6", "#470000", "pink", "purple",
                "deep-purple", "indigo", "blue", "cyan", "teal", "green", "lime", "yellow", "amber",
                "orange", "brown", "blue-grey", "grey"
            ],
            zoom: 13,
            center: L.latLng(45.0990572, 39.0090881),
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',


            showParagraph: true,
            mapOptions: {
                zoomSnap: 0.5
            },
            loading: true,
            dataList: [],
            selectedPoint: [],

            selectedUser: null,
            date: null,
            usersPositionList: [],

            polyline: {
                latlngs: [[47.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
                color: 'green'
            },

            userGeoPolyline: [],

            clusterOptions: {
                showCoverageOnHover: false,
                zoomToBoundsOnClick: true,
            },
            patterns: [
                {offset: 25, repeat: 50, symbol: L.Symbol.arrowHead({pixelSize: 15, polygon: false, pathOptions: {stroke: true}})}
            ]
        };
    },

    computed: {
        height: function () {
            if (this.$vuetify.breakpoint.width < 992) {
                return "500px"
            } else {
                return this.$vuetify.breakpoint.height - 95
            }
        },
        requiredDateRules() {
            return requiredDateRules
        }
    },
    watch: {
        selectedUser: function () {
            if (this.selectedUser && this.date) {
                this.getUsersPositionList()
                this.getUsersPolylineList()
            } else {
                this.usersPositionList = []
                this.userGeoPolyline = []
            }

        },
        date: function () {
            if (this.selectedUser && this.date) {
                this.getUsersPositionList()
                this.getUsersPolylineList()
            } else {
                this.usersPositionList = []
                this.userGeoPolyline = []
            }
        },

        selectedPoint: {
            handler: function () {
                this.getObjectList();
            },
            immediate: true
        },
    },
    created() {
        //Здесь нужно получить текущие координаты пользователя, чтоб отцентровать по ним карут
        this.$getLocation({
            enableHighAccuracy: true, //defaults to false
            timeout: Infinity, //defaults to Infinity
            maximumAge: 0 //defaults to 0
        })
            .then(coordinates => {
                // console.log(coordinates)
                this.center = L.latLng(coordinates.lat, coordinates.lng)
            })
            .catch(error => {
                // console.log(error)
            });

        this.getObjectList()
    },
    methods: {
        rebuildObjectPosition(item) {
            if (item.geo_lat && item.geo_lon) {
                return L.latLng(item.geo_lat, item.geo_lon)
            } else if (item.address && item.address.geo_lat && item.address.geo_lon) {
                return L.latLng(item.address.geo_lat, item.address.geo_lon)
            }
            return false
        },
        getAddress(item) {
            if (item.address && item.address.result) {
                return item.address.result
            }
            return ""
        },
        rebuildUsersPosition(lat, lon) {
            return L.latLng(lat, lon)
        },

        getUsersPositionList() {
            if (this.selectedUser.length && this.date) {
                this.loading = true
                let data = {
                    selectedUser: this.selectedUser,
                    date: this.date
                }
                axios.post('/api/map/userspositionlist', data)
                    .then(response => {
                        this.usersPositionList = response.data
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                    .finally(() => {
                        this.loading = false
                    });
            }
        },

        getUsersPolylineList() {
            if (this.selectedUser && this.date) {
                this.loading = true
                axios.get('/api/geolocation', {
                    params: {
                        date: this.date,
                        user_id: this.selectedUser
                    }
                })
                    .then(response => {
                        console.log(response.data)
                        console.log(response.data?.coordinates)
                        this.userGeoPolyline = response.data.coordinates
                        this.loading = false
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                    .finally(() => {
                        this.loading = false
                    });
            }
        },

        getObjectList() {
            this.loading = true
            let data = {options: this.selectedPoint}
            axios.post('/api/map/objectlist', data)
                .then(response => {
                    this.dataList = response.data
                })
                .catch(error => {
                    this.showError(error)
                }).finally(() => {
                // this.$refs.clusterRef.mapObject.refreshClusters()
                this.loading = false
            });
        },
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
        showLongText() {
            this.showParagraph = !this.showParagraph;
        },
        innerClick() {
            // alert("Click!");
        }
    }
}
</script>

<style>
@import '~leaflet/dist/leaflet.css';
</style>
