<template>
    <v-menu
        bottom
        close-on-content-click
        left
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
            >
                {{ filterName|uppercase }}
            </v-btn>
        </template>

        <v-list>
            <v-list-item
                v-for="(item, index) in filterList"
                :key="index"
                v-on:click="$emit('click', item)"
                v-on:keydow="$emit('keydow', item)"
            >
                <v-list-item-title>{{ item.name|uppercase }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {VBtn, VList, VListItem, VListItemTitle, VMenu} from 'vuetify/lib'

export default {
    name: "TaskListFilterMenu",
    components: {
        VMenu, VBtn, VList, VListItem, VListItemTitle
    },
    props: {
        filterList: {
            requred: true
        },
        filterName: {
            requred: true
        }
    },
    filters: {
        uppercase: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.toUpperCase()
        }
    },

    methods: {
        onClick(item) {

        }
    }
}
</script>

<style scoped>
/*.task-list-filter-btn >>> .v-input__control {*/
/*    height: 32px;*/
/*}*/
</style>