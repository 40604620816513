<template>
    <v-dialog v-model="display" :width="dialogWidth">
        <template v-slot:activator="{ on }">
            <v-text-field
                :class="classObject"
                :disabled="disabled"
                :hide-details="hideDetails"
                :label="label"
                :loading="loading"
                :readonly="readonly"
                :value="formattedDatetime"
                readonly
                v-bind="textFieldProps"
                v-on="!disabled && !readonly ? on : ''"
            >
                <template v-slot:progress>
                    <slot name="progress">
                        <v-progress-linear absolute color="primary" height="2" indeterminate></v-progress-linear>
                    </slot>
                </template>
            </v-text-field>
        </template>

        <v-card>
            <v-card-text class="px-0 py-0">
                <v-tabs v-model="activeTab" fixed-tabs>
                    <v-tab key="calendar">
                        <slot name="dateIcon">
                            <v-icon>event</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab key="timer" :disabled="dateSelected">
                        <slot name="timeIcon">
                            <v-icon>access_time</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab-item key="calendar">
                        <v-date-picker v-model="date" full-width v-bind="datePickerProps"
                                       @input="showTimePicker"></v-date-picker>
                    </v-tab-item>
                    <v-tab-item key="timer">
                        <v-time-picker
                            ref="timer"
                            v-model="time"
                            class="v-time-picker-custom"
                            full-width
                            v-bind="timePickerProps"
                        ></v-time-picker>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <slot :parent="this" name="actions">
                    <v-btn color="grey lighten-1" text @click.native="clearHandler">{{ clearText }}</v-btn>
                    <v-btn color="green darken-1" text @click="okHandler">{{ okText }}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import {format, parse} from "date-fns";
import {shortDateYearFirst, shortTime} from "../../mixins/commonMethod.js";

const DEFAULT_DATE = ''
const DEFAULT_TIME = '00:00:00'
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT = 'CLEAR'
const DEFAULT_OK_TEXT = 'OK'

export default {
    name: "BaseDateTimePicker",
    model: {
        prop: 'datetime',
        event: 'input'
    },
    props: {
        classObject: {},
        datetime: {
            type: [String],
            default: null
        },
        disabled: {
            type: Boolean
        },
        readonly: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean
        },
        label: {
            type: String,
            default: ''
        },
        dialogWidth: {
            type: Number,
            default: DEFAULT_DIALOG_WIDTH
        },
        dateFormat: {
            type: String,
            default: DEFAULT_DATE_FORMAT
        },
        timeFormat: {
            type: String,
            default: 'HH:mm'
        },
        clearText: {
            type: String,
            default: DEFAULT_CLEAR_TEXT
        },
        okText: {
            type: String,
            default: DEFAULT_OK_TEXT
        },
        textFieldProps: {
            type: Object
        },
        datePickerProps: {
            type: Object
        },
        timePickerProps: {
            type: Object
        },
        hideDetails: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            display: false,
            activeTab: 0,
            date: DEFAULT_DATE,
            time: DEFAULT_TIME
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        dateTimeFormat() {
            return this.dateFormat + ' ' + this.timeFormat
        },
        defaultDateTimeFormat() {
            return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT
        },
        formattedDatetime() {
            return this.selectedDatetime ? this.selectedDatetime : ''
            // return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : ''
        },
        selectedDatetime() {
            if (this.date && this.time) {
                return this.date + ' ' + this.time
                // if (this.time.length === 5) {
                //     datetimeString += ':00'
                // }
                // return shortDateWithTime(datetimeString)
                // return parse(datetimeString, this.defaultDateTimeFormat, new Date())
            } else {
                return null
            }
        },
        dateSelected() {
            return !this.date
        }
    },
    methods: {
        init() {
            if (!this.datetime) {
                return
            }
            // console.log(this.datetime)
            this.date = shortDateYearFirst(this.datetime)
            this.time = shortTime(this.datetime)
        },
        okHandler() {
            this.resetPicker()
            this.$emit('input', this.selectedDatetime)
        },
        clearHandler() {
            this.resetPicker()
            this.date = DEFAULT_DATE
            this.time = DEFAULT_TIME
            this.$emit('input', null)
        },
        resetPicker() {
            this.display = false
            this.activeTab = 0
            if (this.$refs.timer) {
                this.$refs.timer.selectingHour = true
            }
        },
        showTimePicker() {
            this.activeTab = 1
        }
    },
    watch: {
        datetime: function () {
            this.init()
        }
    }
}
</script>

<style scoped>
.v-time-picker-custom :deep(.v-picker__title) {
    height: 84px;
    padding-top: 10px;
}

.font-size {
    font-size: .85rem;
}

.cursor-pointer :hover {
    cursor: pointer;
}
</style>
