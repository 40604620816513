<template>
    <v-snackbar
        v-model="snackbar"
        color="info"
        multi-line
        style="z-index: 999999"
        top
    >
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn
                text
                @click="snackbar = false"
            >
                <v-icon>
                    {{ $store.state.Icon.svgMdiClose }}
                </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "BaseInfoSnackbar",
    props: {
        value: false,
        text: String,
    },
    data: function () {
        return {
            snackbar: false,
        }
    },
    watch: {
        value: function () {
            this.snackbar = this.value
        },
        snackbar: function () {
            this.$emit('input', this.snackbar)
        }
    }
}
</script>

<style scoped>

</style>
