<template>
    <g transform="matrix(.15942 0 0 .15953 0 2.5)">
        <path :fill="iconColor"
              d="M128.89.23h24.99c1.62 0 2.94 1.32 2.94 2.94v24.99c0 1.62-1.32 2.94-2.94 2.94h-24.99c-1.62 0-2.94-1.32-2.94-2.94V3.17c0-1.62 1.32-2.94 2.94-2.94z"/>
        <path :fill="iconColor"
              d="M128.89 94.5h24.99c1.62 0 2.94 1.32 2.94 2.94v24.99c0 1.62-1.32 2.94-2.94 2.94h-24.99c-1.62 0-2.94-1.32-2.94-2.94V97.44c0-1.62 1.32-2.94 2.94-2.94z"/>
        <path :fill="iconColor"
              d="M128.89 47.37h24.99c1.62 0 2.94 1.32 2.94 2.94V75.3c0 1.62-1.32 2.94-2.94 2.94h-24.99c-1.62 0-2.94-1.32-2.94-2.94V50.31c0-1.62 1.32-2.94 2.94-2.94z"/>
        <path :fill="iconColor"
              d="M61.61 42.15h33.43c2.17 0 3.94 1.77 3.94 3.94v33.43c0 2.17-1.77 3.94-3.94 3.94H61.61c-2.17 0-3.94-1.77-3.94-3.94V46.09c0-2.17 1.77-3.94 3.94-3.94z"/>
        <path :fill="iconColor"
              d="M27.93 0H2.94C1.32 0 0 1.32 0 2.94v24.99c0 1.62 1.32 2.94 2.94 2.94h24.99c1.62 0 2.94-1.32 2.94-2.94V2.94c0-1.62-1.32-2.94-2.94-2.94z"/>
        <path :fill="iconColor"
              d="M27.93 94.28H2.94C1.32 94.28 0 95.6 0 97.22v24.99c0 1.62 1.32 2.94 2.94 2.94h24.99c1.62 0 2.94-1.32 2.94-2.94V97.22c0-1.62-1.32-2.94-2.94-2.94z"/>
        <path :fill="iconColor"
              d="M27.93 47.37H2.94C1.32 47.37 0 48.69 0 50.31V75.3c0 1.62 1.32 2.94 2.94 2.94h24.99c1.62 0 2.94-1.32 2.94-2.94V50.31c0-1.62-1.32-2.94-2.94-2.94z"/>
        <path :fill="iconColor"
              d="M61.41 60.72H46.53V16.98c0-2.12-1.73-3.85-3.85-3.85H28.03v4.78h13.96v42.81H28.11v4.53h13.88v42.45H28.03v4.78h18.5V65.25h14.88zm35.34 0h13.53V16.98c0-2.12 1.73-3.85 3.85-3.85h13.61v4.78h-12.91v42.81h12.91v4.53h-12.91v42.45h12.91v4.78h-17.45V65.25H96.76v-4.53z"/>
    </g>
</template>

<script>
export default {
    name: "HierarchyIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
