<template>
    <v-card :loading="loading">
        <v-form
            ref="Form"
            v-model="Valid"
        >
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-tabs v-model="tab" centered grow>
                    <v-tab key="data">Данные</v-tab>
                    <v-tab key="systems">Системы</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item key="data">
                        <div class="scrol-div">
                            <v-col class="px-1 pt-5 pb-0" cols="12">
                                <v-text-field
                                    v-model="editedItem.name"
                                    :rules="requiredRules"
                                    label="Название группы"
                                    required>
                                    <template v-slot:label>
                                        Название группы<sup>*</sup>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col class="px-1 py-0" cols="12">
                                <v-text-field
                                    v-model="editedItem.description"
                                    label="Описание">
                                </v-text-field>
                            </v-col>
                            <v-row align="center" justify="center">
                                <v-col class="shrink" style="min-width: 220px;">
                                    <v-text-field
                                        v-model="editedItem.color"
                                        :mask="colorMask"
                                        class="ma-0 pa-0"
                                        hide-details
                                        solo
                                    >
                                        <template v-slot:append>
                                            <v-menu
                                                v-model="colorMenu"
                                                :close-on-content-click="false"
                                                nudge-bottom="105" nudge-left="16"
                                                top
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <div :style="swatchStyle" v-on="on"/>
                                                </template>
                                                <v-card>
                                                    <v-card-text class="pa-0">
                                                        <v-color-picker
                                                            v-model="editedItem.color"
                                                            flat
                                                            hide-mode-switch
                                                            mode="hexa"
                                                        />
                                                    </v-card-text>
                                                </v-card>
                                            </v-menu>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </div>
                    </v-tab-item>
                    <v-tab-item key="systems">
                        <div class="scrol-div">
                            <v-col class="p-0" cols="12">
                                <v-text-field
                                    v-model="systemsSearch"
                                    clearable
                                    label="Найти">
                                </v-text-field>
                            </v-col>
                            <v-treeview
                                v-model="editedItem.systems_id"
                                :items="allSystems"
                                :open.sync="treeOpen"
                                :search="systemsSearch"
                                dense
                                open-on-click
                                selectable
                            ></v-treeview>
                        </div>
                    </v-tab-item>
                </v-tabs-items>


            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="UserCan.ObjectGroup.Edit && editedItem.id" :disabled="inProcess" color="black" text
                       @click="del">Удалить
                </v-btn>
                <v-btn :disabled="inProcess" color="blue" text @click="close">Отмена</v-btn>
                <v-btn v-if="UserCan.ObjectGroup.Edit" :disabled="!Valid || inProcess" color="blue" text
                       @click="save">Сохранить
                </v-btn>
            </v-card-actions>
        </v-form>
        <base-error-snackbar
            v-model="Error"
            v-bind:error-text="ErrorText"
        />
    </v-card>
</template>

<script>

import BaseErrorSnackbar from "../BaseComponents/BaseErrorSnackbar";
import requiredRules from "../../Rules/requiredRules";

export default {
    name: "ObjectGroupForm",
    components: {BaseErrorSnackbar},
    props: {
        propsEditedIndex: 0, //Индекс редактируемого элемента
        propsEditedItem: null
    },
    data: function () {
        return {
            editedItem: Object, //Переменная с данными редактируемого элемента
            defaultItem: {
                name: null,
                description: null,
                color: "#FF0000FF",
                systems: [],
            },

            Valid: false, //Переменная для контроля валидности данных формы
            options: {},
            ErrorText: '',
            Error: false,
            tab: null,
            color: '#1976D2FF',
            colorMask: '!#XXXXXXXX',
            colorMenu: false,
            systemsSearch: null,
            allSystems: [],
            treeOpen: [],
            inProcess: false,
            loading: false,
        }
    },
    watch: {
        propsEditedIndex: {
            handler: function (val) {
                if (val) {
                    this.tab = 0
                    if (val === -1) {
                        this.editedItem = Object.assign({}, this.defaultItem)
                        this.color = '#1976D2FF'
                        this.getAllSystemsForNewGroup()
                    } else {
                        if (val !== 0) {
                            this.editedItem = Object.assign({}, this.propsEditedItem)
                            this.getAllSystemsForGroup(val)
                        }
                    }
                }
            },
            immediate: true,
        }
    },
    computed: {
        formTitle() {
            return this.propsEditedIndex === -1 ? "Создать группу объектов" : "Изменить группу объектов"
        },
        swatchStyle() {
            const {color, menu} = this
            return {
                backgroundColor: this.editedItem.color,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: menu ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
        requiredRules() {
            return requiredRules
        },
    },

    methods: {
        wait: function (act) {
            if (act === "start") {
                this.inProcess = true
                this.loading = true
            }
            if (act === "stop") {
                this.inProcess = false
                this.loading = false
            }
        },

        resetValidation() {
            this.Valid = false
            this.$refs.Form.reset()
            this.$refs.Form.resetValidation()
        },

        getDataForGroup(groupID) {

        },

        getAllSystemsForGroup(groupID) {
            axios.get('/api/objectgroup/getallsystems/' + groupID)
                .then(response => {
                    this.allSystems = response.data
                    this.treeOpen = ["all-1"]
                })
                //если есть ошибки, то выводим текст ошибки
                .catch(error => {
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = "Не удалось загрузить список доступных систем."
                    }
                });
        },

        getAllSystemsForNewGroup() {
            axios.get('/api/objectgroup/getallsystemsfornew')
                .then(response => {
                    this.allSystems = response.data
                    this.treeOpen = ["all-1"]
                })
                //если есть ошибки, то выводим текст ошибки
                .catch(error => {
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = "Не удалось загрузить список доступных систем."
                    }
                });
        },

        close() {
            this.$emit('close')
            this.tab = 0

            this.Error = false
            this.ErrorText = ''
            this.wait("stop")
            setTimeout(() => {
                this.editedItem = Object.assign({}, {})
                this.resetValidation()
            }, 100)
        },


        save() {
            if (this.UserCan.ObjectGroup.Edit) {
                this.wait("start")
                const emitedData = Object.assign({}, this.editedItem)
                if (this.propsEditedIndex === -1) {
                    axios.put('/api/objectgroup/newgroup', emitedData)
                        .then(() => {
                            this.close()
                        })
                        //если есть ошибки, то выводим текст ошибки
                        .catch(error => {
                            if (error.response) {
                                this.Error = true
                                this.ErrorText = "Не удалось создать новую группу."
                            }
                            this.wait("stop")
                        });
                } else {
                    if (this.editedItem.id) {
                        axios.put('/api/objectgroup/' + this.editedItem.id, emitedData)
                            .then(() => {
                                this.close()
                            })
                            //если есть ошибки, то выводим текст ошибки
                            .catch(error => {
                                if (error.response) {
                                    this.Error = true
                                    this.ErrorText = "Не удалось изменить группу."
                                }
                                this.wait("stop")
                            });
                    }
                }
            }
        },

        del() {
            if (this.UserCan.ObjectGroup.Edit) {
                if (this.editedItem.id) {
                    if (confirm("Удалить группу объектов?")) {
                        this.wait("start")
                        axios.delete('/api/objectgroup/' + this.editedItem.id)
                            .then(() => {
                                this.close()
                            })
                            //если есть ошибки, то выводим текст ошибки
                            .catch(error => {
                                //console.log(error.request.status)
                                if (error) {
                                    this.Error = true
                                    this.ErrorText = "Не удалось удалить группу."
                                }
                                this.wait("stop")
                            });
                    }
                }
            }
        },

    }
}
</script>

<style scoped>
.scrol-div {
    overflow: auto;
    overflow-x: hidden;
    height: 590px;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 6px; /* высота для горизонтального скролла */
    background-color: #ffffff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #3273a7;
}

</style>
