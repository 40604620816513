<template>
    <g transform="matrix(.04367 0 0 .04926 0 0)">
        <path :fill="iconColor"
              d="M92.99 507.53l144.52-.32-.02-131.68 93.97-.13.35 131.61 134.3-.03c18.25.08 22.7-3.19 22.7-21.71V261.02L314.79 106.84c-8.4-7.18-16.58-16.81-25.27-22.45-4.01 1.87-2 1.04-5.56 3.79l-82.67 69.31c-30.13 26.7-77.31 64.44-110.42 92.28-11.76 9.89-13.6 7.66-13.5 28.21v202.67c-.04 15.11-2.45 26.86 15.62 26.86z"/>
        <path :fill="iconColor"
              d="M0 254.15c4.75 17.25 27.59 20.34 44.62 10.82 6.75-3.78 12.99-9 18.35-13.9L255.11 88.55c5.76-4.89 12.25-9.8 17.56-14.68 5.08-4.67 12.92-11.67 18.38-14.84L490.32 236.9c12.1 9.68 18.32 19.4 35.57 27.38 18.81 8.7 38.39 6.22 46.61-9.29L309.27 15.69c-3.41-2.77-6.02-4.63-9.11-7.82-4.58-4.73-3.98-4.99-9.11-7.86-5.89 2.74-28.69 24.97-36.31 31.33L0 254.16z"/>
        <path :fill="iconColor"
              d="M505.19 172.91l.09-115.06-56.44-.4c-.82 13.84-.17 29-.17 42.45-.01 34.44-4.2 21.14 32.78 53.74 6.76 5.96 17.44 16.22 23.75 19.26z"/>
    </g>
</template>

<script>
export default {
    name: "ObjectsIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
