<template>
    <v-textarea
        v-model="localData"
        :clearable="!disabled && !read_only"
        :disabled="disabled"
        :readonly="read_only"
        auto-grow
        class="task-textarea pt-0 mt-0 align-items-stretch"
        hide-details
        rows="1"
        v-on:change="$emit('change', $event)"
    ></v-textarea>
</template>

<script>
export default {
    name: "TextArea",
    props: {
        value: null,
        extra_attributes: null,
        read_only: false,
        disabled: false,
    },
    data: function () {
        return {
            localData: null,

        }
    },
    created() {
        this.localData = this.value
    },
    watch: {
        value: function (val) {
            this.localData = val
        },
    }
}
</script>

<style scoped>
div.task-textarea {
    font-size: .85rem;
}

div.task-textarea :deep(.v-input__slot::before),
div.task-textarea :deep(.v-input__slot::before) {
    border-style: none;
}


div.task-textarea :deep(input[type="text"]) {
    padding: 3px 0 3px;
}

div.task-textarea :deep(textarea) {
    line-height: 1.25rem;

}
</style>
