<template>
    <v-container>
        <v-card v-if="UserCan.Profile.Update">
            <v-form
                ref="Form"
                v-model="Valid"
                lazy-validation
            >
                <v-card-title>
                    <span class="headline">Личные данные</span>
                </v-card-title>
                <v-card-text>
                    <v-col class="px-1 pt-5 pb-0" cols="12">
                        <v-text-field
                            ref="login"
                            v-model="editedItem.login"
                            :disabled="loginDisabled"
                            :rules="requiredRules"
                            label="Логин"
                            required>
                            <template v-slot:label>
                                Логин<sup>*</sup>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col class="px-1 py-0" cols="12">
                        <v-text-field
                            ref="surname"
                            v-model="editedItem.surname"
                            :rules="requiredRules"
                            label="Фамилия"
                            required>
                            <template v-slot:label>
                                Фамилия<sup>*</sup>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col class="px-1 py-0" cols="12">
                        <v-text-field
                            ref="name"
                            v-model="editedItem.name"
                            :rules="requiredRules"
                            label="Имя"
                            required>
                            <template v-slot:label>
                                Имя<sup>*</sup>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col class="px-1 py-0" cols="12">
                        <v-text-field v-model="editedItem.patronymic" label="Отчество"></v-text-field>
                    </v-col>
                    <v-col class="px-1 py-0" cols="12">
                        <v-text-field v-model="editedItem.position" label="Должность"></v-text-field>
                    </v-col>
                    <v-col class="px-1 py-0" cols="12">
                        <v-text-field v-model="editedItem.tel" label="Номер телефона"></v-text-field>
                    </v-col>
                    <v-col class="px-1 py-0" cols="12">
                        <v-text-field
                            ref="email"
                            v-model="editedItem.email"
                            :rules="requiredEmailRules"
                            label="Электронная почта">
                            <template v-slot:label>
                                Электронная почта<sup>*</sup>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-card-text>
                <v-card-actions class="justify-md-end justify-center">
                    <v-row>
                        <v-col class="d-flex justify-end justify-sm-center" cols="12" md="3" sm="6">
                            <v-btn
                                color="blue-grey" text
                                @click="connectToTelegram"
                            >
                                <v-icon dark left>{{ $store.state.Icon.svgMdiTelegram }}</v-icon>
                                Подключить бота
                            </v-btn>
                        </v-col>
                        <v-col class="d-flex justify-end justify-sm-center" cols="12" md="3" sm="6">
                            <v-btn
                                color="blue-grey" text
                                @click="forgetTelegramConnection"
                            >
                                Отключить бота
                            </v-btn>
                        </v-col>
                        <v-col class="d-flex justify-end justify-sm-center" cols="12" md="3" sm="6">
                            <v-btn color="blue" text @click="changePassword">Изменить пароль</v-btn>
                        </v-col>
                        <v-col class="d-flex justify-end justify-sm-center" cols="12" md="3" sm="6">
                            <v-btn v-if="UserCan.User.Update || UserCan.User.Create" :disabled="!Valid" color="blue"
                                   text
                                   @click="save">Сохранить
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-actions>
            </v-form>
        </v-card>

        <base-modal v-model="changePasswordDialog">
            <v-card v-if="UserCan.Profile.Update">
                <v-form
                    ref="passwordForm"
                    v-model="validPassword"
                >
                    <v-card-title>
                        <span class="headline">Изменить пароль</span>
                    </v-card-title>
                    <v-card-text>
                        <label hidden>
                            <input :value="editedItem.login" autocomplete="username" hidden type="text">
                        </label>
                        <v-col class="px-1 py-0" cols="12">
                            <v-text-field
                                v-model="oldPassword"
                                :append-icon="showOldPassword ? $store.state.Icon.svgMdiEye : $store.state.Icon.svgMdiEyeOff"
                                :rules="[rules.required, rules.oldMin]"
                                :type="showOldPassword ? 'text' : 'password'"
                                autocomplete="current-password"
                                label="Старый пароль"
                                validate-on-blur
                                @click:append="showOldPassword = !showOldPassword"
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1 py-0" cols="12">
                            <v-text-field
                                v-model="newPassword"
                                :append-icon="showNewPassword ? $store.state.Icon.svgMdiEye : $store.state.Icon.svgMdiEyeOff"
                                :rules="[rules.required, rules.min, rules.unique]"
                                :type="showNewPassword ? 'text' : 'password'"
                                autocomplete="new-password"
                                counter
                                hint="Пароль должен содержать минимум 6 символов"
                                label="Новый пароль"
                                persistent-hint
                                validate-on-blur
                                @click:append="showNewPassword = !showNewPassword"
                            ></v-text-field>
                        </v-col>
                        <v-col class="px-1 py-0" cols="12">
                            <v-text-field
                                v-model="newPasswordConfirm"
                                :append-icon="showNewPasswordConfirm ? $store.state.Icon.svgMdiEye : $store.state.Icon.svgMdiEyeOff"
                                :rules="[rules.required, rules.match]"
                                :type="showNewPasswordConfirm ? 'text' : 'password'"
                                autocomplete="new-password"
                                label="Повторите новый пароль"
                                validate-on-blur
                                @click:append="showNewPasswordConfirm = !showNewPasswordConfirm"
                            ></v-text-field>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue" text @click="closeChangePassword">
                            Отмена
                        </v-btn>
                        <v-btn :disabled="validChangePassword()" color="blue" text @click="saveChangedPassword">
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </base-modal>


    </v-container>
</template>

<script>
import BaseModal from "../BaseComponents/BaseModal";
import requiredRules from "../../Rules/requiredRules";
import requiredEmailRules from "../../Rules/requiredEmailRules";

export default {
    name: "Profile",
    components: {BaseModal},
    data: function () {
        return {
            changePasswordDialog: false,
            Valid: true, //Переменная для контроля валидности данных формы
            loginDisabled: true,
            editedItem: this.getDefaultData(),
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
            showOldPassword: false,
            showNewPassword: false,
            showNewPasswordConfirm: false,
            rules: {
                required: v => !!v || 'Это обязательное поле.',
                oldMin: v => v.length >= 4 || 'Вы неправильно ввели старый пароль.',
                min: v => v.length >= 6 || 'Должно быть минимум 6 символов.',
                unique: v => v !== this.oldPassword || 'Новый пароль должен отличаться от старого.',
                match: v => v === this.newPassword || 'Парольи отличаются.',
            },
            validPassword: false,
        }
    },

    created() {
        this.getData()

    },
    computed: {
        requiredRules() {
            return requiredRules
        },
        requiredEmailRules() {
            return requiredEmailRules
        }
    },


    methods: {
        getDefaultData() {
            return {
                login: null,
                surname: null,
                name: null,
                patronymic: null,
                position: null,
                tel: null,
                email: null,
            }
        },

        getData() {
            axios.get('/api/user/profile')
                .then(response => {
                    this.editedItem = response.data
                })
        },
        changePassword() {
            this.changePasswordDialog = true
        },
        save() {
            let emitedData = Object.assign({}, this.editedItem)
            axios.post('/api/user/updateProfile', emitedData)
                .then(response => {
                    alert("Данные успешно изменены.")
                })
        },
        validChangePassword() {
            return !(this.oldPassword &&
                this.newPassword &&
                this.newPasswordConfirm &&
                this.newPassword === this.newPasswordConfirm &&
                this.newPassword !== this.oldPassword &&
                this.newPassword.length >= 6 &&
                this.oldPassword.length >= 4
            );
        },
        closeChangePassword() {
            this.oldPassword = ''
            this.newPassword = ''
            this.newPasswordConfirm = ''
            // this.$refs.passwordForm.reset()
            this.$refs.passwordForm.resetValidation()
            this.changePasswordDialog = false
        },
        saveChangedPassword() {
            let emitedData = Object.assign({}, {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
                newPasswordConfirm: this.newPasswordConfirm
            })
            axios.post('/api/user/updatePswd', emitedData)
                .then(response => {
                    this.closeChangePassword()
                    window.location.reload()
                })
        },
        forgetTelegramConnection() {
            if (confirm("Вы уверены, что хотите отключить телеграм бот? Вам больше не будут приходить уведомления.")) {
                axios.get('/api/user/telegram/forgetConnect')
                    .then(response => {
                        this.showInfo(response.data.message)
                    })
                    .catch(error => {
                        this.showError(error)
                    })
            }
        },
        connectToTelegram() {
            axios.get('/api/user/telegram/getTempToken')
                .then(response => {
                    window.open('https://t.me/CRMAssistmanRuInfoBot?start=' + response.data, '_blank');
                })
                .catch(error => {
                    this.showError(error)
                })
        }
    }
}
</script>

<style scoped>

</style>
