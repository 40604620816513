import { render, staticRenderFns } from "./ObjectCardContractDescription.vue?vue&type=template&id=5bab2b4d&scoped=true"
import script from "./ObjectCardContractDescription.vue?vue&type=script&lang=js"
export * from "./ObjectCardContractDescription.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bab2b4d",
  null
  
)

export default component.exports