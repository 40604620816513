<template>
    <v-card
        :loading="loading">
        <v-col cols="12">
            <v-row v-if="!this.loading">
                <v-col cols="12" offset-xl="1" xl="10">
                    <v-toolbar color="corpColor" dark height="50px">
                        <v-toolbar-title class="font-weight-bold text-h5">Карточка объекта {{ objectCardData.name }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn dark icon @click="close">
                            <v-icon>{{ $store.state.Icon.svgMdiClose }}</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-col class="px-0 pb-5 pt-0">
                        <table class="taskHeaderTable elevation-3">
                            <tbody>
                            <tr>
                                <td class="taskMainTableTitle">Название объекта</td>
                                <td class="taskMainTableRow">
                                    {{ objectCardData.name }}
                                </td>
                            </tr>
                            <tr>
                                <td class="taskMainTableTitle">Тип объекта</td>
                                <td class="taskMainTableRow">
                                    <template v-if="objectCardData.type">
                                        {{ objectCardData.type }}
                                    </template>
                                    <template v-else>
                                        Тип не указан
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td class="taskMainTableTitle">Адрес объекта</td>
                                <td class="taskMainTableRow">
                                    <a :href="locationLink"
                                       class="noDecor"
                                       target="_blank"
                                    >
                                        {{ objectCardData.address }}
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </v-col>
                    <v-col class="px-0 pb-1 text-center font-italic font-weight-bold">
                        <h3>Системы установленные на объекте</h3>
                    </v-col>
                    <v-card-text>
                        <object-card-system-types
                            :system-types="objectCardData.systemTypes"
                            :object-id="objectId"
                        />
                    </v-card-text>
                </v-col>
            </v-row>
        </v-col>

    </v-card>
</template>

<script>

import ObjectCardSystemTypes from "./ObjectCardSystemTypes.vue";

export default {
    name: "ObjectCard",
    components: {
        ObjectCardSystemTypes
    },
    props: {
        objectItem: {}
    },
    data: function () {
        return {
            loading: true,
            objectCardDataDefault: {},
            objectCardData: {},
            objectId: null,
        }
    },

    created() {
        this.objectId = this.$route.name === "objectcard" ? this.$route.params.objectid : this.objectItem.id
        this.getData()
    },

    computed: {
        locationLink: function () {
            return "https://maps.yandex.ru/?text=" + this.objectCardData.geo_lat + "," + this.objectCardData.geo_lon
        },
    },

    watch: {
        objectItem: function (val) {
            if (val) {
                this.objectId = val.id
                this.getData();
            }
        },
    },
    methods: {

        close() {
            this.$emit('close', false)
            if (this.$route.name === "objectcard") {
                window.close()
            }
            this.objectCardData = Object.assign({}, this.objectCardDataDefault)
            this.loading = true
        },

        getData() {
            axios.get('/api/objectcard/' + this.objectId)
                .then(response => {
                    // console.log(response)
                    this.objectCardData = response.data
                })
                .catch(error => {
                    this.showError(error)
                }).finally(() => {
                this.loading = false
            });
        },

    }
}
</script>

<style scoped>

.object-card-table {
    border: #99B1C6 1px solid;
    width: 100%;
    border-collapse: collapse;
}

.object-card-table > tr > td {

}


tr.simple-table-row > td {
    font-size: 13px !important;
}


.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px !important;
}


.object-card-expansion-panel :deep(.object-card-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 17px;
}

.object-card-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 17px !important;
}

.object-card-contract-expansion-panel :deep(.object-card-contract-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 15px;
}

.object-card-contract-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 15px !important;
}

.object-card-task-expansion-panel :deep(.object-card-task-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 15px;
}

.object-card-task-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 15px !important;
}

.object-card-task-expansion-panel-active > .v-expansion-panel-content {
    background-color: #ffffff;
    color: #000000;
}

.object-card-expansion-panel :deep(.v-expansion-panel-content__wrap) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

a.noDecor {
    color: rgba(0, 0, 0, 0.87) !important;
    text-decoration: none;
}

a.noDecor:link,
a.noDecor:hover,
a.noDecor:active {
    color: rgba(0, 0, 0, 0.87);
}

</style>
