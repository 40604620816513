<template>
    <g transform="matrix(15.267176,0,0,15.267176,20,20)">
        <polygon
            class="fil0"
            points="0.76,3.93 1.96,2.73 3.17,3.93 3.93,3.17 2.73,1.96 3.93,0.76 3.17,0 1.96,1.2 0.76,0 0,0.76 1.2,1.96 0,3.17 "/>
    </g>
</template>

<script>
export default {
    name: "CanceledStatus"
}
</script>

<style scoped>
.fil0 {
    fill: #285473
}

.fil1 {
    fill: #fefefe
}
</style>
