<template>
    <div>
        <template
            v-for="(item, index) in custom_data_settings"
        >
            <object-card-row
                v-if="item.type_key !== customDataDirectoryName"
                :content='getSystemDataByFieldName(item.name)'
                :title="item.name"
            />
        </template>
        <div class="object-card-table-last">

        </div>

        <template
            v-for="(item, index) in custom_data_settings"
        >
            <object-card-directory-row
                v-if="item.type_key === customDataDirectoryName"
                :field="item"
                :local-data="getSystemDataByFieldName(item.name)"
            />

        </template>

    </div>
</template>

<script>
import ObjectCardRow from "./ObjectCardRow";
import ObjectCardDirectoryRow from "./ObjectCardDirectoryRow";
import CustomDataTypesName from "../BaseCustomDataComponents/CustomDataFieldsType/CustomDataTypesName";

export default {
    name: "ObjectCardSystemDataRow",
    components: {ObjectCardRow, ObjectCardDirectoryRow},
    props: {
        custom_data_settings: {
            required: true
        },
        data: {
            required: true
        },
    },
    computed: {
        customDataDirectoryName() {
            return CustomDataTypesName.Directory
        }
    },
    methods: {
        getSystemDataByFieldName(fieldName) {
            return this.data[fieldName] ? this.data[fieldName] : null
        }
    }
}
</script>

<style scoped>

</style>