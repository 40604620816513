<template>
    <g transform="scale(.04112 .04286)">
        <path :fill="iconColor"
              d="M19.4 0h569.11c10.67 0 19.4 8.73 19.4 19.4v661.22c0 10.67-8.73 19.4-19.4 19.4H19.4c-10.67 0-19.4-8.73-19.4-19.4V19.4C0 8.73 8.73 0 19.4 0zm541.14 47.37H47.37v605.27h513.17V47.37z"/>
        <path :fill="iconColor" d="M204.61 212.91h261.25v26.54H204.61z"/>
        <path :fill="iconColor" d="M204.61 304.58h261.25v26.54H204.61v-26.54z"/>
        <path :fill="iconColor" d="M204.61 396.25h261.25v26.54H204.61v-26.54z"/>
        <path :fill="iconColor" d="M204.61 487.92h261.25v26.54H204.61z"/>
        <path :fill="iconColor" d="M142.05 212.91h26.54v26.54h-26.54z"/>
        <path :fill="iconColor" d="M142.05 304.58h26.54v26.54h-26.54v-26.54z"/>
        <path :fill="iconColor" d="M142.05 396.25h26.54v26.54h-26.54v-26.54z"/>
        <path :fill="iconColor" d="M142.05 487.92h26.54v26.54h-26.54z"/>
    </g>
</template>

<script>
export default {
    name: "TasksListIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
