window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// window.axios.defaults.withCredentials = true; //Если включить это, то ломаются запросы между доменами (CORS) а это нужно для подсказок DaData
// window.axios.defaults.headers.common['X-Location'] = 'paramparampam';
