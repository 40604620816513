<template>
    <g transform="matrix(.64188 0 0 .6365 0 4.501)">
        <rect :fill="iconColor" height="12.17" rx=".78" ry=".78" transform="rotate(20.783 2.664 11.042)" width="3.37"
              x="0"
              y="0"/>
        <path :fill="iconColor"
              d="M9.75 18.8c-1.1.3-2.04-.84-1.56-1.82.38-.52.82-.95 1.34-1.23.94-.51 2.28.88 1.51 2.14-.25.32-.65.62-1.28.92z"/>
        <path :fill="iconColor"
              d="M11.52 18.77c.55-.78 1.2-1.43 2.49-2.53 1.33-1.08 2.51.3 1.81 1.91-.64.8-1.39 1.31-2.36 2.16-1.11.79-2.54-.05-1.94-1.54z"/>
        <path :fill="iconColor"
              d="M14.19 21.1c.97-1.09 2.04-2.11 3.27-3.03 1.33-.89 2.44.45 1.78 1.77-1.02 1.21-2.15 2.34-3.48 3.29-1.34.53-2.13-.87-1.57-2.04z"/>
        <path :fill="iconColor"
              d="M17.62 23.41c.54-.97 1.25-1.73 2.08-2.33 1.25-.48 1.94.68 1.46 1.96-.44.84-1.03 1.41-1.87 2.04-1.22.31-1.97-.73-1.67-1.67z"/>
        <path :fill="iconColor"
              d="M24.18 21.33c.2 1.4-.53 2.34-1.94 2.28l-.27.49c1.66.64 2.87-.59 2.91-2.21 1.58.77 3.62-.29 3.46-1.94 1.73.57 3.35-.49 3.2-2.33l-.77.39c-.12.82-.58 1.32-1.62 1.28-1.19-.1-3.11-3.14-4.66-4.72-.25.12-.47.24-.46.64 1.2 1.42 3.24 3.46 3.53 4.19-.06 1.51-.61 2.39-2.31 1.8-1.05-.84-2.44-2.55-3.82-4.22-.24-.05-.56.33-.47.6.98 1.28 2.69 2.83 3.2 3.74z"/>
        <path :fill="iconColor"
              d="M29.78 3.14c1.65 3.11 3.29 6.23 4.94 9.34-.83 1.68-2.19 3.02-3.82 4.19-1.04.01-9.55-11.15-10.44-10.96-2.18 1.17-4.36 2.34-6.54 3.52-1.55.54-2.41-1.08-1.81-1.88 2.47-2.3 6.24-5.04 9.91-6.11 2.31-.53 5.46 3.77 7.76 1.91z"/>
        <path :fill="iconColor"
              d="M8.39 1.76c.26-.44.69-.34 1.11-.1 2.02 2.98 5.75-2.16 8.98-.46-.11.16-.37.35-.73.54-2.42-1-5.85 3.7-8.87.76-1.18 3.17-2.36 6.34-3.54 9.52-.07.31 1.84 2.16 2.76 3.24l-.52.67c-1.04-1.2-3.08-3.11-3.11-3.64-.18-.72 2.61-7.01 3.92-10.51z"/>
        <rect :fill="iconColor" fill="#285473" height="12.17" rx=".78" ry=".78"
              transform="rotate(-27.349 18.757 -62.116)" width="3.37" x="0"
              y="0"/>
    </g>
</template>

<script>
export default {
    name: "ContractsIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
