<template>
    <base-modal v-model="value">
        <base-modal v-model="PersonDialog">
            <PersonForm
                v-bind:props-edited-index="-1"
                v-bind:props-edited-item="{}"
                v-on:close="onClosePersonForm"
                v-on:return="onReturnDataFormLocal"
            />
        </base-modal>

        <v-card>
            <v-card-title>
                <span class="headline">Изменить ответственного</span>
            </v-card-title>
            <v-card-text>
                <v-combobox
                    v-model="observer"
                    :items="headData"
                    :search-input.sync="searchHead"
                    item-text="full_name"
                    item-value="id"
                    label="Ответственный со стороны заказчика"
                    open-on-clear
                    return-object
                >
                    <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    "<strong>{{ searchHead }}</strong>" не найден. Нажмите кнопку "Добавить" если вы
                                    хотите добавить контакт
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-combobox>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" text @click="createPerson()">Добавить</v-btn>
                <v-btn color="blue" text @click="close()">Отмена</v-btn>
                <v-btn :disabled="disabledSave" color="blue" text @click="save">Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>

    </base-modal>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";
import PersonForm from "../../../Admin/Person/PersonForm";

export default {
    name: "TaskObserverEdit",
    components: {
        BaseModal,
        PersonForm
    },
    props: {
        value: {
            default: false
        },
    },
    data: function () {
        return {
            observerEditDialog: false,
            PersonDialog: false,
            observer: null,
            headData: [],
            searchHead: null,
        }
    },
    created() {
        this.getHeadData()
    },
    computed: {
        taskId: function () {
            return this.$store.getters["TaskData/getTaskId"]
        },

        disabled: function () {
            return this.$store.getters["TaskData/getDisabled"]
        },

        currentObserver: function () {
            return this.$store.getters["TaskData/getTaskData"].system.observer
        },

        disabledSave: function () {
            return !this.observer || !this.observer.id
        }
    },

    watch: {
        currentObserver: {
            handler: function (val) {
                this.observer = val ? val : null
            },
            immediate: true
        },
    },


    methods: {
        close() {
            this.$emit("input", false)
            this.observer = null
            this.searchHead = null
            this.$store.dispatch('TaskData/updateTaskData')
        },
        save() {
            let data = {observerId: this.observer.id}
            axios.put('/api/task/observer/' + this.taskId, data)
                .then(response => {
                    this.close()
                })
                //если есть ошибки, то выводим текст ошибки
                .catch(console.log.bind(console));
        },
        createPerson() {
            this.PersonDialog = true
        },
        onClosePersonForm() {
            this.PersonDialog = false
            this.getHeadData()
        },
        onReturnDataFormLocal(e) {
            // console.log(e)
            // this.observer= this.headData.find(item => item.full_name === e.surname+" "+e.name+" "+e.patronymic)
            this.observer = null
        },
        fullname(item) {
            return item.surname + ' ' + item.name + ' ' + item.patronymic
        },
        getHeadData() {
            axios.get('/api/person?fields[person]=id,short_name,full_name')
                .then(response => (this.headData = response.data))
                .catch(error => {
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = error.response.data.message
                    }
                });
        },
    }
}
</script>

<style scoped>

</style>
