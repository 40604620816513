<template>
    <base-modal
        v-if="directoryId && directoryData"
        v-model="openDialog"
        :fullscreen="fullscreen"
    >
        <v-card
            :loading="dataLoading"
        >
            <v-form
                ref="Form"
                v-model="valid"
            >
                <v-card-title>
                    <span class="headline">{{ directoryData.name }}</span>
                </v-card-title>

                <v-card-subtitle class="pb-1">
                    <span>{{ directoryData.description }}</span>
                </v-card-subtitle>

                <v-card-text>
                    <div class="scrol-div pt-1">
                        <div v-if="directoryData && directoryData.fields">
                            <template v-for="item in directoryData.fields">
                                <v-autocomplete
                                    v-if="item.show && item.show_when_choosing"
                                    v-model="result[item.name]"
                                    :items="items && items[item.name] ? items[item.name] : []"
                                    :label="item.name"
                                    auto-select-first
                                    class="py-1"
                                    clearable
                                    hide-details
                                    v-on:input="getDirectoryEntity"
                                />
                            </template>
                        </div>
                        <div v-if="directoryData && directoryData.additional_fields">
                            <template v-for="item in directoryData.additional_fields">
                                <component
                                    :is="item.type.key"
                                    v-if="item.type && checkAdditionalField(item.name)"
                                    v-model="result[item.name]"
                                    :added-rules="getRulesForAdditionalField(item, selectedItem?.max)"
                                    :extra_attributes="item.extra_attributes"
                                    :hide-details="false"
                                    :label="item.name"
                                    :required="item.required"
                                ></component>
                            </template>
                        </div>
                    </div>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
                    <v-btn :disabled="!valid || !selectedItem" color="blue darken-1" text @click="save">Сохранить
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </base-modal>


</template>

<script>

import String from "../../BaseCustomDataComponents/CustomDataFieldsType/String";
import TextArea from "../../BaseCustomDataComponents/CustomDataFieldsType/TextArea";
import Number from "../../BaseCustomDataComponents/CustomDataFieldsType/Number";
import Money from "../../BaseCustomDataComponents/CustomDataFieldsType/Money";
import Select from "../../BaseCustomDataComponents/CustomDataFieldsType/Select";
import MultiSelect from "../../BaseCustomDataComponents/CustomDataFieldsType/MultiSelect";
import DateTime from "../../BaseCustomDataComponents/CustomDataFieldsType/DateTime";
import Date from "../../BaseCustomDataComponents/CustomDataFieldsType/Date";
import Time from "../../BaseCustomDataComponents/CustomDataFieldsType/Time";
import Checkbox from "../../BaseCustomDataComponents/CustomDataFieldsType/Checkbox";
import _ from "lodash";
import requiredUnsignedIntegerRules from "../../../Rules/requiredUnsignedIntegerRules";
import BaseModal from "../BaseModal";

export default {
    name: "DirectoryItemSelect",
    components: {String, TextArea, Number, Money, Select, MultiSelect, DateTime, Date, Time, Checkbox, BaseModal},
    props: {
        openDialog: {
            type: Boolean,
            default: false
        },
        directoryId: {
            default: null,
            required: true
        },
        value: {
            default: null
        },
        warehouse: {
            type: Boolean,
            default: false
        },
        urlForItemSelect: {
            required: false
        },
    },
    data: function () {
        return {
            componentsList: Object.keys(this.$options.components),
            dataLoading: false,
            directoryData: {
                name: null,
                description: null,
            },
            result: {},
            valid: false,
            items: {},
            selectedItem: null,
            fullscreenWidth: 700,
        }
    },
    watch: {
        openDialog: {
            handler: function (newVal) {
                if (newVal && this.directoryId) {
                    this.result = {}
                    this.getDirectoryData(this.directoryId)
                }
            },
            immediate: true
        },
    },

    computed: {
        fullscreen: function () {
            return this.$vuetify.breakpoint.width < this.fullscreenWidth
        },

    },

    methods: {
        getRulesForAdditionalField(item, max = null) {
            let rules = []
            if (item?.name === 'Количество' && item?.type?.key === 'Number') {
                rules = rules.concat(requiredUnsignedIntegerRules)
                if (max && max > 0) {
                    const rule = v => parseFloat(v) <= max || 'Это поле должно быть меньше или равно ' + parseInt(max)
                    rules.push(rule)
                }
            }
            return rules
        },

        checkAddonComponent(component) {
            return this.componentsList.includes(component)
        },

        checkAdditionalField(name) {
            return !(this.warehouse && (name !== 'Количество' && name !== 'Ед.Измерения'));
        },

        getDirectoryData(directoryId) {
            this.dataLoading = true
            axios.get('/api/directory/settings/' + directoryId)
                .then(response => {
                    this.directoryData = response.data
                })
                .then(this.sortDirectoryFields)
                .then(this.creatingInitialValues)
                .then(this.getDirectoryEntity)
                .finally(() => (this.dataLoading = false));
        },

        sortDirectoryFields() {
            if (this.directoryData && this.directoryData.fields) {
                this.directoryData.fields.sort((firstItem, secondItem) => firstItem.following - secondItem.following);
            }
        },

        creatingInitialValues() {
            if (this.directoryData && this.directoryData.fields) {
                this.directoryData.fields.forEach(element => {
                    if (element.show) {
                        this.result[element.name] = this.value && this.value[element.name] ? this.value[element.name] : null
                    }
                });
            }
            if (this.directoryData && this.directoryData.additional_fields) {
                this.directoryData.additional_fields.forEach(element => {
                    this.result[element.name] = this.value && this.value[element.name] ? this.value[element.name] : null
                });
            }
            this.result['id'] = this.value && this.value['id'] ? this.value['id'] : null
        },

        getDirectoryEntity() {
            this.dataLoading = true
            axios.post(this.urlForItemSelect, {'query': this.result})
                .then(response => {
                    this.items = response.data.items
                    this.selectedItem = response.data.result
                })
                .finally(() => (this.dataLoading = false));
        },

        close() {
            this.$emit('close')
            this.$refs.Form.reset()
            this.$refs.Form.resetValidation()
        },

        prepareDateForSaving() {
            return _.merge(this.result, this.selectedItem);
        },

        save() {
            this.$emit('return', Object.assign({}, this.prepareDateForSaving()))
            this.close()
        }
    }
}
</script>

<style scoped>
.scrol-div {
    overflow: auto;
    overflow-x: hidden;
    min-height: 590px;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 6px; /* высота для горизонтального скролла */
    background-color: #ffffff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #3273a7;
}
</style>
