<template>
    <v-checkbox
        v-model="localData"
        :disabled="disabled"
        :readonly="read_only"
        :ripple="false"
        class="ma-0 px-0 py-1"
        hide-details
        v-on:change="$emit('change', $event)"
    ></v-checkbox>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        value: null,
        extra_attributes: null,
        read_only: false,
        disabled: false,
    },
    data: function () {
        return {
            localData: null,

        }
    },
    created() {
        this.localData = this.value
    },
    watch: {
        value: function (val) {
            this.localData = val
        },
    }
}
</script>

<style scoped>

</style>
