<template>
    <base-modal
        v-model="openDialog"
        max-width="1000"
        :fullscreen="this.$vuetify.breakpoint.width < 700"
    >
        <v-card v-if="openDialog">
            <v-container>
                <v-card-title>
                    <span class="headline">Информация по дефекту</span>
                </v-card-title>
                <v-card-text class="px-0">
                    <v-col class="py-1" cols="12"><b>Описание:</b> {{ value.description }}</v-col>
                    <v-col v-if="value.necessary_work" class="py-1" cols="12"><b>Необходимые работы:</b>
                        {{ value.necessary_work }}
                    </v-col>
                    <v-col v-if="value.fix_to" class="py-1" cols="12"><b>Устранить до:</b> {{ value.fix_to }}
                    </v-col>
                    <v-col class="py-1" cols="12"><b>Статус:</b>
                        <template v-if="value.fixed_at">
                            Дефект исправлен
                            <v-icon color="green">{{ $store.state.Icon.svgMdiCheck }}</v-icon>
                        </template>
                        <template v-else>
                            Дефект не исправлен
                            <v-icon color="red">{{ $store.state.Icon.svgMdiExclamation }}</v-icon>
                        </template>
                    </v-col>

                    <system-defect-info-create
                        :value="value"
                    />

                    <system-defect-info-fix
                        :value="value"
                    />

                    <system-defect-media
                        :value="value?.media"
                        v-on:selectFile="selectedFile = $event"
                    />

                    <system-defect-info-comments
                        :value="value.comments"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="canDeleteFile" color="black" text v-on:click="deleteSelectedFiles">Удалить файлы
                    </v-btn>
                    <v-btn color="blue" text v-on:click="close">Закрыть</v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </base-modal>
</template>
<script>
import BaseModal from "../BaseComponents/BaseModal.vue";
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";
import SystemDefectInfoComments from "./SystemDefectInfoComments.vue";
import SystemDefectInfoCreate from "./SystemDefectInfoCreate.vue";
import SystemDefectInfoFix from "./SystemDefectInfoFix.vue";
import SystemDefectMedia from "./SystemDefectMedia.vue";
import NumberMood from "../../Helpers/NumberMood";

export default {
    name: "SystemDefectInfo",
    components: {SystemDefectMedia, SystemDefectInfoFix, SystemDefectInfoCreate, SystemDefectInfoComments, BaseModal},
    props: {
        value: {
            default: SystemDefectDefaultItem(),
        },
        taskId: {
            type: Number,
            nullable: true,
            default: null
        },
        systemId: {
            type: Number,
            nullable: true,
            default: null
        }
    },
    data: function () {
        return {
            openDialog: false,
            selectedFile: null
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if (val) {
                    this.openDialog = true
                }
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        canDeleteFile: function () {
            return this.selectedFile && Array.isArray(this.selectedFile) && this.selectedFile.length
        }
    },
    methods: {
        close() {
            this.openDialog = false
            this.$emit('close')
        },
        numberMoodFiles(length) {
            return NumberMood(length, 'файл', 'файла', 'файлов')
        },

        getPath() {
            if (this.taskId && this.value?.id) {
                return '/api/system-defect/deletefile/task/' + this.taskId + '/defect/' + this.value.id
            }

            if (this.systemId && this.value?.id) {
                return '/api/system-defect/deletefile/system/' + this.systemId + '/defect/' + this.value.id
            }
            return null
        },

        deleteSelectedFiles() {
            if (this.canDeleteFile) {
                const length = this.selectedFile.length
                this.$store.getters['getConfirmDialog'].show({
                    title: 'Удалить вложения в дефект?',
                    question: `Вы уверены, что хотите удалить ${length} ${this.numberMoodFiles(length)}?`,
                    okButton: 'Удалить',
                    cancelButton: 'Отменить'
                }).then(() => {
                    axios.post(this.getPath(), {deletedFiles: this.selectedFile})
                        .then(() => {
                            this.selectedFile = null
                        })
                        .catch(error => {
                            this.showError(error)
                        })
                        .finally(() => {
                            this.close()
                            this.$emit('need-update')
                        });
                })
            }
        }
    }
}
</script>
