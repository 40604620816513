<template>
    <base-combobox-with-request
        v-model="assistants"
        :clearable="true"
        :disabled="taskAssistantDisabled()"
        :hide-details="true"
        :multiple="true"
        :open-on-clear="false"
        :readonly="watchedTask"
        :return-object="true"
        class="task-assistants-combobox"
        item-text="short_name"
        item-value="id"
        label=""
        title=""
        url="user/simpleList"
        v-on:blur="changeAssistants"
    />
</template>

<script>
import BaseAutocompleteWithRequest from "../../../BaseComponents/BaseAutocompleteWithRequest";
import BaseComboboxWithRequest from "../../../BaseComponents/BaseComboboxWithRequest";
import {CanceledStatus, CloseStatus, DoneStatus} from "../../../../Constants/TaskStatusConst";

export default {
    name: "TaskAssistants",
    components: {
        BaseComboboxWithRequest,
        BaseAutocompleteWithRequest,
    },
    data: function () {
        return {
            assistants: this.getAssistants(),
            closedStatuses: [
                CanceledStatus,
                DoneStatus,
                CloseStatus
            ]
        }
    },

    computed: {
        watchedTask() {
            return this.$store.getters["TaskData/getWatchedTask"]
        },
    },

    methods: {
        getAssistants() {
            return this.$store.getters["TaskData/getTaskAssistants"]
        },

        taskAssistantDisabled: function () {
            if (this.watchedTask) {
                return true
            } else {
                return this.closedStatuses.includes(this.$store.getters["TaskData/getTaskStatus"].id) || !this.$store.getters["TaskData/canEditTask"]
            }
        },

        changeAssistants() {
            axios.put('/api/task/assistants/' + this.$store.getters["TaskData/getTaskId"],
                {assistants: _.map(this.assistants, 'id')}
            )
                .then(() => {
                    this.$store.dispatch('TaskData/updateTaskData')
                })
                .catch(error => {
                    this.showError(error)
                });
        }
    }
}
</script>

<style scoped>
div.task-assistants-combobox {
    text-align: center;
    font-size: 0.9rem;
    margin-top: 0;
    padding-top: 0;
}

div.task-assistants-combobox :deep(div.v-select__slot) {
    height: 25px;
}

div.task-assistants-combobox :deep(div.v-input__append-inner) {
    margin-top: 0;
}
</style>
