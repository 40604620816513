<template>
    <div>
        <base-autocomplete-with-request
            v-model="type"
            :clearable="true"
            :hint="type && type.description ? type.description : null"
            :open-on-clear="false"
            :return-object="true"
            :rules="requiredRules"
            :url="forDirectory ? 'data_types/indexDirectory' : 'data_types/index'"
            item-text="name"
            item-value="key"
            label="Тип поля"
            required
            title="Тип поля"
        />

        <v-text-field
            v-if="type && type.key && (type.key === 'Select' || type.key === 'MultiSelect')"
            v-model="extraAttributesVibor"
            :rules="requiredRules"
            class="py-1"
            clearable
            hint="Перечислите через точку с запятой значения, которые будут отображаться при выборе"
            label="Выбираемые значения"
            required
        ></v-text-field>

        <template v-if="type && type.key && type.key === 'Directory'">
            <base-autocomplete-with-request
                v-model="extraAttributesSpravocnikDirctory"
                :clearable="true"
                :open-on-clear="false"
                :return-object="false"
                :rules="requiredRules"
                hint="Укажите справочник, данный из которого можно будет выбирать"
                item-text="name"
                item-value="id"
                label="Справочник"
                required
                title="Справочник"
                url="directory/index"
            />
            <v-checkbox
                v-model="extraAttributesSpravocnikSaveData"
                :disabled="!extraAttributesSpravocnikDirctory"
                label="Сохранять значение справочника"
            ></v-checkbox>
        </template>
    </div>
</template>

<script>
import BaseComboboxWithRequest from "./BaseComboboxWithRequest";
import BaseAutocompleteWithRequest from "./BaseAutocompleteWithRequest";
import requiredRules from "../../Rules/requiredRules";

export default {
    name: "BaseDataTypeSelect",
    components: {BaseComboboxWithRequest, BaseAutocompleteWithRequest},
    props: {
        propsType: {
            required: true,
            default: null
        },
        propsExtraAttributes: {
            required: true,
            default: null
        },
        forDirectory: {
            default: false
        }
    },
    data: function () {
        return {
            type: null,
            extraAttributes: null
        }
    },

    watch: {
        propsType: {
            handler: function (val) {
                if (val) {
                    if (val !== this.type) {
                        this.type = Object.assign({}, val)
                    }
                } else {
                    this.type = null
                }
            },
            immediate: true,
            deep: true
        },
        propsExtraAttributes: {
            handler: function (val) {
                console.log(val)
                if (val) {
                    if (val !== this.extraAttributes) {
                        this.extraAttributes = val
                    }
                } else {
                    this.extraAttributes = null
                }
                console.log(this.extraAttributes)
            },
            immediate: true,
            deep: true
        },
        type: {
            handler: function (val) {
                this.$emit('update:propsType', val)
            },
            immediate: false,
            deep: true
        },
        extraAttributes: {
            handler: function (val) {
                this.$emit('update:propsExtraAttributes', val)
            },
            immediate: false,
            deep: true
        }
    },

    computed: {
        extraAttributesVibor: {
            get: function () {
                //Из массива делаем строку с разделителем '; '
                //todo Сделать, чтоб проверялось, что editedItem.type == vybor || mnozestvennyi-vybor
                console.log('get', this.extraAttributes)
                return this.extraAttributes && Array.isArray(this.extraAttributes)
                    ? this.extraAttributes.join('; ')
                    : null
            },
            set: function (newValue) {
                console.log('get', this.extraAttributes)
                //Из строки с разделителем ";" делаем массив
                let array = newValue ? newValue.split(";") : []
                array = array.map(function (item) {
                    return item.trim();
                });
                this.extraAttributes = array.filter(item => item);

            }
        },
        extraAttributesSpravocnikDirctory: {
            get: function () {
                return this.extraAttributes && this.extraAttributes.directory ? this.extraAttributes.directory : null
            },
            set: function (newValue) {
                if (this.extraAttributes) {
                    this.extraAttributes.directory = newValue
                    // this.extraAttributes.saveData =  false
                } else {
                    this.extraAttributes = {
                        directory: newValue,
                        saveData: false
                    }
                }

            }
        },
        extraAttributesSpravocnikSaveData: {
            get: function () {
                return this.extraAttributes && this.extraAttributes.saveData ? this.extraAttributes.saveData : false
            },
            set: function (newValue) {
                if (this.extraAttributes) {
                    this.extraAttributes.saveData = newValue
                } else {
                    this.extraAttributes = {saveData: newValue}
                }
            }
        },
        requiredRules() {
            return requiredRules
        },
    },
}
</script>

<style scoped>

</style>
