import {TaskDataType} from "../../mixins/dataType";
import {CloseStatus} from '../../Constants/TaskStatusConst'

const state = {
    id: null, //Идентификатор задачи
    data: new TaskDataType(), //данные задачи
    loading: true, //признак того, что загружаются какие-то данные
    disabled: true, //Задача отключена для редактирования
    watchedTask: false, //наблюдаемая задача
    selectedAttachments: [],
}


function checkingUserRights(state, rootGetters, rights) {
    if (state.data.whose_task === "Someone else task" && rootGetters['UserRights/getRights'].includes('AllTasks-' + rights)) return true
    if (state.data.whose_task === "Department task" && rootGetters['UserRights/getRights'].includes('DepTasks-' + rights)) return true
    if (state.data.whose_task === "User task" && rootGetters['UserRights/getRights'].includes('UsersTasks-' + rights)) return true
    return false;
}


const getters = {
    getTaskId: (state) => parseInt(state.id),
    getTaskData: (state) => state.data,
    getLoading: (state) => !!state.loading, //    !! - для того, чтоб точно получилось логическое значение
    getDisabled: (state) => !!state.disabled,
    getWatchedTask: (state) => !!state.watchedTask,
    getSelectedAttachments: (state) => state.selectedAttachments,
    getTaskPriority: (state) => state.data.priority,
    getTaskStatus: (state) => state.data.status,
    getTaskExecutor: (state) => state.data.executor,
    getTaskExecutorId: (state) => parseInt(state.data.executor.id),
    getTaskAssistants: (state) => state.data.assistants,
    getTaskCustomData: (state) => state.data.custom_data,
    getSystemDefects: (state) => state.data.system_defect,
    getSystemFieldsSettings: (state) => state.data.result_system_fields_settings,
    getTaskSystemData: (state) => state.data.task_system_data,
    getSystemData: (state) => state.data.system_data,
    getPlan: (state) => state.data.plan,
    getTaskCreatedAt: (state) => state.data.created_at,
    getTaskDeadline: (state) => state.data.deadline,
    getSeals: (state) => state.data.seals,
    getTaskSystemId: (state) => parseInt(state.data.systems_id),


    canDeleteTask(state, getters, rootState, rootGetters) {
        return checkingUserRights(state, rootGetters, 'delete')
    },
    canEditTask(state, getters, rootState, rootGetters) {
        return checkingUserRights(state, rootGetters, 'update')
    },
    canEditPriority(state, getters, rootState, rootGetters) {
        return checkingUserRights(state, rootGetters, 'changePriority')
    },
    canChangeExecutor(state, getters, rootState, rootGetters) {
        return checkingUserRights(state, rootGetters, 'reassign')
    },
    canPlan(state, getters, rootState, rootGetters) {
        return checkingUserRights(state, rootGetters, 'plan')
    },
    canChangeDeadline(state, getters, rootState, rootGetters) {
        return checkingUserRights(state, rootGetters, 'changeDeadline')
    },
    isClosed(state, getters, rootState, rootGetters) {
        return state.data.status.id === CloseStatus;
    },
}


const mutations = {

    setTaskId(state, data) {
        state.id = data
    },

    setTaskData(state, data) {
        state.data = data
    },

    setLoading(state, data) {
        state.loading = data
    },

    setDisabled(state) {
        if (state.data.whose_task === 'Watched task') {
            state.disabled = true
        } else {
            state.disabled = state.data.status.id !== 3
        }
    },

    setWatchedTask(state) {
        state.watchedTask = state.data.whose_task === 'Watched task'
    },

    setSelectedAttachments(state, data) {
        state.selectedAttachments = data
    },

}

const actions = {
    startLoading({commit}) {
        commit('setLoading', true)
    },

    stopLoading({commit}) {
        commit('setLoading', false)
    },


    updateTaskData({state, commit, dispatch}) {
        dispatch('startLoading')
        if (state.id) {
            axios.get('/api/task/' + state.id)
                .then(response => {
                    commit('setTaskData', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        commit('setErrors', error.response, {root: true})
                    }
                })
                .finally(() => {
                    commit('setWatchedTask')
                    commit('setDisabled')
                    dispatch('stopLoading')
                })
        }
    },

    setDefaultTaskData({state, commit, dispatch}) {
        commit('setTaskData', new TaskDataType())
        commit('setTaskId', null)
        commit('setDisabled')
        commit('setSelectedAttachments', [])
    },
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
