<template>
    <path :fill="iconColor"
          d="M12.565 25c-3.252-.033-6.677-1.04-10.208-2.658-.134-2.433-.458-3.812.066-4.742.466-.828 1.248-1.249 2.125-1.554 1.52-.526 2.706-.852 4.277-2.026l2.238 7.098.909-2.448c-1.077-1.481-1.258-3.051.595-3.042 1.852-.01 1.672 1.56.595 3.042l.909 2.448 2.238-7.098c1.571 1.172 2.758 1.498 4.277 2.026.878.303 1.66.725 2.125 1.554.524.93.2 2.307.066 4.742-3.532 1.619-6.956 2.623-10.21 2.658zm0-10.314c-2.446-.318-3.856-1.77-4.345-4.231-.304-.19-.76-.939-.979-1.797-.291-1.147-.13-1.735.455-1.594-.552-2.13-.212-3.88 1.162-5.184.84-.796 2.012-1.847 3.707-1.88 1.695.033 2.902 1.048 3.707 1.88.602.118 1.04.599 1.268 1.237.357 1.003.21 2.647-.104 3.945.585-.14.744.447.455 1.594-.219.859-.675 1.608-.979 1.797-.487 2.462-1.898 3.914-4.345 4.23z"/>
</template>

<script>
export default {
    name: "CustomersIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
