<template>
    <g transform="scale(.06287 .0683)">
        <path :fill="iconColor"
              d="M156.48 329.88c0-13.83 20.9-13.73 20.9 0 0 13.7-20.9 13.7-20.9 0zm-48.4-249.13h5.03c2.88.14 5.54.77 7.71 3.2 1.52 1.7 2.65 4.45 3.6 6.85 2 5.06 3.79 9.48 5.81 14.54 4.05 10.1 7.67 19.46 11.71 29.54l46.82 118.16c3.46 8.65 19.51 50.87 23.26 58.69l107.79-41.25c1.79-.72 5.37-2.19 7.71-2.19 7.33 0 11.55 5.36 11.55 9.35 0 8.65-3.04 10.81-11.89 13.96-3.22 1.14-5.52 2.09-8.77 3.33-2.93 1.12-5.41 2.05-8.33 3.22-30.46 12.25-63.6 23.97-94.2 36.14-5.84 2.32-10.25 4.41-16.49 1.11-1.05 2.18-2.71 12.33-9.81 18.24-5.9 4.91-12.58 7.89-19.39 8.71h-7.54c-4.59-.56-9.13-2.14-13.43-4.83-10.56-6.61-15.84-18.48-14.78-30.97 1.52-17.97 17.89-31.25 35.34-29.21 3.88.45 4.56.4 7.83 1.53 2.4.83 4.39 1.92 6.38 2.97l-9.74-24.91c-3.38-8.44-6.42-16.32-9.82-24.83l-56.72-142.91c-.8-2.01-1.52-4.14-2.36-5.89-8.32.19-18.45 6.48-23.57 12.17-1.21 1.35-1.98 2.46-3.13 4.02-2.02 2.77-5.06 4.7-9.6 4.7-7.96 0-10.45-6.01-10.45-12.65 0-3.59 5.68-10.38 7.83-12.52 10.41-10.32 25.08-18.28 41.66-18.28z"/>
        <path :fill="iconColor"
              d="M234.03 225.39c0-6.77 7.48-8 13.83-10.37 3.05-1.14 5.99-2.25 8.8-3.3 2.39-.89 6.59-2.83 9.27-2.83 5.36 0 12.54 10.33-1.3 15.2-3.81 1.34-20.45 8.01-22.53 8.24-4.53.5-8.06-3.79-8.06-6.94zm-37.95-4.95c0 5.33 15.98 45 19.09 52.96 2.24 5.74 3.38 12.49 11.16 12.49 3.33 0 9.31-2.94 12.44-4.06 4.26-1.51 7.65-3.01 11.96-4.54 14.97-5.32 32.58-12.79 48.13-18.42 3.08-1.12 15.05-5.32 17.47-7.28 6.8-5.52-.04-16.62-3.02-24.63l-16.19-42.11c-4.54-8.58-14.78-2.86-21.06-.48l-35.77 13.72c-3.42 1.22-38.96 14.35-41.32 16.42-1.1.96-2.88 4.04-2.88 5.92z"/>
        <path :fill="iconColor"
              d="M207.08 143.99c-5.48 0-10.87-10.69-1.59-14.24 4.4-1.68 24.54-9.41 26.34-9.41 6.42 0 11.39 9.85 2.03 14.13-2.87 1.31-5.78 2.13-8.79 3.31-3.01 1.18-15.44 6.21-17.99 6.21zm-44.55-11.55c0 4.87 4.18 13.39 5.97 18.23l12.9 33.85c2.48 6.36 3.37 12.81 11.38 12.81 4.63 0 63.28-23.72 73.35-27.29 19.15-6.8 22.82-7.71 16.84-22.75l-17.91-46.98c-.7-1.85-.79-2.75-1.74-4.31-4.8-7.9-14.19-2.69-20.81-.17-4.09 1.56-8.05 3.23-11.86 4.64l-60.1 22.94c-4.29 1.7-8.01 3.3-8.01 9.04z"/>
        <path :fill="iconColor"
              d="M12.69 0h372.28c6.98 0 12.69 5.71 12.69 12.69v413.8c0 6.98-5.71 12.69-12.69 12.69H12.69C5.71 439.18 0 433.47 0 426.49V12.69C0 5.71 5.71 0 12.69 0zm353.98 30.99H30.99v377.2h335.68V30.99z"/>
    </g>
</template>

<script>
export default {
    name: "WarehouseIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
