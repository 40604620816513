<template>
    <div style="z-index: 20000">
        <v-dialog
            :fullscreen="fullscreen"
            :max-width="maxWidth"
            :scrollable="scrollable"
            :value="value"
            persistent
            width="100%"
        >
            <slot>

            </slot>
        </v-dialog>
    </div>
</template>

<script>
import {VDialog} from 'vuetify/lib'

export default {
    name: "EntityFormDialog",
    components: {
        VDialog
    },
    props: {
        scrollable: true,
        value: false,
        maxWidth: {
            type: String,
            default: '600px'
        },
        fullscreen: false
    },
}
</script>

<style scoped>

</style>