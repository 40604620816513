export default function () {
    return {
        system_id: null,
        id: null,
        number: null,
        description: null,
        necessary_work: null,
        fix_to: null,
        created_by_task: null,
        changed_by_task: null,
        created_at: null,
        fixed_at: null,
        fixed_by: null,
        creator: null,
        repairer: null,
        comments: null,
        logs: null,
        media_count: 0,
        is_printable: true
    }
}
