<template>
    <v-col cols="12">
        <v-row v-if="UserCan.ObjectCard.View">
            <base-error-snackbar
                v-model="Error"
                v-bind:error-text="ErrorText"
            />

            <base-resize-panel :mobile="mobile">
                <template v-slot:left>
                    <base-left-menu
                        v-model="activeMenuPoint"
                        :selectable="false"
                        :updateAll="updateAll"
                        object-only
                    />
                </template>
                <template v-slot:right>
                    <v-card
                        :height="height"
                        class="mx-auto overflow-y-auto"
                    >
                        <v-card-text class="pa-0">
                            <FilteredObjectCardList
                                ref="filteredObjectList"
                                :selected-point="activeMenuPoint"
                            />
                        </v-card-text>
                    </v-card>
                </template>
            </base-resize-panel>

        </v-row>
    </v-col>
</template>

<script>
import BaseLeftMenu from "../BaseComponents/BaseLeftMenu.vue";
import BaseErrorSnackbar from "../BaseComponents/BaseErrorSnackbar.vue";
import FilteredObjectCardList from "../ObjectCardOld/FilteredObjectCardList.vue";
import BaseResizePanel from "../BaseComponents/BaseResizePanel.vue";

export default {
    name: "ObjectCardList",
    components: {BaseLeftMenu, BaseErrorSnackbar, FilteredObjectCardList, BaseResizePanel},
    data: function () {
        return {
            activeMenuPoint: [],
            updateAll: null,

            ErrorText: '',
            Error: false,
        }
    },
    computed: {
        height: function () {
            if (this.$vuetify.breakpoint.width < 992) {
                return "100%"
            } else {
                return this.$vuetify.breakpoint.height - 95
            }
        },
        mobile: function () {
            return this.height === "100%"
        },
    },
    //отслеживаем готовность покинуть компонет и проверяем открыто ли диалоговое окно карточки,
    // если да, то закрываем его и остаемся на странице,
    // если нет, то идем куда шли
    beforeRouteLeave(to, from, next) {
        if (this.$refs.filteredObjectList.dialog) {
            this.$refs.filteredObjectList.dialog = false
            next(false)
        } else {
            next()
        }
    }
}
</script>

<style scoped>

</style>
