<template>
    <div style="height: 100vh; background-color: #f5f5f5; padding-top: 40px">
        <form>
            <div class="text-center">
                <img class="img-fluid" src="img\logo.svg" style="width: 100%; height: auto;" alt="">
                <h3 style="color: #777; text-align: center; padding-bottom: 20px; padding-top: 20px">Система контроля
                    качества выполнения работ</h3>
                <small style="color: #777; text-align: center">Авторизация пользователя</small>
                <br>
            </div>

            <div class="form-group row">
                <div class="col">
                    <input id="login"
                           type="text"
                           class="form-control is-invalid "
                           name="login"
                           required
                           autocomplete="login"
                           autofocus
                           placeholder="Логин"
                           v-model="formData.login"
                    >
                </div>
            </div>

            <div class="form-group row">
                <div class="col">
                    <input id="password"
                           type="password"
                           class="form-control"
                           name="password"
                           required
                           autocomplete="current-password"
                           placeholder="Пароль"
                           v-model="formData.password"
                    >
                </div>
            </div>

            <div class="form-group row">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" style="font-weight: normal;" type="checkbox" name="remember"
                               id="remember" v-model="formData.remember">
                        <label class="form-check-label" for="remember">
                            Запомнить меня
                        </label>
                    </div>
                </div>
            </div>
            <h3 v-if="errorMessage" style="color: #a94442; text-align: center; ">{{ errorMessage }}</h3>
            <div class="form-group row mb-0">
                <div class="col text-center">
                    <button
                        class="btn btn-primary btn-block"
                        v-on:click.stop.prevent="login"
                    >
                        Войти
                    </button>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            errorMessage: null,
            formData: {
                login: null,
                password: null,
                remember: false
            }
        };
    },
    methods: {
        login() {
            axios.get("/sanctum/csrf-cookie").then(() => {
                axios.post('/login', this.formData)
                    .then(response => {
                        this.$store.dispatch('UserData/getUserData');
                    })
                    .then(() => {
                        this.$router.replace({name: 'main'})
                        location.reload()
                    })
                    .catch(error => {
                        console.log(error)
                        this.errorMessage = error.response.data.message
                        this.formData.login = null
                        this.formData.password = null
                    })
            });
        }
    }

}
</script>

<style scoped>

input.form-control {
    width: 100%;
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    color: #777;
}


form {
    max-width: 360px;
    padding: 19px 29px 29px;
    margin: 0 auto 20px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

button {
    width: 100%;
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 4px;
}
</style>
