<template>
    <base-status-icon
        v-if="id"
        :height="height"
        :icon="icon"
        :icon-name="iconName"
        :width="width"
    />
</template>

<script>
import BaseStatusIcon from "../components/BaseComponents/BaseStatusIcon";

export default {
    name: "StatusIcon",
    components: {BaseStatusIcon},
    props: {
        id: {
            required: true,
        },
        width: {
            type: [Number, String],
            default: 25
        },
        height: {
            type: [Number, String],
            default: 25
        },
    },
    data: function () {
        return {
            icons: [
                {
                    id: -1,
                    name: 'Еще не создана',
                    icon: 'NotCreated'
                },
                {
                    id: 1,
                    name: 'Не прочитана',
                    icon: 'NotReadStatus'
                },
                {
                    id: 2,
                    name: 'Прочитана',
                    icon: 'ReadStatus'
                },
                {
                    id: 3,
                    name: 'В работе',
                    icon: 'InWorkStatus'
                },
                {
                    id: 4,
                    name: 'Приостановлена',
                    icon: 'SuspendedStatus'
                },
                {
                    id: 5,
                    name: 'Отменена',
                    icon: 'CanceledStatus'
                },
                {
                    id: 6,
                    name: 'Выполнена',
                    icon: 'DoneStatus'
                },
                {
                    id: 7,
                    name: 'Закрыта',
                    icon: 'CloseStatus'
                },
                {
                    id: 8,
                    name: 'Удалена',
                    icon: 'RemoteStatus'
                },
                {
                    id: 9,
                    name: 'Возвращена в доработку',
                    icon: 'ReturnedForRevisionStatus'
                },
            ]
        }
    },

    computed: {
        item: function () {
            return this.id ? this.icons.find(item => item.id === this.id) : null
        },
        icon: function () {
            return this.item ? this.item.icon : null
        },
        iconName() {
            return this.item ? this.item.name : null
        }
    }
}
</script>

<style scoped>

</style>