const Roles = () => import(/* webpackChunkName: "Roles" */ '../components/Admin/Roles/Roles.vue')
const RoleCreate = () => import(/* webpackChunkName: "Roles" */ '../components/Admin/Roles/RoleCreate.vue')
const RoleUpdate = () => import(/* webpackChunkName: "Roles" */ '../components/Admin/Roles/RoleUpdate.vue')
const RoleDelete = () => import(/* webpackChunkName: "Roles" */ '../components/Admin/Roles/RoleDelete.vue')
export const rolesRoutes = [
    {
        path: '/role', name: "role.index", component: Roles,
        children: [
            {path: "create", component: RoleCreate, name: "role.create"},
            {path: "delete/:id", component: RoleDelete, name: "role.delete", props: true},
            {path: ":id", component: RoleUpdate, name: "role.update", props: true},
        ]
    },
]
