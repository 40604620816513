<template>
    <v-select
        v-model="localData"
        :clearable="!disabled && !read_only"
        :disabled="disabled"
        :items="extra_attributes"
        :readonly="read_only"
        class="task-text-field pt-0 mt-0"
        hide-details
        no-resize
        spellcheck="false"
        v-on:change="$emit('change', $event)"
    ></v-select>
</template>

<script>
export default {
    name: "Select",
    props: {
        value: null,
        extra_attributes: null,
        read_only: false,
        disabled: false,
    },
    data: function () {
        return {
            localData: null,

        }
    },
    created() {
        this.localData = this.value
    },
    watch: {
        value: function (val) {
            this.localData = val
        },
    }
}
</script>

<style scoped>
div.task-text-field {
    font-size: .85rem;
}

div.task-text-field :deep(input[type="text"]) {
    padding: 3px 0 3px;
}

div.task-text-field :deep(input[disabled="disabled"]) {
    color: rgba(0, 0, 0, 0.87);
}
</style>
