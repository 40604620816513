<template>
    <v-container fluid style="max-width: 1200px">
        <v-card>
            <v-card-title>
                <span class="headline">Настрока оповещения</span>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col class="text-center  " cols="12" md="6" sm="7">Событие</v-col>
                    <v-col class="text-center">E-mail</v-col>
                    <v-col class="text-center">Telegram Bot</v-col>
                    <v-col class="text-center">Push</v-col>
                    <v-col class="text-center">Bot без звука</v-col>
                </v-row>
                <v-row
                    v-for="item in notificationList"
                    v-if="$store.state.UserRights.all.includes(item.permission)"
                    :key="item.id"
                    no-gutters
                >
                    <v-col cols="12" md="6" sm="7">{{ item.description }}</v-col>
                    <v-col class="text-center">
                        <v-simple-checkbox
                            v-model="userSelect[item.id].email"
                            color="blue"
                        ></v-simple-checkbox>
                    </v-col>
                    <v-col class="text-center">
                        <v-simple-checkbox
                            v-model="userSelect[item.id].telegram"
                            color="blue"
                        ></v-simple-checkbox>
                    </v-col>
                    <v-col class="text-center">
                        <v-simple-checkbox
                            v-model="userSelect[item.id].push"
                            color="blue"
                        ></v-simple-checkbox>
                    </v-col>
                    <v-col class="text-center">
                        <v-simple-checkbox
                            v-model="userSelect[item.id].tlgQuiet"
                            color="blue"
                        ></v-simple-checkbox>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-row no-gutters>
                    <v-col class="text-right text-sm-center" cols="12" sm="4">
                        <v-btn text
                               @click="getEmptyList">Очистить все
                        </v-btn>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn color="blue" text
                               @click="save">Сохранить
                        </v-btn>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn color="blue" text
                               @click="cancel">Отмена
                        </v-btn>
                    </v-col>

                </v-row>
            </v-card-actions>
        </v-card>
        <base-info-snackbar
            :text="infoText"
            :value="info"
        />
    </v-container>
</template>

<script>
import BaseInfoSnackbar from "../BaseComponents/BaseInfoSnackbar";

export default {
    name: "NotificationSettings",
    components: {BaseInfoSnackbar},
    data: function () {
        return {
            infoText: null,
            info: false,
            notificationList: [],
            userSelect: {
                1: {email: false, telegram: false, push: false, tlgQuiet: false},
                2: {email: false, telegram: false, push: false, tlgQuiet: false},
                3: {email: false, telegram: false, push: false, tlgQuiet: false},
                4: {email: false, telegram: false, push: false, tlgQuiet: false},
                5: {email: false, telegram: false, push: false, tlgQuiet: false},
                6: {email: false, telegram: false, push: false, tlgQuiet: false},
                7: {email: false, telegram: false, push: false, tlgQuiet: false},
                8: {email: false, telegram: false, push: false, tlgQuiet: false},
                9: {email: false, telegram: false, push: false, tlgQuiet: false},
                10: {email: false, telegram: false, push: false, tlgQuiet: false},
                11: {email: false, telegram: false, push: false, tlgQuiet: false},
                12: {email: false, telegram: false, push: false, tlgQuiet: false},
                13: {email: false, telegram: false, push: false, tlgQuiet: false},
                14: {email: false, telegram: false, push: false, tlgQuiet: false},
                15: {email: false, telegram: false, push: false, tlgQuiet: false},
                16: {email: false, telegram: false, push: false, tlgQuiet: false},
                17: {email: false, telegram: false, push: false, tlgQuiet: false},
                18: {email: false, telegram: false, push: false, tlgQuiet: false},
                19: {email: false, telegram: false, push: false, tlgQuiet: false},
                20: {email: false, telegram: false, push: false, tlgQuiet: false},
                21: {email: false, telegram: false, push: false, tlgQuiet: false},
                22: {email: false, telegram: false, push: false, tlgQuiet: false},
                23: {email: false, telegram: false, push: false, tlgQuiet: false},
                24: {email: false, telegram: false, push: false, tlgQuiet: false},
            },
        }
    },

    created() {
        this.getEmptyList()
        this.getData()
        this.getUserData()

    },
    methods: {
        getData() {
            axios.get('/api/notification/index')
                .then(response => {
                    this.notificationList = response.data
                })
        },

        getUserData() {
            axios.get('/api/user/subscriptions')
                .then(response => {
                    this.userSelect = response.data
                })
        },

        getEmptyList() {
            axios.get('/api/notification/emptylist')
                .then(response => {
                    this.userSelect = response.data
                })
        },

        save() {
            let data = this.userSelect
            axios.post('/api/user/subscriptions', data)
                .then(response => {
                    this.infoText = "Данные успешно сохранены"
                    this.info = true
                })
                .catch(console.log.bind(console));
        },

        cancel() {
            this.getUserData()
        }
    }
}
</script>

<style scoped>

</style>
