<template>
    <div>
        {{ notificationData.message }}<br>
        {{ notificationData.object }}<br>
        {{ notificationData.address }}<br>
    </div>
</template>

<script>
export default {
    name: "NotificationToast",
    props: ['notificationData']
}
</script>

<style scoped>

</style>