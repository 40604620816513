<template>
    <div>
        <v-data-table
            v-if="value.length"
            :headers="headers"
            :item-class="rowClass"
            :items="value"
            :items-per-page="-1"
            class="task-plan-table"
            dense
            disable-sort
            hide-default-footer
            v-on:click:row="clickRow"
        >
            <template v-slot:item.status="{ item }">
                <v-icon v-if="item.disabled"
                        class="mr-2"
                        small
                >
                    {{ $store.state.Icon.svgMdiClose }}
                </v-icon>
                <v-icon v-else
                        class="mr-2"
                        small
                >
                    {{ $store.state.Icon.svgMdiCheck }}
                </v-icon>
            </template>

            <template v-slot:item.start="{ item }">
                {{ getDate(item.start) }}
            </template>

            <template v-slot:item.end="{ item }">
                {{ getDate(item.end) }}
            </template>

        </v-data-table>
        <v-dialog
            v-model="aboutDialog"
            max-width="600px"
        >
            <v-card>
                <v-card-text class="pb-0 pt-5">
                    <div class="about-text">
                        <div>Начало:</div>
                        <div>{{ aboutItem.start }}</div>
                        <div>Конец:</div>
                        <div>{{ aboutItem.end }}</div>
                        <div>Исполнитель:</div>
                        <div>{{ aboutItem.user }}</div>
                        <template v-if="aboutItem.assistants">
                            <div>Помощьники:</div>
                            <div>{{ aboutItem.assistants }}</div>
                        </template>

                        <div>Интервал изменил:</div>
                        <div>{{ aboutItem.editor }}</div>
                        <div>Дата изменения:</div>
                        <div>{{ aboutItem.updated_at }}</div>
                        <template v-if="aboutItem.disabled">
                            <div>Причина отмены:</div>
                            <div>{{ aboutItem.reason }}</div>
                        </template>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="aboutDialog = false"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import {DateFormatteWithTime} from "../../../../mixins/commonMethod";


function getDefaultAboutItem() {
    return {
        start: null,
        end: null,
        editor: null,
        user: null,
        assistants: null,
        reason: null,
        disabled: false,
        updated_at: null
    }
}

export default {
    name: "TaskPlanItems",
    props: {
        value: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        headers: [
            {text: '', value: 'status', align: 'center'},
            {text: 'Начало', value: 'start'},
            {text: 'Конец', value: 'end'},
            {text: 'Исполнитель', value: 'user.short_name'},
        ],
        aboutDialog: false,
        aboutItem: getDefaultAboutItem()
    }),
    watch: {
        aboutDialog: function (newVal) {
            if (!newVal) {
                this.aboutItem = getDefaultAboutItem()
            }
        },
    },
    methods: {
        getDate(value) {
            return DateFormatteWithTime(value)
        },
        rowClass(item) {
            const disabled = item.disabled ? 'disabled' : ''
            return 'task-plan-item ' + disabled;
        },

        getAssistants(assistants) {
            if (assistants && Array.isArray(assistants) && assistants.length) {
                let result = []
                assistants.forEach(function (item) {
                    result.push(item.short_name ? item.short_name : item.surname)
                })
                return result.join('; ')

            }
            return null
        },

        clickRow(item) {
            this.aboutItem = {
                'start': this.getDate(item.start),
                'end': this.getDate(item.end),
                'editor': item.editor.short_name,
                'user': item.user.short_name,
                'reason': item.reason,
                'disabled': item.disabled,
                'updated_at': this.getDate(item.updated_at),
                'assistants': this.getAssistants(item.assistants)
            }
            this.aboutDialog = true
        }
    }
}
</script>

<style scoped>
.task-plan-table :deep(th) {
    padding: 0 5px !important;
}

.task-plan-table :deep(td) {
    padding: 0 5px !important;
}

.task-plan-table :deep(.task-plan-item) {
    cursor: pointer;
}

.task-plan-table :deep(.task-plan-item.disabled) {
    color: #b4b4b4;
}

.about-text {
    width: 100%;
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 5px 10px;
}
</style>