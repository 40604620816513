<template>
    <v-col ref="observedElement" class="px-0 pb-1">
        <div class="works_intervals-title">Интервалы работ</div>
        <div v-if="show" class="works_interval">
            <div class="works_interval-section header">
                <div class="works_interval-header works_interval-user">Сотрудник</div>
                <div class="works_interval-header works_interval-arrival">Время прибытия</div>
                <div class="works_interval-header works_interval-departure">Время убытия</div>
                <div class="works_interval-header works_interval-description">Примечание</div>
                <div class="works_interval-header works_interval-status">Статус</div>
            </div>

            <template v-for="item in model">
                <div class="works_interval-section">
                    <div class="works_interval-field works_interval-user">
                        {{ item.user.short_name }}
                    </div>
                    <div class="works_interval-field works_interval-arrival">
                        <v-text-field
                            :disabled="!UserCan.WorksIntervals.Edit"
                            :error="arrivalTimeError"
                            :value="formatToInputData(item.arrival_time)"
                            class="datetime  pt-0 mt-0"
                            hide-details
                            no-resize
                            type="datetime-local"
                            v-on:change="saveItem('arrival_time', item.id, $event)"
                        ></v-text-field>
                    </div>
                    <div class="works_interval-field works_interval-departure">
                        <v-text-field
                            :disabled="!UserCan.WorksIntervals.Edit"
                            :value="formatToInputData(item.departure_time)"
                            class="datetime pt-0 mt-0"
                            hide-details
                            no-resize
                            type="datetime-local"
                            v-on:change="saveItem('departure_time', item.id, $event)"
                        ></v-text-field>
                    </div>
                    <div class="works_interval-field works_interval-description">
                        <v-text-field
                            v-model="item.description"
                            :disabled="!UserCan.WorksIntervals.Edit"
                            class="task-text-field pt-0 mt-0"
                            clearable
                            hide-details
                            no-resize
                            v-on:change="saveItem('description', item.id, $event)"
                        >
                        </v-text-field>
                    </div>
                    <div class="works_interval-field works_interval-status">
                        <v-switch
                            v-model="item.disabled"
                            :disabled="!UserCan.WorksIntervals.TurnOn"
                            :false-value=true
                            :true-value=false
                            class="my-0 py-0"
                            dense
                            hide-details
                            v-on:change="saveItem('disabled', item.id, $event)"
                        ></v-switch>
                    </div>
                </div>
            </template>
        </div>
    </v-col>
</template>

<script>
import {shortDateWithTime, shortDateWithTimeAndT} from "../../../../mixins/commonMethod";
import shownWhenIntersectionComponent from "../../../../mixins/shownWhenIntersectionComponent";

export default {
    name: "TaskWorksIntervals",
    mixins: [shownWhenIntersectionComponent],
    data: function () {
        return {
            model: [],
            arrivalTimeError: false
        }
    },

    computed: {
        taskId() {
            return this.$store.getters["TaskData/getTaskId"]
        },
        value() {
            return this.$store.getters["TaskData/getTaskData"].intervals
        },

    },

    watch: {
        value: {
            handler: function (val) {
                if (val) {
                    this.model = Object.assign({}, val)
                } else {
                    this.model = []
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {

        saveItem(type, itemId, value) {
            const editTypes = ['arrival_time', 'departure_time', 'description'];
            const turnOnTypes = ['disabled'];

            if (
                (this.UserCan.WorksIntervals.Edit && editTypes.includes(type)) ||
                (this.UserCan.WorksIntervals.TurnOn && turnOnTypes.includes(type))
            ) {
                const data = {
                    type: type,
                    id: itemId,
                    value: value
                }
                axios.put('/api/works_interval/update/' + this.taskId, data)
                    .then(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                ;
            }
        },

        formatToInputData(dataTime) {
            return shortDateWithTimeAndT(dataTime)
        },
        formatFromInputData(dataTime) {
            return shortDateWithTime(dataTime)
        },
    }
}
</script>

<style scoped>

.works_intervals-title {
    display: grid;
    border: 1px solid #285473;
    border-bottom-width: 0;
    height: 28px;
    width: 100%;
    font-size: 1rem;
    font-style: italic;
    background-color: #cccccc;
    text-align: left;
    padding-left: 8px;
    align-content: space-evenly;
}


.works_interval {
    width: 100%;
    display: grid;
    border-bottom: 1px solid #285473;
}

.works_interval-header {
    display: grid;
    width: 100%;
    font-size: 1rem;
    font-style: italic;
    background-color: #cccccc;
    text-align: left;
    padding-left: 8px;
}

.works_interval > .works_interval-section > div {
    padding: 0 5px;
}

.works_interval-field {
    display: grid;
    align-content: space-around;
    white-space: nowrap;
    background-color: white;
    font-size: .85rem;
    min-height: 32px;
}

.works_interval-status {
    justify-content: space-around
}

.works_interval-section {
    display: grid;
    border: 1px solid #285473;
    border-bottom-width: 0;
    grid-gap: 1px;
    background-color: #285473;
}

.datetime {
    font-size: .85rem;
    width: 100%;
}

/*Для экранов больше 850 делаем одну строку*/
@media screen and (min-width: 850px) {
    .datetime-width {
        max-width: 185px;
    }

    .works_interval-section {
        grid-template-columns: 220px 190px 190px auto 66px;
    }
}

@media screen and (min-width: 410px) and (max-width: 850px) {
    .datetime-width {
        max-width: 185px;
    }

    .works_interval-section {
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
                        "a a a a a a"
                        "b b b c c c"
                        "d d d d d e";
    }

    .works_interval-user {
        grid-area: a;
    }

    .works_interval-arrival {
        grid-area: b;
    }

    .works_interval-departure {
        grid-area: c;
    }

    .works_interval-description {
        grid-area: d;
    }

    .works_interval-status {
        grid-area: e;
    }
}

@media screen and (max-width: 410px) {
    .works_interval-section.header {
        display: none;
    }

    .works_interval-header {
        display: none;
    }

    .works_interval-section {
        grid-template-columns: auto 60px;
        grid-template-areas:
                        "a a"
                        "b b"
                        "c c"
                        "d e";
    }

    .works_interval-user {
        grid-area: a;
    }

    .works_interval-arrival {
        grid-area: b;
    }

    .works_interval-departure {
        grid-area: c;
    }

    .works_interval-description {
        grid-area: d;
    }

    .works_interval-status {
        grid-area: e;
    }
}


</style>
