<template>
    <v-combobox
        v-model="user"
        :clearable="clearable"
        :hide-details="hideDetails"
        :item-text="itemText"
        :items="usersData"
        :multiple="multiple"
        :required="required"
        :rules="rules"
        class="user-combobox"
        item-value="id"
        return-object
        spellcheck="false"
    >
        <template v-slot:label> {{ label }} <sup v-if="required">*</sup></template>
    </v-combobox>
</template>

<script>
import {VCombobox} from 'vuetify/lib'

export default {
    name: "BaseUserCombobox",
    components: {VCombobox},
    props: {
        value: null,
        label: {
            type: String,
            default: "Сотрудник"
        },
        rules: {
            required: false
        },
        required: {
            required: false
        },
        clearable: {
            default: true
        },
        hideDetails: {
            default: true
        },
        multiple: {
            required: false,
            default: false
        },
        itemText: {
            type: String,
            required: false,
            default: 'short_name'
        },
        save: {
            type: Boolean,
            required: false,
            default: false,
        },
        saveName: {
            type: String,
            required: false,
            default: 'user'
        }
    },
    data: function () {
        return {
            usersData: [],
            user: null,
        }
    },
    created() {
        this.getData()
        if (this.value) {
            this.user = this.value
        } else {
            this.user = this.load()
        }
    },
    watch: {
        value: function () {
            if (this.value) {
                this.user = this.value
            }
        },
        user: function () {
            this.store(this.user)
            this.$emit('input', this.user)
        },
    },
    methods: {
        getData() {
            axios.get('/api/user/simpleList')
                .then(response => (
                    this.usersData = response.data))
                .catch(error => {
                    this.showError(error)
                });
        },
        store(val) {
            if (this.save) {
                localStorage[this.saveName] = val ? JSON.stringify(val) : null
            }
        },

        load(def = null) {
            if (this.save && localStorage[this.saveName]) {
                return JSON.parse(localStorage[this.saveName])
            }
            return def
        },

    },
}
</script>

<style scoped>
.user-combobox :deep(.v-select__selections) {
    font-weight: normal;
}
</style>