<template>
    <v-card>
        <v-container>
            <v-card-title>
                <span class="headline">Добавить файлы</span>
            </v-card-title>
            <v-card-text>
                <div class="upload">
                    <ul>
                        <li v-for="(file) in files" :key="file.id">
                            <span>{{ file.name }}</span> -
                            <span v-if="file.error === 'compressed'">
                                <v-icon
                                    color="green darken-2"
                                    small
                                >
                                    {{ $store.state.Icon.svgMdiCloudSync }}
                                </v-icon>
                            </span>
                            <span v-else-if="file.success">
                                <v-icon
                                    color="green darken-2"
                                    small
                                >
                                    {{ $store.state.Icon.svgMdiCloudCheck }}
                                </v-icon>
                            </span>
                            <span v-else-if="file.active">
                                <v-icon
                                    color="red darken-2"
                                    small
                                >
                                    {{ $store.state.Icon.svgMdiCloudUpload }}
                                </v-icon>
                            </span>
                            <span v-else></span>
                        </li>
                    </ul>
                </div>
            </v-card-text>
            <v-card-actions>
                <file-upload
                    ref="upload"
                    v-model="files"
                    :custom-action="customAction"
                    :data="{name: this.fName}"
                    :multiple="true"
                    :post-action="postAction"
                    :size="1024*1024*10*10"
                    :thread="3"
                    :accept="acceptFileType"
                    :extensions="acceptFileExtensions"
                    @input-filter="inputFilter"
                    @input-file="inputFile">
                    <v-icon>{{ $store.state.Icon.svgMdiPlus }}</v-icon>
                    Добавить
                </file-upload>
                <v-spacer></v-spacer>
                <button v-if="!$refs.upload || !$refs.upload.active" class="btn btn-success small" type="button"
                        @click.prevent="$refs.upload.active = true">
                    <v-icon>{{ $store.state.Icon.svgMdiArrowUpBold }}</v-icon>
                    Начать
                </button>
                <button v-else class="btn btn-danger small" type="button" @click.prevent="$refs.upload.active = false">
                    <v-icon>{{ $store.state.Icon.svgMdiStop }}</v-icon>
                    Отменить
                </button>
                <v-spacer></v-spacer>
                <button :disabled="disabledClose()" class="btn btn-secondary small" type="button"
                        @click.prevent="close">
                    <v-icon>{{ $store.state.Icon.svgMdiClose }}</v-icon>
                    Закрыть
                </button>
            </v-card-actions>

        </v-container>
    </v-card>
</template>


<script>
import FileUpload from 'vue-upload-component';
import Compressor from 'compressorjs';
import {acceptFileExtensions, acceptFileType} from "./FileParam";

export default {

    components: {
        FileUpload,
    },
    props: {
        url: {
            required: true
        },
    },
    data() {
        return {
            files: [],
            autoCompress: 1024 * 1024,
            fName: "1"
        }
    },
    created() {
        // console.log(document.head.querySelector('meta[name="csrf-token"]').content)

    },
    computed: {
        postAction: function () {
            return this.url;
        },

        acceptFileType: function () {
            return acceptFileType()
        },

        acceptFileExtensions: function () {
            return acceptFileExtensions()
        }
    },
    methods: {

        close() {
            this.$emit('input', false)
            this.files = []
        },

        disabledClose() {
            return !(!this.$refs.upload || !this.$refs.upload.active);

        },
        async customAction(file) {
            let formData = new FormData()
            formData.set("file", file.file)
            formData.set("name", file.name)
            return await axios.post(
                this.url,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
            )
        },


        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Before adding a file
                // 添加文件前
                // Filter system files or hide files
                // 过滤系统文件 和隐藏文件
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent()
                }
                // Filter php html js file
                // 过滤 php html js 文件
                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent()
                }
                // Automatic compression
                // 自动压缩
                if (newFile.file && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
                    // console.log(newFile.size)
                    newFile.error = 'compressed'
                    let upload = this.$refs.upload
                    new Compressor(newFile.file, {
                        quality: 0.8,
                        maxWidth: 2500,
                        maxHeight: 2500,
                        success(file) {
                            // console.log(file.size)
                            upload.update(newFile, {error: '', file, size: file.size, type: file.type})
                        },
                        error(err) {
                            // console.log("err")
                            upload.update(newFile, {error: err.message || 'compress'})
                        },
                    });
                }
            }
            if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                // Create a blob field
                // 创建 blob 字段
                newFile.blob = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL) {
                    newFile.blob = URL.createObjectURL(newFile.file)
                }
                // Thumbnails
                // 缩略图
                newFile.thumb = ''
                if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                    newFile.thumb = newFile.blob
                }
            }
        },
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // add
                // console.log('add', newFile)
            }
            if (newFile && oldFile) {
                // update
                // console.log('update', newFile)
            }
            if (!newFile && oldFile) {
                // remove
                // console.log('remove', oldFile)
            }
        }
    }
}
</script>

<style>
span.file-uploads label {
    cursor: pointer;
}
</style>
