<template>
    <directory-form
        ref="directoryForm"
        :fields="mergedFields"
        :name="custom_data_settings?.name"
        :requred="custom_data_settings?.requred"
        :data="localData"
        :disabled="disabled"
        :read_only="disabled"
        :with-remove="withRemove"
        v-on:addAddedItem="onAddAddedItem"
        v-on:editAddedItem="onEditAddedItem"
        v-on:deleteAddedItem="onDeleteAddedItem"
        v-on:addRemovedItem="onAddRemovedItem"
        v-on:editRemovedItem="onEditRemovedItem"
        v-on:deleteRemovedItem="onDeleteRemovedItem"
    >
        <template v-slot:add-item-form>
            <slot name="add-item-form">
                <directory-item-select
                    v-if="extra_attributes?.directory"
                    :directory-id="extra_attributes?.directory"
                    :open-dialog="addItemDialog"
                    :url-for-item-select="urlForItemSelect"
                    :value="editableAddedItem"
                    v-on:close="addItemDialog = false"
                    v-on:return="itemSelectReturnFunction"
                />
            </slot>
        </template>

        <template v-if="withRemove" v-slot:remove-item-form>
            <slot name="remove-item-form"/>
        </template>

    </directory-form>
</template>

<script>
import DirectoryForm from "./DirectoryForm.vue";
import DirectoryItemSelect from "../../../../BaseComponents/Directory/DirectoryItemSelect.vue";

export default {
    name: "Directory",
    components: {DirectoryForm, DirectoryItemSelect},
    props: {
        custom_data_settings: null,
        value: null,
        extra_attributes: null,
        read_only: false,
        disabled: false,
        withRemove: {
            type: Boolean,
            default: false
        },
        urlForItemSelectAdd: null,
        urlForItemSelectRemove: null,
        removeItemDialog: false,
        fields: null
    },
    data: function () {
        return {
            localData: null,
            directoryData: null,
            filtredFields: null,
            mergedFields: null,
            resizeObserver: null,
            blockInLine: true,
            addItemDialog: false,
            editableAddedItem: null,
            editableAddedIndex: null,

            itemSelectReturnFunction: this.onReturnAddedItem,

            editableRemovedItem: null,
            editableRemovedIndex: null,
        }
    },
    created() {
        this.localData = this.value
    },

    watch: {
        value: function (val) {
            this.localData = val
        },
        extra_attributes: {
            handler: function (val) {
                if (val && val.directory) {
                    this.getDirectoryData(val?.directory)
                }
            },
            immediate: true
        },
    },

    computed: {
        urlForItemSelect() {
            return this.urlForItemSelectAdd ?? '/api/directoryitem/indexToChoose/' + this.extra_attributes?.directory
        }
    },

    methods: {
        getDirectoryData(directoryId) {
            if (this.fields && Array.isArray(this.fields)) {
                this.mergedFields = this.fields
            } else if (directoryId) {
                axios.get('/api/directory/settings/' + directoryId)
                    .then(response => {
                        this.directoryData = response.data
                        //Сортируем поля справочника
                        if (this.directoryData && this.directoryData.fields) {
                            this.directoryData.fields.sort((firstItem, secondItem) => firstItem.following - secondItem.following);
                        }
                        //Фильтруем поля которые можно показывать
                        if (this.directoryData && this.directoryData.fields) {
                            this.filtredFields = this.directoryData.fields.filter(field => field.show === true);
                        }

                        this.mergedFields = this.filtredFields
                            .concat(this.directoryData.additional_fields)
                            .map(item => item.name)
                    });
            }
        },


        onAddAddedItem() {
            this.itemSelectReturnFunction = this.onReturnAddedItem
            this.editableAddedItem = null
            this.editableAddedIndex = null
            this.addItemDialog = true
        },

        onEditAddedItem(key) {
            this.editableAddedItem = this.localData[key]
            this.editableAddedItem.id = null
            this.editableAddedIndex = key
            this.addItemDialog = true
        },


        onDeleteAddedItem(key) {
            if (confirm('Удалить запись?')) {
                this.$emit('deleteAddedItem', key)
            }
        },

        onReturnAddedItem(item) {
            if (this.editableAddedIndex !== null) {
                this.$emit('updateAddedItem', {item: item, index: this.editableAddedIndex})
            } else {
                this.$emit('addAddedItem', item)
            }
            this.editableAddedItem = null
            this.editableAddedIndex = null
        },

        onAddRemovedItem() {
            this.editableRemovedItem = null
            this.editableRemovedIndex = null
            this.$emit('update:removeItemDialog', true)
        },

        onEditRemovedItem(key) {

        },


        onDeleteRemovedItem(key) {
            if (confirm('Удалить запись?')) {
                this.$emit('deleteRemovedItem', key)
            }
        },
    }


}
</script>


