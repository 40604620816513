<template>
    <g transform="scale(.06212 .05985)">
        <path :fill="iconColor"
              d="M18.11 501.22h350.27c9.96 0 18.11-8.15 18.11-18.11V255.4h5.03c6.01 0 10.93-4.92 10.93-10.93V10.93c0-6.01-4.92-10.93-10.93-10.93H180.19c-6.01 0-10.92 4.92-10.92 10.93v103.8H18.11C8.15 114.73 0 122.88 0 132.84v350.27c0 9.96 8.15 18.11 18.11 18.11zM192.52 23.25H379.2v208.89H192.52zm162.84 232.14v214.02H31.81V208.96h137.46v35.51c0 6.01 4.92 10.93 10.92 10.93h175.17zm-186.09-64H31.81v-45.2h137.46z"/>
        <path :fill="iconColor" d="M59.09 298.39h114.19v17.57H59.09z"/>
        <path :fill="iconColor" d="M210.41 298.39H324.6v17.57H210.41z"/>
        <path :fill="iconColor" d="M59.09 353.82h114.19v17.57H59.09z"/>
        <path :fill="iconColor" d="M210.41 334.45H324.6v56.29H210.41z"/>
        <path :fill="iconColor"
              d="M207.11 132.84V122c0-3.64 2.98-6.61 6.61-6.61h17.45a55.75 55.75 0 017.62-18.13L226.53 85c-2.57-2.57-2.57-6.78 0-9.35l7.66-7.67c2.57-2.57 6.78-2.57 9.35 0l12.34 12.34a55.57 55.57 0 0118.21-7.44V55.55c0-3.64 2.98-6.61 6.61-6.61h10.84c3.64 0 6.61 2.98 6.61 6.61V73a55.75 55.75 0 0118.13 7.62l12.26-12.26c2.57-2.57 6.78-2.57 9.35 0l7.66 7.66c2.57 2.57 2.57 6.78 0 9.35l-12.34 12.34a55.57 55.57 0 017.44 18.21h17.33c3.64 0 6.61 2.98 6.61 6.61v10.84c0 3.64-2.98 6.61-6.61 6.61h-17.45a55.75 55.75 0 01-7.62 18.13l12.26 12.26c2.57 2.57 2.57 6.78 0 9.35l-7.66 7.66c-2.57 2.57-6.78 2.57-9.35 0l-12.34-12.34a55.57 55.57 0 01-18.21 7.44v17.33c0 3.64-2.97 6.61-6.61 6.61h-10.84c-3.64 0-6.61-2.98-6.61-6.61v-17.45a55.75 55.75 0 01-18.13-7.62L243.16 187c-2.57 2.57-6.78 2.57-9.35 0l-7.67-7.66c-2.57-2.57-2.57-6.78 0-9.35l12.34-12.34a55.57 55.57 0 01-7.44-18.21h-17.33c-3.64 0-6.61-2.98-6.61-6.61zm78.75-35.77c16.91 0 30.62 13.71 30.62 30.62s-13.71 30.62-30.62 30.62-30.62-13.71-30.62-30.62 13.71-30.62 30.62-30.62z"/>
    </g>
</template>

<script>
export default {
    name: "AdminIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
