<template>
    <div>
        <base-modal
            v-model="openEquipmentSelectionDialog"
            max-width="1000"
        >
            <v-card>
                <v-container>
                    <v-card-title>
                        <span class="headline">Оборудование и материалы</span>
                    </v-card-title>
                    <v-card-text>
                        <div class="scrol-div">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="section_1"
                                        :disabled="disabled"
                                        autocomplete="off"
                                        clearable
                                        hide-details
                                        label="Наименование"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="name"
                                        :disabled="!section_1"
                                        autocomplete="off"
                                        clearable
                                        hide-details
                                        label="Модель"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="description"
                                        :disabled="!name"
                                        autocomplete="off"
                                        clearable
                                        hide-details
                                        label="Описание"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="quantity"
                                        :disabled="!name"
                                        clearable
                                        hide-details
                                        label="Количество"
                                        type="number"
                                    ></v-text-field>
                                    <base-combobox-with-request
                                        v-model="unit"
                                        :disabled="!name"
                                        :return-object="true"
                                        item-text="description"
                                        item-value="id"
                                        label="Ед.изм"
                                        title="Ед.изм"
                                        url="unit/index"
                                    />
                                    <v-combobox
                                        v-model="status"
                                        :disabled="!name"
                                        :items="statusItems"
                                        hide-details
                                        label="Статус"
                                    >
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue" text v-on:click="save()">Сохранить</v-btn>
                        <v-btn color="blue" text v-on:click="close()">Закрыть</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </base-modal>

        <table class="taskMainTable elevation-3">
            <tbody>
            <tr style="height: 33px">
                <td class="taskHeaderTableTitle text-left pl-3 pr-3" colspan="7">
                    <v-row class="py-0">
                        <v-col class="py-0" cols="10">Оборудование и материалы</v-col>
                        <v-col class="py-0 text-right" cols="2">
                            <v-btn
                                :disabled="disabled"
                                icon
                                small
                                @click="openEquipmentSelectionDialog=true"
                            >
                                <v-icon>{{ $store.state.Icon.svgMdiPlus }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td class="Title text-center font-weight-bold">Наименование</td>
                <td class="Title text-center font-weight-bold">Модель</td>
                <td class="Title text-center font-weight-bold">Описание</td>
                <td class="Title text-center font-weight-bold width-10">Кол-во</td>
                <td class="Title text-center font-weight-bold width-10">Ед. изм.</td>
                <td class="Title text-center font-weight-bold width-10">Статус</td>
                <td class="Title text-center font-weight-bold width-10">Удалить</td>
            </tr>
            <tr v-for="(item) in value">
                <td class="TableRow">{{ item.section_1 }}</td>
                <td class="TableRow">{{ item.name }}</td>
                <td class="TableRow">{{ item.description }}</td>
                <td class="TableRow text-center width-10">{{ item.quantity }}</td>
                <td class="TableRow text-center width-10">{{ item.unit.name }}</td>
                <td class="TableRow text-center width-10">
                    <template v-if="item.status">Исправно</template>
                    <template v-else>Неисправно</template>
                </td>
                <td class="TableRow text-center width-10">
                    <v-btn
                        :disabled="disabled"
                        icon
                        x-small
                        @click="deleteOneTimeWorksItem(item.id)"
                    >
                        <v-icon>{{ $store.state.Icon.svgMdiDelete }}</v-icon>
                    </v-btn>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";
import BaseComboboxWithRequest from "../../../BaseComponents/BaseComboboxWithRequest";

export default {
    name: "FreeEquipment",
    components: {
        BaseModal,
        BaseComboboxWithRequest
    },
    props: {
        value: {
            default: null
        },
        taskId: {
            type: Number,
            required: true
        },
        disabled: {
            type: Boolean,
            required: true
        }
    },
    data: function () {
        return {
            openEquipmentSelectionDialog: false,
            name: null,
            section_1: null,
            description: null,
            quantity: 1,
            unit: {description: "не установлен"},
            status: 'Исправно',
            statusItems: ["Исправно", "Неисправно"]
        }
    },

    methods: {
        save() {
            let payload = {
                "section_1": this.section_1,
                "name": this.name,
                "description": this.description,
                "quantity": this.quantity,
                "unit": this.unit,
                "status": this.status === "Исправно",
            }
            axios.put('/api/freeEquipment/' + this.taskId, {'data': payload})
                .then(() => (this.close()))
                .catch(function (error) {
                    console.log(error)
                });
        },
        close() {
            this.section_1 = null
            this.name = null
            this.description = null
            this.quantity = 1
            this.unit = {description: "не установлен"}
            this.status = 'Исправно'
            this.openEquipmentSelectionDialog = false
            this.$emit('input')
        },
        deleteOneTimeWorksItem(id) {
            if (confirm("Вы уверены, что хотите удалить оборудование?")) {
                axios.delete('/api/freeEquipment/' + this.taskId + '/' + id,)
                    .then(() => (
                        this.$emit('input')
                    ))
                    .catch(function (error) {
                        console.log(error)
                    });
            }

            // console.log("Удаляем разовую работу №", id)
        },
    },
}
</script>

<style scoped>

</style>
