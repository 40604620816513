export const acceptFileType = () => {
    return [
        '.3gp',
        '.7z',
        '.avi',
        '.bmp',
        '.doc',
        '.docx',
        '.gif',
        '.jpeg',
        '.jpg',
        '.mkv',
        '.mov',
        '.mp4',
        '.mpeg',
        '.ods',
        '.odt',
        '.pdf',
        '.png',
        '.rar',
        '.rtf',
        '.webm',
        '.webp',
        '.xls',
        '.xlsm',
        '.xlsx',
        '.zip',
        'application/msword',
        'application/octet-stream',
        'application/pdf',
        'application/rtf',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.rar',
        'application/x-7z-compressed',
        'application/x-rar-compressed',
        'application/x-zip-compressed',
        'application/zip',
        'image/bmp',
        'image/gif',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/webp',
        'multipart/x-zip',
        'video/avi',
        'video/mkv',
        'video/mp4',
        'video/mpeg',
        'video/quicktime',
        'video/webm',
    ].join()
}

export const acceptFileExtensions = () => {
    return []
}