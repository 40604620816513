<template>
    <g transform="matrix(0.14046522,0,0,0.2045966,0,0.00795403)">
        <path
            :fill="iconColor"
            d="M 17.56,33.98 17.54,9.47 29.57,9.39 c 0.17,2.95 0.04,6.18 0.04,9.04 0,7.34 0.9,4.51 -6.99,11.45 -1.44,1.27 -3.71,3.46 -5.06,4.1 z"
        />
        <path
            :fill="iconColor"
            d="M 78.1,33.75 65.9,22.95 60.52,18.17 55.14,22.95 18.06,55.8 v 47.78 c 0,3.94 0.95,4.64 4.84,4.62 l 28.62,0.01 0.06,-23.14 C 51.04,85.49 50.49,85.9 49.93,86.3 42.64,91.45 34.1,93.24 27.99,85.08 L 25.33,81.53 78.11,33.76 Z"
        />
        <path
            :fill="iconColor"
            d="M 60.53,12.6 81.17,30.99 87.97,24.84 60.53,0.01 0,54.79 c 5.31,7.09 13.06,0.02 17.51,-3.85 L 60.53,12.61 Z"
        />
        <path
            :fill="iconColor"
            d="m 161.11,61.05 0.02,-29.59 -14.51,-0.1 c -0.21,3.56 -0.04,7.46 -0.04,10.92 0,5.5 -0.49,6.35 1.54,8.06 l 11.96,10.08 c 0.35,0.3 0.73,0.48 1.04,0.63 z"
        />
        <path
            :fill="iconColor"
            d="m 104.93,112.81 -10.79,-0.01 -0.09,33.84 -34.54,-0.01 c -4.69,0.02 -5.84,-0.82 -5.84,-5.58 V 83.38 l 44.75,-39.65 6.5,-5.77 6.5,5.77 44.75,39.65 v 57.67 c 0,4.76 -1.14,5.6 -5.84,5.58 l -34.54,0.01 -0.09,-33.84 -10.79,0.01 z"
        />
        <path
            :fill="iconColor"
            d="M 104.93,31.25 53.01,77.51 c -5.38,4.68 -14.72,13.21 -21.13,4.65 l 73.05,-66.12 73.05,66.12 c -6.41,8.56 -15.76,0.03 -21.13,-4.65 z"
        />
    </g>
</template>

<script>
export default {
    name: "ObjectGroupIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>