<template>
    <v-menu
        ref="menu"
        v-model="datePickerMenu"
        :close-on-content-click="false"
        :disabled="readonly || disabled"
        :nudge-right="40"
        min-width="0"
        offset-y
        transition="scale-transition"
    >
        <template v-slot:activator="{on}">
            <v-text-field
                v-model="computedDateFormattedMomentjs"
                :class="classObject"
                :clearable="!disabled && !readonly"
                :disabled="disabled"
                :hide-details="hideDetails"
                :label="label"
                :name="name"
                :title="title"
                readonly
                v-on="on"
                @click:clear="date = null"
            />
        </template>
        <v-date-picker
            v-model="date"
            :max="max"
            :min="min"
            locale="ru"
            no-title
            scrollable
        >
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="datePickerMenu = false">Отмена</v-btn>
            <v-btn color="primary" text @click="$refs.menu.save(date)">OK</v-btn>
        </v-date-picker>


    </v-menu>
</template>

<script>
// import moment from "moment";
// moment.locale("ru");
import {shortDateYearFirst} from "../../mixins/commonMethod.js";


export default {
    name: "BaseDatePicker",
    props: {
        name: "",
        value: null,
        label: '',
        min: undefined,
        max: undefined,
        title: '',
        format: null,
        hideDetails: false,
        classObject: {},
        readonly: false,
        disabled: false
    },
    data: function () {
        return {
            date: null,
            datePickerMenu: false,
        }
    },

    watch: {
        value: function () {
            if (typeof (this.value) == "string") {
                this.date = this.value.substr(0, 10)
            }
        },
        date: function () {
            this.$emit('input', this.date)
            this.$emit('change', this.date)
        }
    },
    created() {
        if (typeof (this.value) == "string") {
            this.date = this.value.substr(0, 10)
        }
    },
    computed: {
        computedDateFormattedMomentjs: {
            get: function () {
                return shortDateYearFirst(this.date)
                // return this.DateFormattedMomentjs(this.date)
            },
            set: function (newValue) {
                return shortDateYearFirst(newValue)
                // return this.DateFormattedMomentjs(newValue)
            }
        },
    },
    // methods: {
    //     DateFormattedMomentjs(date){
    //         return date ? moment(date, "YYYY-MM-DD").format('LL') : ''
    //     }
    // },
}
</script>

<style scoped>
.font-size {
    font-size: .85rem;
}

.cursor-pointer :hover {
    cursor: pointer;
}
</style>
