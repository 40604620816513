<template>
    <g transform="scale(0.06207325,0.06744604)">
        <path
            :fill="iconColor"
            d="m 12.85,0 c 161,0 216.05,0 377.05,0 7.07,0 12.85,5.78 12.85,12.85 0,146.02 0,193.31 0,339.34 0,7.07 -5.79,12.85 -12.85,12.85 H 12.85 C 5.79,365.04 0,359.26 0,352.19 V 12.85 C 0,5.78 5.78,0 12.85,0 Z m 358.52,31.38 c -113.54,0 -226.45,0 -339.98,0 v 302.27 h 339.98 c 0,-133.67 0,-168.6 0,-302.27 z"/>
        <path
            :fill="iconColor"
            d="m 125.78,375.14 h 151.18 v 41.56 h 33.44 c 7.73,0 14.05,6.32 14.05,14.05 v 0 c 0,7.73 -6.32,14.05 -14.05,14.05 H 92.33 c -7.73,0 -14.05,-6.32 -14.05,-14.05 v 0 c 0,-7.73 6.32,-14.05 14.05,-14.05 h 33.44 v -41.56 z"/>
        <path
            :fill="iconColor"
            d="m 135.44,192.32 24.86,-47.46 c 2.4,-5.33 11.55,-4.6 12.7,1.38 L 190.68,226.13 225,85.98 c 1.28,-6.05 11.03,-6.52 13.3,-0.65 l 0.06,0.17 31.51,106.82 h 73.99 c 12.45,0.24 12.13,18.69 -0.16,18.69 h -88.26 l -22.59,-76.6 -35.42,144.65 -0.03,0.12 c -1.96,6.42 -13.21,6.19 -14.68,-0.46 l -21.09,-95.28 -14.44,27.58 H 58.95 c -12.24,0 -12.41,-18.55 0,-18.7 h 76.51 z"/>
    </g>
</template>

<script>
export default {
    name: "StatusMonitorIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
