<template>
    <div>
        <directory
            :custom_data_settings="settings"
            :disabled="disabled"
            :extra_attributes="settings?.extra_attributes"
            :url-for-item-select-add="'/api/warehouse/selection/user/' + userId"
            :value="value"
            :with-remove="true"
            :warehouse="true"
            v-on:addAddedItem="onAddAddedItem($event, value.name)"
            v-on:deleteAddedItem="onDeleteAddedItem($event, value.name)"
            v-on:updateAddedItem="onUpdateAddedItem($event, value.name)"
            v-bind:removeItemDialog.sync="removeItemDialog"
            v-on:deleteRemovedItem="onDeleteRemovedItem($event, value.name)"
            :fields="['Операция', 'Наименование', 'Место установки', 'Дата установки','Количество', 'Ед.Измерения']"
        >
            <template v-slot:remove-item-form>
                <system-equipment-select
                    :open-dialog="removeItemDialog"
                    :task-id="taskId"
                    v-on:addRemovedItem="addRemovedItem($event, value.name)"
                    v-on:close="onCloseSystemEquipmentSelect"
                />
            </template>
        </directory>
    </div>

</template>

<script>


import Directory from "../TaskSystemData/FieldType/Directory";
import SystemEquipmentSelect from "./SystemEquipmentSelect.vue";

export default {
    name: "WarehouseEquipment",
    components: {Directory, SystemEquipmentSelect},
    props: {
        value: {
            default: null
        },
        taskId: {
            type: Number,
            required: true
        },
        disabled: {
            type: Boolean,
            required: true
        }
    },

    data: function () {
        return {
            directoryId: null,
            settings: null,
            removeItemDialog: false
        }
    },
    created() {
        this.getDirectoryData()
    },

    computed: {
        userId: function () {
            return this.$store.getters["TaskData/getTaskExecutorId"]
        },
        systemId: function () {
            return this.$store.getters["TaskData/getTaskSystemId"]
        },
    },

    methods: {
        getDirectoryData() {
            axios.get('/api/warehouse_config/directory/id')
                .then(response => {
                    if (response.data && parseInt(response.data)) {
                        this.settings = {
                            name: 'Оборудование',
                            extra_attributes: {
                                directory: parseInt(response.data)
                            }
                        }
                    }
                });
        },

        onAddAddedItem(data, key) {
            if (this.taskId && data) {
                axios.post('/api/taskWrhEquip/' + this.taskId, data)
                    .then(
                        response => {
                        }
                    )
                    .catch(
                        error => this.showError(error)
                    )
                    .finally(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                ;
            }
        },

        onUpdateAddedItem(data, key) {
            if (this.taskId && data.item) {
                axios.put('/api/taskWrhEquip/' + this.taskId, data.item)
                    .then(
                        response => {
                        }
                    )
                    .catch(
                        error => this.showError(error)
                    )
                    .finally(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                ;
            }
        },

        onDeleteAddedItem(data, key) {
            if (this.taskId && data && this.value[data]) {
                axios.post('/api/taskWrhEquip/delete/' + this.taskId, this.value[data])
                    .then(
                        response => {
                        }
                    )
                    .catch(
                        error => this.showError(error)
                    )
                    .finally(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                ;
            }
        },

        addRemovedItem(data, key) {
            if (this.taskId && data) {
                axios.post('/api/taskWrhEquip/removed/' + this.taskId, data)
                    .then(
                        response => {
                        }
                    )
                    .catch(
                        error => this.showError(error)
                    )
                    .finally(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                ;
            }
        },

        onCloseSystemEquipmentSelect() {
            this.removeItemDialog = false
        },


        onDeleteRemovedItem(data, key) {
            if (this.taskId && data && this.value[data]) {
                axios.post('/api/taskWrhEquip/removed/delete/' + this.taskId, this.value[data])
                    .then(
                        response => {
                        }
                    )
                    .catch(
                        error => this.showError(error)
                    )
                    .finally(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                ;
            }
        }
    }
}
</script>

<style scoped>

</style>