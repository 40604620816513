<template>
    <v-row v-if="defects && Array.isArray(defects) && defects.length"
           no-gutters
    >
        <v-col
            class="px-0 pt-1"
        >
            <div class="object-card-table-title font-weight-bold">
                Текущие дефекты системы
            </div>
            <div v-for="defect in defects" class="object-card-table-row">
                {{ defect.description }} <br>
                {{ defect.necessary_work }}
            </div>
            <div class="object-card-table-last">

            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ObjectCardSystemDefectRow",
    props: {
        defects: {
            required: true
        },
    },
}
</script>

<style scoped>

</style>