var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"position-x":_vm.rightClickMenu_x,"position-y":_vm.rightClickMenu_y,"absolute":"","offset-y":""},model:{value:(_vm.showRightClickMenu),callback:function ($$v) {_vm.showRightClickMenu=$$v},expression:"showRightClickMenu"}},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.rightClick}},[_c('v-list-item-title',[_vm._v("Открыть карточку в новом окне")])],1)],1)],1),_vm._v(" "),_c('v-data-table',{attrs:{"footer-props":{
            showCurrentPage: true,
            itemsPerPageOptions: [10,15,20,30,-1],
            showFirstLastPage: true,
            firstIcon: _vm.$store.state.Icon.svgMdiArrowCollapseLeft,
            lastIcon: _vm.$store.state.Icon.svgMdiArrowCollapseRight
        },"headers":_vm.headers,"item-class":_vm.row_classes,"items":_vm.dataList,"items-per-page":_vm.itemsPerPage,"loading":_vm.DataTableLoading,"search":_vm.search,"dense":"","loading-text":"Загрузка данных... Пожалуйста подождите","locale":"ru-RU","multi-sort":""},on:{"click:row":_vm.openObjectCard,"contextmenu:row":_vm.openRightClickMenu},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"pb-4 px-3",attrs:{"append-icon":_vm.$store.state.Icon.svgMdiMagnify,"clearable":"","hide-details":"","label":"Найти","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),_c('base-full-screen-modal',{staticStyle:{"z-index":"19999"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('object-card',{attrs:{"object-item":_vm.objectItem},on:{"close":_vm.onCloseObjectCard}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }