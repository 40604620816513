<template>
    <v-col class="px-0 pb-5 pt-0 pb-1">
        <v-row no-gutters>
            <v-col
                class="taskHeaderTitle pl-sm-0 pl-2 py-1 text-sm-center order-sm-1 order-1 text-body-2 text-sm-subtitle-1"
                cols="6" sm="3">Приоритет
            </v-col>
            <v-col
                class="taskHeaderTitle pl-sm-0 pl-2 py-1 text-sm-center order-sm-2 order-3 text-body-2 text-sm-subtitle-1"
                cols="6" sm="3">Статус
            </v-col>
            <v-col
                class="taskHeaderTitle pl-sm-0 pl-2 py-1 text-sm-center order-sm-3 order-5 text-body-2 text-sm-subtitle-1"
                cols="6" sm="3">Автор задачи
            </v-col>
            <v-col
                class="taskHeaderTitle pl-sm-0 pl-2 py-1 text-sm-center order-sm-4 order-7 text-body-2 text-sm-subtitle-1"
                cols="6" sm="3">Исполнитель
            </v-col>

            <v-col class="taskHeaderSimpleText text-sm-center order-sm-5 order-2" cols="6" sm="3">
                <task-priority
                    v-if="$store.getters['TaskData/getTaskPriority']"
                />
            </v-col>
            <v-col class="taskHeaderSimpleText text-sm-center order-sm-6 order-4" cols="6" sm="3">
                <task-status
                    v-if="$store.getters['TaskData/getTaskStatus']"
                />
            </v-col>
            <v-col class="taskHeaderSimpleText justify-content-center pl-sm-0 pl-4 text-sm-center order-sm-7 order-6"
                   cols="6" sm="3">
                <div class="my-1 text-left pl-4" style="font-size: .875rem">
                    {{ this.$store.getters["TaskData/getTaskData"].creator.short_name }}
                </div>
            </v-col>
            <v-col class="taskHeaderSimpleText text-sm-center order-sm-8 order-8" cols="6" sm="3">
                <task-executor
                    v-if="$store.getters['TaskData/getTaskExecutor']"
                />
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import TaskPriority from "./Module/TaskPriority/TaskPriority";
import TaskStatus from "./Module/TaskStatus/TaskStatus";
import TaskExecutor from "./Module/TaskExecutor/TaskExecutor";

export default {
    name: "TaskSubtitle",
    components: {TaskPriority, TaskStatus, TaskExecutor}
}
</script>

<style scoped>

</style>
