<template>
    <v-autocomplete
        v-if="extra_attributes && Array.isArray(extra_attributes)"
        v-model="model"
        :clearable="clearable"
        :disabled="disabled"
        :hide-details="hideDetails"
        :items="extra_attributes"
        :label="label"
        :required="required"
        :rules="rules"
        spellcheck="false"
        v-on:blur="$emit('blur')"
    />
</template>

<script>
import MainFieldsType from "./MainFieldsType";

export default {
    name: "Select",
    extends: MainFieldsType,

}
</script>

<style scoped>

</style>
