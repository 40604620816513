<template>
    <div>
        <v-overflow-btn
            v-if="status && items"
            v-model="status"
            :disabled="watchedTask"
            :items="items"
            :readonly="watchedTask"
            class="my-0 task-status-btn"
            flat
            hide-details
            item-disabled="disabled"
            item-text="name"
            item-value="id"
            label="Статус"
        >
        </v-overflow-btn>

        <base-modal v-model="commentDialog">
            <v-card>
                <v-form ref="form"
                        v-model="valid"
                >
                    <v-card-title>
                        <span class="headline">Обязательный комментарий</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row no-gutters>
                                <v-textarea
                                    v-model="comment"
                                    :required="true"
                                    :rules="[ rules ]"
                                    auto-grow
                                    counter
                                    no-resize
                                    rows="2"
                                >
                                </v-textarea>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeCommentDialog()">Отмена</v-btn>
                        <v-btn :disabled="!valid" color="blue darken-1" text @click="saveComment()">Сохранить</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </base-modal>
    </div>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";

export default {
    name: "TaskStatus",
    components: {BaseModal},

    data: function () {
        return {
            comment: null,
            commentDialog: false,
            valid: false,
            newStatusId: null
        }
    },

    created() {
        this.$store.dispatch('TaskStatusSettings/taskStatusSattings');
    },

    computed: {
        items() {
            return this.$store.getters["TaskData/getTaskData"].status_list
        },
        taskId() {
            return this.$store.getters["TaskData/getTaskId"]
        },
        watchedTask() {
            return this.$store.getters["TaskData/getWatchedTask"]
        },
        status: {
            get: function () {
                return this.$store.getters["TaskData/getTaskStatus"]
            },
            set: function (newValue) {
                this.changeStatus(newValue)
            }
        }
    },

    methods: {

        rules(value) {
            let lengs = this.getStatusSettingByIdLength(this.newStatusId) ?? null
            if (lengs && (!value || value.length < lengs)) {
                return "Минимум " + lengs;
            } else {
                return true;
            }
        },

        sendGeoData(coordinates, error, taskId, status) {
            let data = {
                status: status,
                coordinates: coordinates,
                error: error
            }
            axios.put('/api/geo/' + taskId, data)
                .then((resp) => {
                    //console.log(resp)
                })
                .catch(error => {
                    // console.log(error)
                });
        },

        geoData(status) {
            if (status) {
                this.$getLocation({
                    enableHighAccuracy: true, //defaults to false
                    timeout: Infinity, //defaults to Infinity
                    maximumAge: 0 //defaults to 0
                })
                    .then(coordinates => {
                        this.sendGeoData(coordinates, false, this.taskId, status)
                    })
                    .catch(error => {
                        this.sendGeoData(null, error, this.taskId, status)
                    });
            }
        },


        changeStatus(status) {
            if (confirm("Вы уверены, что хотите изменить статус задачи?")) {
                if (this.getStatusSettingByIdComment(status)) {
                    this.openCommentDialog(status)
                } else {
                    this.submitStatusChangeRequest(status)
                }
            }
        },

        openCommentDialog(status) {
            this.newStatusId = status
            this.commentDialog = true
        },

        closeCommentDialog() {
            this.commentDialog = false
            this.newStatusId = null
            this.comment = null
            this.valid = false
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
        },

        getStatusName(statusId) {
            return this.items.find(x => x.id === statusId)?.name
        },

        saveComment() {
            if (this.comment && this.comment.length >= this.getStatusSettingByIdLength(this.newStatusId)) {
                axios.post('/api/task/newcomment/' + this.taskId,
                    {
                        comment: 'Установил статус "' + this.getStatusName(this.newStatusId) + '" по причине: ' + this.comment
                    }
                )
                    .then(() => {
                        this.submitStatusChangeRequest(this.newStatusId)
                        this.closeCommentDialog()
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                ;
            }
        },

        getStatusSetting(settingName) {
            return this.$store.getters["TaskStatusSettings/" + settingName]
        },

        getStatusSettingByIdComment(statusId) {
            switch (statusId) {
                case 3:
                    return this.$store.getters["TaskStatusSettings/requireCommentWhenSetInWork"]
                case 4:
                    return this.$store.getters["TaskStatusSettings/requireCommentWhenSetSuspended"]
                case 5:
                    return this.$store.getters["TaskStatusSettings/requireCommentWhenSetCanceled"]
                case 6:
                    return this.$store.getters["TaskStatusSettings/requireCommentWhenSetDone"]
                case 7:
                    return this.$store.getters["TaskStatusSettings/requireCommentWhenSetClose"]
                default:
                    return false;
            }
        },

        getStatusSettingByIdLength(statusId) {
            switch (statusId) {
                case 3:
                    return this.$store.getters["TaskStatusSettings/InWorkCommentLength"]
                case 4:
                    return this.$store.getters["TaskStatusSettings/SuspendedCommentLength"]
                case 5:
                    return this.$store.getters["TaskStatusSettings/CanceledCommentLength"]
                case 6:
                    return this.$store.getters["TaskStatusSettings/DoneCommentLength"]
                case 7:
                    return this.$store.getters["TaskStatusSettings/CloseCommentLength"]
                default:
                    return null
            }
        },

        submitStatusChangeRequest(status) {
            axios.put('/api/taskstatus/' + this.taskId, {status: status})
                .then(() => {
                    this.geoData(status) //Запускаем отправку координат
                    this.$store.dispatch('TaskData/updateTaskData')
                })
                .catch(error => {
                    this.showError(error)
                });
        }
    }
}
</script>

<style scoped>
div.task-status-btn {
    text-align: center;
    font-size: 0.9rem;
}

div.task-status-btn :deep(div.v-select__slot) {
    height: 25px;
}

div.task-status-btn :deep(div.v-input__slot) {
    border-width: 0;
    border-style: none;
}

div.task-status-btn :deep(div.v-select__selection--disabled) {
    color: rgba(0, 0, 0, 0.87) !important;
}
</style>
