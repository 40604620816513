<template>
    <v-hover v-slot="{ hover }">
        <v-btn
            :color="hover ? 'corpColor' : 'normal' "
            :title="title"
            :to="to"
            icon
            retain-focus-on-click
            small
            v-on="on"
            v-on:click="$emit('click', $event)"
        >
            <v-icon>{{ icon }}</v-icon>
        </v-btn>
    </v-hover>
</template>

<script>
import {VBtn, VHover, VIcon} from 'vuetify/lib'

export default {
    name: "BaseIconBtn",
    components: {
        VHover, VBtn, VIcon
    },
    props: {
        to: {
            type: String
        },
        title: {
            type: String
        },
        icon: {
            type: String
        },
        on: {}
    },
}
</script>

<style scoped>

</style>