// initial state
import {UserSettingsDataType} from "../../DataTypes/userSettingsDataType";
import {AuthUserDataType} from "../../DataTypes/authUserDataType";

const state = {
    data: new AuthUserDataType(),
    settings: new UserSettingsDataType() //Настройки пользователя
}

// getters
const getters = {
    userId: state => {
        return state.data?.id
    },

    getSettings: (state) => state.settings,

    getTaskHeaderSettings: state => {
        if (state.settings && state.settings.taskListHeader) {
            return state.settings.taskListHeader
        }
        return new UserSettingsDataType().taskListHeader
    },

    getContractFilter: state => {
        if (state.settings && state.settings.contractFilter) {
            return state.settings.contractFilter
        }
        return new UserSettingsDataType().contractFilter
    },

    getLeftMenuSettings: state => {
        if (state.settings && state.settings.leftMenu) {
            return state.settings.leftMenu
        }
        return new UserSettingsDataType().leftMenu
    },


    getStatusDefectSettings: state => {
        return state.data?.settings?.status_defect
    }

}

// actions
const actions = {
    async getUserData() {
        try {
            const response = await axios.get(`/api/user/getauthuser`);
            this.commit('UserData/setUserData', response.data)
        } catch (error) {
            this.commit('UserData/setUserData', new AuthUserDataType())
        }
    },

    //Получаем настройки пользователя
    getUserSettings() {
        axios.get('/api/user/authUserSettings')
            .then(response => {
                this.commit('UserData/setUserSettings', response.data)
            })
            .catch(error => {
                if (error.response) {
                    this.commit('setErrors', error.response, {root: true})
                }
            })
    },

    updateUserSettings() {
        axios.put('/api/user/authUserSettings', state.settings)
            .catch(error => {
                if (error.response) {
                    this.commit('setErrors', error.response, {root: true})
                }
            })
    },

}


// mutations
const mutations = {
    setUserData(state, data) { //По моему нигде не используется
        state.data = data
    },

    setUserSettings(state, data) {
        state.settings = data
    },

    setTaskHeaderShowTaskId(state, data) {
        state.settings.taskListHeader.showTaskId = data
        dispatch('updateUserSettings')
    }

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
