<template>
    <div>
        <v-radio-group v-model="dayGroup">
            <v-radio
                key="EveryDay"
                class="pb-2"
                label="Каждый день"
                value="EveryDay"
            ></v-radio>
            <v-radio
                key="SelectedDays"
                class="pb-2"
                label="Только выбранные дни"
                value="SelectedDays"
            >
                <template v-slot:label>
                    <div class="pr-3">Только выбранные числа</div>
                    <div>
                        <v-select
                            v-model="selectedDays"
                            :disabled="dayGroup!=='SelectedDays'"
                            :items="daysNumber"
                            dense
                            hide-details
                            item-text="name"
                            item-value="id"
                            multiple
                            return-object
                        ></v-select>
                    </div>

                </template>

            </v-radio>
        </v-radio-group>
        <v-divider/>

        <v-radio-group v-model="monthGroup">
            <v-radio
                key="EveryMonth"
                class="pb-2"
                label="Каждый месяц"
                value="EveryMonth"
            ></v-radio>
            <v-radio
                key="SelectedMonth"
                class="pb-2"
                label="Только выбранные месяца"
                value="SelectedMonth"
            >
                <template v-slot:label>
                    <div class="pr-3">Только выбранные месяца</div>
                    <div>
                        <v-select
                            v-model="selectedMonth"
                            :disabled="monthGroup!=='SelectedMonth'"
                            :items="months"
                            dense
                            hide-details
                            item-text="name"
                            item-value="id"
                            multiple
                            return-object
                        ></v-select>
                    </div>
                </template>
            </v-radio>
        </v-radio-group>
        <v-divider/>

        <v-radio-group>
            <v-text-field
                v-model="time"
                dense
                hide-details
                style="width: 150px"
                type="time"
            >
                <template v-slot:prepend>
                    <div class="v-label theme--light" style="min-width: 50px">Время</div>
                </template>
            </v-text-field>
        </v-radio-group>
    </div>
</template>

<script>

export default {
    name: "VueCronEditor",
    data: function () {
        return {
            name: null,
            monthGroup: 'EveryMonth',
            dayGroup: 'SelectedDays',

            recurringDays: 1,
            selectedDays: [1],

            recurringMonths: 1,
            selectedMonth: [{id: 1}],
            time: '09:00',
            months: [
                {id: 1, name: 'Январь'},
                {id: 2, name: 'Февраль'},
                {id: 3, name: 'Март'},
                {id: 4, name: 'Апрель'},
                {id: 5, name: 'Май'},
                {id: 6, name: 'Июнь'},
                {id: 7, name: 'Июль'},
                {id: 8, name: 'Август'},
                {id: 9, name: 'Сентябрь'},
                {id: 10, name: 'Октябрь'},
                {id: 11, name: 'Ноябрь'},
                {id: 12, name: 'Декабрь'}
            ],
            monthsNumber: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            daysNumber: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        }
    },
    computed: {
        cronExpression: function () {
            return `${this.getMinutes()} ${this.getHours()} ${this.getDay()} ${this.getMonth()} *`
        },
    },

    watch: {
        cronExpression: {
            handler: function (val) {
                this.$emit('expression', val)
            },
            immediate: true
        },
    },

    methods: {
        getMinutes() {
            if (typeof this.time === 'string' && this.time.length === 5) {
                const minuts = this.time.slice(3, 5)
                return minuts[0] === '0' ? minuts[1] : minuts
            }
            return 0
        },

        getHours() {
            if (typeof this.time === 'string' && this.time.length === 5) {
                const hours = this.time.slice(0, 2)
                return hours[0] === '0' ? hours[1] : hours
            }
            return 0
        },
        getDay() {
            if (this.dayGroup === 'EveryDay') {
                return '*'
            }
            if (this.dayGroup === 'SelectedDays' && this.selectedDays.length) {
                return this.selectedDays.join()
            }
        },
        getMonth() {
            if (this.monthGroup === 'EveryMonth') {
                return '*'
            }
            if (this.monthGroup === 'SelectedMonth' && this.selectedMonth.length) {
                const allMonthId = this.selectedMonth.map((item) => item.id)
                return allMonthId.join()
            }
        },
    }
}
</script>

<style scoped>
.width100 {
    width: 100px
}
</style>