<template>
    <g transform="matrix(0.126968,0,0,0.12748907,0,4.5)">
        <path :fill="iconColor"
              d="m 37.22,0 h 152.13 c 4.15,0 7.55,3.4 7.55,7.55 v 122.68 c 0,4.15 -3.4,7.55 -7.55,7.55 H 72.21 l -0.36,-0.36 c 0.76,-1.5 1.42,-3.05 1.95,-4.64 h 6.01 c 4.01,0 7.32,-3.07 7.7,-6.98 h 93.63 c 2.08,0 3.77,-1.7 3.77,-3.77 V 15.77 c 0,-2.08 -1.7,-3.77 -3.77,-3.77 H 45.43 c -2.08,0 -3.77,1.7 -3.77,3.77 v 60.37 h -2.72 c -4.27,0 -7.74,3.48 -7.74,7.74 v 5.92 c -0.51,0.17 -1.02,0.34 -1.52,0.53 V 7.56 c 0,-4.15 3.4,-7.55 7.55,-7.55 z M 0,124.74 v -5.73 c 0,-1.92 1.57,-3.5 3.5,-3.5 h 9.23 c 0.78,-3.46 2.16,-6.7 4.03,-9.59 l -6.48,-6.48 c -1.36,-1.36 -1.36,-3.59 0,-4.95 l 4.05,-4.05 c 1.36,-1.36 3.59,-1.36 4.95,0 l 6.53,6.53 c 2.91,-1.84 6.16,-3.19 9.63,-3.93 v -9.17 c 0,-1.92 1.57,-3.5 3.5,-3.5 h 5.73 c 1.92,0 3.5,1.57 3.5,3.5 v 9.23 c 3.46,0.78 6.7,2.16 9.59,4.03 l 6.48,-6.48 c 1.36,-1.36 3.59,-1.36 4.95,0 l 4.05,4.05 c 1.36,1.36 1.36,3.59 0,4.95 l -6.53,6.53 c 1.84,2.91 3.19,6.16 3.93,9.63 h 9.17 c 1.92,0 3.5,1.57 3.5,3.5 v 5.73 c 0,1.92 -1.57,3.5 -3.5,3.5 h -9.23 c -0.78,3.47 -2.16,6.7 -4.03,9.59 l 6.48,6.48 c 1.36,1.36 1.36,3.59 0,4.95 l -4.05,4.05 c -1.36,1.36 -3.59,1.36 -4.95,0 l -6.53,-6.53 c -2.91,1.84 -6.16,3.19 -9.63,3.93 v 9.17 c 0,1.92 -1.57,3.5 -3.5,3.5 h -5.73 c -1.92,0 -3.5,-1.57 -3.5,-3.5 v -9.23 c -3.47,-0.78 -6.7,-2.16 -9.59,-4.03 l -6.48,6.48 c -1.36,1.36 -3.59,1.36 -4.95,0 l -4.05,-4.05 c -1.36,-1.36 -1.36,-3.59 0,-4.95 l 6.53,-6.53 c -1.84,-2.91 -3.19,-6.15 -3.93,-9.63 H 3.5 c -1.92,0 -3.5,-1.57 -3.5,-3.5 z m 41.65,-18.92 c 8.94,0 16.2,7.25 16.2,16.2 0,8.94 -7.25,16.2 -16.2,16.2 -8.94,0 -16.2,-7.25 -16.2,-16.2 0,-8.94 7.25,-16.2 16.2,-16.2 z m 40.77,45.8 h 9.89 v -9.98 h 41.93 v 9.98 h 9.89 c 2.08,0 3.77,1.7 3.77,3.77 v 5.56 c 0,2.08 -1.7,3.77 -3.77,3.77 H 82.42 c -2.08,0 -3.77,-1.7 -3.77,-3.77 v -5.56 c 0,-2.08 1.7,-3.77 3.77,-3.77 z"/>
    </g>
</template>

<script>
export default {
    name: "UsersIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
