const Customer = () => import(/* webpackChunkName: "Customer" */ '../components/Admin/Customers/Customers.vue')
const CustomerCreate = () => import(/* webpackChunkName: "Customer" */ '../components/Admin/Customers/CustomerCreate.vue')
const CustomerDelete = () => import(/* webpackChunkName: "Customer" */ '../components/Admin/Customers/CustomerDelete.vue')
const CustomerUpdate = () => import(/* webpackChunkName: "Customer" */ '../components/Admin/Customers/CustomerUpdate.vue')
export const customerRoutes = [
    {
        path: '/customer', name: "customer.index", component: Customer,
        children: [
            {path: "create", component: CustomerCreate, name: "customer.create"},
            {path: "delete/:id", component: CustomerDelete, name: "customer.delete", props: true},
            {path: ":id", component: CustomerUpdate, name: "customer.update", props: true},
        ]
    },
]