<template>
    <base-system-defects
        v-slot="scope"
        :task-id="taskId"
    >
        <div>
            <v-row v-if="checkData(existingDefects)"
                   no-gutters
            >
                <v-col
                    class="px-0 pt-1"
                >
                    <div class="object-card-table-title font-weight-bold">
                        Существующие дефекты
                    </div>
                    <div v-for="defect in existingDefects" class="object-card-table-row">
                        <system-defect-item-in-object-card
                            :task-id="taskId"
                            :value="defect"
                            v-on:info="scope.openInfoDialog"
                        />
                    </div>
                    <div class="object-card-table-last">
                    </div>
                </v-col>
            </v-row>

            <v-row v-if="checkData(foundDefects)"
                   no-gutters
            >
                <v-col
                    class="px-0 pt-1"
                >
                    <div class="object-card-table-title font-weight-bold">
                        Выявленные дефекты
                    </div>

                    <div v-for="defect in foundDefects" class="object-card-table-row">
                        <system-defect-item-in-object-card
                            :task-id="taskId"
                            :value="defect"
                            v-on:info="scope.openInfoDialog"
                        />
                    </div>
                    <div class="object-card-table-last">
                    </div>
                </v-col>
            </v-row>

            <v-row v-if="checkData(fixedDefects)"
                   no-gutters
            >
                <v-col
                    class="px-0 pt-1"
                >
                    <div class="object-card-table-title font-weight-bold">
                        Устраненные дефекты
                    </div>
                    <div v-for="defect in fixedDefects" class="object-card-table-row">
                        <system-defect-item-in-object-card
                            :task-id="taskId"
                            :value="defect"
                            v-on:info="scope.openInfoDialog"
                        />
                    </div>
                    <div class="object-card-table-last">
                    </div>
                </v-col>
            </v-row>

        </div>
    </base-system-defects>


</template>

<script>
import SystemDefectItemInObjectCard from "../../SystemDefect/SystemDefectItemInObjectCard.vue";
import SystemDefectItemInTask from "../../SystemDefect/SystemDefectItemInTask.vue";
import BaseSystemDefects from "../../SystemDefect/BaseSystemDefects.vue";

export default {
    name: "ObjectCardTaskSystemDefect",
    components: {BaseSystemDefects, SystemDefectItemInTask, SystemDefectItemInObjectCard},
    props: {
        taskId: {
            required: true
        },
        fixedDefects: {
            required: true
        },
        existingDefects: {
            required: true
        },
        foundDefects: {
            required: true
        },
    },
    methods: {
        checkData(data) {
            return data && Array.isArray(data) && data.length
        }
    }
}
</script>

<style scoped>

</style>
