<template>
    <div v-if="showLink">
        <v-input
            :disabled="disabled"
            class="task-text-field pt-0 mt-0"
            dense
            hide-details
            readonly

        >
            <template v-slot:default>
                <a v-on:click="imgDialog=true">{{ localData.name }}</a>
            </template>
            <template v-slot:append>
                <v-icon
                    v-on:click="downloadFile"
                >
                    {{ $store.state.Icon.svgMdiDownload }}
                </v-icon>
                <v-icon
                    :disabled="disabled"
                    v-on:click="deleteFile"
                >
                    {{ $store.state.Icon.svgMdiDelete }}
                </v-icon>
            </template>
        </v-input>
        <v-dialog
            v-model="imgDialog"
            max-width="1000px"
            scrollable
        >
            <v-card>
                <v-toolbar
                    dense
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="imgDialog=false"
                    >
                        <v-icon>{{ $store.state.Icon.svgMdiClose }}</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-img contain max-width="100%" v-bind:src="localData.url"></v-img>
            </v-card>
        </v-dialog>
    </div>
    <div v-else>
        <v-file-input
            v-model="localData"
            :append-icon="$store.state.Icon.svgMdiPaperclip"
            :clearable="!disabled && !read_only"
            :disabled="disabled"
            :readonly="read_only"
            :rules="requiredFileRules"
            class="task-text-field pt-0 mt-0"
            dense
            hide-details
            no-resize
            prepend-icon=""
            v-on:change="$emit('change', $event)"
        >
        </v-file-input>
    </div>
</template>

<script>
import requiredFileRules from "../../../../../Rules/requiredFileRules";
import downloadDataUrl from "../../../../../Helpers/downloadDataUrl";

export default {
    name: "File",
    props: {
        value: null,
        extra_attributes: null,
        read_only: false,
        disabled: false,
    },
    data: function () {
        return {
            localData: null,
            imgDialog: false,
        }
    },
    created() {
        this.localData = this.value
    },
    watch: {
        value: function (val) {
            this.localData = val
        },
    },
    computed: {
        showLink() {
            return !!(this.localData && this.localData.url);
        },
        requiredFileRules() {
            return requiredFileRules
        }
    },
    methods: {
        deleteFile() {
            this.localData = null
            this.$emit('change', null)
        },
        downloadFile() {
            downloadDataUrl(this.localData.url)
        },


    }
}
</script>

<style scoped>
div.task-text-field {
    font-size: .85rem;
}

div.task-text-field :deep(input[type="text"]) {
    padding: 3px 0 3px;
}

</style>
