<template>
    <div>
        <PersonCreate
            v-if="PersonDialog"
            v-on:close="onClosePersonForm"
            v-on:return="onReturnData"
        />

        <v-autocomplete
            v-model="person"
            :items="personData"
            :required="required"
            :rules="rules"
            :search-input.sync="searchPerson"
            clearable
            item-text="full_name"
            item-value="id"
            return-object
            spellcheck="false"
        >
            <template v-slot:label> {{ label }} <sup v-if="required">*</sup></template>
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            "<strong>{{ searchPerson }}</strong>" не найден. Нажмите
                            <v-btn
                                :disabled="!UserCan.Person.Create"
                                class="mb-2"
                                color="normal"
                                raised
                                small
                                @click="createPerson()"
                            >
                                Создать
                            </v-btn>
                            если вы хотите добавить контакт
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseErrorSnackbar from "./BaseErrorSnackbar";
import PersonCreate from "../Admin/Person/PersonCreate";

export default {
    name: "BasePersonCombobox",
    components: {
        PersonCreate,
        BaseModal,
        BaseErrorSnackbar
    },
    props: {
        value: null,
        label: {
            type: String,
            default: "Контактное лицо"
        },
        rules: {
            required: false
        },
        required: {
            required: false
        }
    },
    data: function () {
        return {
            PersonDialog: false,
            searchPerson: null,
            personData: [],
            person: null,
        }
    },
    created() {
        this.getData()
        if (this.value) {
            this.person = this.value
        }
    },
    watch: {
        value: function () {
            if (this.value) {
                this.person = this.value
            }
        },
        person: function () {
            this.$emit('input', this.person)
        },
    },
    methods: {
        createPerson() {
            this.PersonDialog = true
        },
        onClosePersonForm() {
            this.PersonDialog = false
            this.getData()
        },
        onReturnData(e) {
            this.person = e
        },
        getData() {
            axios.get('/api/person?fields[person]=id,full_name')
                .then(response => (this.personData = response.data))
                .catch(error => {
                    this.showError(error)
                });
        },
    },
}
</script>

<style scoped>

</style>
