<template>
    <g>
        <path :fill="iconColor"
              d="M8.52 20.29c.025.867-.165 1.395-.818 1.454H6.125c-1.026-.248-.676-1.581-.726-2.575-.676-2.226-.71-4.097.532-5.23l-.457-.233c-.735-.375-.608-1.227.2-1.336.555-.075.787-.21 1.169.481.21-.666.618-1.825.939-2.36.684-1.144 1.671-1.11 4.718-1.225 3.047.115 4.034.081 4.718 1.225.32.535.73 1.694.94 2.36.381-.69.613-.556 1.168-.481.808.109.935.96.2 1.336l-.457.234c1.241 1.132 1.208 3.003.532 5.229-.05.994.3 2.327-.726 2.575h-1.577c-.653-.059-.843-.587-.819-1.455H8.521Zm9.675-2.751c-.587.153-1.267.116-1.868.101-.322-.008-.587-.792-.363-1.144.31-.487 1.982-.948 2.307-.874.72.164.644 1.728-.076 1.917zm-11.39 0c.587.153 1.267.116 1.868.101.322-.008.587-.792.363-1.144-.31-.487-1.982-.948-2.307-.874-.72.164-.644 1.728.076 1.917zm.305-3.922c.73-1.623.773-2.588 1.64-3.222.638-.465 6.862-.465 7.5 0 .867.634.91 1.6 1.64 3.222-3.216-.337-7.564-.337-10.78 0z"
              style="stroke-width:.00587532"/>
        <path :fill="iconColor"
              d="M.798 0h23.404c.439 0 .798.39.798.867v28.266c0 .477-.359.867-.798.867H.798C.359 30 0 29.61 0 29.133V.867C0 .39.359 0 .798 0Zm22.254 2.117H1.948v25.766h21.104V2.117z"
              style="stroke-width:.00587532"/>
    </g>
</template>

<script>
export default {
    name: "TransportIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
