<template>
    <v-expansion-panels
        v-if="systemTypes"
        accordion
        hover
        active-class="object-card-expansion-panel-active"
        class="object-card-expansion-panel elevation-3"
    >
        <v-expansion-panel
            v-for="(item,i) in systemTypes"
            :key="i"
        >
            <v-expansion-panel-header class="py-0 ">
                <div class="text-left px-0">{{ i + 1 }}</div>
                <v-col class="text-left" cols="12">
                    {{ item.name }}
                    <template v-if="item.description && item.description !== item.name"> -
                        {{ item.description }}
                    </template>
                </v-col>
            </v-expansion-panel-header>
            <v-expansion-panel-content class=" px-0 pl-4">
                <object-card-contracts
                    :object-id="objectId"
                    :system-type-id="item.id"
                />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

import ObjectCardContracts from "./ObjectCardContracts.vue";

export default {
    name: "ObjectCardSystemTypes",
    components: {
        ObjectCardContracts
    },
    props: {
        objectId: {
            required: true
        },
        systemTypes: {
            required: true,
        }
    },

}
</script>

<style scoped>

.object-card-table {
    border: #99B1C6 1px solid;
    width: 100%;
    border-collapse: collapse;
}

.object-card-table > tr > td {

}


tr.simple-table-row > td {
    font-size: 13px !important;
}


.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px !important;
}


.object-card-expansion-panel :deep(.object-card-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 17px;
}

.object-card-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 17px !important;
}

.object-card-contract-expansion-panel :deep(.object-card-contract-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 15px;
}

.object-card-contract-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 15px !important;
}

.object-card-task-expansion-panel :deep(.object-card-task-expansion-panel-active) {
    color: #285473;
    background-color: #F7FAFC;
    font-size: 15px;
}

.object-card-task-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
    font-size: 15px !important;
}

.object-card-task-expansion-panel-active > .v-expansion-panel-content {
    background-color: #ffffff;
    color: #000000;
}

.object-card-expansion-panel :deep(.v-expansion-panel-content__wrap) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

a.noDecor {
    color: rgba(0, 0, 0, 0.87) !important;
    text-decoration: none;
}

a.noDecor:link,
a.noDecor:hover,
a.noDecor:active {
    color: rgba(0, 0, 0, 0.87);
}

</style>