<template>
    <v-autocomplete
        v-model="selectedData"
        :clearable="clearable"
        :dense="dense"
        :disabled="disabled"
        :hide-details="hideDetails"
        :hint="hint"
        :item-disabled="itemDisadled"
        :item-text="itemText"
        :item-value="itemValue"
        :items="data"
        :label="label"
        :multiple="multiple"
        :open-on-clear="openOnClear"
        :placeholder="placeholder"
        :required="required"
        :return-object="returnObject"
        :readonly="readonly"
        :rules="rules"
        :title="title"
        :validate-on-blur="validateOnBlur"
        class="text-truncate"
        spellcheck="false"
        v-on:blur="$emit('blur', selectedData)"
        v-on:change="$emit('change', selectedData)"
    >
        <template v-slot:label>
            {{ label }}<sup v-if="required">*</sup>
        </template>

        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>

    </v-autocomplete>
</template>

<script>
export default {
    name: "BaseAutocompleteWithRequest",
    props: {
        value: null,
        label: '',
        title: '',
        url: '',
        itemValue: {
            default: 'id'
        },
        itemText: {
            default: 'name'
        },
        itemDisadled: '',
        required: {
            type: Boolean,
            default: false
        },
        rules: "",
        multiple: false,
        dense: false,
        clearable: false,
        disabled: false,
        filterData: null,
        returnObject: {
            type: Boolean,
            default: true
        },
        openOnClear: {
            type: Boolean,
            default: true
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        validateOnBlur: {
            type: Boolean,
            default: false
        },
        hint: {
            default: undefined
        },
        placeholder: {
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        }

    },
    data: function () {
        return {
            data: [],
            selectedData: null
        }
    },
    created() {
        this.getData();
        if (this.value) {
            this.selectedData = this.value
        }
    },
    watch: {
        value: function () {
            if (this.value) {
                this.selectedData = this.value
            } else {
                this.selectedData = null
            }
        },
        selectedData: function () {
            this.$emit('input', this.selectedData)
        },
        filterData: function () {
            this.getData();
            this.selectedData = null
        }
    },
    methods: {

        getData() {
            if (this.url) {
                if (this.filterData) {
                    axios.post('/api/' + this.url, {'filterData': this.filterData})
                        .then(response => (this.data = response.data))
                        .catch(function (error) {
                            console.log(error);
                        })
                        .finally(() => {
                            this.$emit('loaded')
                        })
                    ;
                } else {
                    axios.get('/api/' + this.url)
                        .then(response => (this.data = response.data))
                        .catch(function (error) {
                            console.log(error);
                        })
                        .finally(() => {
                            this.$emit('loaded')
                        })
                    ;
                }
            }
        },
    },
}
</script>

<style scoped>

</style>
