<template>
    <v-card
        :height="height"
        class="mx-auto overflow-y-auto"
    >
        <v-card-title>
            Сотрудники
        </v-card-title>
        <v-card-text>
            <v-checkbox
                v-for="(user, index) in userList"
                :key="user.id"
                v-model="selectedUser"
                :disabled="user.disabled"
                :label="user.short_name"
                :value="user.id"
                class="user-list"
                hide-details
                :multiple="multiple"
            ></v-checkbox>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "BaseUsersCheckboxList",
    props: {
        value: {
            default: null
        },
        height: {
            default: "100%"
        },
        multiple: {
            default: true
        }
    },
    data() {
        return {
            userList: [], //Список пользователей
            selectedUser: null, //Выбранные пользователи
        };
    },
    created() {
        this.getUsersList()
    },
    watch: {
        value: {
            handler: function (val) {
                this.selectedUser = val
            },
            immediate: true
        },
        selectedUser: {
            handler: function (val) {
                this.$emit('input', val)
            },
            immediate: true
        },
    },
    methods: {
        /**
         * Метод получает список всех пользователей
         */
        getUsersList() {
            return axios.get('/api/user/simpleList')
                .then(response => {
                    // this.allSystems = objToArray(response.data)
                    this.userList = response.data
                })
                .catch(console.log.bind(console));
        },
    }
}
</script>

<style scoped>
.user-list :deep(label) {
    margin-bottom: 0
}

.user-list {
    margin-top: 5px;
}
</style>
