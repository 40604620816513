<template>
    <v-col v-if="systemFieldsSettings.length" class="px-0 pb-1">
        <template v-for="(value, index) in systemFieldsSettings">
            <v-row
                v-if="value.type_key && checkAddonComponent(value.type_key) && value.type_key !== customDataTypesName.Directory"
                no-gutters
            >
                <v-col
                    :title="value.description"
                    class="taskHeaderTitle setMargin pl-2 pt2 justify-center" cols="12" md="3"
                    sm="5"
                >
                    {{ value.name }}<sup v-if="value.requred">*</sup>
                </v-col>
                <v-col
                    class="taskHeaderSimpleText setMargin pt2 pl-2"
                    cols="12" md="9" sm="7"
                >
                    <component
                        :is="value.type_key"
                        :disabled="disabled"
                        :extra_attributes="value.extra_attributes"
                        :read_only="value.read_only"
                        :value="selectValue(value.name, value.fill_primary_data)"
                        v-on:change="updateData($event, value.name)"
                    ></component>
                </v-col>
            </v-row>
        </template>
    </v-col>
</template>

<script>
import CustomDataTypesName from "../../../BaseCustomDataComponents/CustomDataFieldsType/CustomDataTypesName";
import String from "./FieldType/String";
import TextArea from "./FieldType/TextArea";
import Number from "./FieldType/Number";
import Select from "./FieldType/Select";
import MultiSelect from "./FieldType/MultiSelect";
import Checkbox from "./FieldType/Checkbox";
import Date from "./FieldType/Date";
import DateTime from "./FieldType/DateTime";
import Money from "./FieldType/Money";
import Time from "./FieldType/Time";
import File from "./FieldType/File";

export default {
    name: "TaskSystemData",
    components: {String, TextArea, Number, Select, MultiSelect, Checkbox, Date, DateTime, Money, Time, File},
    data: function () {
        return {
            componentsList: Object.keys(this.$options.components),
        }
    },
    computed: {
        customDataTypesName() {
            return CustomDataTypesName
        },
        taskId() {
            return this.$store.getters["TaskData/getTaskId"]
        },
        disabled() {
            return this.$store.getters["TaskData/getDisabled"]
        },

        systemFieldsSettings() {
            return this.$store.getters["TaskData/getSystemFieldsSettings"]
        },

        taskSystemData() {
            return this.$store.getters["TaskData/getTaskSystemData"]
        },
        systemData() {
            return this.$store.getters["TaskData/getSystemData"]
        },

    },
    methods: {
        onConsole(evt) {
            // console.log(evt)
        },
        checkObject(obj) {
            return Object.keys(obj).length !== 0
        },
        checkAddonComponent(component) {
            return this.componentsList.includes(component)
        },

        selectValue(key, fill_primary_data) {
            if (this.taskSystemData && this.taskSystemData.hasOwnProperty(key)) {
                return this.taskSystemData[key]
            }

            if (fill_primary_data && this.systemData[key]) {
                return this.systemData[key]
            }
            return null
        },

        updateData(data, key) {

            let sendData;
            let contentType;
            if (typeof data === "object" && Object.prototype.toString.call(data) === '[object File]') {
                sendData = new FormData()
                sendData.append('data', data)
                sendData.append('key', key)
                contentType = 'multipart/form-data'
            } else {
                sendData = {
                    key: key,
                    data: data,
                }
                contentType = 'application/json'
            }

            axios({
                method: 'post',
                url: '/api/task/data/' + this.taskId,
                data: sendData,
                headers: {'Content-Type': contentType}
            })
                .then(() => {
                    this.$store.dispatch('TaskData/updateTaskData')
                })
                .catch(error => {
                    this.showError(error)
                });
        }


    }
}
</script>

<style scoped>

</style>
