<template>
    <v-card :loading="loading">
        <v-form
            ref="Form"
            v-model="Valid"
        >
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-tabs
                    v-model="tab"
                    centered
                    grow
                >
                    <v-tab key="Data">Основное</v-tab>
                    <v-tab key="CustomData">Дополнительно</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item key="Data">
                        <div class="scrol-div">
                            <v-col class="px-1 py-1" cols="12">
                                <v-text-field
                                    v-model="editedItem.name"
                                    :rules="requiredRules"
                                    hide-details
                                    label="Название справочника"
                                    required>
                                    <template v-slot:label>
                                        Название справочника<sup>*</sup>
                                    </template>
                                </v-text-field>
                            </v-col>

                            <v-col class="px-1 py-1" cols="12">
                                <v-text-field
                                    v-model="editedItem.description"
                                    hide-details
                                    label="Описание">
                                </v-text-field>
                            </v-col>

                            <v-simple-table
                                v-if="editedItem.fields && editedItem.fields.length"
                                class="pt-3"
                                dense
                            >
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left" width="10%">

                                        </th>
                                        <th class="text-left">
                                            Название поля
                                        </th>
                                        <th class="text-left">
                                            Тип поля
                                        </th>
                                        <th :width="10" class="text-center">
                                            Действия
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                        v-for="(item, index) in editedItem.fields"
                                        :key="index"
                                        class="simple-table-row"
                                    >
                                        <td>
                                            <v-icon
                                                :disabled="!(item.following > 0)"
                                                small
                                                @click="upField(index)"
                                            >
                                                {{ $store.state.Icon.svgMdiArrowUp }}
                                            </v-icon>
                                            <v-icon
                                                :disabled="!(editedItem.fields.length > (item.following+1))"
                                                small
                                                @click="downField(index)"
                                            >
                                                {{ $store.state.Icon.svgMdiArrowDown }}
                                            </v-icon>
                                        </td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.type.name }}</td>
                                        <td class="text-center">
                                            <v-icon
                                                small
                                                @click="deleteFieldsItem(index)"
                                            >
                                                {{ $store.state.Icon.svgMdiDelete }}
                                            </v-icon>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-container>
                                <v-row no-gutters>
                                    <v-col class="d-flex justify-end">
                                        <v-btn color="blue" text @click="FieldsDialog = true">Добавить</v-btn>
                                    </v-col>
                                </v-row>
                                <base-modal v-model="FieldsDialog">
                                    <v-card
                                        v-if="FieldsDialog"
                                    >
                                        <v-form
                                            ref="AdditionalFieldsForm"
                                            lazy-validation
                                        >
                                            <v-card-title>
                                                <span class="headline">Добавить поле</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                    <v-row>
                                                        <v-col class="px-1 py-0" cols="12">
                                                            <v-text-field
                                                                v-model="newFields.name"
                                                                class="py-1"
                                                                clearable
                                                                label="Название"
                                                            ></v-text-field>
                                                            <base-data-type-select
                                                                :for-directory="true"
                                                                :props-extra-attributes.sync="newFields.extra_attributes"
                                                                :props-type.sync="newFields.type"
                                                            />
                                                            <v-switch
                                                                v-model="newFields.required"
                                                                label="Обязательное поле?"
                                                            ></v-switch>
                                                            <v-switch
                                                                v-model="newFields.show"
                                                                label="Показывать?"
                                                            ></v-switch>
                                                            <v-switch
                                                                v-model="newFields.show_when_choosing"
                                                                label="Показывать поле при выборе?"
                                                            ></v-switch>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeFieldsDialog">Отмена
                                                </v-btn>
                                                <v-btn
                                                    :disabled="!newFields.name || !newFields.type"
                                                    color="blue darken-1"
                                                    text
                                                    @click="addFields">
                                                    Сохранить
                                                </v-btn>
                                            </v-card-actions>
                                        </v-form>
                                    </v-card>
                                </base-modal>
                            </v-container>
                        </div>
                    </v-tab-item>
                    <v-tab-item key="CustomData">
                        <div class="scrol-div ">
                            <v-simple-table
                                v-if="editedItem.additional_fields && editedItem.additional_fields.length"
                                dense
                            >
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">
                                            Название поля
                                        </th>
                                        <th class="text-left">
                                            Тип поля
                                        </th>
                                        <th :width="10" class="text-center">
                                            Действия
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                        v-for="(item, index) in editedItem.additional_fields"
                                        :key="index"
                                        class="simple-table-row"
                                    >
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.type.name }}</td>
                                        <td class="text-center">
                                            <v-icon
                                                small
                                                @click="deleteAdditionalFieldsItem(index)"
                                            >
                                                {{ $store.state.Icon.svgMdiDelete }}
                                            </v-icon>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <v-container>
                                <v-row no-gutters>
                                    <v-col class="d-flex justify-end">
                                        <v-btn color="blue" text @click="AdditionalFieldsDialog = true">Добавить</v-btn>
                                    </v-col>
                                </v-row>
                                <base-modal v-model="AdditionalFieldsDialog">
                                    <v-card
                                        v-if="AdditionalFieldsDialog"
                                    >
                                        <v-form
                                            ref="AdditionalFieldsForm"
                                            lazy-validation
                                        >
                                            <v-card-title>
                                                <span class="headline">Добавить поле</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                    <v-row>
                                                        <v-col class="px-1 py-0" cols="12">
                                                            <v-text-field
                                                                v-model="newAdditional_fields.name"
                                                                class="py-1"
                                                                clearable
                                                                label="Название"
                                                            ></v-text-field>
                                                            <base-data-type-select
                                                                :for-directory="true"
                                                                :props-extra-attributes.sync="newAdditional_fields.extra_attributes"
                                                                :props-type.sync="newAdditional_fields.type"
                                                            />
                                                            <v-switch
                                                                v-model="newAdditional_fields.required"
                                                                label="Обязательное поле?"
                                                            ></v-switch>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeAdditionalFieldsDialog">
                                                    Отмена
                                                </v-btn>
                                                <v-btn
                                                    :disabled="!newAdditional_fields.name || !newAdditional_fields.type"
                                                    color="blue darken-1"
                                                    text
                                                    @click="addAdditionalFields">
                                                    Сохранить
                                                </v-btn>
                                            </v-card-actions>
                                        </v-form>
                                    </v-card>
                                </base-modal>
                            </v-container>
                        </div>
                    </v-tab-item>

                </v-tabs-items>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="inProcess" color="blue" text @click="close">Отмена</v-btn>
                <v-btn v-if="UserCan.Directory.Edit" :disabled="!Valid || inProcess || !editedItem.fields.length"
                       color="blue" text
                       @click="save">Сохранить
                </v-btn>
            </v-card-actions>
        </v-form>
        <base-error-snackbar
            v-model="Error"
            v-bind:error-text="ErrorText"
        />
    </v-card>
</template>

<script>
import BaseErrorSnackbar from "../../BaseComponents/BaseErrorSnackbar";
import BaseModal from "../../BaseComponents/BaseModal";
import BaseDataTypeSelect from "../../BaseComponents/BaseDataTypeSelect";
import requiredRules from "../../../Rules/requiredRules";

export default {
    name: "DirectoryForm",
    components: {BaseErrorSnackbar, BaseModal, BaseDataTypeSelect},
    props: {
        propsEditedIndex: 0, //Индекс редактируемого элемента
        propsEditedItem: null
    },
    data: function () {
        return {
            tab: null,
            editedItem: Object, //Переменная с данными редактируемого элемента
            defaultItem: {
                name: null,
                description: null,
                fields: [],
                additional_fields: []
            },

            Valid: false, //Переменная для контроля валидности данных формы
            options: {},
            ErrorText: '',
            Error: false,
            loading: false,
            inProcess: false,
            newAdditional_fields: this.defaultAdditionalFields(),
            newFields: this.defaultFields(),
            // fieldTypes: ["Строка", "Текст", "Целое число", "Деньги", "Дата"],
            AdditionalFieldsDialog: false,
            FieldsDialog: false,

        }
    },
    created() {


    },
    computed: {
        formTitle() {
            return this.propsEditedIndex === -1 ? "Создать справочник" : "Изменить справочник"
        },
        requiredRules() {
            return requiredRules
        },
    },
    watch: {
        propsEditedIndex: {
            handler: function (val) {
                if (val) {
                    this.tab = 0
                    if (val === -1) {
                        this.editedItem = Object.assign({}, this.defaultItem)
                    } else {
                        if (val !== 0) {
                            this.editedItem = Object.assign({}, this.propsEditedItem)
                            this.editedItem.fields.sort((a, b) => a.following > b.following ? 1 : -1);
                        }
                    }
                }
            },
            immediate: true,
        },

    },


    methods: {
        defaultFields: function () {
            return {
                name: null,
                type: null,
                show: true,
                show_when_choosing: false,
                required: false,
                following: 0,
                old_name: null,
            }
        },

        defaultAdditionalFields: function () {
            return {
                name: null,
                type: null,
                extra_attributes: null,
                required: false
            }
        },
        getFieldTypes: function () {
            axios.put('/api/directory/new', emitedData)
                .then(() => {
                    this.close()
                })
                .catch(error => {
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = "Не удалось создать новый справочник."
                    }
                })
                .finally(() => {
                    this.wait("stop")
                });
        },


        wait: function (act) {
            if (act === "start") {
                this.inProcess = true
                this.loading = true
            }
            if (act === "stop") {
                this.inProcess = false
                this.loading = false
            }
        },

        resetValidation() {
            this.Valid = false
            this.$refs.Form.reset()
            this.$refs.Form.resetValidation()
        },

        close() {
            this.$emit('close')
            // this.Error = false
            // this.ErrorText = ''
            // this.tab = null
            // setTimeout(() => {
            //     this.editedItem = Object.assign({}, {})
            //     this.resetValidation()
            // }, 100)
        },

        save() {
            if (this.UserCan.Directory.Edit) {
                this.wait("start")
                const emitedData = Object.assign({}, this.editedItem)
                if (this.propsEditedIndex === -1) {
                    axios.put('/api/directory/new', emitedData)
                        .then(() => {
                            this.close()
                        })
                        .catch(error => {
                            if (error.response) {
                                this.Error = true
                                this.ErrorText = "Не удалось создать новый справочник."
                            }
                        })
                        .finally(() => {
                            this.wait("stop")
                        });
                } else {
                    if (this.editedItem.id) {
                        axios.put('/api/directory/' + this.editedItem.id, emitedData)
                            .then(() => {
                                this.close()
                            })
                            .catch(error => {
                                if (error.response) {
                                    this.Error = true
                                    this.ErrorText = "Не удалось изменить справочник."
                                }
                            })
                            .finally(() => {
                                this.wait("stop")
                            });
                    }
                }
            }
        },

        deleteAdditionalFieldsItem(index) {
            if (this.editedItem.additional_fields
                && this.editedItem.additional_fields.length
                && this.editedItem.additional_fields[index] !== void 0) {
                if (confirm("Вы уверены, что хотите удалить поле?")) {
                    this.editedItem.additional_fields.splice(index, 1)
                }
            }
        },

        deleteFieldsItem(index) {
            if (this.editedItem.fields
                && this.editedItem.fields.length
                && this.editedItem.fields[index] !== void 0) {
                if (confirm("Вы уверены, что хотите удалить поле?")) {

                    for (let i = (index + 1); i <= (this.editedItem.fields.length - 1); i++) {
                        this.editedItem.fields[i].following--
                    }

                    this.editedItem.fields.splice(index, 1)
                }
            }
        },

        upField(index) {
            if (this.editedItem.fields
                && this.editedItem.fields.length
                && this.editedItem.fields[index] !== void 0) {
                //Смотрим позицию сортировки переданного элемента. Если мы элемент поднимаем вверх, то новая позиция будет = позиция - 1
                let newFollowing = this.editedItem.fields[index].following - 1
                //Находим индекс элемента который уже стоит на новой позиции
                let fieldIndex = this.editedItem.fields.findIndex(field => field.following === newFollowing)
                //Передвигаем найденный выше элемент на одну позицию вниз
                this.editedItem.fields[fieldIndex].following = newFollowing + 1
                //Обрабатываемы элемент ставим на нужную позицию
                this.editedItem.fields[index].following = newFollowing
            }
            //Пересортировываем массив
            this.editedItem.fields.sort((a, b) => a.following > b.following ? 1 : -1);
        },

        downField(index) {
            if (this.editedItem.fields
                && this.editedItem.fields.length
                && this.editedItem.fields[index] !== void 0) {
                //Смотрим позицию сортировки переданного элемента. Если мы элемент поднимаем вниз, то новая позиция будет = позиция + 1
                let newFollowing = this.editedItem.fields[index].following + 1
                //Находим индекс элемента который уже стоит на новой позиции
                let fieldIndex = this.editedItem.fields.findIndex(field => field.following === newFollowing)
                //Передвигаем найденный выше элемент на одну позицию вверх
                this.editedItem.fields[fieldIndex].following = newFollowing - 1
                //Обрабатываемы элемент ставим на нужную позицию
                this.editedItem.fields[index].following = newFollowing
            }
            //Пересортировываем массив
            this.editedItem.fields.sort((a, b) => a.following > b.following ? 1 : -1);
        },

        closeAdditionalFieldsDialog() {
            this.AdditionalFieldsDialog = false
            this.newAdditional_fields = Object.assign({}, this.defaultAdditionalFields())
        },

        closeFieldsDialog() {
            this.FieldsDialog = false
            this.newFields = this.defaultFields()
        },

        addAdditionalFields() {
            if (this.newAdditional_fields.name && this.newAdditional_fields.type) {
                let field = Object.assign({}, this.newAdditional_fields)

                if (this.editedItem.additional_fields) {
                    this.editedItem.additional_fields.push(field)
                } else {
                    this.editedItem.additional_fields = [field]
                }
            }
            this.closeAdditionalFieldsDialog()
        },

        addFields() {
            if (this.newFields.name && this.newFields.type) {
                // const field = {
                //     name: this.newFields.name,
                //     type: this.newFields.type,
                //     following: this.editedItem.fields.length,
                //     show_when_choosing: this.newFields.show_when_choosing,
                //     required: this.newFields.show_when_choosing,
                //     old_name: null,
                // }

                this.newFields.following = this.editedItem.fields.length

                if (this.editedItem.fields) {
                    this.editedItem.fields.push(this.newFields)
                } else {
                    this.editedItem.fields = [this.newFields]
                }

            }
            this.closeFieldsDialog()
        }
    }
}
</script>

<style scoped>
.scrol-div {
    overflow: auto;
    overflow-x: hidden;
    min-height: 590px;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 6px; /* высота для горизонтального скролла */
    background-color: #ffffff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #3273a7;
}

/*tr.simple-table-row > td {*/
/*    font-size: 0.75rem!important;*/
/*}*/

</style>
