<template>
    <g transform="scale(.2839 .27451)">
        <path :fill="iconColor"
              d="M0 21.01V18.3c0-.91.75-1.66 1.66-1.66h4.37C6.4 15 7.05 13.47 7.94 12.1L4.87 9.03c-.64-.64-.64-1.7 0-2.34l1.92-1.92c.64-.64 1.7-.64 2.34 0l3.09 3.09c1.38-.87 2.91-1.51 4.56-1.86V1.66c0-.91.75-1.66 1.66-1.66h2.71c.91 0 1.66.75 1.66 1.66v4.37c1.64.37 3.17 1.02 4.54 1.91l3.07-3.07c.64-.64 1.7-.64 2.34 0l1.92 1.92c.64.64.64 1.7 0 2.34l-3.09 3.09c.87 1.38 1.51 2.91 1.86 4.56h4.34c.91 0 1.66.75 1.66 1.66v2.71c0 .91-.75 1.66-1.66 1.66h-4.37c-.37 1.64-1.02 3.17-1.91 4.54l3.07 3.07c.64.64.64 1.7 0 2.34l-1.92 1.92c-.64.64-1.7.64-2.34 0l-3.09-3.09c-1.38.87-2.91 1.51-4.56 1.86v4.34c0 .91-.75 1.66-1.66 1.66H18.3c-.91 0-1.66-.75-1.66-1.66v-4.37c-1.64-.37-3.17-1.02-4.54-1.91l-3.07 3.07c-.64.64-1.7.64-2.34 0l-1.92-1.92c-.64-.64-.64-1.7 0-2.34l3.09-3.09c-.87-1.38-1.51-2.91-1.86-4.56H1.66c-.91 0-1.66-.75-1.66-1.66zm19.72-8.96c4.23 0 7.67 3.43 7.67 7.67 0 4.23-3.43 7.67-7.67 7.67-4.23 0-7.67-3.43-7.67-7.67 0-4.23 3.43-7.67 7.67-7.67z"/>
        <path :fill="iconColor"
              d="M13.83 46.45v-4.11c0-1.38 1.13-2.51 2.51-2.51h6.61c.56-2.48 1.55-4.8 2.89-6.87.72.29.75.74 1.13.54l2.25 2.26c1.24 1.24 3.27 1.24 4.52 0l1.92-1.92c1.24-1.24 1.24-3.27 0-4.52l-2.23-2.23c-.32-.04-.59-.22-.95-.65 2.04-1.27 4.31-2.2 6.74-2.72v-6.57c0-1.38 1.13-2.51 2.51-2.51h4.11c1.38 0 2.51 1.13 2.51 2.51v6.61c2.48.56 4.8 1.55 6.87 2.89L59.87 22c.98-.97 2.57-.97 3.54 0l2.91 2.9c.97.97.97 2.57 0 3.55l-4.68 4.68a21.06 21.06 0 012.82 6.9h6.57c1.38 0 2.51 1.13 2.51 2.51v4.11c0 1.38-1.13 2.51-2.51 2.51h-6.61c-.56 2.48-1.55 4.8-2.89 6.87-.34.48-.28.39-.61.99l-1.82-1.55a3.277 3.277 0 00-4.62 0l-1.91 1.91a3.277 3.277 0 000 4.62l1.2 1.2c-1.74.94-3.63 1.65-5.63 2.07v6.57c0 1.38-1.13 2.51-2.51 2.51h-4.11c-1.38 0-2.51-1.13-2.51-2.51v-6.61c-2.48-.56-4.8-1.55-6.87-2.89l-4.65 4.65c-.98.97-2.57.97-3.54 0l-2.91-2.9c-.97-.97-.97-2.57 0-3.55l4.68-4.68a21.06 21.06 0 01-2.82-6.9h-6.57c-1.38 0-2.51-1.13-2.51-2.51zm29.85-13.56c6.41 0 11.61 5.2 11.61 11.61 0 6.41-5.2 11.61-11.61 11.61-6.41 0-11.61-5.2-11.61-11.61 0-6.41 5.2-11.61 11.61-11.61z"/>
        <path :fill="iconColor"
              d="M48.89 72.76v-2.69c0-.9.74-1.64 1.64-1.64h4.34c.37-1.63 1.02-3.15 1.9-4.51l-3.05-3.05c-.64-.64-.64-1.69 0-2.33l1.91-1.91c.64-.64 1.69-.64 2.33 0l3.07 3.07c1.37-.87 2.89-1.5 4.53-1.85v-4.31c0-.9.74-1.64 1.64-1.64h2.69c.9 0 1.64.74 1.64 1.64v4.34c1.63.36 3.15 1.02 4.51 1.9l3.05-3.05c.64-.64 1.69-.64 2.33 0l1.91 1.91c.64.64.64 1.69 0 2.33l-3.07 3.07c.87 1.37 1.5 2.89 1.85 4.53h4.31c.9 0 1.64.74 1.64 1.64v2.69c0 .9-.74 1.64-1.64 1.64h-4.34c-.36 1.63-1.02 3.15-1.9 4.51l3.05 3.05c.64.64.64 1.69 0 2.33l-1.91 1.91c-.64.64-1.69.64-2.33 0l-3.07-3.07c-1.37.87-2.89 1.5-4.53 1.85v4.31c0 .9-.74 1.64-1.64 1.64h-2.69c-.9 0-1.64-.74-1.64-1.64v-4.34c-1.63-.37-3.15-1.01-4.51-1.9l-3.05 3.05c-.64.64-1.69.64-2.33 0l-1.91-1.91c-.64-.64-.64-1.69 0-2.33l3.07-3.07c-.87-1.37-1.5-2.89-1.85-4.53h-4.31c-.9 0-1.64-.74-1.64-1.64zm19.58-8.89c4.21 0 7.61 3.41 7.61 7.61 0 4.21-3.41 7.61-7.61 7.61-4.21 0-7.61-3.41-7.61-7.61 0-4.21 3.41-7.61 7.61-7.61z"/>
    </g>
</template>

<script>
export default {
    name: "SystemsIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
