<template>
    <div>
        <v-col class="px-0 pb-1">
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Помогал в
                    выполнении
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2" cols="12" md="9" sm="7">
                    <task-assistants
                        v-if="!loading"
                    />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Контактное
                    лицо
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">
                    {{ this.contact_person_name }}
                    <template v-if="this.contact_person_tel">
                        <a :href="'tel:'+this.contact_person_tel" class="noDecor">{{ this.contact_person_tel }}</a>
                    </template>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Представитель
                    заказчика
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">
                    {{ this.system_observer_name }}
                    <template v-if="this.system_observer_tel">
                        <a :href="'tel:'+this.system_observer_tel" class="noDecor">{{ this.system_observer_tel }}</a>
                    </template>

                </v-col>
            </v-row>
        </v-col>
        <v-col class="px-0 pb-1">
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Дата создания
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">{{ this.created_at }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Последнее
                    изменение
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">{{ this.updated_at }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Выполнить до
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2" cols="12" md="9" sm="7">
                    <v-text-field
                        :disabled="!canChangeDeadline"
                        :readonly="!canChangeDeadline"
                        :value="deadline"
                        class="datetime pt-0 mt-0 pb-0"
                        hide-details
                        no-resize
                        type="date"
                        v-on:change="setNewDeadline($event)"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="px-0 pb-1">
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Заказчик
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">{{ this.customerName }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Объект</v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">{{ this.object.name }}
                </v-col>
            </v-row>
            <v-row v-if="this.object.address" no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Адрес объекта
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">
                    <a :href="locationLink" class="noDecor" target="_blank">{{ this.object.address.result }}</a>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Система</v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">
                    {{ this.systemType.name }} - {{ this.systemType.description }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Тип задач
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">{{ this.taskTypeName }}
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3" sm="5">Описание
                    задачи
                </v-col>
                <v-col class="taskHeaderSimpleText setMargin pl-2 pt2" cols="12" md="9" sm="7">
                    {{ this.taskDescription }}
                </v-col>
            </v-row>
        </v-col>
    </div>
</template>

<script>

import TaskAssistants from "./Module/TaskAssistants/TaskAssistants";
import {DateFormattedMomentjs, shortDateYearFirst} from "../../mixins/commonMethod";

export default {
    name: "TaskHeader",
    components: {TaskAssistants},
    computed: {
        loading: function () {
            return this.$store.getters["TaskData/getLoading"]
        },
        contact_person: function () {
            return this.$store.getters["TaskData/getTaskData"].contact_person
        },

        contact_person_name: function () {
            return this.contact_person && this.contact_person.full_name ? this.contact_person.full_name : "Не назначен"
        },

        contact_person_tel: function () {
            return this.contact_person && this.contact_person.tel ? this.contact_person.tel : null
        },

        system_observer: function () {
            return this.$store.getters["TaskData/getTaskData"].system.observer
        },

        system_observer_name: function () {
            return this.system_observer && this.system_observer.full_name ? this.system_observer.full_name : "Не назначен"
        },
        system_observer_tel: function () {
            return this.system_observer && this.system_observer.tel ? this.system_observer.tel : null
        },

        created_at: function () {
            return DateFormattedMomentjs(this.$store.getters["TaskData/getTaskData"].created_at)
        },
        updated_at: function () {
            return DateFormattedMomentjs(this.$store.getters["TaskData/getTaskData"].updated_at)
        },

        object: function () {
            return this.$store.getters["TaskData/getTaskData"].system.object
        },

        locationLink: function () {
            if (this.object.geo_lat && this.object.geo_lon) {
                return "https://maps.yandex.ru/?text=" + this.object.geo_lat + "," + this.object.geo_lon
            }
            if (this.object.address) {
                if (this.object.address?.geo_lat && this.object.address?.geo_lon) {
                    return "https://maps.yandex.ru/?text=" +
                        this.object.address.geo_lat
                        + "," +
                        this.object.address.geo_lon
                }
            }
            return ""
        },

        customerName: function () {
            return this.$store.getters["TaskData/getTaskData"].system.contract.customer.name
        },

        systemType: function () {
            return this.$store.getters["TaskData/getTaskData"].system.type
        },

        taskTypeName: function () {
            return this.$store.getters["TaskData/getTaskData"].type.name
        },

        taskDescription: function () {
            return this.$store.getters["TaskData/getTaskData"].description
        },

        canChangeDeadline: function () {
            if (this.$store.getters["TaskData/isClosed"]) {
                return false
            }
            return this.$store.getters["TaskData/canChangeDeadline"]
        },

        deadline: {
            get: function () {
                return shortDateYearFirst(this.$store.getters["TaskData/getTaskData"].deadline)
            }
        }
    },
    methods: {
        // formatToInputData(dataTime){
        //     return shortDateWithTimeAndT(dataTime)
        // },
        setNewDeadline(newValue) {
            if (this.canChangeDeadline) {
                const taskId = this.$store.getters["TaskData/getTaskId"]

                axios.put('/api/task/deadline/' + taskId, {
                    deadline: newValue
                })
                    .then(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                ;
            }
        }
    }
}
</script>

<style scoped>
.datetime {
    font-size: .85rem;
    width: 100%;
}

.datetime :deep(div.v-text-field__slot) {
    height: 25px;
}

.datetime :deep(input) {
    padding-top: 0;
    padding-bottom: 0;
    border-width: 0;
    border-style: none;
}

.datetime :deep(input[disabled="disabled"]) {
    color: rgba(0, 0, 0, 0.87);
}

.datetime :deep(.v-input__slot::before) {
    border: none;
}
</style>
