// src/plugins/vuetify.js

import Vue from 'vue'
import '@koumoul/vjsf/dist/main.css'

// import '@mdi/font/css/materialdesignicons.css'
// import Vuetify from 'vuetify/lib'
import Vuetify from 'vuetify'
import ru from 'vuetify/lib/locale/ru'

Vue.use(Vuetify)

const VuetifyOpts = {
    lang: {
        locales: {ru},
        current: 'ru',
    },
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        themes: {
            light: {
                corpColor: "#285473", // Объявляем корпоративный цвет, чтоб потом его использовать в color="corpColor"

            },
        },
    },
}

export default new Vuetify(VuetifyOpts)

