<template>
    <div
        v-if="directoryData"
        class="py-0"
    >
        <v-simple-table
            v-if="directoryData.fields"
            dense
        >
            <template v-slot:default>
                <thead>
                <tr>
                    <template v-for="item in directoryData.fields">
                        <th
                            v-if="item.show"
                            class="simple-table-row"
                        >
                            {{ item.name }}
                        </th>
                    </template>

                    <template v-for="item in directoryData.additional_fields">
                        <th
                            v-if="checkAdditionalField(item.name)"
                            class="simple-table-row"
                        >
                            {{ item.name }}
                        </th>
                    </template>

                    <th v-if="!readonly" class="text-left">
                        Действия
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(field, key) in model"
                    :key="key+'-'+randomKeyForUpdat"
                >
                    <template v-for="item in directoryData.fields">
                        <td
                            v-if="item.show"
                        >
                            {{ field[item.name] }}
                        </td>
                    </template>
                    <template v-for="item in directoryData.additional_fields">
                        <td v-if="checkAdditionalField(item.name)">
                            {{ field[item.name] }}
                        </td>
                    </template>

                    <td v-if="!readonly" class="text-center">
                        <v-icon
                            small
                            title="Изменить"
                            @click="editItem(key)"
                        >
                            {{ $store.state.Icon.svgMdiPencil }}
                        </v-icon>
                        <v-icon
                            small
                            title="Удалить"
                            @click="deleteItem(key)"
                        >
                            {{ $store.state.Icon.svgMdiDelete }}
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-container v-if="!readonly">
            <v-row no-gutters>
                <v-col class="d-flex justify-end">
                    <div v-if="canLoadedItemFromFile">
                        <input type="file" hidden ref="file" @change="onFileChoosed" accept=".xlsx">
                        <v-btn color="blue" text @click="chooseFile">Загрузить из файла</v-btn>
                    </div>
                    <v-btn color="blue" text @click="addItem">Добавить</v-btn>
                </v-col>
            </v-row>
        </v-container>

        <directory-item-select
            :directory-id="directoryId"
            :open-dialog="addItemDialog"
            :url-for-item-select="urlForItemSelect"
            :value="editedItem"
            :warehouse="warehouse"
            v-on:close="addItemDialog = false"
            v-on:return="onReturnDirectoryItem"
        />

    </div>

</template>

<script>
import MainFieldsType from "./MainFieldsType";
import DirectoryItemSelect from "../../BaseComponents/Directory/DirectoryItemSelect";

export default {
    name: "Directory",
    extends: MainFieldsType,
    components: {DirectoryItemSelect},
    props: {
        warehouse: {
            type: Boolean,
            default: false
        },
        userId: {
            default: null
        },
        systemId: {
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        canLoadedItemFromFile: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            directoryData: null,
            directoryId: null,
            addItemDialog: false,
            model: [],
            editedIndex: null,
            editedItem: null,
            randomKeyForUpdat: this.getRandomKey()
        }
    },
    created() {
        if (this.extra_attributes && this.extra_attributes.directory) {
            this.directoryId = this.extra_attributes.directory
            this.getDirectoryData(this.directoryId)
        }
    },

    computed: {
        urlForItemSelect() {
            return this.urlForItemSelectAdd ?? this.getUri()
        }
    },

    methods: {
        getUri() {
            if (this.userId) {
                return '/api/warehouse/selection/user/' + this.userId
            }
            return '/api/directoryitem/indexToChoose/' + this.directoryId
        },


        checkAdditionalField(name) {
            return !(this.warehouse && (name !== 'Количество' && name !== 'Ед.Измерения'));
        },

        chooseFile() {
            let fileInputElement = this.$refs.file;
            fileInputElement.click();
        },

        onFileChoosed() {
            if (this.$refs.file.files[0]) {
                const formData = new FormData();
                formData.append('file', this.$refs.file.files[0]);
                formData.append('directoryId', this.directoryId)

                axios.post('/api/directoryitem/loadItemsFromFile/' + this.directoryId, formData)
                    .then(response => {
                        this.model = this.model.concat(response.data)
                        this.randomKeyForUpdat = this.getRandomKey()
                    });
            }
        },

        getRandomKey() {
            const max = 10
            return Math.floor(Math.random() * max);
        },

        getDirectoryData(directoryId) {
            axios.get('/api/directory/settings/' + directoryId)
                .then(response => {
                    this.directoryData = response.data
                    //Сортируем поля справочника
                    if (this.directoryData && this.directoryData.fields) {
                        this.directoryData.fields.sort((firstItem, secondItem) => firstItem.following - secondItem.following);
                    }
                });
        },
        addItem() {
            this.editedIndex = null
            this.editedItem = null
            this.addItemDialog = true
        },

        editItem(key) {
            this.editedIndex = key
            this.editedItem = this.model[this.editedIndex]
            this.addItemDialog = true
        },

        onReturnDirectoryItem(item) {
            if (this.editedIndex !== null) {
                this.model[this.editedIndex] = item
            } else {
                if (Array.isArray(this.model)) {
                    this.model.push(item)
                } else {
                    this.model = [item]
                }
            }

            //Так как Vue не распознает изменения при обновлении объекта, то и не перерисовывает табличку.
            //Мы генерируем новый ключ и тогда все обновляется
            this.randomKeyForUpdat = this.getRandomKey()
        },

        deleteItem(key) {
            if (confirm('Удалить запись?')) {
                this.model.splice(key, 1)
            }
        },
    }
}
</script>

<style scoped>

</style>
