<template>
    <v-textarea
        v-model="model"
        :disabled="componentDisabled"
        auto-grow
        class="task-textarea pt-0 mt-0 align-items-stretch"
        clearable
        hide-details
        rows="1"
        v-on:blur="$emit('blur')"
    ></v-textarea>
</template>

<script>
export default {
    name: "TaskCustomDataTextarea",
    props: {
        value: null,
        componentDisabled: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            model: null
        }
    },
    created() {
        this.model = this.value
    },
    watch: {
        value: function (val) {
            this.model = val
        },
        model: function (val) {
            this.$emit('input', val)
        }
    }
}
</script>

<style scoped>
div.task-textarea {
    font-size: .85rem;
}

div.task-textarea :deep(input[type="text"]) {
    padding: 3px 0 3px;
}

div.task-textarea :deep(textarea[disabled="disabled"]) {
    color: rgba(0, 0, 0, 0.87);
}

div.task-textarea :deep(textarea) {
    line-height: 1.25rem;

}
</style>
