<template>
    <v-col ref="observedElement" class="px-0 pb-1">
        <div class="comments comments-header">
            Комментарии к задаче
        </div>
        <div v-if="show" class="comments comments-body">
            <v-timeline
                align-top
                class="pt-5"
                dense
            >
                <template v-if="receivedComments.length">
                    <v-timeline-item
                        v-for="(item, key) in receivedComments"
                        :key="key"
                        class="mb-1 p-0 pr-1"
                        color="#cccccc"
                        small
                    >
                        <v-row justify="space-between">
                            <v-col class="pb-0 pt-0 pl-0" cols="12"><b>{{ item.created_by_fio }}</b> <span
                                class="pl-5 font-italic" style="color: #b3b3b3">{{ dateFormat(item.created_at) }}</span>
                            </v-col>
                            <v-col class="pt-0 pb-2 pl-0" cols="12">
                                {{ item.comment }}
                            </v-col>

                        </v-row>
                    </v-timeline-item>
                </template>


                <v-timeline-item
                    class="mb-1 p-0 pr-6"
                    color="#cccccc"
                    hide-dot
                    small
                >
                    <v-row justify="space-between">
                        <v-col class="pb-0 pt-0 pb-2 pl-0" cols="12">
                            <v-text-field
                                v-model="newComment"
                                :loading="loading"
                                autocomplete="off"
                                clearable
                                hide-details
                                label="Оставьте свой комментарий"
                                @keydown.enter="sendComment"
                            >
                                <template v-slot:append>
                                    <v-btn
                                        class="mx-0"
                                        depressed
                                        icon
                                        @click="sendComment"
                                    >
                                        <v-icon>{{ $store.state.Icon.svgMdiSend }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-timeline-item>
            </v-timeline>
        </div>

    </v-col>
</template>

<script>
import {DateFormatteWithTime} from "../../../../mixins/commonMethod.js"
import shownWhenIntersectionComponent from "../../../../mixins/shownWhenIntersectionComponent";

export default {
    name: "TaskComments",
    mixins: [shownWhenIntersectionComponent],
    data: function () {
        return {
            newComment: '',
            loading: false,
        }
    },
    computed: {

        taskId() {
            return this.$store.getters["TaskData/getTaskId"]
        },
        receivedComments() {
            return this.$store.getters["TaskData/getTaskData"].comment
        }
    },

    methods: {
        dateFormat(date) {
            return DateFormatteWithTime(date)
        },
        sendComment() {
            if (this.newComment) {
                this.loading = true
                axios.post('/api/task/newcomment/' + this.taskId, {comment: this.newComment})
                    .then(response => {
                        this.newComment = ""
                    })
                    .then(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                    .finally(() => {
                        this.loading = false
                    })
                ;
            }
        }
    }
}
</script>

<style scoped>
.comments {
    display: grid;
    border: 1px solid #285473;
}

.comments-header {
    height: 33px;
    font-size: 1rem;
    font-weight: bold;
    font-style: italic;
    background-color: #cccccc;
    text-align: left;
    align-content: space-evenly;
    border-bottom-width: 0;
    padding-left: 8px;
}

.comments-body {

}
</style>
