import { render, staticRenderFns } from "./ObjectCardSystemDescription.vue?vue&type=template&id=08073cb9&scoped=true"
import script from "./ObjectCardSystemDescription.vue?vue&type=script&lang=js"
export * from "./ObjectCardSystemDescription.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08073cb9",
  null
  
)

export default component.exports