<template>
    <base-modal
        v-model="openDialog"
        max-width="1000"
    >
        <v-card>
            <v-container>
                <v-card-title>
                    <span class="headline">{{ title }}</span>
                </v-card-title>
                <v-card-text>
                    <div class="scrol-div">
                        <v-row no-gutters>
                            <v-col class="pt-0">
                                <base-combobox-with-request
                                    v-model="editedItem.user"
                                    :multiple="editedIndex === -1"
                                    :return-object="true"
                                    :rules="requiredRules"
                                    hide-details
                                    item-text="short_name"
                                    item-value="id"
                                    label="Сотрудник"
                                    title="Сотрудник"
                                    url="user/simpleList"
                                    validate-on-blur
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0">
                                <v-text-field
                                    v-model="datePicker"
                                    :rules="requiredDateRules"
                                    label="Дата"
                                    type="date"
                                    validate-on-blur
                                />
                            </v-col>
                            <v-col class="py-0">
                                <v-text-field
                                    v-model="startPicker"
                                    :rules="requiredTimeRules"
                                    hide-details
                                    label="Начало"
                                    type="time"
                                    validate-on-blur
                                />
                            </v-col>
                            <v-col class="py-0">
                                <v-text-field
                                    v-model="endPicker"
                                    :min="startPicker"
                                    :rules="requiredTimeRules"
                                    hide-details
                                    label="Конец"
                                    type="time"
                                    validate-on-blur
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="!editedItem.task">
                            <v-col class="pb-0">
                                <v-text-field
                                    v-model="editedItem.object"
                                    hide-details
                                    label="Объект"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="!editedItem.task">
                            <v-col class="pb-0">
                                <v-textarea
                                    v-model="editedItem.works"
                                    auto-grow
                                    hide-details
                                    label="Выполняемые работы"
                                    rows="1"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-if="!editedItem.task">
                            <v-col class="pb-0">
                                <v-textarea
                                    v-model="editedItem.description"
                                    auto-grow
                                    label="Описание"
                                    rows="1"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-if="editedItem.task">
                            <v-col class="pb-0 text-left">
                                <h5>
                                    <router-link :to="{ name: 'task', params: { taskid: editedItem.task_id }}"
                                                 target="_blank">Это
                                        событие создано автоматически из задачи №{{ editedItem.task_id }}
                                    </router-link>
                                </h5>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="editedIndex !== -1" color="blue" text v-on:click="deleteItem()">Удалить</v-btn>
                    <v-btn :disabled="saveDisabled" color="blue" text v-on:click="save()">Сохранить</v-btn>
                    <v-btn color="blue" text v-on:click="close()">Закрыть</v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </base-modal>
</template>

<script>
import BaseModal from "../BaseComponents/BaseModal";
import BaseComboboxWithRequest from "../BaseComponents/BaseComboboxWithRequest";
// import moment from "moment";
import {DateFormattedMomentjs, shortDateYearFirstNow} from "../../mixins/commonMethod.js";
import requiredRules from "../../Rules/requiredRules";
import requiredDateRules from "../../Rules/requiredDateRules";
import requiredTimeRules from "../../Rules/requiredTimeRules";

export default {
    name: "EventEditForm",
    components: {
        BaseModal,
        BaseComboboxWithRequest,
    },
    props: {
        value: false,
        item: null,
        editedIndex: null
    },
    data() {
        return {
            openDialog: false,
            defaultItem: {
                arrival_time: null,
                departure_time: null,
                description: null,
                object: null,
                task: null,
                task_id: null,
                type: null,
                user_id: null,
                works: null,
                user: null
            },
            editedItem: null,
            startPicker: null,
            endPicker: null,
        };
    },
    computed: {
        title: function () {
            return this.editedIndex !== -1 ? "Изменить событие" : "Создать событие"
        },
        datePicker: {
            get: function () {

                return this.editedItem.arrival_time ? this.editedItem.arrival_time.substring(0, 10) : shortDateYearFirstNow()
            },
            set: function (newValue) {
                this.editedItem.arrival_time = this.editedItem.arrival_time ? newValue + this.editedItem.arrival_time.substring(10) : newValue
                this.editedItem.departure_time = this.editedItem.departure_time ? newValue + this.editedItem.departure_time.substring(10) : newValue
            }
        },
        saveDisabled: function () {
            let result = !!this.editedItem
                && !!this.editedItem.user
                && !!this.editedItem.arrival_time
                && !!this.editedItem.departure_time
                && this.editedItem.arrival_time.length === 19
                && this.editedItem.departure_time.length === 19
            return !result
        },
        requiredRules() {
            return requiredRules
        },
        requiredDateRules() {
            return requiredDateRules
        },
        requiredTimeRules() {
            return requiredTimeRules
        }
    },
    watch: {
        value: {
            handler: function (val) {
                this.openDialog = !!val;
            },
            immediate: true
        },
        item: {
            handler: function (val) {
                if (val) {
                    this.editedItem = Object.assign({}, val)
                    this.startPicker = this.editedItem.arrival_time ? this.editedItem.arrival_time.substring(11, 16) : null
                    this.endPicker = this.editedItem.departure_time ? this.editedItem.departure_time.substring(11, 16) : null
                    return this.editedItem
                }
                return this.editedItem = Object.assign({}, this.defaultItem)
            },
            immediate: true,
            deep: true
        },
        startPicker: function (val) {
            if (val && val.length === 5) {
                this.editedItem.arrival_time = this.datePicker.substring(0) + " " + val + ":00"
            }
        },
        endPicker: function (val) {
            if (val && val.length === 5) {
                this.editedItem.departure_time = this.datePicker.substring(0) + " " + val + ":00"
            }
        },
    },
    methods: {
        newItem() {
            let payload = {
                usersId: this.editedItem.user ? this.editedItem.user.map((item) => (item.id)) : null,
                arrival_time: this.editedItem.arrival_time,
                departure_time: this.editedItem.departure_time,
                object: this.editedItem.object,
                works: this.editedItem.works,
                description: this.editedItem.description,
                type: "user"
            }
            if (payload.arrival_time && payload.departure_time && payload.usersId) {
                axios.put('/api/calendar/newintervals', payload)
                    // .then(response => (
                    //     // console.log(response.data)
                    // ))
                    .then(() => (this.close()))
                    .catch(function (error) {
                        console.log(error)
                    });
            }
        },
        editUserItem() {
            let payload = {
                usersId: this.editedItem.user ? this.editedItem.user.id : null,
                arrival_time: this.editedItem.arrival_time,
                departure_time: this.editedItem.departure_time,
                object: this.editedItem.object,
                works: this.editedItem.works,
                description: this.editedItem.description,
                type: "user"
            }
            let id = this.editedItem.id
            if (payload.arrival_time && payload.departure_time && payload.usersId) {
                axios.post('/api/calendar/userintervals/' + id, payload)
                    // .then(response => (
                    //     console.log(response.data)
                    // ))
                    .then(() => (this.close()))
                    .catch(function (error) {
                        console.log(error)
                    });
            }
        },
        editTaskItem() {
            let payload = {
                usersId: this.editedItem.user ? this.editedItem.user.id : null,
                arrival_time: this.editedItem.arrival_time,
                departure_time: this.editedItem.departure_time,
            }
            let id = this.editedItem.id
            if (payload.arrival_time && payload.departure_time && payload.usersId) {
                axios.post('/api/calendar/taskintervals/' + id, payload)
                    // .then(response => (
                    //     console.log(response.data)
                    // ))
                    .then(() => (this.close()))
                    .catch(function (error) {
                        console.log(error)
                    });
            }
        },
        editItem() {
            if (this.editedItem.id) {
                if (this.editedItem.task) {
                    this.editTaskItem()
                } else {
                    this.editUserItem()
                }
            }
        },
        save() {
            if (this.editedIndex === -1) {
                this.newItem()
            } else {
                this.editItem()
            }
        },
        close() {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.startPicker = null
            this.endPicker = null
            this.openDialog = false
            this.$emit('input', false)
        },
        deleteItem() {
            if (this.editedItem.id) {
                if (confirm("Удалить событие?")) {
                    axios.delete('/api/calendar/intervals/' + this.editedItem.id)
                        // .then(response => (
                        //     console.log(response.data)
                        // ))
                        .then(() => (this.close()))
                        .catch(function (error) {
                            console.log(error)
                        });
                }
            }
        },

        datePickerTitle(date) {
            return DateFormattedMomentjs(date)
            // moment.locale("ru");
            // return moment(date, "YYYY-MM-DD").format('LL')
        },


    },
}
</script>

<style scoped>
.scrol-div {
    overflow: auto;
    overflow-x: hidden;
    min-height: 200px;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 6px; /* высота для горизонтального скролла */
    background-color: #ffffff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #3273a7;
}
</style>
