<template>
    <g transform="matrix(8.77963 0 0 8.8968 0 -.178)">
        <path class="fil0"
              d="M0 5.63v.47l1.53.51c.06.25.14.48.24.71L.27 8.77l.16.26c.43.71 1 1.26 1.75 1.62l.25.12.19-.2L3.74 9.4c.29.14.59.26.91.33l.51 1.53h.94l.51-1.53c.44-.1.86-.28 1.23-.51l1.44.72.33-.33.33-.33-.72-1.44c.23-.38.41-.79.51-1.23l1.53-.51v-.94l-.28-.09.41-.4-1.55-1.58.25-.28-.38-.38.23-.45-.33-.33-.33-.33-.45.23-.44-.44-.29.18-.8-.77-.98.17L6.1.02h-.94l-.3.91-1 .17.52.52c-.34.11-.66.25-.95.43l-1.44-.72-.33.33-.33.33.72 1.44c-.23.38-.41.79-.51 1.23l-1.53.51v.47zm.75 3.2l5.51-5.32 1.32 1.33C5.84 6.66 4.09 8.48 2.34 10.3c-.68-.33-1.2-.83-1.59-1.47zM7.16.95l.88.85.28-.18 1.19 1.2-.25.27 1.53 1.56-1.44 1.4-3.6-3.64-.18-.18-.87-.87L7.15.95z"/>
        <circle id="circle8" class="fil1" cx="5.69" cy="5.63" r="3.18"/>
        <path class="fil0" d="M8.1 1.3l.28-.18 1.69 1.69-.26.28 1.55 1.57-2.01 1.95L3.89 1.1l3.4-.58z"/>
        <path class="fil1" d="M7.16.94l.88.86.28-.18 1.2 1.2-.25.27 1.53 1.57-1.44 1.4-3.61-3.64-.18-.19-.86-.87z"/>
        <path class="fil0"
              d="M2.19 10.62A4.1 4.1 0 01.45 9.01L.3 8.77l5.96-5.76 1.8 1.83-.24.25c-1.75 1.82-3.49 3.64-5.24 5.46l-.18.18-.23-.11z"/>
        <path class="fil1" d="M.75 8.83l5.51-5.32 1.32 1.33C5.84 6.66 4.09 8.48 2.34 10.3c-.68-.33-1.2-.83-1.59-1.47z"/>
    </g>
</template>

<script>
export default {
    name: "InWorkStatus"
}
</script>

<style scoped>
.fil0 {
    fill: #285473
}

.fil1 {
    fill: #fefefe
}
</style>
