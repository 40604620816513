<template>
    <v-snackbar
        v-model="errorSnackbar"
        color="error"
        multi-line
        top
    >
        {{ errorText }}
        <template v-slot:action="{ attrs }">
            <v-btn
                text
                @click="errorSnackbar = false"
            >
                <v-icon>
                    {{ $store.state.Icon.svgMdiClose }}
                </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "BaseErrorSnackbar",
    props: {
        value: false,
        errorText: String,
    },
    data: function () {
        return {
            errorSnackbar: false,
        }
    },
    watch: {
        value: function () {
            this.errorSnackbar = this.value
        },
        errorSnackbar: function () {
            this.$emit('input', this.errorSnackbar)
        }
    }
}
</script>

<style scoped>

</style>
