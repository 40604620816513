<template>
    <v-navigation-drawer
        color="transparent"
        fixed
        overlay-color="secondary"
        overlay-opacity=".8"
        style="z-index: 10000"
        temporary
        v-bind="$attrs"
        v-on="$listeners"
    >
        <v-list
            class="overflow-y-auto"
            color="white"
            height="100%"

            nav
        >
            <template v-for="(point, index) in navbar">
                <v-list-item
                    v-if="!point.children && point.show"
                    :key="index"
                    :to="point.path"
                    color="primary"
                >
                    <v-list-item-avatar
                        height="30"
                        tile
                        width="25"
                    >
                        <BaseNavbarIcon
                            :icon="point.img"
                            :icon-name="point.name"
                            icon-color="#285473"
                        />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-text="point.name"/>
                    </v-list-item-content>
                </v-list-item>
                <v-list-group
                    v-if="point.children && point.show"
                >
                    <template v-slot:activator>
                        <v-list-item-avatar
                            height="30"
                            tile
                            width="25"
                        >
                            <BaseNavbarIcon
                                :icon="point.img"
                                :icon-name="point.name"
                                icon-color="#285473"
                            />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ point.name }}</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item
                        v-for="subpoint in point.children"
                        :key="subpoint.key"
                        :to="subpoint.path"
                        dense
                    >
                        <v-list-item-avatar
                            height="25"
                            tile
                            width="25"
                        >
                            <BaseNavbarIcon
                                :icon="subpoint.img"
                                :icon-name="subpoint.name"
                                icon-color="#285473"
                            />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-text="subpoint.name"/>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>

import BaseNavbarIcon from "./BaseNavbarIcon";

export default {
    name: 'HomeDrawer',
    components: {
        BaseNavbarIcon,
    },
    props: {
        navbar: {
            required: true,
            default: {}
        }
    },

}
</script>
