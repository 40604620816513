<template>
    <v-row no-gutters>
        <v-col class="object-card-table-title" cols="12" lg="2" md="3" sm="5">{{ title }}</v-col>
        <v-col class="object-card-table-row" cols="12" lg="10" md="9" sm="7">
            {{ content }}
            <a v-if="tel" :href="'tel:'+this.tel" class="noDecor">{{ this.tel }}</a>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ObjectCardRow",
    props: {
        title: {
            required: true
        },
        content: {
            required: true
        },
        tel: {
            required: false,
            default: null
        }

    }
}
</script>

<style scoped>
/*.object-card-table-title {*/
/*    background-color: #EBECEC;*/
/*    padding: 5px 10px;*/
/*    border-left: #E1E1E1 1px solid;*/
/*    border-top: #E1E1E1 1px solid;*/
/*    border-right: #E1E1E1 1px solid;*/
/*}*/
/*.object-card-table-row {*/
/*    padding: 5px 10px;*/
/*    font-size: 13px;*/
/*    border-left: #E1E1E1 1px solid;*/
/*    border-top:  #E1E1E1 1px solid;*/
/*    border-right: #E1E1E1 1px solid;*/
/*}*/
</style>