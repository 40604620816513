// initial state
const state = {
    navbar: []
}

// getters
const getters = {
    getNavbar: (state) => state.navbar,
}

// actions
const actions = {
    getData() {
        this.commit('Navbar/setNavbar', null)
        axios.get('/api/navbar')
            .then(response => {
                this.commit('Navbar/setNavbar', response.data)
            })
    }
}

// mutations
const mutations = {
    setNavbar(state, rights) {
        state.navbar = rights
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
