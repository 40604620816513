<template>
    <v-col class="py-0" cols="12">
        <v-row v-if="UserCan.UsersCalendar.View ||
                     UserCan.DepCalendar.View ||
                     UserCan.AllCalendar.View">
            <base-error-snackbar
                v-model="Error"
                v-bind:error-text="ErrorText"
            />

            <EventEditForm
                v-model="editEvent"
                :editedIndex="editedItem ? editedItem.id : -1"
                :item="editedItem"
            />
            <base-resize-panel :mobile="mobile">
                <template v-slot:left>
                    <base-users-checkbox-list
                        v-model="selectedUser"
                        :height="height"
                    />
                </template>
                <template v-slot:right>
                    <v-card
                        :height="height"
                        :loading="loading"
                        class="mx-auto overflow-y-auto"
                    >
                        <v-card-title>
                            <v-toolbar
                                flat
                            >
                                <v-btn
                                    class="ma-2"
                                    icon
                                    @click="$refs.calendar.prev()"
                                >
                                    <v-icon>{{ $store.state.Icon.svgMdiChevronLeft }}</v-icon>
                                </v-btn>
                                <v-toolbar-title v-if="$refs.calendar">
                                    {{ $refs.calendar.title }}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="ma-2"
                                    color="grey darken-2"
                                    outlined
                                    @click="editEvent=true"
                                >
                                    Добавить событие
                                </v-btn>
                                <v-btn
                                    class="ma-2"
                                    color="grey darken-2"
                                    outlined
                                    @click="setToday"
                                >
                                    Сегодня
                                </v-btn>
                                <v-select
                                    v-model="type"
                                    :items="types"
                                    class="ma-2"
                                    dense
                                    hide-details
                                    label="Тип календаря"
                                    outlined
                                ></v-select>

                                <v-btn
                                    class="ma-2"
                                    icon
                                    @click="$refs.calendar.next()"
                                >
                                    <v-icon>{{ $store.state.Icon.svgMdiChevronRight }}</v-icon>
                                </v-btn>
                            </v-toolbar>
                        </v-card-title>
                        <v-card-text class="p-0" style="height: 85%">
                            <v-calendar
                                ref="calendar"
                                v-model="focus"
                                :categories="categories"
                                :event-category="eventCategory"
                                :event-color="getEventColor"
                                :event-overlap-threshold="30"
                                :events="worksIntervalList"
                                :short-months="false"

                                :short-weekdays="false"
                                :type="type"
                                :weekdays="weekday"
                                category-hide-dynamic
                                event-end="departure_time"

                                event-name="type"
                                event-overlap-mode="column"
                                event-start="arrival_time"
                                first-interval="6"
                                interval-count="15"
                                @click:event="showEditEventForm"

                            >
                                <template v-slot:category="{ category }">
                                    <div class="text-center">{{ categoriesName(category) }}</div>
                                </template>

                                <template v-slot:event="{ event, eventParsed }">
                                    <div class="text-center font-weight-bold px-1">
                                        {{ eventParsed.start.time }} - {{ eventParsed.end.time }}
                                    </div>
                                    <template v-if="event.task">
                                        <div class="px-1">
                                            Выполнение работ по задачи №{{ event.task.id }}<br>
                                            {{ event.task.type.name }}<br>
                                            {{ event.task.system.object.name }}<br>
                                            {{ event.task.system.type.name }}<br>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="px-1">
                                            {{ event.object }}<br>
                                            {{ event.works }}<br>
                                            {{ event.description }}<br>
                                        </div>
                                    </template>

                                </template>

                            </v-calendar>
                        </v-card-text>
                    </v-card>
                </template>
            </base-resize-panel>

        </v-row>
    </v-col>
</template>

<script>
import BaseErrorSnackbar from "../BaseComponents/BaseErrorSnackbar";
import EventEditForm from "./EventEditForm";
import BaseUsersCheckboxList from "../BaseComponents/BaseUsersCheckboxList";
import {shortDateWithTime} from "../../mixins/commonMethod";
import BaseResizePanel from "../BaseComponents/BaseResizePanel";

export default {
    name: "Calendar",
    components: {
        BaseErrorSnackbar,
        EventEditForm,
        BaseUsersCheckboxList,
        BaseResizePanel
    },
    data() {
        return {
            // userList: [], //Список пользователей
            selectedUser: [], //Выбранные пользователи
            worksIntervalList: [], //Список интервалов

            loading: false, //Признак того, что идет загрузка списка событий

            ErrorText: '',
            Error: false,

            type: 'month',
            types: [
                {text: 'Месяц', value: 'month'},
                {text: 'Неделя', value: 'week'},
                {text: 'День', value: 'category'},
            ],
            weekday: [1, 2, 3, 4, 5, 6, 0],
            value: '',
            events: [],
            colors: ["#7690C9", "#769897", "#D6E9D8", "#EC9C9C", "#B7CBD8", "#FCD5A6", "#470000", "pink", "purple",
                "deep-purple", "indigo", "blue", "cyan", "teal", "green", "lime", "yellow", "amber",
                "orange", "brown", "blue-grey", "grey"
            ],
            focus: '',


            editEvent: false, //признак открытия окна редактирования
            editedItem: null, //Редактируемый объект. Если null, то создаем новый

            userList: null
        };
    },
    created() {
        this.getUsersList()
    },

    computed: {
        categories: function () {
            return this.selectedUser.map(function (user) {
                return user.toString()
            })
        },

        height: function () {
            if (this.$vuetify.breakpoint.width < 992) {
                return "500px"
            } else {
                return this.$vuetify.breakpoint.height - 95
            }
        },
        mobile: function () {
            return this.height === "500px"
        },
    },


    watch: {
        selectedUser: {
            handler: function (val) {
                if (val.length) {
                    this.getWorksIntervals()
                } else {
                    this.worksIntervalList = []
                }
            },
            immediate: true
        },
        focus: {
            handler: function (val) {
                this.getWorksIntervals()
            },
        },
        editEvent: { //Проверяем, если окно редактирования события закртыо, о очищаем редактироуемый объект
            handler: function (val) {
                if (!val) {
                    this.editedItem = null
                    this.getWorksIntervals()
                }
            },
        }
    },

    methods: {
        intervalFormat(interval) {
            shortDateWithTime(interval)
        },

        /**
         * Метод получает свписок всех событий за текущий месяц
         */
        getWorksIntervals() {
            if (this.selectedUser.length) {
                this.loading = true
                let data = {
                    focus: this.focus,
                    selectedUser: this.selectedUser
                }
                return axios.post('/api/calendar/intervals', data)
                    .then(response => {
                        // this.allSystems = objToArray(response.data)
                        this.worksIntervalList = response.data
                        this.loading = false
                    })
                    .catch(() => {
                        //console.log.bind(console)
                        this.loading = false
                    });
            }
        },

        /**
         * Метод перебирает массив всех пользователей и по ИД возвращает имя для заголовка категории календаря
         * @param id
         * @returns {string|null}
         */
        categoriesName(id) {
            if (this.userList) {
                let user = this.userList.find(item => item.id.toString() === id.toString())
                return user.short_name
            }
            return null
        },

        /**
         * Метод получает объект пользователя и возвращает его ИД в виде строки, для выборки из списка событий
         * @param e
         * @returns {string}
         */
        eventCategory(e) {
            //console.log(e)
            return e.user_id.toString()
        },

        /**
         * Устанавливает фокус на сегодняшней дате
         */
        setToday() {
            this.focus = ''
        },

        // getEvents ({ start, end }) {
        //     const events = []
        //
        //     const min = new Date(`${start.date}T00:00:00`)
        //     const max = new Date(`${end.date}T23:59:59`)
        //     const days = (max.getTime() - min.getTime()) / 86400000
        //     const eventCount = this.rnd(days, days + 20)
        //
        //     for (let i = 0; i < eventCount; i++) {
        //         const allDay = this.rnd(0, 3) === 0
        //         const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        //         const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        //         const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        //         const second = new Date(first.getTime() + secondTimestamp)
        //
        //         events.push({
        //             name: this.names[this.rnd(0, this.names.length - 1)],
        //             start: first,
        //             end: second,
        //             color: this.colors[this.rnd(0, this.colors.length - 1)],
        //             timed: !allDay,
        //         })
        //     }
        //
        //     this.events = events
        // },

        getEventColor(event) {
            let index = this.selectedUser.findIndex(item => item === event.user_id)
            return this.colors[index]
        },

        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },

        showEditEventForm({event}) {
            this.editedItem = event
            this.editEvent = true
        },
        getUsersList() {
            return axios.get('/api/user/simpleList')
                .then(response => {
                    // this.allSystems = objToArray(response.data)
                    this.userList = response.data
                })
                .catch(console.log.bind(console));
        },
    },
}
</script>

<style scoped>

</style>
