<template>
    <div class="object-card-system-defect-wrapper cursor-pointer"
         v-on:click="$emit('info', value)"
    >
        <div class="object-card-system-defect-icon">
            <system-defect-status-icon
                :value="value"
                :task-id="taskId"
            />
        </div>
        <div class="object-card-system-defect-attach">
            <v-icon v-if="value.media_count">{{ $store.state.Icon.svgMdiPaperclip }}</v-icon>
        </div>
        <div class="object-card-system-defect-number">
            {{ value.number }}
        </div>
        <div class="object-card-system-defect-text">
            {{ result }}
        </div>
    </div>
</template>


<script>
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";
import SystemDefectStatusIcon from "./SystemDefectStatusIcon.vue";
import {shortDateYearFirst} from "../../mixins/commonMethod";

export default {
    name: "SystemDefectItemInObjectCard",
    components: {SystemDefectStatusIcon},
    props: {
        value: {
            default: SystemDefectDefaultItem(),
        },
        taskId: {
            type: Number,
            required: true
        },
    },
    computed: {
        result() {
            let result = [this.value.description]

            if (this.value.necessary_work) {
                result.push(this.value.necessary_work)
            }

            if (this.value.fix_to) {
                result.push('Устранить до: ' + shortDateYearFirst(this.value.fix_to))
            }

            return result.join('\n');
        }
    }
}
</script>


<style scoped>
.object-card-system-defect-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

}

.object-card-system-defect-icon,
.object-card-system-defect-attach,
.object-card-system-defect-number {
    width: 24px;
}
</style>
