<template>
    <v-data-table
        v-if="UserCan.Directory.Read"
        :footer-props="{
                showCurrentPage: true,
                itemsPerPageOptions: [25,50,100,-1],
                showFirstLastPage: true,
                firstIcon: $store.state.Icon.svgMdiArrowCollapseLeft,
                lastIcon: $store.state.Icon.svgMdiArrowCollapseRight
            }"
        :headers="headers"
        :items="dataList"
        :items-per-page="25"
        :loading="dataTableLoading"
        :search="search"
        dense
        loading-text="Загрузка данных... Пожалуйста подождите"
        v-on:click:row="openDirectory"
    >
        <template v-slot:top>
            <v-toolbar color="white" flat>
                <v-toolbar-title>Справочники</v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>
                <v-text-field
                    v-model="search"
                    dense
                    hide-details
                    outlined
                    placeholder="Найти"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <base-modal
                    v-model="editDialog"
                    max-width="800">
                    <directory-form
                        v-if="editDialog"
                        :props-edited-index="editedIndex"
                        :props-edited-item="editedItem"
                        v-on:close="onCloseEditForm"
                    ></directory-form>
                </base-modal>
                <base-error-snackbar
                    v-model="Error"
                    v-bind:error-text="ErrorText"
                />
                <base-info-snackbar
                    v-model="info"
                    v-bind:text="infoText"
                />
                <v-btn
                    :disabled="!UserCan.Directory.Edit"
                    class="mb-2"
                    color="normal"
                    raised
                    small
                    @click="newItem()"
                >
                    Создать
                </v-btn>

            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                :title='UserCan.Directory.Edit ? "Редактировать справочник" : "Просмотреть справочник"'
                class="mr-2"
                small
                v-on:click.stop="editItem(item)"
            >
                <template v-if="UserCan.Directory.Edit">
                    {{ $store.state.Icon.svgMdiPencil }}
                </template>
                <template v-else>
                    {{ $store.state.Icon.svgMdiEye }}
                </template>
            </v-icon>
            <v-icon v-if="UserCan.Directory.Edit"
                    class="mr-2"
                    small
                    title='Удалить справочник'
                    v-on:click.stop="deleteItem(item)"
            >
                {{ $store.state.Icon.svgMdiDelete }}
            </v-icon>
            <v-icon v-if="UserCan.DirectoryItem.Read"
                    class="mr-2"
                    small
                    title='Скачать справочник'
                    v-on:click.stop="downloadItem(item)"
            >
                {{ $store.state.Icon.svgMdiDownload }}
            </v-icon>
            <v-icon v-if="UserCan.DirectoryItem.Edit"
                    class="mr-2"
                    small
                    title='Загрузить справочник'
                    v-on:click.stop="onUploadButtonClick(item)"
            >
                {{ $store.state.Icon.svgMdiUpload }}
            </v-icon>
            <input
                :ref='"uploader_"+item.id'
                accept=".xlsx"
                class="d-none"
                type="file"
                @change="uploadItem(item.id, $event)"
                v-on:click.stop
            >
        </template>
    </v-data-table>
</template>

<script>
import BaseModal from "../../BaseComponents/BaseModal";
import BaseErrorSnackbar from "../../BaseComponents/BaseErrorSnackbar";
import DirectoryForm from "./DirectoryForm";
import BaseInfoSnackbar from "../../BaseComponents/BaseInfoSnackbar";

export default {
    name: "Directory",
    components: {BaseModal, BaseErrorSnackbar, DirectoryForm, BaseInfoSnackbar},
    data: () => ({
        search: '',
        dataList: [],
        dataTableLoading: true,
        editDialog: false,
        headers: [
            {text: 'ИД', value: 'id', align: 'start', width: '10%', cellClass: 'directory'},
            {text: 'Наименование', value: 'name', cellClass: 'directory'},
            {text: 'Описание', value: 'description', cellClass: 'directory'},
            {text: 'Дейстивя', value: 'actions', sortable: false, width: '10%'},
        ],
        Error: false,
        ErrorText: null,
        info: false,
        infoText: null,
        editedIndex: -1,
        editedItem: null
    }),
    created() {
        this.getData()
    },
    methods: {

        onCloseEditForm: function () {
            this.editDialog = false
            this.editedIndex = 0
            this.getData()
        },

        getData() {
            axios.get('/api/directory/index')
                .then(response => {
                    this.dataList = response.data
                })
                .catch(error => {
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = "Не удалось загрузить список справочников."
                    }
                })
                .finally(() => (this.dataTableLoading = false))
            ;
        },

        openDirectory(item) {
            if (item && item.id) {
                this.$router.push({name: 'directory', params: {dirid: item.id}})
            }
        },

        newItem() {
            this.editedIndex = -1
            this.editedItem = {}
            this.editDialog = true
        },

        editItem(item) {
            if (typeof (item) === "object") {
                this.editedIndex = item.id
                this.editedItem = item
            } else {
                this.editedIndex = -1
                this.editedItem = {}
            }
            this.editDialog = true
        },

        deleteItem(item) {
            if (this.UserCan.Directory.Edit) {
                if (item && item.id) {
                    if (confirm("Удалить справочник?")) {
                        axios.delete('/api/directory/' + item.id)
                            .then()
                            //если есть ошибки, то выводим текст ошибки
                            .catch(error => {
                                //console.log(error.request.status)
                                if (error) {
                                    this.Error = true
                                    this.ErrorText = "Не удалось удалить справочник."
                                }
                            })
                            .finally(() => {
                                this.getData()
                            });
                    }
                }
            }
        },

        downloadItem(item) {
            axios({
                url: '/api/directory/download/' + item.id,
                method: 'GET',
                responseType: 'blob', // important
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.name + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    if (error.response) {
                        this.Error = true
                        this.ErrorText = "Не удалось скачать справочников."
                    }
                });
        },

        onUploadButtonClick(item) {
            this.$refs["uploader_" + item.id].click()
        },

        uploadItem(directoryId, e) {
            if (directoryId && e && e.target && e.target.files && e.target.files[0]) {
                this.dataTableLoading = true
                let data = new FormData();
                data.append('file', e.target.files[0]);
                axios.post('/api/directory/upload/' + directoryId, data)
                    .then(response => {
                        this.infoText = "Данные из файла успешно загружены в справочник"
                        this.info = true
                    })
                    .catch(error => {
                        if (error.response) {
                            this.Error = true
                            this.ErrorText = "Не удалось загрузить справочник."
                        }
                    })
                    .finally(() => {
                        this.dataTableLoading = false
                    })
                ;
            }

        },
    }
}
</script>

<style scoped>

.v-data-table :deep(.directory) {
    cursor: pointer !important;
}

.d-none {
    display: none !important
}
</style>
