<template>
    <v-textarea
        v-model="this.result"
        auto-grow
        class="task-textarea system-defect pt-0 mt-0 "
        dense
        hide-details
        outlined
        readonly
        rows="1"
        color="black"
    >
        <template v-slot:prepend-inner>
            <div style="display: flex; align-items: center;">
                <div class="pr-2">
                    <system-defect-status-icon
                        :value="value"
                        :task-id="taskId"
                    />
                </div>
                <v-checkbox
                    v-if="settings?.show_printable"
                    class="mt-0 pt-0 justify-center align-center"
                    dense
                    :ripple="false"
                    hide-details
                    color="corpColor"
                    :input-value="value.is_printable"
                    v-on:change="$emit('changeIsPrintable', {
                        id: value.id,
                        is_printable: $event
                    })"
                ></v-checkbox>
                <div
                    v-if="settings?.show_number"
                    class="pr-2"
                    style="min-width: 26px"
                >
                    {{ value.number }}
                </div>
            </div>
        </template>

        <template v-slot:append>
            <v-icon v-if="value.media_count">{{ $store.state.Icon.svgMdiPaperclip }}</v-icon>
            <system-defect-action-menu
                :value="value"
                :disabled="disabled"
                :task-id="taskId"
                v-on:delete="$emit('delete', $event)"
                v-on:edit="$emit('edit', $event)"
                v-on:info="$emit('info', $event)"
                v-on:add-comment="$emit('add-comment', $event)"
                v-on:add-file="$emit('add-file', $event)"
                v-on:change-status="$emit('change-status', $event)"
            />
        </template>
    </v-textarea>
</template>

<script>
import {shortDateYearFirst} from "../../mixins/commonMethod";
import SystemDefectStatusIcon from "./SystemDefectStatusIcon.vue";
import SystemDefectActionMenu from "./SystemDefectActionMenu.vue";
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";

export default {
    name: "BaseSystemDefectTextarea",
    components: {SystemDefectActionMenu, SystemDefectStatusIcon},
    props: {
        value: {
            default: SystemDefectDefaultItem(),
        },
        disabled: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: Number,
            required: true
        },
    },
    computed: {
        settings() {
            return this.$store.getters["UserData/getStatusDefectSettings"]
        },
        result() {
            let result = [this.value.description]

            if (this.value.necessary_work) {
                result.push(this.value.necessary_work)
            }

            if (this.value.fix_to) {
                result.push('Устранить до: ' + shortDateYearFirst(this.value.fix_to))
            }

            return result.join('\n');
        }
    }
}
</script>

<style scoped>
.task-textarea.system-defect :deep(fieldset) {
    border-radius: 0;
    border-color: #285473 !important;
    margin-bottom: -1px;
}

.task-textarea.system-defect :deep(textarea) {
    line-height: 1.25rem;
    font-size: .85rem;
    color: black;
}
</style>
