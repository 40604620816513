<template>
    <g
        transform="matrix(10.509554,0,0,10.509554,17,17)">
        <rect
            class="fil0"
            height="6.2800002"
            rx="0.36000001"
            ry="0.38999999"
            width="6.2800002"
            x="0"
            y="0"/>
    </g>
</template>

<script>
export default {
    name: "MediumPriority"
}
</script>

<style scoped>
.fil0 {
    fill: #61A375
}
</style>
