<template>
    <div>
        <v-col class="px-0 pb-1">
            <v-row class="HeaderTitle-row setMarginBottom" no-gutters>
                <v-col class="HeaderTitle-col setMargin pl-2 justify-center pt2" cols="12">Состояние системы</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-textarea
                        v-model="state"
                        :disabled="disabled"
                        auto-grow
                        class="task-textarea system-state pt-0 mt-0 "
                        dense
                        hide-details
                        outlined
                        rows="1"
                        v-on:blur="editSystemState"
                    >
                    </v-textarea>
                </v-col>
            </v-row>
        </v-col>

    </div>
</template>

<script>

export default {
    name: "SystemState",
    props: {
        value: {
            default: {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: Number,
            required: true
        },
    },
    data: function () {
        return {
            state: null,
            oldState: null
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if (val && val.system_state) {
                    this.state = val.system_state.description
                    this.oldState = val.system_state.description
                } else {
                    this.state = null
                    this.oldState = null
                }

            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        editSystemState() {
            if (this.state && this.state !== this.oldState) {
                axios.put('/api/systemState/update/' + this.taskId, {description: this.state})
                    .then(() => {
                        this.$emit('input')
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                    .finally()
                ;
            }
        }

    },
}
</script>

<style scoped>

.HeaderTitle-row {
    background-color: #cccccc;
    border: 1px solid #285473;
}

.setMarginBottom {
    margin-bottom: -1px;
}

.task-textarea.system-state :deep(fieldset) {
    border-radius: 0;
    border-color: #285473 !important;
    margin-bottom: -1px;
}

</style>
