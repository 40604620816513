<template>
    <div>
        <base-modal
            v-model="openEquipmentSelectionDialog"
            max-width="1000"
        >
            <v-card>
                <v-container>
                    <v-card-title>
                        <span class="headline">Оборудование</span>
                    </v-card-title>
                    <v-card-text>
                        <div class="scrol-div">
                            <v-row>
                                <v-col>
                                    <v-combobox
                                        v-model="selectedSection1"
                                        :items="items.section_1"
                                        hide-details
                                        label="Группа"
                                    >
                                    </v-combobox>
                                    <v-combobox
                                        v-model="selectedSection2"
                                        :disabled="!selectedSection1"
                                        :items="items.section_2"
                                        hide-details
                                        label="Тип оборудования"
                                    >
                                    </v-combobox>
                                    <v-combobox
                                        v-model="selectedName"
                                        :disabled="!selectedSection2"
                                        :items="items.name"
                                        hide-details
                                        label="Наименование"
                                    >
                                    </v-combobox>
                                    <v-text-field
                                        v-model="quantity"
                                        :disabled="!selectedName"
                                        clearable
                                        hide-details
                                        label="Количество"
                                        min="1"
                                        type="number"
                                    ></v-text-field>
                                    <v-combobox
                                        v-model="selectedStatus"
                                        :disabled="!selectedName"
                                        :items="statusItems"
                                        hide-details
                                        label="Статус"
                                    >
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!quantity" color="blue" text v-on:click="save()">Сохранить</v-btn>
                        <v-btn color="blue" text v-on:click="close()">Закрыть</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </base-modal>

        <table class="taskMainTable elevation-3">
            <tbody>
            <tr style="height: 33px">
                <td class="taskHeaderTableTitle text-left pl-3 pr-3" colspan="6">
                    <v-row class="py-0">
                        <v-col class="py-0" cols="10">Оборудование и материалы</v-col>
                        <v-col class="py-0 text-right" cols="2">
                            <v-btn
                                :disabled="disabled"
                                icon
                                small
                                @click="openEquipmentSelectionDialog=true"
                            >
                                <v-icon>{{ $store.state.Icon.svgMdiPlus }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td class="Title text-center font-weight-bold">Группа</td>
                <td class="Title text-center font-weight-bold">Тип оборудования</td>
                <td class="Title text-center font-weight-bold">Наименование</td>
                <td class="Title text-center font-weight-bold width-10">Кол-во</td>
                <td class="Title text-center font-weight-bold width-10">Статус</td>
                <td class="Title text-center font-weight-bold width-10">Удалить</td>
            </tr>
            <tr v-for="(item) in value">
                <td class="TableRow">{{ item.section_1 }}</td>
                <td class="TableRow">{{ item.section_2 }}</td>
                <td class="TableRow">{{ item.name }}</td>
                <td class="TableRow text-center width-10">{{ item.pivot.quantity }}</td>
                <td class="TableRow text-center width-10">
                    <template v-if="item.pivot.status">Исправно</template>
                    <template v-else>Неисправно</template>
                </td>
                <td class="TableRow text-center width-10">
                    <v-btn
                        :disabled="disabled"
                        icon
                        x-small
                        @click="deleteEquipmentItem(item.pivot.id)"
                    >
                        <v-icon>{{ $store.state.Icon.svgMdiDelete }}</v-icon>
                    </v-btn>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";

export default {
    name: "FixedEquipment",
    components: {
        BaseModal
    },
    props: {
        value: {
            default: null
        },
        taskId: {
            type: Number,
            required: true
        },
        disabled: {
            type: Boolean,
            required: true
        }
    },
    data: function () {
        return {
            openEquipmentSelectionDialog: false, //для открытия окна выбора работ
            items: {
                "section_1": null,
                "section_2": null,
                "name": null,
            },
            selectedSection1: null,
            selectedSection2: null,
            selectedName: null,
            quantity: null,
            selectedStatus: 'Исправно',
            statusItems: ["Исправно", "Неисправно"]
        }
    },
    created() {
        this.getData();
    },
    watch: {
        selectedSection1: {
            handler: function (val) {
                this.getData("section_2");
                this.selectedSection2 = null
                this.selectedName = null
                this.quantity = null
            },
        },
        selectedSection2: {
            handler: function (val) {
                if (val) {
                    this.getData("name");
                }
                this.selectedName = null
                this.quantity = null
            },
        },
        selectedName: {
            handler: function (val) {
                this.quantity = null
            },
        },
    },
    methods: {
        save() {
            let payload = {
                "section_1": this.selectedSection1,
                "section_2": this.selectedSection2,
                "name": this.selectedName,
                "quantity": this.quantity,
                "status": this.selectedStatus === "Исправно",
            }
            axios.put('/api/fixedEquipment/' + this.taskId, {'data': payload})
                .then(() => (this.close()))
                .catch(function (error) {
                    console.log(error)
                });
        },
        close() {
            this.selectedSection1 = null
            this.selectedSection2 = null
            this.selectedName = null
            this.quantity = null
            this.selectedStatus = 'Исправно'
            this.openEquipmentSelectionDialog = false
            this.$emit('input')
        },
        getData(section = "section_1") {
            let payload = {
                "section_1": this.selectedSection1,
                "section_2": this.selectedSection2,
                "name": this.selectedName,
                "quantity": this.quantity,
            }
            axios.post('/api/fixedEquipment/getData/' + this.taskId, {'section': section, 'data': payload})
                .then(response => (
                    this.items[section] = response.data
                ))
                .catch(function (error) {
                    console.log(error)
                });
        },

        deleteEquipmentItem(id) {
            if (confirm("Вы уверены, что хотите удалить оборудование?")) {
                axios.delete('/api/fixedEquipment/' + this.taskId + '/' + id,)
                    .then(() => (
                        this.$emit('input')
                    ))
                    .catch(function (error) {
                        console.log(error)
                    });
            }

            // console.log("Удаляем разовую работу №", id)
        },
    },

}
</script>

<style>
td.Title {
    height: 25px;
    font-style: italic;
    /*font-weight: bold;*/
    /*font-size: 16px;*/
    background-color: #cccccc;
    text-align: left;
    border: 1px solid #285473;
    padding-left: 5px;
}

td.TableRow {
    height: 25px;
    border: 1px solid #285473;
    padding-left: 5px;
}

.width-10 {
    width: 10%
}

.scrol-div {
    overflow: auto;
    overflow-x: hidden;
    max-height: 590px;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 6px; /* высота для горизонтального скролла */
    background-color: #ffffff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #3273a7;
}
</style>
