export const screenHeight = {
    computed: {
        height: function () {
            if (this.$vuetify.breakpoint.width < 992) {
                return "100%"
            } else {
                return this.$vuetify.breakpoint.height - 95
            }
        },
    },
}