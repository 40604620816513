<template>
    <base-modal
        v-model="openDialog"
        :fullscreen="fullscreen"
        max-width="1200"
    >
        <v-card
            :loading="dataLoading"
        >
            <v-card-title>
                <span class="headline">Установленное оборудование</span>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    dense
                    disable-pagination
                    disable-filtering
                    disable-sort
                    hide-default-footer
                    :headers="headers"
                    :items="data"
                    :items-per-page="-1"
                    v-on:click:row="onClickRow"
                ></v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
            </v-card-actions>
        </v-card>

        <base-modal
            v-model="openQtyDialog"
            max-width="300"
        >
            <v-card>
                <v-card-title>
                    <span class="headline">Количество</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="qty"
                        type="number"
                        min="1"
                        :max="maxQty"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeQtyDialog">Отмена</v-btn>
                    <v-btn :disabled="!canSaveQty" color="blue darken-1" text @click="saveQtyDialog">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </base-modal>

    </base-modal>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal.vue";

export default {
    name: "SystemEquipmentSelect",
    components: {
        BaseModal
    },

    props: {
        openDialog: {
            type: Boolean,
            default: false
        },
        taskId: {
            required: true
        }
    },

    data: function () {
        return {
            fullscreenWidth: 700,
            dataLoading: false,
            headers: [],
            data: [],
            openQtyDialog: false,
            maxQty: 1,
            qty: 1,
            selectedItem: null
        }
    },

    watch: {
        openDialog: {
            handler: function (newVal) {
                if (newVal) {
                    this.getSystemEquipmentData(this.taskId)
                }
            },
            immediate: true
        },
    },

    computed: {
        fullscreen: function () {
            return this.$vuetify.breakpoint.width < this.fullscreenWidth
        },
        canSaveQty: function () {
            return this.qty >= 1 && this.qty <= this.maxQty
        },
    },

    methods: {
        getSystemEquipmentData(taskId) {
            axios.get('/api/taskWrhEquip/' + taskId)
                .then(response => {
                    this.headers = this.fieldsToHeader(response.data.fields)
                    this.data = response.data.data
                });
        },

        fieldsToHeader(fields) {
            let result = []
            fields.forEach(item => {
                result.push({text: item, value: item})
            })
            return result
        },

        onClickRow(item) {
            this.selectedItem = item
            if (this.selectedItem['Количество'] === 1) {
                this.qty = 1
                this.save()
            } else {
                this.qty = 1
                this.maxQty = this.selectedItem['Количество']
                this.openQtyDialog = true
            }
        },

        closeQtyDialog() {
            this.qty = 1
            this.maxQty = 1
            this.openQtyDialog = false
        },

        saveQtyDialog() {
            this.save()
        },

        close() {
            this.$emit('close')
        },

        save() {
            this.$emit('addRemovedItem', Object.assign({}, {item: this.selectedItem, qty: this.qty}))
            this.closeQtyDialog()
            this.close()
        }
    }
}
</script>

<style scoped>

</style>