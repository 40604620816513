<template>
    <v-card
        :loading="loading">
        <base-error-snackbar
            v-model="Error"
            v-bind:error-text="ErrorText"
        />
        <v-col cols="12">
            <v-row>
                <v-col cols="12" offset-xl="1" xl="10">
                    <task-title
                        v-on:close="close"
                    />

                    <task-subtitle/>

                    <task-header/>

                    <task-system-data/>

                    <task-custom-data
                        v-if="taskData.custom_data.length"
                    />

                    <task-system-data-directory/>

                    <template v-for="value in taskData.add_on_module">
                        <component
                            :is="value"
                            v-if="checkAddonComponent(value)"
                            :disabled="taskDisabled"
                            :task-id="taskData.id"
                            v-bind:value="taskData"
                            v-on:input="onReturnData"
                        ></component>
                    </template>

                    <task-works-intervals
                        v-if="UserCan.WorksIntervals.Edit || UserCan.WorksIntervals.TurnOn"
                    />

                    <v-col v-if="taskData && taskData.media && taskData.media.length"
                           class="px-0 pt-0 pb-3"
                    >
                        <base-image-list
                            :attachment="taskData.media"
                            :disabled="taskDisabled"
                            v-on:selectFile="$store.commit('TaskData/setSelectedAttachments', $event) "
                        />
                    </v-col>

                    <task-comments/>

                    <v-card-text>

                    </v-card-text>
                </v-col>
            </v-row>
        </v-col>

    </v-card>
</template>

<script>

import TaskTitle from "./TaskTitle";
import TaskSubtitle from "./TaskSubtitle";
import TaskHeader from "./TaskHeader";

import TaskSystemData from "./Module/TaskSystemData/TaskSystemData";
import TaskSystemDataDirectory from "./Module/TaskSystemData/TaskSystemDataDirectory";

import BaseErrorSnackbar from "../BaseComponents/BaseErrorSnackbar";
import {TaskDataType} from "../../mixins/dataType"
import TaskCustomData from "./Module/TaskCustomData/TaskCustomData";
import TaskPlannedWorks from "./Module/PlannedWorks/TaskPlannedWorks";
import BaseImageList from "../BaseComponents/BaseImageList";
import TaskComments from "./Module/TaskComments/TaskComments";
import TaskOneTimeWorks from "./Module/TaskOneTimeWorks/TaskOneTimeWorks";
import TaskEquipment from "./Module/TaskEquipment/TaskEquipment";
import TaskAssignedWorksAndMaterials from "./Module/TaskAssignedWorksAndMaterials/TaskAssignedWorksAndMaterials";
import TaskWorksIntervals from "./Module/TaskWorksIntervals/TaskWorksIntervals";
import BaseModal from "../BaseComponents/BaseModal";
import SystemDefects from "./Module/SystemDefects/SystemDefects";
import SystemState from "./Module/SystemState/SystemState";
import EquipmentSeal from "./Module/EquipmentSeal/EquipmentSeal";

export default {
    name: "Task",
    components: {
        TaskTitle,
        TaskSubtitle,
        TaskHeader,
        BaseErrorSnackbar,
        TaskCustomData,
        TaskPlannedWorks,
        BaseImageList,
        TaskComments,
        TaskOneTimeWorks,
        TaskEquipment,
        TaskAssignedWorksAndMaterials,
        TaskWorksIntervals,
        BaseModal,
        SystemDefects,
        SystemState,
        TaskSystemData,
        TaskSystemDataDirectory,
        EquipmentSeal
    },
    // props: {
    //     taskid: {
    //         default: 0,
    //     },
    //     taskItem: {
    //     }
    // },
    data: function () {
        return {
            // loading: true,
            taskDataDefault: new TaskDataType(),
            // taskData: new TaskDataType(),
            ErrorText: '',
            Error: false,
            taskDisabled: false,
            openObserverForm: false,
        }
    },

    created() {
        if (this.$route.params.taskid) {
            this.$store.commit('TaskData/setTaskId', this.$route.params.taskid)
            this.$store.dispatch('TaskData/updateTaskData');
        }
    },

    destroyed() {
        //перед уничтожением компонента, устанавливаем данные по умолчанию для задачи в vuex
        this.$store.dispatch('TaskData/setDefaultTaskData');
    },


    computed: {
        taskData: function () {
            return this.$store.getters["TaskData/getTaskData"]
        },

        loading: function () {
            return this.$store.getters["TaskData/getLoading"]
        },

        assistants: function () {
            let string = ''
            if (this.taskData.assistants) {
                this.taskData.assistants.forEach(function (item) {
                    string = string + item.short_name + '; '
                })
                return string
            } else {
                return null
            }
        },
    },
    methods: {
        checkAddonComponent(addonModuleName) {
            let components = Object.keys(this.$options.components)
            return components.includes(addonModuleName)
        },


        onReturnData() {
            this.$store.dispatch('TaskData/updateTaskData');
        },

        /**
         * Закрываем страницу задачи
         */
        close() {
            this.$emit('close', false)
            if (this.$route.name === "task") {
                window.close()
            } else {
                this.$router.back()
            }
            this.$store.dispatch('TaskData/setDefaultTaskData')
        },
    },


}
</script>


<style>
.row + .row {
    margin-top: 0;
}

table.taskHeaderTable,
table.taskMainTable {
    width: 100%;
    border: 1px solid #285473;
    border-collapse: collapse;
}

td.taskMainTableTitle {
    width: 25%;
    height: 25px;
    min-width: 121px;
    font-style: italic;
    /*font-weight: bold;*/
    /*font-size: 16px;*/
    background-color: #cccccc;
    text-align: left;
    border: 1px solid #285473;
    padding-left: 5px;
}

.taskHeaderTableTitle {
    width: 20%;
    height: 25px;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    background-color: #cccccc;
    text-align: center;
    border: 1px solid #285473;
}

.taskHeaderTitle {
    background-color: #cccccc;
    border: 1px solid #285473;
    padding: 3px;
}

.taskHeaderSimpleText {
    border: 1px solid #285473;
    font-size: .85rem;
}

.setMargin.taskHeaderTitle,
.setMargin.taskHeaderSimpleText {
    margin-bottom: -1px;
    min-height: 27px;
}


.pt2 {
    padding-top: 2px !important;
}

.setMargin.taskHeaderTitle {
    font-style: italic;
}


@media (min-width: 576px) {
    .setMargin.taskHeaderTitle {
        margin-right: -1px;
    }
}


td.taskMainTableRow {
    text-align: left;
    height: 25px;
    border: 1px solid #285473;
    padding-left: 5px;
}

td.taskHeaderTableRow {
    width: 20%;
    height: 25px;
    text-align: center;
    border: 1px solid #285473;
}


a.noDecor {
    color: rgba(0, 0, 0, 0.87) !important;
    text-decoration: none;
}

a.noDecor:link,
a.noDecor:hover,
a.noDecor:active {
    color: rgba(0, 0, 0, 0.87);
}

</style>
