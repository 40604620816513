<template>
    <g>
        <path :fill="iconColor"
              d="M5.925 20.259c.04 1.32-.274 2.123-1.353 2.212H1.968C.273 22.093.852 20.066.769 18.556c-1.117-3.385-1.172-6.228.88-7.95l-.756-.356c-1.215-.572-1.005-1.867.33-2.031.917-.114 1.299-.318 1.93.73.346-1.012 1.022-2.775 1.552-3.588C5.835 3.62 7.466 3.672 12.5 3.5c5.034.174 6.665.122 7.795 1.86.53.814 1.205 2.577 1.552 3.59.631-1.05 1.013-.845 1.93-.731 1.335.164 1.545 1.46.33 2.03l-.755.357c2.051 1.722 1.996 4.565.879 7.95-.082 1.51.497 3.537-1.199 3.915h-2.604c-1.079-.089-1.392-.894-1.353-2.212H5.927Zm15.982-4.182c-.969.233-2.093.176-3.086.153-.532-.013-.971-1.204-.6-1.74.514-.74 3.274-1.44 3.811-1.328 1.189.25 1.065 2.628-.125 2.913zm-18.815 0c.968.233 2.092.176 3.085.153.533-.013.971-1.204.6-1.74-.514-.74-3.274-1.44-3.81-1.328-1.189.25-1.065 2.628.125 2.913Zm.503-5.963C4.802 7.647 4.87 6.18 6.305 5.216c1.053-.707 11.337-.707 12.388 0 1.433.964 1.503 2.431 2.71 4.898-5.312-.513-12.496-.513-17.808 0z"
              style="stroke-width:.145818"/>
    </g>
</template>

<script>
export default {
    name: "AutomobileIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
