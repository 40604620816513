<template>
    <v-text-field
        :append-icon="$store.state.Icon.svgMdiMagnify"
        :value="value"
        clearable
        hide-details
        label="Найти"
        single-line
        v-on:input="$emit('input', $event)"
    />
</template>

<script>
import {VTextField} from 'vuetify/lib'

export default {
    name: "BaseSearchField",
    components: {VTextField},
    props: {
        value: {
            default: null
        }
    }
}
</script>

<style scoped>

</style>