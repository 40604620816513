<template>
    <v-checkbox
        v-model="model"
        :clearable="clearable"
        :hide-details="hideDetails"
        :label="label"
    ></v-checkbox>
</template>

<script>
import MainFieldsType from "./MainFieldsType";

export default {
    name: "Checkbox",
    extends: MainFieldsType,

}
</script>

<style scoped>

</style>
