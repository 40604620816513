<template>
    <v-card>
        <base-error-snackbar
            v-model="Error"
            v-bind:error-text="ErrorText"
        />
        <v-container>
            <v-form
                ref="Form"
                v-model="Valid"
            >
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        ref="login"
                        v-model="editedItem.name"
                        :rules="requiredRules"
                        label="Наименование"
                        required
                    >
                        <template v-slot:label>
                            Наименование<sup>*</sup>
                        </template>
                    </v-text-field>
                    <base-combobox-with-request
                        v-model="editedItem.system_type"
                        :clearable="true"
                        :multiple="true"
                        :open-on-clear="false"
                        item-text="name"
                        item-value="id"
                        label="Тип систем"
                        title="Тип систем"
                        url="newtask/getsystemtype"
                    />
                    <base-combobox-with-request
                        v-model="editedItem.task_status"
                        :clearable="true"
                        :multiple="true"
                        :open-on-clear="false"
                        item-text="name"
                        item-value="id"
                        label="Статус задачи"
                        title="Статус задачи"
                        url="taskstatus/index"
                    />
                    <base-combobox-with-request
                        v-model="editedItem.task_type"
                        :clearable="true"
                        :multiple="true"
                        :open-on-clear="false"
                        item-disadled="disabled"
                        item-text="name"
                        item-value="id"
                        label="Тип задачи"
                        title="Тип задачи"
                        url="tasktype/index"
                    />
                    <base-combobox-with-request
                        v-model="editedItem.task_priority"
                        :clearable="true"
                        :multiple="true"
                        :open-on-clear="false"
                        item-text="name"
                        item-value="id"
                        label="Приоритет"
                        title="Приоритет"
                        url="taskpriority/index"
                    />
                    <base-combobox-with-request
                        v-model="editedItem.created_by"
                        :clearable="true"
                        :multiple="true"
                        :open-on-clear="false"
                        item-disadled="disabled"
                        item-text="short_name"
                        item-value="id"
                        label="Задачу создал"
                        title="Задачу создал"
                        url="user/simpleList"
                    />

                    <base-combobox-with-request
                        v-model="editedItem.executor"
                        :clearable="true"
                        :multiple="true"
                        :open-on-clear="false"
                        item-disadled="disabled"
                        item-text="short_name"
                        item-value="id"
                        label="Исполнитель"
                        title="Исполнитель"
                        url="user/simpleList"
                    />

                    <v-row no-gutters>
                        <v-col>
                            <v-combobox
                                v-model="editedItem.creation_date"
                                :items="dateSettingsList"
                                :open-on-clear="false"
                                clearable
                                item-text="name"
                                item-value="id"
                                label="Дата создания"
                                spellcheck="false"
                                title="Дата создания"
                            />
                        </v-col>
                        <v-col
                            v-if="editedItem.creation_date && editedItem.creation_date.id && editedItem.creation_date.id === -1"
                            class="pl-sm-3" cols="12"
                            sm="6">
                            <date-range
                                v-model="editedItem.creation_date_period"
                                label="Период создания"
                            />
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col>
                            <v-combobox
                                v-model="editedItem.update_date"
                                :items="dateSettingsList"
                                :open-on-clear="false"
                                clearable
                                item-text="name"
                                item-value="id"
                                label="Дата изменения"
                                spellcheck="false"
                                title="Дата последнего изменения"
                            />
                        </v-col>
                        <v-col
                            v-if="editedItem.update_date && editedItem.update_date.id && editedItem.update_date.id === -1"
                            class="pl-sm-3" cols="12"
                            sm="6">
                            <date-range
                                v-model="editedItem.update_date_period"
                                label="Период изменения"
                            />
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col>
                            <v-combobox
                                v-model="editedItem.deadline"
                                :items="dateSettingsList"
                                :open-on-clear="false"
                                clearable
                                item-text="name"
                                item-value="id"
                                label="Крайний срок выполнения"
                                spellcheck="false"
                                title="Крайний срок выполнения"
                            />
                        </v-col>
                        <v-col v-if="editedItem.deadline && editedItem.deadline.id && editedItem.deadline.id === -1"
                               class="pl-sm-3" cols="12"
                               sm="6">
                            <date-range
                                v-model="editedItem.deadline_period"
                                label="Период срока выполнения"
                            />
                        </v-col>
                    </v-row>


                    <base-combobox-with-request
                        v-if="canFilterByPlan"
                        v-model="editedItem.planned_for"
                        :clearable="true"
                        :multiple="true"
                        :open-on-clear="false"
                        item-disadled="disabled"
                        item-text="short_name"
                        item-value="id"
                        label="Задача запланирована для"
                        title="Задача запланирована для"
                        url="user/simpleList"
                    />

                    <v-row v-if="canFilterByPlan" no-gutters>
                        <v-col>
                            <v-combobox
                                v-model="editedItem.plan_date"
                                :items="dateSettingsList"
                                :open-on-clear="false"
                                clearable
                                item-text="name"
                                item-value="id"
                                label="Задача запланирована на"
                                spellcheck="false"
                                title="Задача запланирована на"
                            />
                        </v-col>
                        <v-col v-if="editedItem.plan_date && editedItem.plan_date.id && editedItem.plan_date.id === -1"
                               class="pl-sm-3" cols="12"
                               sm="6">
                            <date-range
                                v-model="editedItem.plan_date_period"
                                label="Период планирования"
                            />
                        </v-col>
                    </v-row>


                    <v-combobox
                        v-model="editedItem.items_per_page"
                        :items="itemsPerPageSettingsList"
                        :open-on-clear="false"
                        clearable
                        item-text="name"
                        item-value="id"
                        label="Строк на листе"
                        spellcheck="false"
                        title="Сколько строк отображать на листе"
                    />

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="del">Удалить</v-btn>
                    <v-btn color="blue" text @click="closeForm">Отмена</v-btn>
                    <v-btn :disabled="!Valid" color="blue" text @click="save">Сохранить</v-btn>
                </v-card-actions>
            </v-form>
        </v-container>
    </v-card>
</template>

<script>
import BaseErrorSnackbar from "../BaseComponents/BaseErrorSnackbar";
import BaseComboboxWithRequest from "../BaseComponents/BaseComboboxWithRequest";
import BaseDatePicker from "../BaseComponents/BaseDatePicker";
import requiredRules from "../../Rules/requiredRules";

import DateRange from "../../BaseComponents/DateRange";

export default {
    name: "TaskListViewForm",
    components: {BaseDatePicker, BaseErrorSnackbar, BaseComboboxWithRequest, DateRange},
    props: {
        propsEditedItem: Object, //Свойство с данными редактируемого элемента
    },
    data: function () {
        return {
            ErrorText: '',
            Error: false,
            defaultItem: {
                name: null,
                system_type: null,
                task_priority: null,
                task_status: null,
                task_type: null,
                created_by: null,
                executor: null,
                creation_date: null,
                creation_date_period: null,
                update_date: null,
                update_date_period: null,
                deadline: null,
                deadline_period: null,
                items_per_page: null,
                plan_date: null,
                plan_date_period: null,
                planned_for: null,
            },
            editedItem: Object,
            Valid: false,
            dateSettingsList: [
                {id: -1, name: "Указанный период"},
                {id: 1, name: "С начала года"},
                {id: 5, name: "Предыдущий месяц"},
                {id: 2, name: "С начала месяца"},
                {id: 3, name: "С начала недели"},
                {id: 4, name: "С начала дня"},

            ],
            itemsPerPageSettingsList: [
                {id: 1, name: "10"},
                {id: 2, name: "15"},
                {id: 3, name: "20"},
                {id: 4, name: "30"},
                {id: 5, name: "Все"},
            ]

        }
    },

    created() {
        /*
         * При создании компонента, проверяем свойство с данными редактируемого элемента, и если оно не пустое,
         * то присваиваем их переменной, иначе присваиваем переменной дефолтные данные
         */
        if (Object.keys(this.propsEditedItem).length) {
            this.editedItem = Object.assign({}, this.propsEditedItem)
        } else {
            this.editedItem = Object.assign({}, this.defaultItem)
        }
    },

    watch: {
        /*
         * При изменение свойства с данными редактируемого элемента проверяем его и если оно не пустое,
         * то присваиваем их переменной для редактирования
         */
        propsEditedItem: function (val) {
            if (Object.keys(val).length) {
                this.editedItem = Object.assign({}, val)
            } else {
                this.editedItem = Object.assign({}, this.defaultItem)
            }
        },
    },
    computed: {
        canFilterByPlan() {
            return this.$store.getters['UserRights/canAllTaskPlan'] ||
                this.$store.getters['UserRights/canDepTaskPlan'] ||
                this.$store.getters['UserRights/canUsersTaskPlan']
        },
        formTitle() {
            return this.propsEditedItem.id === -1 ? "Создать представление" : "Изменить представление"
        },
        requiredRules() {
            return requiredRules
        },
    },
    methods: {
        save() {
            let emitedData = Object.assign({}, this.editedItem)
            if (emitedData.id !== -1) {
                axios.put('/api/tasklist/views/' + emitedData.id, emitedData)
                    .then(response => {
                        this.$emit('return')
                        this.closeForm()
                    })
                    //если есть ошибки, то выводим текст ошибки
                    .catch(error => {
                        if (error.response) {
                            this.Error = true
                            this.ErrorText = error.response.data.message
                        }
                    });
            } else {
                axios.post('/api/tasklist/views/new', emitedData)
                    .then(response => {
                        this.$emit('return')
                        this.closeForm()
                    })
                    .catch(error => {
                        if (error.response) {
                            this.Error = true
                            this.ErrorText = error.response.data.message
                        }
                    });
            }
        },

        closeForm() {
            this.$emit('close')
            this.tab = 0
            this.Error = false
            this.ErrorText = ''
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.$refs.Form.reset()
                this.$refs.Form.resetValidation()
            }, 300)
        },

        del() {
            if (confirm("Действительно удалить отображение?")) {
                axios.delete('/api/tasklist/views/' + this.editedItem.id)
                    .then(response => {
                        this.$emit('return')
                        this.closeForm()
                    })
                    .catch(error => {
                        if (error.response) {
                            this.Error = true
                            this.ErrorText = error.response.data.message
                        }
                    });
            }
        }
    },
}
</script>

<style scoped>


</style>
