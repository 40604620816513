<template>
    <v-row v-if="systemData"
           no-gutters
    >
        <v-col
            class="px-0 pt-1"
        >
            <object-card-system-data-row
                :custom_data_settings="settings"
                :data="systemData"
            />
        </v-col>
    </v-row>
</template>

<script>
import ObjectCardSystemDataRow from "../../ObjectCardOld/ObjectCardSystemDataRow.vue";

export default {
    name: "ObjectCardTaskSystemData",
    components: {
        ObjectCardSystemDataRow
    },
    props: {
        systemData: {
            required: true
        },
        settings: {
            required: true
        }
    },
}
</script>

<style scoped>

</style>