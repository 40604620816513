<template>
    <svg :aria-labelledby="iconName"
         :height="height"
         :width="width"
         clip-rule="evenodd"
         fill-rule="evenodd"
         image-rendering="optimizeQuality"
         role="presentation"
         shape-rendering="geometricPrecision"
         text-rendering="geometricPrecision"
         viewBox="0 0 100 100"
         xml:space="preserve"
         xmlns="http://www.w3.org/2000/svg"
    >
        <title
            :id="iconName"
            lang="ru"
        >{{iconName}}</title>
        <g>
            <component :is="icon"></component>
        </g>
    </svg>
</template>

<script>
import RemoteStatus from "../Icons/Status/RemoteStatus";
import NotReadStatus from "../Icons/Status/NotReadStatus";
import ReadStatus from "../Icons/Status/ReadStatus";
import InWorkStatus from "../Icons/Status/InWorkStatus";
import SuspendedStatus from "../Icons/Status/SuspendedStatus";
import CanceledStatus from "../Icons/Status/CanceledStatus";
import DoneStatus from "../Icons/Status/DoneStatus";
import CloseStatus from "../Icons/Status/CloseStatus";
import ReturnedForRevisionStatus from "../Icons/Status/ReturnedForRevisionStatus";
import NotCreated from "../Icons/Status/NotCreated";

export default {
    name: "BaseStatusIcon",
    components: {
        RemoteStatus,
        NotReadStatus,
        ReadStatus,
        InWorkStatus,
        SuspendedStatus,
        CanceledStatus,
        DoneStatus,
        CloseStatus,
        ReturnedForRevisionStatus,
        NotCreated
    },
    props: {
        icon: {
            required: true,
            type: String
        },
        iconName: {
            type: String,
            default: 'icon'
        },
        width: {
            type: [Number, String],
            default: 25
        },
        height: {
            type: [Number, String],
            default: 25
        },
    }
}
</script>

<style scoped>

</style>
