<template>
    <div>
        <v-menu
            v-model="showRightClickMenu"
            :position-x="rightClickMenu_x"
            :position-y="rightClickMenu_y"
            absolute
            offset-y
        >
            <v-list>
                <v-list-item @click="rightClick">
                    <v-list-item-title>Открыть карточку в новом окне</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-data-table
            v-model="selected"
            :footer-props="{
                showCurrentPage: true,
                itemsPerPageOptions: [10,15,20,30,-1],
                showFirstLastPage: true,
                firstIcon: $store.state.Icon.svgMdiArrowCollapseLeft,
                lastIcon: $store.state.Icon.svgMdiArrowCollapseRight
            }"
            :headers="headers"
            :item-class="row_classes"
            :items="dataList"
            :items-per-page="itemsPerPage"
            :loading="DataTableLoading"
            :search="search"
            dense
            loading-text="Загрузка данных... Пожалуйста подождите"
            locale="ru-RU"
            multi-sort
            v-on:click:row="openObjectCard"
            v-on:contextmenu:row="openRightClickMenu"
        >
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    :append-icon="$store.state.Icon.svgMdiMagnify"
                    class="pb-4 px-3"
                    clearable
                    hide-details
                    label="Найти"
                    single-line
                />
            </template>

        </v-data-table>

        <base-full-screen-modal
            v-model="dialog"
            style="z-index: 19999">
            <object-card
                :object-item="objectItem"
                v-on:close="onCloseObjectCard"
            />

        </base-full-screen-modal>

    </div>

</template>

<script>
import BaseFullScreenModal from "../BaseComponents/BaseFullScreenModal";
// import moment from "moment";
import ObjectCard from "../ObjectCard/ObjectCard.vue";

export default {
    name: "FilteredObjectCardList",
    components: {ObjectCard, BaseFullScreenModal},
    props: {
        selectedPoint: null,
        itemsPerPage: {
            type: Number,
            default: 20
        }
    },
    data: function () {
        return {
            dialog: false,
            objectItem: null,
            DataTableLoading: true,
            dataList: [],
            selected: [],
            search: '',
            showRightClickMenu: false,
            rightClickMenu_x: 0,
            rightClickMenu_y: 0,
            rightClickId: null,
        }
    },
    computed: {
        headers() {
            return [
                {text: 'ИД', value: 'id', align: 'start',},
                {text: 'Тип объекта', value: 'type',},
                {text: 'Наименование', value: 'name',},
                {text: 'Адрес', value: 'address.result',},

            ]
        }
    },
    watch: {
        selectedPoint: {
            handler: function () {
                this.getObjectList();
            },
            immediate: false
        },
    },
    created() {
        this.getObjectList()
    },
    methods: {
        row_classes() {
            return "taskListRow"
        },
        getObjectList() {
            this.DataTableLoading = true
            let data = {
                options: this.selectedPoint && this.selectedPoint[0] ? this.selectedPoint[0].options : null
            }
            axios.post('/api/objectcard', data)
                .then(response => {
                    this.dataList = response.data
                    this.DataTableLoading = false
                    this.$emit('update:needUpdateTaskListData', false)
                })
                .catch(error => {
                    this.showError(error)
                });
        },

        openObjectCard(item) {
            this.objectItem = item
            this.dialog = true

        },
        onCloseObjectCard() {
            this.dialog = false
            this.objectItem = null
            this.getObjectList()
        },

        openRightClickMenu(e, data) {
            this.rightClickId = data.item.id
            e.preventDefault()
            this.showRightClickMenu = false
            this.rightClickMenu_x = e.clientX
            this.rightClickMenu_y = e.clientY
            this.$nextTick(() => {
                this.showRightClickMenu = true
            })
        },

        rightClick(MouseEvent) {
            MouseEvent.preventDefault()
            let routeData = this.$router.resolve({name: 'objectcard', params: {objectid: this.rightClickId}});
            window.open(routeData.href, '_blank');
            this.rightClickId = null
        },
    }
}
</script>

<style scoped>

</style>
