<template>
    <v-snackbar
        v-if="info"
        v-model="snackbar"
        :timeout="timeout"
        color="primary"
        multi-line
        style="z-index: 999999"
        text
        top
    >
        <div>
            {{ info }}
        </div>

        <template v-slot:action="{ attrs }">
            <v-btn
                text
                @click="closeError"
            >
                <v-icon>
                    {{ $store.state.Icon.svgMdiClose }}
                </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "MainInfo",
    data() {
        return {
            snackbar: false,
        }
    },
    computed: {
        info() {
            return this.$store.getters["getInfo"]
        },
        timeout() {
            return this.$store.getters['getInfoTimeout']
        }
    },
    watch: {
        info: function (val) {
            this.snackbar = !!val
        },
        snackbar: function (val) {
            if (!val) {
                this.$store.commit('setNoInfo')
            }
        },
    },
    methods: {
        closeError() {
            this.snackbar = false
        },
    }
}
</script>

<style scoped>

</style>
