<template>
    <v-toolbar color="corpColor" dark height="50px">
        <v-toolbar-title class="font-weight-bold text-h5">Задача №{{ this.taskData.id }} {{ this.readOnly }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>{{ $store.state.Icon.svgMdiDotsVertical }}</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item :disabled="!taskData.reporting_docs_id.length" @click="openReportingDocsForm=true">
                        <v-list-item-title>Отчетные документы</v-list-item-title>
                    </v-list-item>
                    <v-list-item :disabled="disabled" @click="openObserverForm=true">
                        <v-list-item-title>Сменить ответственного со стороны заказчика</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canPlan" @click="openPlanForm=true">
                        <v-list-item-title>План выполнения</v-list-item-title>
                    </v-list-item>


                    <v-list-item @click="openTaskLogForm=true">
                        <v-list-item-title>Просмотреть историю задачи</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="UserCan.ObjectCard.View" @click="openObjectCard">
                        <v-list-item-title>Перейти к карточке объекта</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canEditTask" :disabled="disabled" @click="openAddFileForm=true">
                        <v-list-item-title>Прикрепить файлы</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canEditTask" :disabled="disabled || !selectedAttachments.length"
                                 @click="deleteSelectFile">
                        <v-list-item-title>Удалить выбранные файлы</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canDeleteTask" @click="deleteTask">
                        <v-list-item-title>Удалить задачу</v-list-item-title>
                    </v-list-item>

                </v-list>
            </v-menu>
        </v-toolbar-items>

        <v-btn dark icon @click="close">
            <v-icon>{{ $store.state.Icon.svgMdiClose }}</v-icon>
        </v-btn>


        <task-reporting-docs
            v-model="openReportingDocsForm"
        />

        <task-observer-edit
            v-model="openObserverForm"
        />

        <task-plan
            v-model="openPlanForm"
        />


        <task-logs
            v-model="openTaskLogForm"
            :log="taskData.log"
        />


        <base-modal v-model="openAddFileForm">
            <base-file-upload
                :disabled="$store.getters['TaskData/getDisabled']"
                :url="'/api/task/upload/' + $store.getters['TaskData/getTaskId']"
                @input="closeUploadForm"
            />
        </base-modal>

    </v-toolbar>
</template>

<script>

import TaskReportingDocs from "./Module/TaskReportingDocs/TaskReportingDocs";
import TaskObserverEdit from "./Module/TaskObserverEdit/TaskObserverEdit";
import TaskLogs from "./Module/TaskLogs/TaskLogs";
import BaseModal from "../BaseComponents/BaseModal";
import BaseFileUpload from "../BaseComponents/BaseFileUpload/BaseFileUpload";
import TaskPlan from "./Module/TaskPlan/TaskPlan";

export default {
    name: "TaskTitle",
    components: {
        TaskReportingDocs,
        TaskObserverEdit,
        TaskLogs,
        BaseModal,
        BaseFileUpload,
        TaskPlan
    },
    data: function () {
        return {
            openAddFileForm: false,
            openObserverForm: false,
            openReportingDocsForm: false,
            openTaskLogForm: false,
            openPlanForm: false,
        }
    },
    computed: {
        watchedTask() {
            return this.$store.getters["TaskData/getWatchedTask"]
        },
        readOnly() {
            return this.watchedTask ? 'Только чтение' : ''
        },

        taskData() {
            return this.$store.getters["TaskData/getTaskData"]
        },
        disabled() {
            return this.$store.getters["TaskData/getDisabled"]
        },

        canEditTask() {
            return this.$store.getters['TaskData/canEditTask']
        },

        canDeleteTask() {
            return this.$store.getters['TaskData/canDeleteTask'];
        },

        canPlan() {
            return this.$store.getters['TaskData/canPlan'];
        },

        selectedAttachments() {
            return this.$store.getters['TaskData/getSelectedAttachments']
        },


    },
    methods: {
        openObjectCard() {
            if (this.UserCan.ObjectCard.View) {
                let routeData = this.$router.resolve({
                    name: 'objectcard',
                    params: {objectid: this.taskData.system.object.id}
                });
                window.open(routeData.href, '_blank');
            }
        },

        deleteSelectFile() {
            if (this.selectedAttachments && this.selectedAttachments.length) {
                if (confirm("Вы уверены, что хотите удалить файлы?")) {
                    axios.post('/api/task/deletefile/' + this.taskData.id, {deletedFiles: this.selectedAttachments})
                        .then(() => {
                            this.$store.dispatch('TaskData/updateTaskData');
                        })
                        .catch(error => {
                            if (error.response) {
                                this.$store.commit('setErrors', error.response.data)
                            }
                        });
                }
            }
        },

        closeUploadForm() {
            this.openAddFileForm = false
            this.$store.dispatch('TaskData/updateTaskData');
        },

        deleteTask() {
            if (this.$store.getters["TaskData/canDeleteTask"] && confirm("Вы уверены, что хотите удалить задачу?")) {

                axios.delete('/api/task/' + this.taskData.id)
                    .then(() => {
                        this.close()
                    })
                    .catch(error => {
                        this.showError(error)
                    });
            }
        },

        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>
