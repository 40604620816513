<template>
    <g
        transform="matrix(8.0383231,0,0,8.1234768,0.00142738,0)">
        <path

            class="fil0"
            d="M 6.22,0 4.35,1.39 H 1.89 V 3.13 L 0,4.44 v 6.81 c -0.01,0.52 0.35,1.03 1.04,1.06 h 5.18 5.18 c 0.69,-0.03 1.05,-0.54 1.04,-1.06 V 4.44 L 10.55,3.13 V 1.39 H 8.09 Z"/>
        <polygon

            class="fil1"
            points="9.95,5.89 7.12,7.85 6.22,7.11 5.32,7.85 2.5,5.89 2.5,2.02 6.22,2.02 9.95,2.02 "/>
        <path

            class="fil1"
            d="M 6.22,11.43 H 1.56 C 1.04,11.43 0.87,11 0.86,10.74 V 5.36 l 4.08,2.82 -3.52,2.81 h 0.51 l 4.28,-3.23 4.28,3.23 H 11 L 7.48,8.18 11.56,5.36 v 5.38 c -0.01,0.26 -0.18,0.7 -0.7,0.69 H 6.2 Z"/>
    </g>
</template>

<script>
export default {
    name: "ReadStatus"
}
</script>

<style scoped>
.fil0 {
    fill: #285473
}

.fil1 {
    fill: #fefefe
}
</style>
