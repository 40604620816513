export default function () {
    this.CanceledCommentLength = null
    this.CloseCommentLength = null
    this.DoneCommentLength = null
    this.InWorkCommentLength = null
    this.SuspendedCommentLength = null
    this.requireCommentWhenSetCanceled = false
    this.requireCommentWhenSetClose = false
    this.requireCommentWhenSetDone = false
    this.requireCommentWhenSetInWork = false
    this.requireCommentWhenSetSuspended = false
}