export function getToken(url) {
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then((result) => {
                resolve(result.data.token)
            })
            .catch((error) => {
                reject(error);
            });

    });
}