<template>
    <v-row no-gutters>
        <v-col
            v-if="field"
            ref="directorydiv"
            class="px-0 pt-1"
            v-on:DOMNodeInserted="onShow"
        >
            <div class="object-card-table-title font-weight-bold">
                {{ field.name }}
            </div>
            <div v-if="blockInLine" class="object-card-table-row pa-0">
                <div :style="styleForVariableColumns"
                     class="this-component_section"
                >
                    <div v-for="item in mergedFields" class="this-component_header">
                        {{ item.name }}
                    </div>

                    <template
                        v-for="(dataField, key) in localData"
                    >
                        <div v-for="item in mergedFields" class="this-component-field">
                            {{ dataField[item.name] }}
                        </div>
                    </template>
                </div>
            </div>
            <div v-else>
                <div class="this-component-columnsection">
                    <template
                        v-for="(field, key) in localData"
                    >
                        <template v-for="item in mergedFields">
                            <div class="this-component-columnsection-row-header">
                                {{ item.name }}
                            </div>
                            <div class="this-component-columnsection-row-item">
                                {{ field[item.name] }}
                            </div>
                        </template>
                        <div class="this-component-columnsection-row-header last"></div>
                        <div class="this-component-columnsection-row-item last"></div>
                    </template>
                </div>
            </div>
        </v-col>

    </v-row>
</template>

<script>
export default {
    name: "ObjectCardDirectoryRow",
    props: {
        field: {
            required: true
        },
        localData: {
            required: true
        }
    },
    data: function () {
        return {
            blockInLine: true,
            directoryData: null,
            filtredFields: null,
            mergedFields: null,
            extra_attributes: null,
            resizeObserver: null,
        }
    },
    created() {
        this.extra_attributes = this.field.extra_attributes
    },
    computed: {
        styleForVariableColumns() {
            if (this.directoryData && this.directoryData.fields) {
                let length = this.directoryData.fields.filter(item => item.show).length
                    + this.directoryData.additional_fields.length

                return 'grid-template-columns: repeat(' + length + ', auto)';
            }
        }
    },
    watch: {
        extra_attributes: {
            handler: function (val) {
                if (val && val.directory) {
                    this.getDirectoryData(val.directory)
                }
            },
            immediate: true
        }
    },
    methods: {
        /**
         * Регистрируем наблюдателя за изменением размера блока
         */
        onShow() {
            this.calcElementWidth()
            this.resizeObserver = new ResizeObserver(this.calcElementWidth).observe(this.$refs.directorydiv);
        },

        /**
         * Метод для расчета вида блока
         */
        calcElementWidth() {
            if (this.$refs.directorydiv) {
                let rect = this.$refs.directorydiv.getBoundingClientRect()
                let width = rect.width
                if (width && this.mergedFields && this.mergedFields.length) {
                    this.blockInLine = (width / (this.mergedFields.length + 1)) > 100
                }
            }
        },
        getDirectoryData(directoryId) {
            axios.get('/api/directory/settings/' + directoryId)
                .then(response => {
                    this.directoryData = response.data
                    //Сортируем поля справочника
                    if (this.directoryData && this.directoryData.fields) {
                        this.directoryData.fields.sort((firstItem, secondItem) => firstItem.following - secondItem.following);
                    }
                    //Фильтруем поля которые можно показывать
                    if (this.directoryData && this.directoryData.fields) {
                        this.filtredFields = this.directoryData.fields.filter(field => field.show === true);
                    }

                    this.mergedFields = this.filtredFields.concat(this.directoryData.additional_fields)
                });
        },
    }
}
</script>

<style scoped>
/*.object-card-table-title {*/
/*    background-color: #EBECEC;*/
/*    padding: 3px 10px;*/
/*    border-left: #E1E1E1 1px solid;*/
/*    border-top: #E1E1E1 1px solid;*/
/*    border-right: #E1E1E1 1px solid;*/
/*}*/

/*.object-card-table-row {*/
/*    padding: 0;*/
/*    font-size: 13px;*/
/*    border-left: #E1E1E1 1px solid;*/
/*    border-top:  #E1E1E1 1px solid;*/
/*    border-right: #E1E1E1 1px solid;*/
/*    background-color: white;*/
/*}*/

.this-component_section {
    display: grid;
    border: 1px solid #E1E1E1;
    grid-gap: 1px;
    background-color: #E1E1E1;
}

.this-component-field {
    display: grid;
    width: 100%;
    font-size: 13px;
    background-color: white;
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
    word-wrap: break-word;
    word-break: normal;
    overflow-wrap: anywhere;
}

.this-component_header {
    display: grid;
    width: 100%;
    font-size: 13px;
    font-style: italic;
    background-color: #EBECEC;
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: anywhere;
}

.this-component-columnsection {
    display: grid;
    border: 1px solid #E1E1E1;
    background-color: #EBECEC;
    grid-gap: 1px;
    grid-template-columns: max-content auto;
    font-size: 0.80rem;
}

.this-component-columnsection-row-item {
    background-color: white;
    padding-left: 8px;
    padding-right: 3px;
}

.this-component-columnsection-row-header {
    background-color: white;
    padding-left: 8px;
    padding-right: 3px;
}

.this-component-columnsection-row-item.last,
.this-component-columnsection-row-header.last {
    border-bottom: 2px solid #E1E1E1;
}
</style>