<template>
    <div v-if="rightToRead">
        <base-resize-panel :mobile="mobile" name="taskList">
            <template v-slot:left>
                <base-left-menu
                    v-model="activeMenuPoint"
                    :object-only="false"
                    :selectable="false"
                    :updateAll="updateAll"
                    :with-search="true"
                />
            </template>
            <template v-slot:right>
                <task-list
                    :can-drag-task="false"
                    :can-opening-task="true"
                    :height="height"
                    :object-tree-options="options"
                    route-prefix="taskslist"
                />
            </template>
        </base-resize-panel>
    </div>
</template>

<script>
import BaseResizePanel from "../BaseComponents/BaseResizePanel";
import BaseLeftMenu from "../BaseComponents/BaseLeftMenu";
import TaskList from "./TaskList";

export default {
    name: "TaskListWithLeftMenu",
    components: {BaseResizePanel, BaseLeftMenu, TaskList},
    data: function () {
        return {
            activeMenuPoint: [],
            updateAll: null,
        }
    },
    computed: {
        rightToRead: function () {
            return this.$store.getters['UserRights/canAllTasksRead'] ||
                this.$store.getters['UserRights/canDepTasksRead'] ||
                this.$store.getters['UserRights/canUsersTasksRead']
        },
        mobile: function () {
            return this.height === "100%"
        },
        height: function () {
            if (this.$vuetify.breakpoint.width < 992) {
                return "100%"
            } else {
                return this.$vuetify.breakpoint.height - 70
            }
        },
        options: function () {
            if (Array.isArray(this.activeMenuPoint) && this.activeMenuPoint.length && this.activeMenuPoint[0].options) {
                return this.activeMenuPoint[0].options
            }
            return {}
        }
    },
}
</script>

<style scoped>

</style>