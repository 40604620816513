<template>
    <div style="z-index: 20000">
        <v-dialog
            v-model="openDialog"
            :fullscreen="fullscreen"
            :max-width="maxWidth"
            :scrollable="scrollable"
            persistent
            width="100%"
        >
            <slot>
                <v-card>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="openDialog = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </slot>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "BaseModal",
    props: {
        scrollable: true,
        value: false,
        maxWidth: {
            type: String,
            default: '600px'
        },
        fullscreen: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data: function () {
        return {
            openDialog: false,
        }
    },
    watch: {
        value: function () {
            this.openDialog = this.value
        },
        openDialog: function () {
            this.$emit('input', this.openDialog)
        }
    }
}
</script>

<style scoped>

</style>
