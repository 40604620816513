<template>
    <div>
        TasksTimetable
    </div>
</template>

<script>
export default {
    name: "TasksTimetable"
}
</script>

<style scoped>

</style>
