<template>
    <v-card
        :height="heightComputed"
        :loading="loading"
    >
        <v-card-text>
            <div class="d-flex " style="
                    width: 100%;
                    height: 49px;
                    padding-right: 8px;
                    border-left: #ddd 1px solid;
                    border-top: #ddd 1px solid;
                    border-right: #ddd 1px solid;
                ">
                <div class="font-weight-bold ml-4 mt-auto mb-auto"></div>
                <div class="font-weight-bold data-div ml-auto mt-auto mb-auto"></div>
                <div class="font-weight-bold data-div-three mt-auto mb-auto">Техническое обслуживание</div>
                <div class="font-weight-bold data-div-two mt-auto mb-auto">Неисправности</div>
                <div class="font-weight-bold data-div-two mt-auto mb-auto">Все остальные</div>
            </div>
            <div class="d-flex " style="
                    width: 100%;
                    height: 49px;
                    padding-right: 8px;
                    border-left: #ddd 1px solid;
                    border-top: #ddd 1px solid;
                    border-right: #ddd 1px solid;
                ">
                <div class="font-weight-bold ml-4 mt-auto mb-auto">{{ title }}</div>
                <div class="font-weight-bold data-div ml-auto mt-auto mb-auto">Всего систем</div>
                <div class="font-weight-bold data-div mt-auto mb-auto">Создано</div>
                <div class="font-weight-bold data-div mt-auto mb-auto">Закрыто</div>
                <div class="font-weight-bold data-div mt-auto mb-auto">Процент выполнения</div>

                <div class="font-weight-bold data-div mt-auto mb-auto">Создано</div>
                <div class="font-weight-bold data-div mt-auto mb-auto">Закрыто</div>

                <div class="font-weight-bold data-div mt-auto mb-auto">Создано</div>
                <div class="font-weight-bold data-div mt-auto mb-auto">Закрыто</div>
            </div>

            <v-sheet
                :height="heightComputed-120"
                class="mx-auto overflow-y-auto"
                width="100%"
            >
                <v-treeview
                    ref="leftMenu"
                    :items="allNodes"
                    :load-children="getNode"
                    :open.sync="open"
                    activatable
                    class="base-left-menu"
                    color="primary"
                    hoverable
                    transition
                    v-on:input="$emit('input', $event)"
                >
                    <template v-slot:append="{ item }">
                        <div class="d-flex " style="width: 100%;">
                            <div class="data-div">{{ item.data.allSystems }}</div>
                            <div class="data-div">{{ item.data.maintenance.created }}</div>
                            <div class="data-div">{{ item.data.maintenance.closed }}</div>
                            <div class="data-div">{{ item.data.maintenance.percent }}</div>

                            <div class="data-div">{{ item.data.defect.created }}</div>
                            <div class="data-div">{{ item.data.defect.closed }}</div>

                            <div class="data-div">{{ item.data.others.created }}</div>
                            <div class="data-div">{{ item.data.others.closed }}</div>
                        </div>
                    </template>
                </v-treeview>
            </v-sheet>

        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "StatusMonitor",
    props: {
        updateAll: null,
        selectable: false,
        objectOnly: { //если труе, то иерархию загружаем только до объекта, если фалсе то до системы
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "Список объектов"
        },
        height: {
            default: null
        }

    },
    data: function () {
        return {
            allNodes: [],
            open: [],
            search: null,
            loading: true
        }
    },
    computed: {
        heightComputed: function () {
            return this.$vuetify.breakpoint.height - 75
        },
        // activatable: function (){
        //     return !this.selectable;
        // },
    },
    created() {
        this.getMainNode()
    },
    watch: {
        // activeMenuPoint: function (val) {
        //     this.$emit("input", val)
        // },
        // updateAll: function (val){
        //     if (val !== null){
        //         // this.$refs.leftMenu.updateAll(val)
        //     }
        // }
    },
    methods: {

        async getMainNode() {
            this.loading = true
            let data = {
                customer_id: null,
                object_department: null,
                object_type: null,
                object_id: null,
                system_id: null,
            }
            return axios.post('/api/statusmonitor/node', data)
                .then(response => {
                    // this.allSystems = objToArray(response.data)
                    this.allNodes = response.data
                })
                .then(() => {
                    this.open = [1]
                    this.loading = false
                })
                .catch(() => {
                    // console.log.bind(console)
                    this.loading = false
                });
        },
        async getNode(item) {
            let data = {
                customer_id: item.customer_id,
                object_department: item.object_department,
                object_type: item.object_type,
                object_id: item.object_id,
                system_id: item.system_id,
            }

            // await pause(0)
            return axios.post('/api/statusmonitor/node', data)
                .then(response => {
                    // this.allSystems = objToArray(response.data)
                    item.children = response.data
                })
                .catch(console.log.bind(console));
        }
    }
}
</script>

<style scoped>
.data-div {
    text-align: center;
    /*float: right;*/
    width: 8.5vw;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
}


.data-div-three {
    text-align: center;
    /*float: right;*/
    width: 25.5vw;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
}

.data-div-two {
    text-align: center;
    /*float: right;*/
    width: 17vw;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
}

.base-left-menu {
    border-left: #ddd 1px solid;
    border-top: #ddd 1px solid;
    border-right: #ddd 1px solid;
}

.base-left-menu :deep(.v-treeview-node__root) {
    border-bottom: #ddd 1px solid;

}
</style>
