<template>
    <g transform="scale(1.1261261,1.0638298)">
        <path
            :fill="iconColor"
            d="m 8.71,0 h 4.78 c 0.55,0 1,0.45 1,1 v 21.5 c 0,0.55 -0.45,1 -1,1 H 8.71 c -0.55,0 -1,-0.45 -1,-1 V 1 c 0,-0.55 0.45,-1 1,-1 z m 2.39,18.83 c 0.77,0 1.4,0.63 1.4,1.4 0,0.77 -0.63,1.4 -1.4,1.4 -0.77,0 -1.4,-0.63 -1.4,-1.4 0,-0.77 0.63,-1.4 1.4,-1.4 z M 9.06,3.36 h 4.08 V 4.4 H 9.06 Z m 0,-1.66 h 4.08 V 2.74 H 9.06 Z"
        />
        <path
            :fill="iconColor"
            d="m 1,0 h 4.78 c 0.55,0 1,0.45 1,1 v 21.5 c 0,0.55 -0.45,1 -1,1 H 1 c -0.55,0 -1,-0.45 -1,-1 V 1 C 0,0.45 0.45,0 1,0 Z m 2.39,18.83 c 0.77,0 1.4,0.63 1.4,1.4 0,0.77 -0.63,1.4 -1.4,1.4 -0.77,0 -1.4,-0.63 -1.4,-1.4 0,-0.77 0.63,-1.4 1.4,-1.4 z M 1.35,3.36 H 5.43 V 4.4 H 1.35 Z m 0,-1.66 H 5.43 V 2.74 H 1.35 Z"
        />
        <path
            :fill="iconColor"
            d="m 16.42,0 h 4.78 c 0.55,0 1,0.45 1,1 v 21.5 c 0,0.55 -0.45,1 -1,1 h -4.78 c -0.55,0 -1,-0.45 -1,-1 V 1 c 0,-0.55 0.45,-1 1,-1 z m 2.39,18.83 c 0.77,0 1.4,0.63 1.4,1.4 0,0.77 -0.63,1.4 -1.4,1.4 -0.77,0 -1.4,-0.63 -1.4,-1.4 0,-0.77 0.63,-1.4 1.4,-1.4 z M 16.77,3.36 h 4.08 V 4.4 h -4.08 z m 0,-1.66 h 4.08 v 1.04 h -4.08 z"
        />
    </g>
</template>

<script>
export default {
    name: "DirectoryIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>