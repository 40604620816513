export default [
    value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (value === null) {
            return true
        } else if (value === '') {
            return true
        } else {
            return pattern.test(value) || 'Это поле должно содержать действующий электронный адрес'
        }
    }
]