<template>
    <div>
        <object-card-row
            v-if="data?.objectgroupName"
            :content='data?.objectgroupName'
            title="Группа объектов"
        />
        <object-card-row
            :content='data?.leadEngineerName'
            :tel="data?.leadEngineerTel"
            title="Ответственный от исполнителя"
        />
        <object-card-row
            :content='data?.observerName'
            :tel="data?.observerTel"
            title="Ответственный от заказчика"
        />

        <object-card-system-data-row
            :custom_data_settings="data.custom_data_settings"
            :data="data.data"
        />

        <object-card-system-defect-row
            :defects="data.current_defects"
        />

        <v-row
            v-if="data && data.media && data.media.length"
            no-gutters>
            <v-col class="px-0 pt-0 pb-3">
                <base-image-list
                    :attachment="data.media"
                    disabled
                />
            </v-col>
        </v-row>

    </div>
</template>

<script>
import ObjectCardRow from "../ObjectCardOld/ObjectCardRow.vue";
import ObjectCardSystemDataRow from "../ObjectCardOld/ObjectCardSystemDataRow.vue";
import ObjectCardSystemDefectRow from "../ObjectCardOld/ObjectCardSystemDefectRow.vue";
import BaseImageList from "../BaseComponents/BaseImageList.vue";

export default {
    name: "ObjectCardSystemDescription",
    components: {BaseImageList, ObjectCardSystemDefectRow, ObjectCardSystemDataRow, ObjectCardRow},
    props: {
        objectId: {
            required: true
        },
        systemTypeId: {
            required: true,
        },
        contractId: {
            required: true
        }
    },
    data: function () {
        return {
            data: null
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            axios.get('/api/objectcard/system/' + this.objectId + '/' + this.systemTypeId + '/' + this.contractId)
                .then(response => {
                    this.data = response.data
                })
                .catch(error => {
                    this.showError(error)
                }).finally(() => {
                this.loading = false
            });
        },
    }
}
</script>

<style scoped>

</style>
