<template>
    <g transform="scale(.4902 .53267)">
        <path :fill="iconColor"
              d="M1.63 0h47.74C50.26 0 51 .73 51 1.63V54.69c0 .9-.74 1.63-1.63 1.63H1.63c-.9 0-1.63-.73-1.63-1.63V1.63C0 .73.73 0 1.63 0zm45.39 16.39V3.97H3.97v48.38h43.05z"/>
        <path :fill="iconColor"
              d="M13.82 43.26c3.31 0 5.54-.01 7.18-.01.08-.29.16-.6.25-.93 0-.03.01-.06.02-.1.03-.13.07-.28.12-.43.43-1.59 1.04-3.74 1.93-6.78.17-.57.14-1.1-.04-1.37-.13-.2-.43-.49-.84-.47l-.87.04.2-.63 5.83-1.03 1.04.02-1.3 4.62c-.61 2.16-1.09 3.71-1.53 5.2-.01.1-.02.18-.02.25 0 .19.05.35.14.47.72.99 2.39-1.71 2.61-2.07l.69.35c-.7 1.22-1.45 2.16-2.15 2.84h9.28c1.1 0 1.37-.19 1.37-1.31V28.37l-10.51-9.31c-.51-.43-1-1.02-1.52-1.36-.25.12-.13.07-.34.23l-4.99 4.19c-1.82 1.61-4.67 3.89-6.67 5.57-.71.6-.83.47-.82 1.71v12.24c0 .91-.15 1.62.94 1.62z"/>
        <path :fill="iconColor"
              d="M8.21 27.96c.28 1.04 1.66 1.23 2.69.65.41-.23.79-.54 1.11-.84l11.61-9.82c.34-.29.74-.59 1.06-.88.3-.29.78-.71 1.11-.9l12.03 10.74c.74.59 1.11 1.18 2.15 1.66 1.14.52 2.32.37 2.82-.56l-15.9-14.46c-.21-.17-.37-.28-.55-.47-.28-.29-.24-.3-.55-.47-.36.16-1.73 1.5-2.2 1.89z"/>
        <path :fill="iconColor"
              d="M38.72 23.05l.01-6.95-3.41-.02c-.05.83-.01 1.75-.01 2.56 0 2.08-.26 1.28 1.98 3.25.41.36 1.05.98 1.43 1.16z"/>
        <path :fill="whiteColor"
              d="M26.01 24.16c.67.05 1.07.36 1.44.73.88.89 1.04 2.38.17 3.34-1.22 1.34-2.94 1.24-3.75.29-1.01-1.18-1.07-2.45.02-3.63.54-.58 1.38-.79 2.12-.73z"/>
    </g>
</template>

<script>
export default {
    name: "ObjectCardListIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
