<template>
    <div v-if="priority">
        <template v-if="$store.getters['TaskData/canEditPriority']">
            <v-overflow-btn
                v-model="priority"
                :items="items"
                class="my-0 task-priority-btn"
                flat
                hide-details
                item-text="name"
                item-value="id"
                label="Приоритет"
                placeholder="Приоритет"

            >
            </v-overflow-btn>
        </template>
        <template v-else>
            {{ this.$store.getters["TaskData/getTaskPriority"].name }}
        </template>
    </div>

</template>

<script>
export default {
    name: "TaskPriority",
    data: function () {
        return {
            items: [
                {id: 1, name: "Низкий"},
                {id: 2, name: "Средний"},
                {id: 3, name: "Высокий"},
                {id: 4, name: "Просрочена"},
            ],
        }
    },

    computed: {
        priority: {
            get: function () {
                return this.$store.getters["TaskData/getTaskPriority"]
            },
            set: function (newValue) {
                this.changePriority(newValue)
            }
        }
    },

    methods: {

        changePriority(newValue) {
            if (confirm("Вы уверены, что хотите изменить приоритет задачи?")) {
                axios.put('/api/taskpriority/' + this.$store.getters["TaskData/getTaskId"], {priority: newValue})
                    .then(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                    .catch(error => {
                        this.showError(error)
                    })
            }

        }
    }
}
</script>

<style scoped>
div.task-priority-btn {
    text-align: center;
    font-size: 0.9rem;
}

div.task-priority-btn :deep(div.v-select__slot) {
    height: 25px;
}

div.task-priority-btn :deep(div.v-input__slot) {
    border-width: 0;
    border-style: none;
}

div.task-priority-btn :deep(div.v-select__selection--disabled) {
    color: rgba(0, 0, 0, 0.87) !important;
}
</style>
