<template>
    <div>
        <object-card-row
            :content="data.number"
            title="Номер:"
        />
        <object-card-row
            :content="data.customerFullname"
            title="Заказчик:"
        />
        <object-card-row
            :content="data.performerFullname"
            title="Исполнитель:"
        />
        <object-card-row
            :content="data.signing_date"
            title="Дата подписания:"
        />
        <object-card-row
            :content="data.completion_date"
            title="Дата завершения:"
        />
    </div>
</template>


<script>
import ObjectCardRow from "../ObjectCardOld/ObjectCardRow.vue";

export default {
    name: "ObjectCardContractDescription",
    components: {ObjectCardRow},
    props: {
        objectId: {
            required: true
        },
        systemTypeId: {
            required: true,
        },
        contractId: {
            required: true
        }
    },
    data: function () {
        return {
            data: null
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            axios.get('/api/objectcard/contract/' + this.objectId + '/' + this.systemTypeId + '/' + this.contractId)
                .then(response => {
                    this.data = response.data
                })
                .catch(error => {
                    this.showError(error)
                }).finally(() => {
                this.loading = false
            });
        },
    }
}
</script>


<style scoped>

</style>