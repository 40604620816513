// import moment from "moment";

export function objToArray(obj) {
    let array = Object.values(obj)
    for (let i = 0; i <= array.length - 1; i++) {
        if (typeof array[i].children != 'undefined') {
            array[i].children = objToArray(array[i].children)
        }
    }
    return array
}

//проверяем объект на пустоту
export function isEmpty(obj) {
    for (let key in obj) {
        // если тело цикла начнет выполняться - значит в объекте есть свойства
        return false;
    }
    return true;
}

export function DateFormattedMomentjs(date) {

    if (date) {
        return new Date(date).toLocaleDateString('ru-RU',
            {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            })
    } else {
        return ""
    }
}

export function DateFormatteWithTime(date) {
    if (date) {
        return new Date(date).toLocaleDateString('ru-RU',
            {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            })
    } else {
        return ""
    }
}


function returnMonth(month) {
    return (month + 1).toString().length < 2 ? '0' + (month + 1) : (month + 1)
}

function returnDate(date) {
    return date.toString().length < 2 ? '0' + date : date
}

function addNull(num) {
    return num.toString().length < 2 ? "0" + num : num
}

function returnHours(hours) {
    return addNull(hours)
}

function returnMinutes(minutes) {
    return addNull(minutes)
}

function returnSeconds(seconds) {
    return addNull(seconds)
}


export function shortDateWithTime(dateString) {
    //YYYY-MM-DD HH:mm:ss
    if (dateString) {
        let date = new Date(dateString)

        return date.getFullYear() + "-" +
            returnMonth(date.getMonth()) + "-" +
            returnDate(date.getDate()) + " " +
            returnHours(date.getHours()) + ":" +
            returnMinutes(date.getMinutes())
        // +":"+ returnSeconds(date.getSeconds())
    } else {
        return null
    }
}

export function shortDateWithTimeAndT(dateString) {
    //YYYY-MM-DDTHH:mm:ss
    //2021-03-16T07:05

    if (dateString) {
        let date = new Date(dateString)
        return date.getFullYear() + "-" +
            returnMonth(date.getMonth()) + "-" +
            returnDate(date.getDate()) + "T" +
            returnHours(date.getHours()) + ":" +
            returnMinutes(date.getMinutes())
        // +":"+ returnSeconds(date.getSeconds())
    } else {
        return null
    }
}


export function shortTimeWithSeconds(dateString) {
    //HH:mm:ss
    if (dateString) {
        let date = new Date(dateString)
        return returnHours(date.getHours()) + ":" +
            returnMinutes(date.getMinutes()) + ":" +
            returnSeconds(date.getSeconds())
    } else {
        return null
    }
}

export function shortTime(dateString) {
    //HH:mm
    if (dateString) {
        let date = new Date(dateString)
        return returnHours(date.getHours()) + ":" +
            returnMinutes(date.getMinutes())
    } else {
        return null
    }
}

export function shortDateYearFirst(dateString) {
    //YYYY-MM-DD
    if (dateString) {
        let date = new Date(dateString)
        return date.getFullYear() + '-' + returnMonth(date.getMonth()) + '-' + returnDate(date.getDate())
    } else {
        return ''
    }
}


export function shortDateYearFirstNow() {
    let date = new Date()
    return date.getFullYear() + '-' + returnMonth(date.getMonth()) + '-' + returnDate(date.getDate())
}


export function shortDate(dateString) {
    //DD.MM.YYYY
    if (dateString) {
        let date = new Date(dateString)
        return returnDate(date.getDate()) + '.' + returnMonth(date.getMonth()) + '.' + date.getFullYear()
    } else {
        return null
    }
    //
    // moment.locale("ru");
    // return  date ? moment(date).format('DD.MM.YYYY') : ''
}


export function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
}