<template>
    <div v-if="canCreate"
         class=" overflow-y-hidden overflow-x-hidden">
        <v-form
            ref="Form"
            v-model="formValid"
            class="pt-3"
        >
            <v-row class="px-3">
                <base-resize-panel :mobile="mobile">
                    <template v-slot:left>
                        <base-left-menu
                            v-model="activeMenuPoint"
                            :selectable="canMultipleTasksCreate"
                            :onlyOneItem="!canMultipleTasksCreate"
                            :updateAll="updateAll"
                            :with-search="true"
                        />
                    </template>
                    <template v-slot:right>
                        <v-card
                            :height="height"
                            :loading="loading"
                            class="mx-auto  overflow-y-auto"
                        >
                            <v-card-title>
                                Создать задачу
                            </v-card-title>

                            <v-card-text>
                                <v-row v-if="UserCan.AllTasks.Create" class="px-4">
                                    <v-col class="px-1 py-0" cols="12" lg="4">
                                        <v-switch
                                            v-model="forServiceEngineer"
                                            label="Для ответственного инженера">
                                        </v-switch>
                                    </v-col>
                                    <v-col class="px-1 py-0" cols="12" lg="8">
                                        <base-combobox-with-request
                                            v-model="item.lead_engineer"
                                            :clearable="true"
                                            :disabled="leadEngineerDisabled"
                                            :required="leadEngineerRequred"
                                            :rules="leadEngineerRules"
                                            item-text="short_name"
                                            item-value="id"
                                            label="Исполнитель"
                                            title="Исполнитель"
                                            url="user/simpleList"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-if="!UserCan.AllTasks.Create && UserCan.DepTasks.Create" class="px-4">
                                    <v-col class="px-1 py-0" cols="12" lg="4">
                                        <v-switch
                                            v-model="forServiceEngineer"
                                            label="Для ответственного инженера">
                                        </v-switch>
                                    </v-col>
                                    <v-col class="px-1 py-0" cols="12" lg="8">
                                        <base-combobox-with-request
                                            v-model="item.lead_engineer"
                                            :clearable="true"
                                            :disabled="leadEngineerDisabled"
                                            :required="leadEngineerRequred"
                                            :rules="leadEngineerRules"
                                            item-text="short_name"
                                            item-value="id"
                                            label="Исполнитель"
                                            title="Исполнитель"
                                            url="user/simpleListdeponly"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col class="px-1 py-0" cols="12">
                                        <base-combobox-with-request
                                            v-model="item.systemType"
                                            :clearable="true"
                                            :filterData="activeMenuPoint"
                                            :multiple="true"
                                            item-text="name"
                                            item-value="id"
                                            label="Тип систем"
                                            title="Тип систем"
                                            url="newtask/getsystemtype"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col class="px-1 py-0" cols="12">
                                        <base-combobox-with-request
                                            v-model="item.taskType"
                                            :clearable="true"
                                            :required="true"
                                            :rules="requiredRules"
                                            item-disadled="disabled"
                                            item-text="name"
                                            item-value="id"
                                            label="Тип задачи"
                                            title="Тип задачи"
                                            url="tasktype/index"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col class="px-1 py-0" cols="12">
                                        <base-person-combobox
                                            v-model="item.contactPerson"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col class="px-1 py-0" cols="12">
                                        <base-combobox-with-request
                                            v-model="item.taskPriority"
                                            :clearable="true"
                                            item-text="name"
                                            item-value="id"
                                            label="Приоритет"
                                            title="Приоритет"
                                            url="taskpriority/index"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col class="px-1 py-0" cols="12">
                                        <base-date-time-picker
                                            v-model="item.deadLine"
                                            :datePickerProps="datePickerProps"
                                            :timePickerProps="timePickerProps"
                                            clearText="Очистить"
                                            label="Выполнить до"
                                            okText="Применить"
                                        >
                                            <template v-slot:dateIcon>
                                                <v-icon>
                                                    {{ $store.state.Icon.svgMdiCalendarMonthOutline }}
                                                </v-icon>
                                            </template>
                                            <template v-slot:timeIcon>
                                                <v-icon>
                                                    {{ $store.state.Icon.svgMdiTimer }}
                                                </v-icon>
                                            </template>
                                        </base-date-time-picker>
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col class="px-1 py-0" cols="12">
                                        <v-textarea
                                            v-model="item.description"
                                            auto-grow
                                            label="Описание задачи"
                                            no-resize
                                            rows="3"
                                            title="Описание задачи"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col class="px-1 py-0" cols="12">
                                        <base-combobox-with-request
                                            v-model="item.plannedWorks"
                                            :clearable="true"
                                            :multiple="true"
                                            item-text="name"
                                            item-value="id"
                                            label="Плановые работы"
                                            title="Плановые работы"
                                            url="plannedworks/index"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="px-4">
                                    <v-col class="px-1 py-0" cols="12">
                                        <base-combobox-with-request
                                            v-model="item.watchers"
                                            :clearable="true"
                                            :multiple="true"
                                            item-text="short_name"
                                            item-value="id"
                                            label="Наблюдатели"
                                            title="Наблюдатели"
                                            url="user/simpleList"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn
                                    v-if="UserCan.UsersTasks.Create || UserCan.DepTasks.Create || UserCan.AllTasks.Create"
                                    :disabled="!formValid || !leftMenuValid" color="blue" text
                                    @click="saveAsSchedule">Сохранить как расписание
                                </v-btn>

                                <v-btn
                                    v-if="UserCan.UsersTasks.Create || UserCan.DepTasks.Create || UserCan.AllTasks.Create"
                                    :disabled="!formValid || !leftMenuValid" color="blue" text
                                    @click="save">Создать
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </template>
                </base-resize-panel>
            </v-row>
        </v-form>

        <SchedulingNewTasks
            :preparedData="prepareData()"
            :value="openSaveAsScheduleDialog"
            v-on:close="openSaveAsScheduleDialog=false"
        />

    </div>
</template>

<script>
import BaseLeftMenu from "../BaseComponents/BaseLeftMenu";
import BaseComboboxWithRequest from "../BaseComponents/BaseComboboxWithRequest";
import BasePersonCombobox from "../BaseComponents/BasePersonCombobox";
import BaseInfoSnackbar from "../BaseComponents/BaseInfoSnackbar";
import BaseDateTimePicker from "../BaseComponents/BaseDateTimePicker";
import BaseResizePanel from "../BaseComponents/BaseResizePanel";
import SchedulingNewTasks from "./SchedulingNewTasks";
import requiredRules from "../../Rules/requiredRules";

export default {
    name: "NewTask",
    components: {
        BasePersonCombobox,
        BaseLeftMenu,
        BaseComboboxWithRequest,
        BaseInfoSnackbar,
        BaseDateTimePicker,
        BaseResizePanel,
        SchedulingNewTasks
    },
    data: function () {
        return {
            activeMenuPoint: [],
            updateAll: null,
            formValid: true, //Переменная для контроля валидности данных формы
            leftMenuValid: false,
            info: false,
            datePickerProps: {
                'show-current': true,
                'no-title': true,
            },
            timePickerProps: {
                format: '24hr',
                'no-title': true,
            },

            forServiceEngineer: true,
            leadEngineerDisabled: true,
            leadEngineerRequred: false,
            leadEngineerRules: [],
            item: {
                lead_engineer: null,
                systemType: null,
                taskType: null,
                contactPerson: null,
                taskPriority: null,
                deadLine: null,
                plannedWorks: null,
                watchers: null,
                description: null,
            },
            loading: false,
            openSaveAsScheduleDialog: false
        }
    },

    computed: {
        height: function () {
            if (this.$vuetify.breakpoint.width < 992) {
                return "100%"
            } else {
                return this.$vuetify.breakpoint.height - 95
            }
        },
        mobile: function () {
            return this.height === "100%"
        },
        requiredRules() {
            return requiredRules
        },
        canCreate: function () {
            return this.$store.getters['UserRights/canAllTasksCreate'] ||
                this.$store.getters['UserRights/canDepTasksCreate'] ||
                this.$store.getters['UserRights/canUsersTasksCreate']
        },
        canMultipleTasksCreate: function () {
            return this.$store.getters['UserRights/canMultipleTasksCreate']
        }
    },
    watch: {
        activeMenuPoint: function (val) {
            this.leftMenuValid = val.length > 0;
        },
        forServiceEngineer: function (val) {
            if (val) {
                this.leadEngineerDisabled = true
                this.leadEngineerRequred = false
                this.leadEngineerRules = []
            } else {
                this.leadEngineerDisabled = false
                this.leadEngineerRequred = true
                this.leadEngineerRules = this.requiredRules
            }
        },
    },
    methods: {
        getLastId(item) {
            if (item.hasOwnProperty('children')) {
                return this.getLastId(item.children[0])
            }
            return item.id
        },

        getSystemsIdFromActiveMenuPoint() {
            return this.activeMenuPoint && this.activeMenuPoint.length ? _.map(this.activeMenuPoint, this.getLastId) : null
        },


        prepareData() {
            return {
                systemTypes: this.item.systemType && this.item.systemType.length ? _.map(this.item.systemType, 'id') : null,
                taskTypeId: this.item.taskType ? this.item.taskType.id : null,
                contactPersonId: this.item.contactPerson ? this.item.contactPerson.id : null,
                taskPriorityId: this.item.taskPriority ? this.item.taskPriority.id : null,
                deadLine: this.item.deadLine,
                description: this.item.description,
                watchersId: this.item.watchers && this.item.watchers.length ? _.map(this.item.watchers, 'id') : null,
                systemsId: this.getSystemsIdFromActiveMenuPoint(),
                forServiceEngineer: this.forServiceEngineer,
                leadEngineerId: this.item.lead_engineer ? this.item.lead_engineer.id : null,
                plannedWorksId: this.item.plannedWorks && this.item.plannedWorks.length ? _.map(this.item.plannedWorks, 'id') : null,
            }

        },


        async getConfirmQuestion(systemsId, systemTypes) {
            const response = await axios.post('/api/newtask/confirm_question',
                {
                    systemsId,
                    systemTypes
                }
            )
            return response.data
        },

        async save() {
            if (this.UserCan.UsersTasks.Create || this.UserCan.DepTasks.Create || this.UserCan.AllTasks.Create) {
                const preparedData = this.prepareData()
                const confirmQuestion = await this.getConfirmQuestion(preparedData.systemsId, preparedData.systemTypes)
                if (confirm(confirmQuestion)) {
                    this.loading = true
                    axios.post('/api/newtask/new', preparedData)
                        .then(response => {
                            this.$emit('return')
                            this.showInfo('Задачи успешно созданы')
                        })
                        //если есть ошибки, то выводим текст ошибки
                        .catch(error => {
                            this.showError(error)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                    ;
                }
            }
        },


        saveAsSchedule() {
            this.openSaveAsScheduleDialog = true
        }
    },
}
</script>

<style scoped>

</style>
