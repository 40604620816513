<template>
    <div>
        <v-menu
            v-if="canOpeningTask"
            v-model="showRightClickMenu"
            :position-x="rightClickMenu_x"
            :position-y="rightClickMenu_y"
            absolute
            offset-y
        >
            <v-list>
                <v-list-item @click.p.prevent="rightClick">
                    <v-list-item-title>Открыть задачу в новом окне</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-data-table
            v-if="headers"
            v-model="selected"
            :footer-props="{
                showCurrentPage: true,
                itemsPerPageOptions: [10,15,20,30,-1],
                showFirstLastPage: true,
                firstIcon: $store.state.Icon.svgMdiArrowCollapseLeft,
                lastIcon: $store.state.Icon.svgMdiArrowCollapseRight
            }"
            :headers="headers"
            :items="dataList"
            :items-per-page="itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="992"
            :search="searchString"
            :show-select="showSelect"
            dense
            item-key="id"
            loading-text="Загрузка данных... Пожалуйста подождите"
            locale="ru-RU"
            multi-sort
        >
            <template v-slot:item="{ item, index, headers, isMobile, isSelected, select }">
                <tr
                    v-if="!isMobile"
                    :class="rowClass"
                    :data-id="item.id"
                    :draggable="canDragTask"
                    v-on:click="openTask(item)"
                    v-on:contextmenu="openRightClickMenu($event, item)"
                    v-on:drag="onRowDrag($event, item)"
                    v-on:dragend="onRowDragend($event, item)"
                    v-on:dragstart="onRowDragstart($event, item)"

                >
                    <td v-for="header in headers">
                        <template v-if="header.value === 'data-table-select'">
                            <v-simple-checkbox
                                :value="isSelected"
                                @input="select($event)"
                            ></v-simple-checkbox>
                        </template>

                        <template v-if="header.value === 'task_priority_id'">
                            <priority-icon :id="item.task_priority_id"/>
                        </template>
                        <template v-else-if="header.value === 'task_status_id'">
                            <status-icon :id="item.task_status_id"/>
                        </template>
                        <template v-else-if="header.value === 'new_defects'">
                            <v-icon
                                v-if="item.new_defects"
                                color="red"
                            >
                                {{ $store.state.Icon.svgMdiDubleExclamation }}
                            </v-icon>
                        </template>
                        <template v-else>
                            {{ item[header.value] }}
                        </template>
                    </td>
                </tr>

                <tr
                    v-if="isMobile"
                    :class="rowClass"
                    :data-id="item.id"
                    :draggable="canDragTask"
                    class="mobile-row v-data-table__mobile-table-row"
                    v-on:click="openTask(item)"
                    v-on:contextmenu="openRightClickMenu($event, item)"
                    v-on:drag="onRowDrag($event, item)"
                    v-on:dragend="onRowDragend($event, item)"
                    v-on:dragstart="onRowDragstart($event, item)"
                >
                    <td v-if="checkFirstLineMobileView(headers)" class="v-data-table__mobile-row"
                        style="justify-content: flex-start; gap: 10px">
                        <div v-if="checkHeadersValue(headers, 'id')" class="">
                            {{ item.id }}
                        </div>
                        <div v-if="checkHeadersValue(headers, 'task_priority_id')" class="">
                            <priority-icon :id="item.task_priority_id"/>
                        </div>
                        <div v-if="checkHeadersValue(headers, 'task_status_id')" class="">
                            <status-icon :id="item.task_status_id"/>
                        </div>
                    </td>

                    <template v-for="header in headers">
                        <td
                            v-if="checkRowValue(header)"
                            class="v-data-table__mobile-row"
                        >
                            <div class="v-data-table__mobile-row__cell">{{ item[header.value] }}</div>
                        </td>
                    </template>
                </tr>
            </template>

            <template v-if="showSelect" v-slot:header.data-table-select="{ on, props }">
                <task-list-item-selection
                    v-model="selected"
                    :data-list="dataList"
                    v-on:need-update="getTaskList()"
                />
            </template>
        </v-data-table>

        <router-view
            v-if="canOpeningTask"
        ></router-view>

        <div v-if="canDragTask" id="draggable-item">
            <task-list-draggable-item
                :item="draggableItem"
            />
        </div>
    </div>
</template>

<script>
import {VBtn, VDataTable, VIcon, VList, VListItem, VListItemTitle, VMenu} from 'vuetify/lib'
import {getPreparedHeaders, prepareFieldsData} from "../../mixins/loadTableHeaders";
import PriorityIcon from "../../BaseComponents/PriorityIcon";
import StatusIcon from "../../BaseComponents/StatusIcon";
import TaskListDraggableItem from "./TaskListDraggableItem";
import TaskListItemSelection from "./TaskListItemSelection.vue";

export default {
    name: "FilteredTaskList",
    components: {
        TaskListItemSelection,
        PriorityIcon,
        StatusIcon,
        VMenu,
        VList,
        VListItem,
        VListItemTitle,
        VDataTable,
        VBtn,
        VIcon,
        TaskListDraggableItem
    },
    props: {
        filterid: {
            default: 0
        },
        objectTreeOptions: {},
        needUpdate: { //Признак того, что список нужно обновить
            type: Boolean,
            default: false
        },
        needUpdateData: { //Признак того, что список нужно обновить "тихо" (не удаляя данные перед этим)
            type: Boolean,
            default: false
        },
        searchString: { //Строка поиска
            default: null
        },
        itemsPerPage: {
            type: Number,
            default: 15
        },
        showSelect: { //Показывать чекбоксы для задач
            type: Boolean,
            default: true
        },
        canOpeningTask: { //Признак того, что задачи можно открыавать
            type: Boolean,
            default: true
        },
        canDragTask: { //Признак того, что задачи можно перетаскивать
            type: Boolean,
            default: false
        },
        routePrefix: {
            type: String,
            default: ''
        },
        type: {  //Название для хранения настроек заголовков
            requred: true,
            type: String
        },
        getTaskListRoute: {
            type: String,
            default: '/api/tasklist'
        },
        month: {
            default: null
        },
        year: {
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            headers: null,
            dataList: [],
            selected: [],
            showRightClickMenu: false,
            rightClickMenu_x: 0,
            rightClickMenu_y: 0,
            draggableItem: null,
        }
    },
    computed: {
        rowClass() {
            return {
                'taskListRow': true,
                'can-opening': this.canOpeningTask,
                'can-drag': this.canDragTask,
            }
        }
    },
    async mounted() {
        await this.getHeaders()
        this.getTaskList()
    },
    beforeUpdate() {

    },
    watch: {
        needUpdate: function (newVal) {
            if (newVal) {
                this.update()
            }
        },

        needUpdateData: function (newVal) {
            if (newVal) {
                this.updateData()
            }
        },

        $route(to, from) {
            if (to.name === "FilteredTaskList") {
                this.updateData()
            }
        },

        filterid: {
            handler: function () {
                this.ressetList()
                this.getTaskList()
            },
            deep: true,
        },
        objectTreeOptions: {
            handler: function () {
                this.ressetList()
                this.getTaskList()
            },
            deep: true,
        },
        month: {
            handler: function () {
                this.ressetList()
                this.getTaskList()
            }
        },
    },

    methods: {
        ressetList() {
            if (this.needQuietlyUpdate) {
                this.dataList = []
            }
        },

        async getHeaders() {
            try {
                this.headers = await getPreparedHeaders(this.type)
            } catch (error) {
                this.showError(error)
            }
        },
        update() {
            this.getHeaders().then(() => {
                this.getTaskList()
                this.$emit('update:needUpdate', false)
            })
        },

        updateData() {
            this.getTaskList()
            this.$emit('update:needUpdateData', false)
        },

        getTaskList() {
            this.loading = true
            const fields = prepareFieldsData(this.headers, this.type)
            let params = fields ? '?' + fields : ''
            let data = {
                filterId: this.filterid ? this.filterid : null,
                options: this.objectTreeOptions ? this.objectTreeOptions : null,
                month: this.month ? this.month : null,
                year: this.year ? this.year : null
            }
            axios.post(this.getTaskListRoute + params, data)
                .then(response => {
                    this.dataList = response.data
                })
                .catch(error => {
                    this.showError(error)
                })
                .finally(() => {
                    this.loading = false
                })
            ;
        },
        openTask(item) {
            if (this.canOpeningTask && item && item.id) {
                this.$router.push({path: `/${this.routePrefix}/${this.filterid}/${item.id}`})
            }
        },
        openRightClickMenu(e, item) {
            if (this.canOpeningTask) {
                e.preventDefault()
                this.rightClickTaskId = item.id
                this.showRightClickMenu = false
                this.rightClickMenu_x = e.clientX
                this.rightClickMenu_y = e.clientY
                this.$nextTick(() => {
                    this.showRightClickMenu = true
                })
            }
        },
        rightClick() {
            let routeData = this.$router.resolve({name: 'task', params: {taskid: this.rightClickTaskId}});
            window.open(routeData.href, '_blank');
            this.rightClickTaskId = null
        },

        onRowDragstart(e, item) {
            this.draggableItem = item
            const draggableConteiner = document.getElementById('draggable-item');
            document.body.appendChild(draggableConteiner);
            e.dataTransfer.setDragImage(draggableConteiner, -10, -10);
            this.$emit('dragstart', {'DragEvent': e, item})
        },
        onRowDragend(e, item) {
            this.$emit('dragend', {'DragEvent': e, item})
        },
        onRowDrag(e, item) {
            this.$emit('drag', {'DragEvent': e, item})
        },
        checkFirstLineMobileView(headers) {
            const id = headers.find(o => o.value === 'id');
            const priority = headers.find(o => o.value === 'task_priority_id');
            const status = headers.find(o => o.value === 'task_status_id');
            return Boolean(id || priority || status)
        },
        checkHeadersValue(headers, value) {
            return Boolean(headers.find(o => o.value === value))
        },
        checkRowValue(header) {
            const exceptions = ['task_priority_id', 'task_status_id', 'id', 'data-table-select']
            return !exceptions.includes(header.value);
        },


    }
}
</script>

<style lang="scss">
.v-data-table {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

tbody {
    tr.can-opening:hover {
        cursor: pointer;
    }
}

.v-data-footer {
    height: 50px;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: 30px;
}

tbody {
    tr.can-drag:hover {
        cursor: move;
    }
}

#draggable-item {
    width: max-content;
    position: absolute;
    top: -150px;
}


</style>
