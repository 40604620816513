<template>
    <v-currency-field
        v-model="model"
        :clearable="clearable"
        :disabled="disabled"
        :hide-details="hideDetails"
        :label="label"
        :required="required"
        :rules="rules"
        no-resize
        suffix="руб."
        value-as-integer
        v-on:blur="$emit('blur')"
    >
        <template v-slot:label>
            {{ label }}<sup v-if="required">*</sup>
        </template>
    </v-currency-field>
</template>

<script>
import MainFieldsType from "./MainFieldsType";

export default {
    name: "Money",
    extends: MainFieldsType,

}
</script>

<style scoped>

</style>
