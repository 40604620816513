import Vue from 'vue'
import Vuex from 'vuex'
import UserRights from './modules/UserRights'
import UserData from './modules/UserData'
import UserStatus from "./modules/UserStatus";
import TaskData from "./modules/TaskData";
import Message from "./modules/Message";
import Icon from "./modules/Icon";
import Navbar from "./modules/Navbar";
import TaskStatusSettings from "./modules/TaskStatusSettings";
import DutyListStore from "./modules/DutyListStore";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        UserRights,
        UserData,
        UserStatus,
        TaskData,
        Message,
        Icon,
        Navbar,
        TaskStatusSettings,
        DutyListStore
    }
})
