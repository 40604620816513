<template>
    <base-modal
        v-model="openDialog"
        max-width="1000"
    >
        <v-card v-if="openDialog">
            <v-container>
                <v-card-title>
                    <span class="headline">{{ title }}</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="editedItem.description"
                                :rules="requiredRules"
                                auto-grow
                                hide-details
                                label="Описание дефекта"
                                rows="2"
                                validate-on-blur
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="editedItem.necessary_work"
                                auto-grow
                                hide-details
                                label="Необходимые работы"
                                rows="2"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="editedItem.fix_to"
                                class="pt-5"
                                hide-details
                                label="Устранить до"
                                type="date"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!editedItem.description" color="blue" text v-on:click="save()">Сохранить</v-btn>
                    <v-btn color="blue" text v-on:click="close()">Закрыть</v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </base-modal>
</template>

<script>
import BaseModal from "../BaseComponents/BaseModal.vue";
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";
import requiredRules from "../../Rules/requiredRules";

export default {
    name: "SystemDefectForm",
    components: {
        BaseModal
    },
    props: {
        value: {
            default: SystemDefectDefaultItem(),
        },
        taskId: {
            type: Number,
            nullable: true,
            default: null
        },
        systemId: {
            type: Number,
            nullable: true,
            default: null
        }
    },
    data: function () {
        return {
            openDialog: false,
            editedItem: SystemDefectDefaultItem()
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if (val?.id) {
                    this.editedItem = Object.assign({}, val)
                } else {
                    this.editedItem = Object.assign({}, SystemDefectDefaultItem())
                }

                if (val) {
                    this.openDialog = true
                }
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        title() {
            return this.editedItem.id ? 'Изменить дефект системы' : 'Добавить дефект системы'
        },
        requiredRules() {
            return requiredRules
        },
    },
    methods: {
        save() {
            if (this.canSave()) {
                let axiosRequest
                if (this.editedItem.id) {
                    axiosRequest = axios.put(this.getPath(), this.editedItem)
                } else {
                    axiosRequest = axios.post(this.getPath(), this.editedItem)
                }

                axiosRequest.then(() => {
                    this.$emit('need-update')
                    this.close()
                })
                    .catch(error => {
                        this.showError(error)
                    });
            }
        },

        canSave() {
            return !!(this.editedItem.description && !this.editedItem.fixed_at && !this.editedItem.fixed_by)
        },

        getMethod() {
            if (this.editedItem.id) {
                return 'put'
            }
            return 'post'
        },

        getPath() {
            if (this.editedItem.id) {
                if (this.taskId) {
                    return '/api/system-defect/task/' + this.taskId + '/defect/' + this.editedItem.id
                }

                return '/api/system-defect/system/' + this.systemId + '/defect/' + this.editedItem.id
            } else {
                if (this.taskId) {
                    return '/api/system-defect/task/' + this.taskId
                }

                return '/api/system-defect/system/' + this.systemId
            }
        },

        close() {
            this.openDialog = false
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>
