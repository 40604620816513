<template>
    <entity-form-dialog
        :fullscreen="fullscreen"
        :max-width="maxWidth"
        :value="dialog"
    >
        <v-card
            :loading="loading"
        >
            <v-card-title>
                <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-card-text class="entity-form-text" v-bind:style="entityFormTextStyle">
                <v-container class="set-height">
                    <v-form
                        ref="form"
                        v-model="valid"
                        :lazy-validation="lazyValidation"
                    >
                        <slot name="main-form"></slot>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <slot name="before-action"></slot>
                <v-spacer></v-spacer>
                <slot name="append-action-buttons"></slot>
                <v-btn color="blue darken-1" text @click="$emit('cancel' )">Отмена</v-btn>
                <v-btn v-if="!readonly" :disabled="saveDisabled" :loading="inSaving" color="blue darken-1" text
                       @click="$emit('save' )">
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
    </entity-form-dialog>
</template>

<script>
import EntityFormDialog from "./EntityFormDialog";
import {VBtn, VCard, VCardActions, VCardText, VCardTitle, VContainer, VSpacer} from 'vuetify/lib'

export default {
    name: "EntityForm",
    components: {
        EntityFormDialog,
        VCard, VCardTitle, VCardText, VCardActions, VContainer, VSpacer, VBtn
    },
    props: {
        title: {
            required: true
        },
        maxWidth: {
            default: '940'
        },
        canSave: {
            required: true,
        },
        loading: {
            default: false,
            required: false
        },
        lazyValidation: {
            default: true,
            required: false
        },
        readonly: {
            default: false,
            required: false
        },
        fullscreenWidth: {
            default: 500,
            type: Number
        },
        inSaving: {
            default: false,
            type: Boolean
        }
    },
    data: function () {
        return {
            dialog: false,
            valid: true,
            // fullscreenWidth: 500
        }
    },
    mounted() {
        this.dialog = true
    },
    computed: {
        saveDisabled: function () {
            return !this.canSave || !this.valid || this.inSaving
        },

        entityFormTextStyle() {
            return {
                height: this.fullscreen ? (this.$vuetify.breakpoint.height - 110) + 'px' : '100%',
                overflow: 'auto',
                'overflow-x': 'hidden',
            }
        },

        fullscreen: function () {
            return this.$vuetify.breakpoint.width < this.fullscreenWidth
        }
    },

}
</script>

<style scoped>
.set-height {
    min-height: 662px;
}

@media (max-width: 750px) {
    .entity-form-text {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}


</style>
