<template>
    <svg
        :height="height"
        :width="width"
        clip-rule="evenodd"
        fill-rule="evenodd"
        image-rendering="optimizeQuality"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
        viewBox="0 0 30 30"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg">
    <title
        :id="iconName"
        lang="ru"
    >{{iconName}}</title>
        <g transform="scale(.09404)">
        <path :fill="iconColor"
              d="M159.5 0C247.59 0 319 71.41 319 159.5S247.59 319 159.5 319 0 247.59 0 159.5 71.41 0 159.5 0zm0 19.52c77.31 0 139.99 62.67 139.99 139.99 0 77.31-62.67 139.99-139.99 139.99-77.31 0-139.99-62.67-139.99-139.99 0-77.31 62.67-139.99 139.99-139.99z"/>
            <path :fill="iconColor"
                  d="M159.5 300.75c-30.66-.32-62.93-9.79-96.23-25.04-1.26-22.95-4.32-35.93.62-44.7 4.4-7.8 11.76-11.78 20.03-14.64 14.32-4.96 25.5-8.03 40.31-19.09l21.09 66.9 8.57-23.07c-10.15-13.97-11.85-28.77 5.6-28.67 17.45-.1 15.75 14.7 5.6 28.67l8.57 23.07 21.09-66.9c14.81 11.06 26 14.13 40.31 19.09 8.27 2.87 15.64 6.84 20.03 14.64 4.94 8.77 1.88 21.75.62 44.7-33.29 15.25-65.57 24.73-96.23 25.04z"/>
            <path :fill="iconColor"
                  d="M159.5 203.53c-23.06-2.99-36.35-16.67-40.95-39.87-2.86-1.78-7.16-8.84-9.22-16.94-2.74-10.81-1.23-16.35 4.28-15.03-5.2-20.08-1.99-36.55 10.96-48.85 7.91-7.51 18.96-17.41 34.93-17.72 15.97.31 27.35 9.88 34.93 17.72 5.67 1.11 9.8 5.64 11.95 11.66 3.36 9.45 1.98 24.96-.99 37.18 5.5-1.32 7.02 4.22 4.28 15.03-2.06 8.1-6.36 15.16-9.22 16.94-4.6 23.2-17.89 36.89-40.95 39.87z"/>
    </g>
</svg>
</template>

<script>
export default {
    name: "ProfileIcon",
    props: {
        iconName: {
            type: String,
            default: 'Профиль'
        },
        iconColor: {
            type: String,
            default: '#285473'
        },
        width: {
            type: [Number, String],
            default: 30
        },
        height: {
            type: [Number, String],
            default: 30
        },
    }
}
</script>

<style scoped>

</style>
