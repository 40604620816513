<template>
    <base-modal
        :value="dialog"
        max-width="400"
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ title }}
            </v-card-title>
            <v-card-text>
                <template v-if="Array.isArray(question)">
                    <div v-for="(string) in question">
                        {{ string }}
                    </div>
                </template>
                <template v-else>
                    {{ question }}
                </template>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="_cancel">{{ cancelButton }}</v-btn>
                <v-btn color="blue darken-1" text @click="_confirm">{{ okButton }}</v-btn>
            </v-card-actions>
        </v-card>
    </base-modal>
</template>

<script>
import BaseModal from "../BaseComponents/BaseModal.vue";

export default {
    name: "ConfirmDialog",
    components: {BaseModal},
    data() {
        return {
            dialog: false,
            title: undefined,
            question: undefined,
            okButton: undefined,
            cancelButton: 'Отмена',

            resolvePromise: undefined,
            rejectPromise: undefined,
        }
    },
    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.question = opts.question
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            this.dialog = true


            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.dialog = false
            this.resolvePromise()
        },

        _cancel() {
            this.dialog = false
            this.rejectPromise()
        },
    }
}
</script>


<style scoped>

</style>
