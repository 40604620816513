<template>
    <div
        class="user-card py-3 px-3"
        style="max-width: 350px"
        v-bind:class="{disabled: userWorkload===0}"
    >
        <progress-bar
            :text="userName+' - '+ userWorkload.toString()+'%'"
            :val="userWorkload"
            size="small"
            text-align="left"
            text-position="top"/>
    </div>
</template>

<script>
import ProgressBar from 'vue-simple-progress'

export default {
    name: "ObjectGroupUserCard",
    components: {
        ProgressBar
    },
    props: {
        userName: {
            required: true
        },
        userWorkload: {
            required: true
        }
    },
}
</script>

<style scoped>
.user-card:not(.disabled):hover {
    cursor: move
}

.user-card.disabled {
    color: #b8b8b8;
}

.user-card :deep(.vue-simple-progress-text) {
    font-size: 15px !important;
}
</style>