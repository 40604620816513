<template>
    <v-col class="px-0 pb-1">
        <FixedOneTimeWorks
            v-if="value.system.contract.settings.one_time_work === 'from_directory'"
            :disabled="disabled"
            :task-id="taskId"
            :value="model"
            @input="$emit('input')"
        />
        <FreeOneTimeWorks
            v-else
            :disabled="disabled"
            :task-id="taskId"
            :value="model"
            @input="$emit('input')"
        />
    </v-col>

</template>

<script>
import FixedOneTimeWorks from "./FixedOneTimeWorks";
import FreeOneTimeWorks from "./FreeOneTimeWorks";

export default {
    name: "TaskOneTimeWorks",
    components: {
        FixedOneTimeWorks,
        FreeOneTimeWorks,
    },
    props: {
        value: {
            default: {}
        },
        taskId: {
            type: Number,
            required: true
        },
    },
    data: function () {
        return {
            model: '',
        }
    },
    computed: {
        disabled() {
            return !!this.$store.getters["TaskData/getDisabled"]
        },
    },
    watch: {
        value: {
            handler: function (val) {
                if (val.system.contract.settings.one_time_work === 'from_directory') {
                    this.model = Object.assign({}, val.fixed_one_time_works)
                } else {
                    this.model = Object.assign({}, val.free_one_time_works)
                }
            },
            deep: true,
            immediate: true
        },
    },
}
</script>

<style scoped>

</style>
