<template>
    <div style="width: 100%">
        <object-card-row
            :content="dateFormatted(data?.created_at)"
            title="Дата создания"
        />
        <object-card-row
            :content="dateFormatted(data?.updated_at)"
            title="Дата завершения"
        />
        <object-card-row
            :content="data?.creatorShortName"
            title="Создатель"
        />
        <object-card-row
            :content="data?.executorShortName"
            title="Исполнитель"
        />

        <object-card-row
            v-if="data?.assistants.length"
            :content="assistantsString(data.assistants)"
            title="Помогал в выполнении"
        />
        <div class="object-card-table-last">

        </div>
        <template v-for="customData in data?.custom_data">
            <object-card-row
                v-if="customData.name"
                :content="customData.data"
                :title="customData.name"
            />
        </template>
        <div class="object-card-table-last">

        </div>

        <object-card-task-system-data
            :settings="data?.result_system_fields_settings"
            :system-data="data?.system_data"
        />

        <object-card-task-system-defect
            :task-id="taskId"
            :existing-defects="data?.existing_defects"
            :fixed-defects="data?.fixed_defects"
            :found-defects="data?.found_defects"
        />

        <!--        <v-row no-gutters>-->
        <!--            <PlannedWorks-->
        <!--                disabled-->
        <!--                v-bind:value="{planned_works: task.planned_works, planned_works_settings: task.planned_works_settings,}"-->
        <!--                :task-id="task.id"-->
        <!--            />-->
        <!--        </v-row>-->
        <v-row no-gutters v-if="data?.media">
            <v-col class="px-0 pt-0 pb-3">
                <base-image-list
                    :attachment="data?.media"
                    disabled
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {DateFormattedMomentjs, shortDate} from "../../../mixins/commonMethod";
import ObjectCardRow from "../../ObjectCardOld/ObjectCardRow.vue";
import BaseImageList from "../../BaseComponents/BaseImageList.vue";
import ObjectCardTaskSystemData from "./ObjectCardTaskSystemData.vue";
import ObjectCardTaskSystemDefect from "./ObjectCardTaskSystemDefect.vue";

export default {
    name: "ObjectCardTask",
    components: {
        ObjectCardTaskSystemDefect,
        ObjectCardTaskSystemData,
        ObjectCardRow, BaseImageList,
    },
    props: {
        objectId: {
            required: true
        },
        systemTypeId: {
            required: true,
        },
        contractId: {
            required: true
        },
        taskId: {
            required: true
        },
    },
    data: function () {
        return {
            data: null
        }
    },
    created() {
        this.getData()
    },
    methods: {

        getData() {
            axios.get('/api/objectcard/task/' + this.objectId + '/' + this.systemTypeId + '/' + this.contractId + '/' + this.taskId)
                .then(response => {
                    this.data = response.data
                })
                .catch(error => {
                    this.showError(error)
                }).finally(() => {
                this.loading = false
            });
        },

        dateFormatted(date) {
            return DateFormattedMomentjs(date)
        },

        dateFormattedShortDate(date) {
            return shortDate(date)
        },

        assistantsString(list) {
            if (list.length) {
                return list.reduce(function (string, currentItem) {
                    return string + currentItem.short_name + " ";
                }, "")
            } else {
                return ""
            }
        },
    }
}
</script>

<style scoped>

</style>
