<template>
    <v-col class="px-0 pb-1">
        <table class="taskMainTable">
            <tbody>
            <tr style="height: 33px">
                <td class="assignedWorksHeaderTableTitle text-left pl-3 pr-3" colspan="5">
                    <v-row class="py-0">
                        <v-col class="py-0" cols="10">Необходимо для выполнения</v-col>
                        <v-col class="py-0 text-right" cols="2">
                            <v-btn
                                :disabled="disabled"
                                icon
                                small
                                @click="openSelectionDialog=true"
                            >
                                <v-icon>{{ $store.state.Icon.svgMdiPlus }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </td>
            </tr>

            <tr>
                <td class="assignedTitle text-center ">Наименование</td>
                <td class="assignedTitle text-center ">Описание</td>
                <td class="assignedTitle text-center  width-10">Кол-во</td>
                <td class="assignedTitle text-center  width-10">Ед. изм.</td>
                <td class="assignedTitle text-center  width-10">Удалить</td>
            </tr>
            <tr v-if="AssignedWorks">
                <td class="assignedTitle" colspan="5">
                    Работы
                </td>
            </tr>
            <tr v-for="(item) in AssignedWorks">
                <td class="TableRow">{{ item.name }}</td>
                <td class="TableRow">{{ item.description }}</td>
                <td class="TableRow text-center width-10">{{ item.quantity }}</td>
                <td class="TableRow text-center width-10">{{ item.unit.name }}</td>
                <td class="TableRow text-center width-10">
                    <v-btn
                        :disabled="disabled"
                        icon
                        x-small
                        @click="deleteItem('works', item.id)"
                    >
                        <v-icon>{{ $store.state.Icon.svgMdiDelete }}</v-icon>
                    </v-btn>
                </td>
            </tr>
            <tr v-if="AssignedMaterials">
                <td class="assignedTitle" colspan="5">
                    Материалы
                </td>
            </tr>
            <tr v-for="(item) in AssignedMaterials">
                <td class="TableRow">{{ item.name }}</td>
                <td class="TableRow">{{ item.description }}</td>
                <td class="TableRow text-center width-10">{{ item.quantity }}</td>
                <td class="TableRow text-center width-10">{{ item.unit.name }}</td>
                <td class="TableRow text-center width-10">
                    <v-btn
                        :disabled="disabled"
                        icon
                        x-small
                        @click="deleteItem('materials', item.id)"
                    >
                        <v-icon>{{ $store.state.Icon.svgMdiDelete }}</v-icon>
                    </v-btn>
                </td>
            </tr>
            </tbody>
        </table>
        <base-modal
            v-model="openSelectionDialog"
            max-width="1000"
        >
            <v-card>
                <v-container>
                    <v-card-title>
                        <span class="headline">Необходимо для выполнения</span>
                    </v-card-title>
                    <v-card-text>
                        <div class="scrol-div">
                            <v-row>
                                <v-col>
                                    <v-combobox
                                        v-model="type"
                                        :disabled="disabled"
                                        :items="typeList"
                                        hide-details
                                        label="Тип заявки"
                                    />
                                    <v-text-field
                                        v-model="name"
                                        :disabled="disabled"
                                        autocomplete="off"
                                        clearable
                                        hide-details
                                        label="Наименование"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="description"
                                        :disabled="!name"
                                        autocomplete="off"
                                        clearable
                                        hide-details
                                        label="Описание"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="quantity"
                                        :disabled="!name"
                                        clearable
                                        hide-details
                                        label="Количество"
                                        type="number"
                                    ></v-text-field>
                                    <base-combobox-with-request
                                        v-model="unit"
                                        :disabled="!name"
                                        :return-object="true"
                                        item-text="description"
                                        item-value="id"
                                        label="Ед.изм"
                                        title="Ед.изм"
                                        url="unit/index"
                                    />

                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue" text v-on:click="save()">Сохранить</v-btn>
                        <v-btn color="blue" text v-on:click="close()">Закрыть</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </base-modal>
    </v-col>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";
import BaseComboboxWithRequest from "../../../BaseComponents/BaseComboboxWithRequest";

export default {
    name: "TaskAssignedWorksAndMaterials",
    components: {
        BaseModal,
        BaseComboboxWithRequest
    },
    props: {
        value: {
            default: {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: Number,
            required: true
        },
    },
    data: function () {
        return {
            AssignedWorks: '',
            AssignedMaterials: '',
            openSelectionDialog: false,
            type: "Материалы",
            typeList: ["Материалы", "Работы"],
            name: null,
            description: null,
            quantity: 1,
            unit: {description: "не установлен"},
        }
    },
    watch: {
        value: {
            handler: function (val) {
                this.AssignedWorks = Object.assign({}, val.assigned_works)
                this.AssignedMaterials = Object.assign({}, val.assigned_materials)
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        save() {
            let payload = {
                "name": this.name,
                "description": this.description,
                "quantity": this.quantity,
                "unit": this.unit,
            }
            let type = this.type === "Материалы" ? "materials" : "works"
            let url = '/api/AssignedWorksAndMaterials/' + type + '/' + this.taskId

            axios.put(url, {'data': payload})
                .then(() => (this.close()))
                .catch(function (error) {
                    console.log(error)
                });
        },
        close() {
            this.type = "Материалы"
            this.name = null
            this.description = null
            this.quantity = 1
            this.unit = {description: "не установлен"}
            this.openSelectionDialog = false
            this.$emit('input')
        },
        deleteItem(itemType, itemId) {
            if (confirm("Вы уверены, что хотите удалить?")) {
                let url = '/api/AssignedWorksAndMaterials/' + itemType + '/' + this.taskId + '/' + itemId
                axios.delete(url)
                    .then(() => (
                        this.$emit('input')
                    ))
                    .catch(function (error) {
                        console.log(error)
                    });
            }

            // console.log("Удаляем разовую работу №", id)
        },
    },
}
</script>

<style scoped>
.assignedWorksHeaderTableTitle {
    width: 20%;
    height: 25px;
    font-size: 16px;
    font-style: italic;
    background-color: #cccccc;
    text-align: center;
    border: 1px solid #285473;
}

td.assignedTitle {
    height: 25px;
    font-style: italic;
    background-color: #cccccc;
    text-align: left;
    border: 1px solid #285473;
    padding-left: 5px;
}
</style>
