export const userRights = {
computed: {
UserCan: function (){
return {
AllCalendar:{
View: this.uCan("AllCalendar-view"),
},
AllObjectMap:{
View: this.uCan("AllObjectMap-view"),
},
AllStatusMonitor:{
View: this.uCan("AllStatusMonitor-view"),
},
AllTasks:{
CancelClosed: this.uCan("AllTasks-cancelClosed"),
ChangeDeadline: this.uCan("AllTasks-changeDeadline"),
ChangePriority: this.uCan("AllTasks-changePriority"),
Complete: this.uCan("AllTasks-complete"),
Create: this.uCan("AllTasks-create"),
Delete: this.uCan("AllTasks-delete"),
Plan: this.uCan("AllTasks-plan"),
Read: this.uCan("AllTasks-read"),
Reassign: this.uCan("AllTasks-reassign"),
SetToCanceled: this.uCan("AllTasks-setToCanceled"),
SetToClosed: this.uCan("AllTasks-setToClosed"),
SetToPaused: this.uCan("AllTasks-setToPaused"),
SetToWork: this.uCan("AllTasks-setToWork"),
Update: this.uCan("AllTasks-update"),
},
AllWarehouse:{
Moving: this.uCan("AllWarehouse-moving"),
MovingConfirm: this.uCan("AllWarehouse-movingConfirm"),
View: this.uCan("AllWarehouse-view"),
Writeoff: this.uCan("AllWarehouse-writeoff"),
WriteoffConfirm: this.uCan("AllWarehouse-writeoffConfirm"),
},
AllWaybill:{
Create: this.uCan("AllWaybill-create"),
Delete: this.uCan("AllWaybill-delete"),
},
Config:{
ContractsCustomData: this.uCan("Config-contractsCustomData"),
CustomersCustomData: this.uCan("Config-customersCustomData"),
DirectoryDefault: this.uCan("Config-directoryDefault"),
ObjectsCustomData: this.uCan("Config-objectsCustomData"),
PerformersCustomData: this.uCan("Config-performersCustomData"),
Plan: this.uCan("Config-plan"),
SystemsCustomData: this.uCan("Config-systemsCustomData"),
SystemsType: this.uCan("Config-systemsType"),
TasksAddOnModule: this.uCan("Config-tasksAddOnModule"),
TasksCustomData: this.uCan("Config-tasksCustomData"),
TasksReportingDocs: this.uCan("Config-tasksReportingDocs"),
TasksType: this.uCan("Config-tasksType"),
},
Contract:{
Create: this.uCan("Contract-create"),
Delete: this.uCan("Contract-delete"),
Read: this.uCan("Contract-read"),
SetStatus: this.uCan("Contract-setStatus"),
Update: this.uCan("Contract-update"),
},
Customer:{
Create: this.uCan("Customer-create"),
Delete: this.uCan("Customer-delete"),
Read: this.uCan("Customer-read"),
Update: this.uCan("Customer-update"),
},
Department:{
Config: this.uCan("Department-config"),
},
DepCalendar:{
View: this.uCan("DepCalendar-view"),
},
DepObjectMap:{
View: this.uCan("DepObjectMap-view"),
},
DepStatusMonitor:{
View: this.uCan("DepStatusMonitor-view"),
},
DepTasks:{
CancelClosed: this.uCan("DepTasks-cancelClosed"),
ChangeDeadline: this.uCan("DepTasks-changeDeadline"),
ChangePriority: this.uCan("DepTasks-changePriority"),
Complete: this.uCan("DepTasks-complete"),
Create: this.uCan("DepTasks-create"),
Delete: this.uCan("DepTasks-delete"),
Plan: this.uCan("DepTasks-plan"),
Read: this.uCan("DepTasks-read"),
Reassign: this.uCan("DepTasks-reassign"),
SetToCanceled: this.uCan("DepTasks-setToCanceled"),
SetToClosed: this.uCan("DepTasks-setToClosed"),
SetToPaused: this.uCan("DepTasks-setToPaused"),
SetToWork: this.uCan("DepTasks-setToWork"),
Update: this.uCan("DepTasks-update"),
},
DepWarehouse:{
Moving: this.uCan("DepWarehouse-moving"),
MovingConfirm: this.uCan("DepWarehouse-movingConfirm"),
View: this.uCan("DepWarehouse-view"),
Writeoff: this.uCan("DepWarehouse-writeoff"),
WriteoffConfirm: this.uCan("DepWarehouse-writeoffConfirm"),
},
Directory:{
Edit: this.uCan("Directory-edit"),
Read: this.uCan("Directory-read"),
},
DirectoryItem:{
Edit: this.uCan("DirectoryItem-edit"),
Read: this.uCan("DirectoryItem-read"),
},
Duty:{
Read: this.uCan("Duty-read"),
Update: this.uCan("Duty-update"),
},
Equipment:{
Create: this.uCan("Equipment-create"),
Delete: this.uCan("Equipment-delete"),
Read: this.uCan("Equipment-read"),
Update: this.uCan("Equipment-update"),
},
Gantt:{
View: this.uCan("Gantt-view"),
},
Help:{
View: this.uCan("Help-view"),
},
Hierarchy:{
Update: this.uCan("Hierarchy-update"),
},
MultipleTasks:{
Create: this.uCan("MultipleTasks-create"),
},
Object:{
Create: this.uCan("Object-create"),
Delete: this.uCan("Object-delete"),
Read: this.uCan("Object-read"),
Update: this.uCan("Object-update"),
},
ObjectCard:{
View: this.uCan("ObjectCard-view"),
},
ObjectGroup:{
Edit: this.uCan("ObjectGroup-edit"),
Read: this.uCan("ObjectGroup-read"),
},
OnetimeWork:{
Create: this.uCan("OnetimeWork-create"),
Delete: this.uCan("OnetimeWork-delete"),
Read: this.uCan("OnetimeWork-read"),
Update: this.uCan("OnetimeWork-update"),
},
Performer:{
Create: this.uCan("Performer-create"),
Delete: this.uCan("Performer-delete"),
Read: this.uCan("Performer-read"),
Update: this.uCan("Performer-update"),
},
Person:{
Create: this.uCan("Person-create"),
Delete: this.uCan("Person-delete"),
Read: this.uCan("Person-read"),
SetupCustomData: this.uCan("Person-setupCustomData"),
Update: this.uCan("Person-update"),
},
Plan:{
View: this.uCan("Plan-view"),
},
Profile:{
Update: this.uCan("Profile-update"),
},
RegularWork:{
Create: this.uCan("RegularWork-create"),
Delete: this.uCan("RegularWork-delete"),
Read: this.uCan("RegularWork-read"),
Update: this.uCan("RegularWork-update"),
},
Reports:{
Create: this.uCan("Reports-create"),
Delete: this.uCan("Reports-delete"),
GenerateAllTasks: this.uCan("Reports-generateAllTasks"),
GenerateDepTasks: this.uCan("Reports-generateDepTasks"),
GenerateUsersTasks: this.uCan("Reports-generateUsersTasks"),
Read: this.uCan("Reports-read"),
Update: this.uCan("Reports-update"),
},
System:{
Create: this.uCan("System-create"),
Delete: this.uCan("System-delete"),
Read: this.uCan("System-read"),
SetServiceEngineer: this.uCan("System-setServiceEngineer"),
Update: this.uCan("System-update"),
},
TaskTimetable:{
Create: this.uCan("TaskTimetable-create"),
Delete: this.uCan("TaskTimetable-delete"),
Read: this.uCan("TaskTimetable-read"),
Update: this.uCan("TaskTimetable-update"),
},
Transport:{
Config: this.uCan("Transport-config"),
Create: this.uCan("Transport-create"),
Delete: this.uCan("Transport-delete"),
Read: this.uCan("Transport-read"),
Statistics: this.uCan("Transport-statistics-view"),
Update: this.uCan("Transport-update"),
},
User:{
ConfigSystemsAccess: this.uCan("User-configSystemsAccess"),
CopyRights: this.uCan("User-copyRights"),
CopySystemsAccess: this.uCan("User-copySystemsAccess"),
Create: this.uCan("User-create"),
Delete: this.uCan("User-delete"),
Read: this.uCan("User-read"),
ReadRights: this.uCan("User-readRights"),
SetupCustomData: this.uCan("User-setupCustomData"),
Update: this.uCan("User-update"),
UpdateRights: this.uCan("User-updateRights"),
},
UsersCalendar:{
View: this.uCan("UsersCalendar-view"),
},
UsersObjectMap:{
View: this.uCan("UsersObjectMap-view"),
},
UsersStatusMonitor:{
View: this.uCan("UsersStatusMonitor-view"),
},
UsersTasks:{
CancelClosed: this.uCan("UsersTasks-cancelClosed"),
ChangeDeadline: this.uCan("UsersTasks-changeDeadline"),
ChangePriority: this.uCan("UsersTasks-changePriority"),
Complete: this.uCan("UsersTasks-complete"),
Create: this.uCan("UsersTasks-create"),
Delete: this.uCan("UsersTasks-delete"),
Plan: this.uCan("UsersTasks-plan"),
Read: this.uCan("UsersTasks-read"),
Reassign: this.uCan("UsersTasks-reassign"),
SetToCanceled: this.uCan("UsersTasks-setToCanceled"),
SetToClosed: this.uCan("UsersTasks-setToClosed"),
SetToPaused: this.uCan("UsersTasks-setToPaused"),
SetToWork: this.uCan("UsersTasks-setToWork"),
Update: this.uCan("UsersTasks-update"),
},
UsersWarehouse:{
Arrival: this.uCan("UsersWarehouse-arrival"),
Moving: this.uCan("UsersWarehouse-moving"),
MovingConfirm: this.uCan("UsersWarehouse-movingConfirm"),
View: this.uCan("UsersWarehouse-view"),
Writeoff: this.uCan("UsersWarehouse-writeoff"),
WriteoffConfirm: this.uCan("UsersWarehouse-writeoffConfirm"),
},
UserWaybill:{
Create: this.uCan("UserWaybill-create"),
Delete: this.uCan("UserWaybill-delete"),
},
Warehouse:{
DeleteWhichOtherCreated: this.uCan("Warehouse-deleteWhichOtherCreated"),
DeleteWhichUserCreated: this.uCan("Warehouse-deleteWhichUserCreated"),
ProcurementPlanning: this.uCan("Warehouse-ProcurementPlanning"),
Settings: this.uCan("Warehouse-settings"),
},
Waybill:{
Close: this.uCan("Waybill-close"),
},
WorksIntervals:{
Edit: this.uCan("WorksIntervals-edit"),
TurnOn: this.uCan("WorksIntervals-turnOn"),
},
}
}
},
methods: {
uCan(permission) {
return this.$store.state.UserRights.all.includes(permission)
},
}
}