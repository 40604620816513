import { render, staticRenderFns } from "./BasePriorityIcon.vue?vue&type=template&id=236e3aff&scoped=true"
import script from "./BasePriorityIcon.vue?vue&type=script&lang=js"
export * from "./BasePriorityIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "236e3aff",
  null
  
)

export default component.exports