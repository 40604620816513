<template>
    <div
        v-if="UserCan.Reports.GenerateAllTasks || UserCan.Reports.GenerateDepTasks || UserCan.Reports.GenerateUsersTasks"
        class="pb-0 overflow-y-hidden overflow-x-hidden">
        <base-error-snackbar
            v-model="error"
            v-bind:text="errorText"
        />
        <v-form
            ref="Form"
            v-model="formValid"
        >
            <v-row class="px-3">
                <v-col class="p-2" cols="12" lg="4" xl="3">
                    <base-left-menu
                        v-model="activeMenuPoint"
                        :selectable="true"
                        :updateAll="updateAll"
                    />
                </v-col>
                <v-col class="p-2" cols="12" lg="8" xl="9">
                    <v-card
                        :height="height"
                        :loading="loading"
                        class="mx-auto  overflow-y-auto"
                    >
                        <v-card-title>
                            Отчеты
                        </v-card-title>

                        <v-card-text>

                            <v-row class="px-4">
                                <v-col class="px-1 py-0" cols="12">
                                    <base-combobox-with-request
                                        v-model="reportType"
                                        :clearable="true"
                                        :required="true"
                                        :rules="requiredRules"
                                        item-disadled="disabled"
                                        item-text="name"
                                        item-value="id"
                                        label="Тип отчета"
                                        title="Тип отчета"
                                        url="reports/type"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="px-4">
                                <v-col class="px-1 py-0" cols="12">
                                    <v-menu
                                        ref="menu"
                                        v-model="dateMenu"
                                        :close-on-content-click="false"
                                        :return-value.sync="date"
                                        min-width="290px"
                                        offset-y
                                        transition="scale-transition"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="dateRangeText"
                                                :required="true"
                                                :rules="requiredRules"
                                                label="Дата отчета"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            :first-day-of-week="1"
                                            no-title
                                            range
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="primary"
                                                text
                                                @click="dateMenu = false"
                                            >
                                                Отмена
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                text
                                                @click="$refs.menu.save(date)"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="px-4">
                                <v-col class="px-1 py-0" cols="12">
                                    <base-combobox-with-request
                                        v-model="taskType"
                                        :clearable="true"
                                        :multiple="true"
                                        item-disadled="disabled"
                                        item-text="name"
                                        item-value="id"
                                        label="Тип задачи"
                                        title="Тип задачи"
                                        url="tasktype/index"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="px-4">
                                <v-col class="px-1 py-0" cols="12">
                                    <base-combobox-with-request
                                        v-model="systemType"
                                        :clearable="true"
                                        :filterData="activeMenuPoint"
                                        :multiple="true"
                                        item-text="name"
                                        item-value="id"
                                        label="Тип систем"
                                        title="Тип систем"
                                        url="newtask/getsystemtype"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="px-4">
                                <v-col class="px-1 py-0" cols="12">
                                    <base-combobox-with-request
                                        v-model="taskStatus"
                                        :clearable="true"
                                        :disabled="taskStatusDisabled"
                                        :multiple="true"
                                        item-disadled="disabled"
                                        item-text="name"
                                        item-value="id"
                                        label="Статус задачи"
                                        title="Статус задачи"
                                        url="taskstatus/index"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="px-4">
                                <v-col class="px-1 py-0" cols="12">
                                    <base-combobox-with-request
                                        v-model="users"
                                        :clearable="true"
                                        :multiple="true"
                                        item-text="short_name"
                                        item-value="id"
                                        label="Сотрудники"
                                        title="Сотрудники"
                                        url="user/simpleList"
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="reportType && reportType.id === 5" class="px-4">
                                <v-col class="px-1 py-0" cols="12">
                                    <base-combobox-with-request
                                        v-model="customData"
                                        :clearable="true"
                                        :multiple="true"
                                        item-text="name"
                                        item-value="name"
                                        label="Настраиваемые данные"
                                        title="Настраиваемые данные"
                                        url="customdatatype/unique"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!--                            <v-btn v-if="UserCan.Reports.GenerateAllTasks || UserCan.Reports.GenerateDepTasks || UserCan.Reports.GenerateUsersTasks" :disabled="!formValid || !leftMenuValid" color="blue" text-->
                            <!--                                   @click="view">Просмотреть-->
                            <!--                            </v-btn>-->
                            <v-btn
                                v-if="UserCan.Reports.GenerateAllTasks || UserCan.Reports.GenerateDepTasks || UserCan.Reports.GenerateUsersTasks"
                                :disabled="!formValid || !leftMenuValid" color="blue" text
                                @click="download">Скачать
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import BaseLeftMenu from "../BaseComponents/BaseLeftMenu";
import BaseComboboxWithRequest from "../BaseComponents/BaseComboboxWithRequest";
import BaseErrorSnackbar from "../BaseComponents/BaseErrorSnackbar";
import requiredRules from "../../Rules/requiredRules";

export default {
    name: "Reports",
    components: {BaseLeftMenu, BaseComboboxWithRequest, BaseErrorSnackbar},
    data: function () {
        return {
            activeMenuPoint: [],
            updateAll: null,
            formValid: true, //Переменная для контроля валидности данных формы
            leftMenuValid: false,
            error: false,
            errorText: null,

            dateMenu: false,
            // dateType: "range",
            taskStatusDisabled: false,

            reportType: null,
            date: null,
            taskType: null,
            systemType: null,
            taskStatus: null,
            users: null,
            customData: null,
            loading: false,
        }
    },
    computed: {
        height: function () {
            if (this.$vuetify.breakpoint.width < 992) {
                return "100%"
            } else {
                return this.$vuetify.breakpoint.height - 95
            }
        },
        dateRangeText() {
            if (this.date) {
                let end = this.date[1] ? " по " + this.date[1] : ""
                return "c " + this.date[0] + end
            }
            return null
        },
        requiredRules() {
            return requiredRules
        },
    },
    watch: {
        reportType: function (val) {
            if (val && val.status) {
                this.taskStatus = JSON.parse(val.status)
                this.taskStatusDisabled = true
                // switch (val.id) {
                //     case 1:
                //     case 2:
                //     case 3:
                //     case 5:
                //         this.taskStatus = [{id: 7, name: "Закрыта"}]
                //         this.taskStatusDisabled = true
                //         break;
                //     case 4:
                //         this.taskStatus = [{id: 4, name: "Приостановлена"},{id: 7, name: "Закрыта"}]
                //         this.taskStatusDisabled = true
                //         break;
                //     default:
                //         this.taskStatus = null
                //         this.taskStatusDisabled = false
                // }
            } else {
                this.taskStatus = null
                this.taskStatusDisabled = false
            }
        },
        activeMenuPoint: function (val) {
            this.leftMenuValid = val.length > 0;
        },
        forServiceEngineer: function (val) {
            if (val) {
                this.leadEngineerDisabled = true
                this.leadEngineerRequred = false
                this.leadEngineerRules = []
            } else {
                this.leadEngineerDisabled = false
                this.leadEngineerRequred = true
                this.leadEngineerRules = this.requiredRules
            }
        },
    },
    methods: {
        prepareData() {
            return {
                systems: this.activeMenuPoint,
                reportType: this.reportType,
                date: this.date,
                taskType: this.taskType,
                systemType: this.systemType,
                taskStatus: this.taskStatus,
                users: this.users,
                customData: this.customData
            }
        },
        view() {

        },
        download() {
            this.loading = true
            axios.post('/api/reports/download', this.prepareData())
                .then(response => {
                    let link = document.createElement('a')
                    link.href = response.data.path
                    link.download = response.data.name
                    link.click()
                })
                .catch(error => {
                    this.showError(error)
                })
                .finally(() => {
                    this.loading = false
                });
        },

        close() {
            setTimeout(() => {
                this.resetValidation()
                this.leftMenuValid = false
                this.activeMenuPoint = []
                //console.log("close")
            }, 300)
        },
        resetValidation() {
            this.$refs.Form.reset()
            this.$refs.Form.resetValidation()
        },
    },
}
</script>

<style scoped>

</style>
