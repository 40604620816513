export default function (dataUrl) {
    const filename = dataUrl.substring(dataUrl.lastIndexOf('/') + 1);

    let link = document.createElement("a");
    link.download = filename;
    link.target = "_blank";
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}