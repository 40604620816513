export const showMessageMixin = {
    methods: {
        showError(error) {
            if (error.response) {

                if (error.response.status === 403 && error.response.data.message === 'This action is unauthorized.') {
                    this.$store.commit('setErrors', ['Данное действие не авторизовано.'])
                }

                if (error.response.status === 404) {
                    this.$store.commit('setErrors', [error.response.data.message])
                }

                if (error.response.status === 405) {
                    this.$store.commit('setErrors', [error.response.data.message])
                }

                if (error.response.status === 422 && error.response.data.message === 'The given data was invalid.') {
                    this.$store.commit('setErrors', error.response.data.errors)
                }

                if (error.response.status === 422) {
                    this.$store.commit('setErrors', error.response.data.errors)
                }

                if (error.response.status === 500) {
                    this.$store.commit('setErrors', [error.response.data.message])
                }

            } else {
                this.$store.commit('setErrors', [error])
            }

        },

        showInfo(message, timeout = 2000) {
            if (message) {
                this.$store.commit('setInfo', message)
            }
            this.$store.commit('setInfoTimeout', timeout)
        },

    }
}
