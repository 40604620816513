export function UserSettingsDataType() {
    this.taskListHeader = {
        showCreator: false,
        showExecutor: true,
        showObjectsName: true,
        showSystemType: true,
        showTaskCreatedAt: true,
        showTaskId: true,
        showTaskPriority: true,
        showTaskStatus: true,
        showTaskType: true,
        showTaskUpdatedAt: true,
    }
    this.leftMenu = {
        showSystemCount: false
    }
    this.contractFilter = {
        status: [],
        customer: [],
        performer: []
    }
}