<template>
    <v-card
        v-if="rightToRead"
        :class="overflowClass"
        :height="height"
        class="mx-auto"
        v-bind:style="overflowStyle"
    >
        <v-toolbar
            :height="height === '100%' ? 73 : 48"
            class="task-list-toolbar"
            dense
            flat
        >
            <div class="toolbar--container">
                <div class="task-list-finde">
                    <base-search-field
                        v-model="search"
                    />
                </div>

                <div class="task-list-menu">
                    <task-list-filter-menu
                        :filter-list="filterList"
                        :filter-name="filterName"
                        v-on:click="toTaskList"
                        v-on:keydown="toTaskList"
                    />
                </div>

                <div class="task-list-newtask">
                    <base-icon-btn
                        v-if="withCreateButton && rightToTaskCreate"
                        :icon="$store.state.Icon.svgMdiPlusOutline"
                        title="Добавить задачу"
                        to="/newtask"
                    />
                </div>

                <div class="task-list-filter">
                    <task-list-filter
                        :filter-list.sync="filterList"
                        v-on:close="needUpdate=true"
                    />
                </div>

                <div class="task-list-header-settings">
                    <base-table-headers-setting
                        :type="type"
                        v-on:close="needUpdate=true"
                    />
                </div>

            </div>
        </v-toolbar>

        <v-card-text class="pa-0">
            <router-view
                :can-drag-task="canDragTask"
                :can-opening-task="canOpeningTask"
                :get-task-list-route="getTaskListRoute"
                :itemsPerPage="itemsPerPage"
                :month="month"
                :needUpdate.sync="needUpdate"
                :needUpdateData.sync="needUpdateDataLocal"
                :objectTreeOptions="objectTreeOptions"
                :route-prefix="routePrefix"
                :searchString="search"
                :show-select="showSelect"
                :type="type"
                :year="year"
                v-on:drag="$emit('drag', $event)"
                v-on:dragend="$emit('dragend', $event)"
                v-on:dragstart="$emit('dragstart', $event)"
            />
        </v-card-text>
    </v-card>
</template>


<script>
import {VCard, VCardText, VToolbar} from 'vuetify/lib'
import BaseTableHeadersSetting from "../BaseComponents/BaseTableHeadersSetting/BaseTableHeadersSetting";
import TaskListFilter from "./TaskListFilter";
import BaseIconBtn from "../../BaseComponents/BaseIconBtn";
import BaseSearchField from "../../BaseComponents/BaseSearchField";
import TaskListFilterMenu from "./TaskListFilterMenu";

export default {
    name: "TaskList",
    components: {
        BaseTableHeadersSetting, TaskListFilter, BaseIconBtn, BaseSearchField, TaskListFilterMenu,
        VCard, VToolbar, VCardText
    },
    props: {
        objectTreeOptions: {},
        height: {},
        withCreateButton: {
            default: true
        },
        showSelect: {
            type: Boolean,
            default: true
        },
        canOpeningTask: {
            type: Boolean,
            default: true
        },
        canDragTask: {
            type: Boolean,
            default: false
        },
        routePrefix: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'taskList'
        },
        getTaskListRoute: {
            type: String,
            default: '/api/tasklist'
        },
        month: {
            default: null
        },
        year: {
            default: null
        },
        needUpdateData: { //Признак того, что список нужно обновить "тихо" (не удаляя данные перед этим)
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            search: null,
            filterList: null,
            needUpdate: false,
            needQuietlyUpdate: false,
            itemsPerPage: 15,
            filterName: 'Фильтры'
        }
    },
    watch: {
        /**
         * Наблюдаем за списком фильтров и если он получен, то переходим на первый из них.
         */
        filterList: function (newList) {
            if (newList && Array.isArray(newList)) {
                const itemId = this.getCurrentFilterId()
                const filter = itemId ? newList.filter(val => val.id === parseInt(itemId))[0] : newList[0]
                this.setItemsPerPage(filter)
                this.setFilterName(filter)

                this.$router.push({path: `/${this.routePrefix}/${this.getFilterId(filter)}`})
                    .catch(err => {
                    })

                // this.$router.push({ name: prefix+'FilteredTaskList', params: { filterid: filter?.id } })
                //     .catch(err => {})
            }
        }
    },
    computed: {
        needUpdateDataLocal: {
            get: function () {
                return this.needUpdateData
            },
            set: function (val) {
                this.$emit('update:needUpdateData', val)
            }
        },

        rightToRead: function () {
            return this.$store.getters['UserRights/canAllTasksRead'] ||
                this.$store.getters['UserRights/canDepTasksRead'] ||
                this.$store.getters['UserRights/canUsersTasksRead']
        },

        rightToTaskCreate: function () {
            return this.$store.getters['UserRights/canAllTasksCreate'] ||
                this.$store.getters['UserRights/canDepTasksCreate'] ||
                this.$store.getters['UserRights/canUsersTasksCreate']
        },
        overflowClass: function () {
            return {
                'overflow-y-auto': this.$vuetify.breakpoint.width > 992
            }
        },
        overflowStyle: function () {
            return {
                'overflow-y': this.$vuetify.breakpoint.width > 992 ? 'visible' : 'hidden'
            }
        }

    },
    methods: {
        getCurrentFilterId() {
            return this.$route?.params?.filterid ? this.$route.params.filterid : null
        },

        setItemsPerPage(item) {
            if (item && item.items_per_page) {
                this.itemsPerPage = item.items_per_page.name !== 'Все' ? parseInt(item.items_per_page.name) : -1
            } else {
                this.itemsPerPage = 15
            }
        },
        setFilterName(item) {
            if (item && item.name) {
                this.filterName = item.name
            } else {
                this.filterName = 'Фильтры'
            }
        },

        getFilterId(item) {
            if (item && item.id) {
                return item.id
            }
            return 0
        },

        toTaskList(item) {
            if (item && item.id) {
                this.setItemsPerPage(item)
                this.setFilterName(item)
                this.$router.push({path: `/${this.routePrefix}/${item.id}`})
                    .catch(err => {
                    })
                // this.$router.push({name:'FilteredTaskList', params: { filterid: item.id } }).catch(err => {})
            }
        }
    }
}
</script>

<style scoped>
.toolbar--container {
    width: 100%;
    display: grid;
    grid-template-columns:  auto max-content max-content max-content max-content;
    gap: 5px;
}


@media only screen and (max-width: 992px) {
    .task-list-finde {
        grid-area: a;
    }

    .task-list-menu {
        grid-area: b;
        justify-self: left;
    }

    .task-list-newtask {
        grid-area: c;
        justify-self: center;
    }

    .task-list-filter {
        grid-area: d;
        justify-self: center;
    }

    .task-list-header-settings {
        grid-area: e;
        justify-self: center;
    }

    .toolbar--container {
        width: 100%;
        display: grid;
        grid-template-rows: auto auto;

        grid-template:  "a a a a"
                            "b c d e" / auto max-content max-content max-content;
        gap: 5px;
        align-items: center;

    }
}


/*.v-card {*/
/*    overflow: auto;*/
/*}*/


</style>
