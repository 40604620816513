<template>
    <svg
        :height="height"
        :width="width"
        clip-rule="evenodd"
        fill-rule="evenodd"
        image-rendering="optimizeQuality"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
        viewBox="0 0 30 30"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
    <g transform="scale(.5083)">
        <circle :fill="iconColor" cx="29.51" cy="29.51" r="21.13"/>
        <path
            :fill="iconColor"
            d="M29.51 0c16.3 0 29.51 13.21 29.51 29.51S45.81 59.02 29.51 59.02 0 45.81 0 29.51 13.21 0 29.51 0zm0 3.59c14.31 0 25.92 11.6 25.92 25.92 0 14.31-11.6 25.92-25.92 25.92-14.31 0-25.92-11.6-25.92-25.92 0-14.31 11.6-25.92 25.92-25.92z"/>
    </g>
</svg>
</template>

<script>
export default {
    name: "ObjectIcon",
    props: {
        iconColor: {
            type: String,
            // default: '#9d9e9e',
            default: '#ff00ff'
        },
        width: {
            type: [Number, String],
            default: 10
        },
        height: {
            type: [Number, String],
            default: 10
        },
    }
}
</script>

<style scoped>

</style>
