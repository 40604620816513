<template>
    <div>
        <v-menu
            v-model="showMenu"
            z-index="101"
        >
            <template v-slot:activator="{on}">
                <v-btn
                    icon
                    title="Настройка списка объектов"
                    v-on="on"
                >
                    <v-icon>{{ $store.state.Icon.svgMdiDotsVertical }}</v-icon>
                </v-btn>
            </template>
            <v-list flat>
                <v-list-item>
                    <v-list-item-group>
                        <v-list-item>
                            <v-list-item-action>
                                <v-checkbox
                                    :input-value="showSystemCount"
                                    v-on:change="setShowSystemCount"
                                />
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>Показывать количество систем</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <template v-if="schema">
                            <v-radio-group
                                :value="selectedSchema"
                                class="py-0"
                                hide-details
                                v-on:change="setLeftMenuSchema"
                            >
                                <v-list-item v-for="item in schema" :key="item.id">
                                    <v-list-item-action>
                                        <v-radio
                                            :value="item.id"
                                            name="schema"
                                        />
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-radio-group>
                        </template>

                    </v-list-item-group>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>

</template>

<script>
export default {
    name: "BaseLeftMenuSettings",
    data: function () {
        return {
            showMenu: false,
            schema: null,
        }
    },

    created: function () {
        axios.get('/api/hierarchy/schema')
            .then((response) => {
                this.schema = response.data
            })
            .catch(error => {
                if (error.response) {
                    this.commit('setErrors', error.response, {root: true})
                }
            });
    },

    computed: {
        showSystemCount: function () {
            const settings = this.$store.getters["UserData/getLeftMenuSettings"]
            return settings.showSystemCount
        },

        selectedSchema: function () {
            const settings = this.$store.getters["UserData/getLeftMenuSettings"]
            return settings.schema
        }
    },
    methods: {
        setConfig(settingsGroup, showSystemCount, value) {
            axios.post('/api/user/authUserSettings', {
                settingsGroup: settingsGroup,
                settings: {
                    [showSystemCount]: value
                }
            })
                .catch(error => {
                    this.showError(error)
                }).finally(() => {
                this.$store.dispatch('UserData/getUserSettings');
            })
        },


        setShowSystemCount(event) {
            this.setConfig('leftMenu', 'showSystemCount', event)
        },

        setLeftMenuSchema(event) {
            this.setConfig('leftMenu', 'schema', event)
        }

    }
}
</script>

<style scoped>

</style>