<template>
    <div v-if="value" class="doc-block">
        <iframe
            :src="'https://view.officeapps.live.com/op/embed.aspx?src='+src"
            :title="alt">

        </iframe>
    </div>
</template>

<script>
export default {
    name: "BaseDocViewer",
    props: {
        src: {
            type: String
        },
        alt: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style scoped>
.doc-block {
    display: flex;
    justify-content: center;
    height: 100%;
}

.doc-block iframe {
    position: fixed;
    border: 1px solid #666CCC;
    margin-top: -60px;
    z-index: 10003;
    height: 95%;
    width: 100%;
}
</style>