<template>
    <path :fill="iconColor"
          d="M12.96 10.488l.254 5.466 6.153 4.172.017.02c.22-.218.46-.427.723-.626-.765-.452-1.284-1.331-1.284-2.342 0-1.472 1.101-2.666 2.46-2.666 1.358 0 2.46 1.194 2.46 2.666 0 1.01-.52 1.89-1.285 2.342C24.278 20.9 25.026 22.735 25 25h-7.433c-.019-1.613.355-3.008 1.23-4.176l-6.262-3.594-6.247 3.707C7.102 22.08 7.452 23.439 7.434 25H.001c-.027-2.265.721-4.1 2.54-5.48-.764-.452-1.284-1.331-1.284-2.342 0-1.472 1.102-2.666 2.46-2.666 1.359 0 2.46 1.194 2.46 2.666 0 1.01-.519 1.89-1.284 2.342.304.23.579.474.824.73l.036-.035 6.1-4.261.114-5.466H8.783c-.026-2.265.722-4.1 2.542-5.48-.766-.453-1.285-1.332-1.285-2.343C10.04 1.193 11.142 0 12.5 0c1.359 0 2.46 1.193 2.46 2.665 0 1.011-.52 1.89-1.285 2.342 1.82 1.38 2.568 3.216 2.542 5.48z"/>
</template>

<script>
export default {
    name: "UsersIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
