// initial state
const state = {
    all: []
}

// getters
const getters = {
    getRights: (state) => state.all,
    getContractRead: (state) => state.all.includes('Contract-read'),

    //Users

    canUserRead: (state) => state.all.includes('User-read'),
    canUserCreate: (state) => state.all.includes('User-create'),
    canUserUpdate: (state) => state.all.includes('User-update'),
    canUserDelete: (state) => state.all.includes('User-delete'),
    canUserRightsRead: (state) => state.all.includes('User-readRights'),
    canUserRightsUpdate: (state) => state.all.includes('User-updateRights'),
    canUserSystemsAccessConfig: (state) => state.all.includes('User-configSystemsAccess'),

    canDepartmentConfig: (state) => state.all.includes('Department-config'),

    canPersonRead: (state) => state.all.includes('Person-read'),
    canPersonCreate: (state) => state.all.includes('Person-create'),
    canPersonUpdate: (state) => state.all.includes('Person-update'),
    canPersonDelete: (state) => state.all.includes('Person-delete'),

    canCustomerRead: (state) => state.all.includes('Customer-read'),
    canCustomerCreate: (state) => state.all.includes('Customer-create'),
    canCustomerUpdate: (state) => state.all.includes('Customer-update'),
    canCustomerDelete: (state) => state.all.includes('Customer-delete'),

    canPerformerRead: (state) => state.all.includes('Performer-read'),
    canPerformerCreate: (state) => state.all.includes('Performer-create'),
    canPerformerUpdate: (state) => state.all.includes('Performer-update'),
    canPerformerDelete: (state) => state.all.includes('Performer-delete'),


    canContractRead: (state) => state.all.includes('Contract-read'),
    canContractCreate: (state) => state.all.includes('Contract-create'),
    canContractUpdate: (state) => state.all.includes('Contract-update'),
    canContractDelete: (state) => state.all.includes('Contract-delete'),

    canObjectRead: (state) => state.all.includes('Object-read'),
    canObjectCreate: (state) => state.all.includes('Object-create'),
    canObjectUpdate: (state) => state.all.includes('Object-update'),
    canObjectDelete: (state) => state.all.includes('Object-delete'),

    canSystemRead: (state) => state.all.includes('System-read'),
    canSystemCreate: (state) => state.all.includes('System-create'),
    canSystemUpdate: (state) => state.all.includes('System-update'),
    canSystemDelete: (state) => state.all.includes('System-delete'),


    canAllTasksRead: (state) => state.all.includes('AllTasks-read'),
    canDepTasksRead: (state) => state.all.includes('DepTasks-read'),
    canUsersTasksRead: (state) => state.all.includes('UsersTasks-read'),

    canAllTasksCreate: (state) => state.all.includes('AllTasks-create'),
    canDepTasksCreate: (state) => state.all.includes('DepTasks-create'),
    canUsersTasksCreate: (state) => state.all.includes('UsersTasks-create'),

    canMultipleTasksCreate: (state) => state.all.includes('MultipleTasks-create'),

    canAllTasksDelete: (state) => state.all.includes('AllTasks-delete'),
    canDepTasksDelete: (state) => state.all.includes('DepTasks-delete'),
    canUsersTasksDelete: (state) => state.all.includes('UsersTasks-delete'),

    canAllTasksSetToClosed: (state) => state.all.includes('AllTasks-setToClosed'),
    canDepTasksSetToClosed: (state) => state.all.includes('DepTasks-setToClosed'),
    canUsersTasksSetToClosed: (state) => state.all.includes('UsersTasks-setToClosed'),

    //Транспорт
    canTransportConfig: (state) => state.all.includes('Transport-config'),
    canTransportRead: (state) => state.all.includes('Transport-read'),
    canTransportCreate: (state) => state.all.includes('Transport-create'),
    canTransportUpdate: (state) => state.all.includes('Transport-update'),
    canTransportDelete: (state) => state.all.includes('Transport-delete'),

    //Маршрутные листы
    canUserWaybillCreate: (state) => state.all.includes('UserWaybill-create'),
    canAllWaybillCreate: (state) => state.all.includes('AllWaybill-create'),
    canUserWaybillDelete: (state) => state.all.includes('UserWaybill-delete'),
    canAllWaybillDelete: (state) => state.all.includes('AllWaybill-delete'),
    canWaybillClose: (state) => state.all.includes('Waybill-close'),

    canTransportStatView: (state) => state.all.includes('Transport-statistics-view'),


    //Планирование
    canPlanView: (state) => state.all.includes('Plan-view'),
    canUsersTaskPlan: (state) => state.all.includes('UsersTasks-plan'),
    canDepTaskPlan: (state) => state.all.includes('DepTasks-plan'),
    canAllTaskPlan: (state) => state.all.includes('AllTasks-plan'),

    //Склады
    canWarehouseArrival: (state) => state.all.includes('UsersWarehouse-arrival'),
    canWarehouseUsersView: (state) => state.all.includes('UsersWarehouse-view'),
    canWarehouseDepView: (state) => state.all.includes('DepWarehouse-view'),
    canWarehouseAllView: (state) => state.all.includes('AllWarehouse-view'),
    canWarehouseUsersMoving: (state) => state.all.includes('UsersWarehouse-moving'),
    canWarehouseDepMoving: (state) => state.all.includes('DepWarehouse-moving'),
    canWarehouseAllMoving: (state) => state.all.includes('AllWarehouse-moving'),

    canWarehouseUsersMovingConfirm: (state) => state.all.includes('UsersWarehouse-movingConfirm'),
    canWarehouseDepMovingConfirm: (state) => state.all.includes('DepWarehouse-movingConfirm'),
    canWarehouseAllMovingConfirm: (state) => state.all.includes('AllWarehouse-movingConfirm'),


    canWarehouseUsersWriteoff: (state) => state.all.includes('UsersWarehouse-writeoff'),
    canWarehouseDepWriteoff: (state) => state.all.includes('DepWarehouse-writeoff'),
    canWarehouseAllWriteoff: (state) => state.all.includes('AllWarehouse-writeoff'),

    canWarehouseUsersWriteoffConfirm: (state) => state.all.includes('UsersWarehouse-writeoffConfirm'),
    canWarehouseDepWriteoffConfirm: (state) => state.all.includes('DepWarehouse-writeoffConfirm'),
    canWarehouseAllWriteoffConfirm: (state) => state.all.includes('AllWarehouse-writeoffConfirm'),
    canWarehouseProcurementPlanning: (state) => state.all.includes('Warehouse-ProcurementPlanning'),

    canPlanSettings: (state) => state.all.includes('Config-plan'),

    //Планирование
    canDutyView: (state) => state.all.includes('Duty-read'),
    canDutyUpdate: (state) => state.all.includes('Duty-update'),
}

// actions
const actions = {
    getUserRights() {
        axios.get('/api/interface/userrights')
            .then(response => {
                this.commit('UserRights/setUserRights', response.data)
            })
    }
}

// mutations
const mutations = {
    setUserRights(state, rights) {
        state.all = rights
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
