<template>
    <base-modal
        v-model="show"
        :maxWidth="'400px'"
        :scrollable="false"
    >
        <v-card v-if="show">
            <v-card-title>
                <span class="headline">Настройка главного меню</span>
            </v-card-title>
            <v-card-text v-if="navbarSettings">
                <h3>Главное меню</h3>

                <draggable :list="navbarSettings" class="list-group draggable" handle=".handle" tag="ul">
                    <li
                        v-for="element in navbarSettings"
                        :key="element.key"
                        class="list-group-item draggable"
                    >

                        <v-checkbox
                            v-model="element.show"
                            :label="element.name"
                            :ripple="false"
                            dense
                            hide-details
                        >
                            <template v-slot:prepend>
                                <v-icon class="handle">{{ $store.state.Icon.svgMdiDotsVertical }}</v-icon>
                            </template>

                        </v-checkbox>

                    </li>
                </draggable>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue" text @click="close">
                    Отмена
                </v-btn>
                <v-btn color="blue" text @click="setNavbarSetting">
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
    </base-modal>
</template>

<script>
import baseModal from "../BaseComponents/BaseModal";
import draggable from 'vuedraggable';

export default {
    name: "UsersMainMenuSetting",
    components: {
        baseModal,
        draggable
    },
    props: {
        show: {
            requred: true,
            default: false,
        },
    },
    data: function () {
        return {
            navbarSettings: false,
        }
    },
    watch: {
        show: function (newVal) {
            if (newVal) {
                this.getNavbarSetting()
            }
        }
    },
    methods: {
        getNavbarSetting() {
            axios.get('/api/navbar/settings')
                .then(response => (this.navbarSettings = response.data))
                .catch(error => {
                    this.showError(error)
                })
        },

        setNavbarSetting() {
            axios.post('/api/navbar/settings', {'data': this.navbarSettings})
                .then(() => {
                    this.close()
                })
                .catch(error => {
                    this.showError(error)
                })
        },

        close() {
            this.$emit('close')
            this.navbarSettings = false
        }
    }
}
</script>

<style scoped>
ul.list-group.list-group {
    padding-left: 0;
}

li.list-group-item.draggable {
    list-style-type: none; /* Убираем маркеры */
}

li.list-group-item.draggable :deep(.v-icon.handle) {
    cursor: move;
}
</style>