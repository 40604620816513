export default function (value, singularNominative, pluralNominative, pluralGenitive) {
    const number = Math.abs(value) % 10;

    if (number === 0 || 5 <= number || (20 >= value && 10 <= value)) {
        return pluralGenitive;
    }

    if (2 <= number && 4 >= number) {
        return pluralNominative;
    }

    return singularNominative;
}