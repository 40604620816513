import ObjectMap from "../components/ObjectMap/ObjectMap.vue";
import Calendar from "../components/Calendar/Calendar.vue";
import ObjectCardList from "../components/ObjectCard/ObjectCardList.vue";
import TaskListWithLeftMenu from "../components/TaskList/TaskListWithLeftMenu.vue";
import FilteredTaskList from "../components/TaskList/FilteredTaskList.vue";
import ModalTask from "../components/Task/ModalTask.vue";
import NewTask from "../components/NewTask/NewTask.vue";
import StatusMonitor from "../components/StatusMonitor/StatusMonitor.vue";
import Reports from "../components/Reports/Reports.vue";
import ObjectGroup from "../components/ObjectGroup/ObjectGroup.vue";
import Task from "../components/Task/Task.vue";
import ObjectCard from "../components/ObjectCard/ObjectCard.vue";
import Profile from "../components/Profile/Profile.vue";
import NotificationSettings from "../components/Profile/NotificationSettings.vue";
import Help from "../components/Help/Help.vue";
import Directory from "../components/Admin/Directory/Directory.vue";
import DirectoryItem from "../components/Admin/Directory/DirectoryItem/DirectoryItem.vue";
import ActivityTypeGroup from "../components/Admin/ActivityTypeGroup/ActivityTypeGroup.vue";
import TasksTimetable from "../components/Admin/TasksTimetable/TasksTimetable.vue";
import MainErrors from "../components/MainComponents/MainErrors.vue";

const Plan = () => import(/* webpackChunkName: "Plan" */  "../components/Plan/Plan")

const TransportStat = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/TransportStat")
const Automobile = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Automobile/Automobile")
const AutomobileCreate = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Automobile/AutomobileCreate")
const AutomobileUpdate = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Automobile/AutomobileUpdate")
const AutomobileDelete = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Automobile/AutomobileDelete")
const Waybill = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Waybill/Waybill")
const WaybillCreate = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Waybill/WaybillCreate")
const WaybillUpdate = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Waybill/WaybillUpdate")
const WaybillDelete = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Waybill/WaybillDelete")

const ArrivedAtObject = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Waybill/ArrivedAtObject")
const DepartedFromObject = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Waybill/DepartedFromObject")
const Refueled = () => import(/* webpackChunkName: "Transport" */  "../components/Transport/Waybill/Refueled")

const WorkTimetable = () => import(/* webpackChunkName: "WorkTimetable" */ '../components/Profile/WorkTimetable')


const Users = () => import(/* webpackChunkName: "Users" */ '../components/Admin/Users/Users')
const UserCreate = () => import(/* webpackChunkName: "Users" */ '../components/Admin/Users/UserCreate')
const UserDelete = () => import(/* webpackChunkName: "Users" */ '../components/Admin/Users/UserDelete')
const UserChangePassword = () => import(/* webpackChunkName: "Users" */ '../components/Admin/Users/UserChangePassword')
const UserUpdate = () => import(/* webpackChunkName: "Users" */ '../components/Admin/Users/UserUpdate')


const Contract = () => import(/* webpackChunkName: "Contract" */  "../components/Admin/Contracts/Contracts")
const ContractCreate = () => import(/* webpackChunkName: "Contract" */  "../components/Admin/Contracts/ContractCreate")
const ContractUpdate = () => import(/* webpackChunkName: "Contract" */  "../components/Admin/Contracts/ContractUpdate")
const ContractDelete = () => import(/* webpackChunkName: "Contract" */  "../components/Admin/Contracts/ContractDelete")

const WarehouseBalanceList = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Balance/WarehouseBalanceList")
const WarehouseBalanceDownload = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Balance/WarehouseBalanceDownload")
const WarehouseBalanceView = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Balance/WarehouseBalanceView")

const WarehouseUnconfirmedBalanceList = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Balance/WarehouseUnconfirmedBalanceList")
const WarehouseUnconfirmedBalanceDownload = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Balance/WarehouseUnconfirmedBalanceDownload")
const ArrivalList = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Arrival/ArrivalList")
const ArrivalCreate = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Arrival/ArrivalCreate")
const ArrivalUpdate = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Arrival/ArrivalUpdate")
const ArrivalDelete = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Arrival/ArrivalDelete")
const ArrivalView = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Arrival/ArrivalView")

const MovingList = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Moving/MovingList")
const MovingView = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Moving/MovingView")
const MovingCreate = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Moving/MovingCreate")
const MovingUpdate = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Moving/MovingUpdate")
const MovingDelete = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Moving/MovingDelete")
const MovingConfirm = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Moving/MovingConfirm")

const WriteoffList = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Writeoff/WriteoffList")
const WriteoffView = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Writeoff/WriteoffView")
const WriteoffCreate = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Writeoff/WriteoffCreate")
const WriteoffUpdate = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Writeoff/WriteoffUpdate")
const WriteoffDelete = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Writeoff/WriteoffDelete")
const WriteoffConfirm = () => import(/* webpackChunkName: "Warehouse" */ "../components/Warehouse/Writeoff/WriteoffConfirm")

const ProcurementPlanningList = () => import(/* webpackChunkName: "ProcurementPlanning" */ '../components/Warehouse/ProcurementPlanning/ProcurementPlanningList.vue')
const DutyList = () => import(/* webpackChunkName: "Dutylist" */ "../components/DutyList/DutyList")

export const routes = [
    {path: '/', name: "main", redirect: '/taskslist'},
    {path: '/home', name: "home", redirect: '/taskslist'},
    {path: '/objectmap', name: "objectmap", component: ObjectMap},
    {path: '/calendar', name: "calendar", component: Calendar},
    {path: '/objectcardlist', name: "objectcardlist", component: ObjectCardList},
    // { path: '/taskslist',               name: "taskslist",          component: TasksList },
    {
        path: '/taskslist', name: "taskslist", props: true, component: TaskListWithLeftMenu,
        children: [
            {
                path: ":filterid", name: "FilteredTaskList", component: FilteredTaskList, props: true,
                children: [
                    {path: ":taskid", name: "TaskFromList", component: ModalTask, props: true,},
                ]
            },
        ]
    },

    {
        path: '/plan', name: "plan", props: true, component: Plan,
        children: [
            {path: ":filterid", name: "PlanFilteredTaskList", component: FilteredTaskList, props: true,},
        ]
    },

    {path: '/newtask', name: "newtask", component: NewTask},

    {path: '/status_monitor', name: "statusMonitor", component: StatusMonitor},

    {path: '/reports', name: "reports", component: Reports},
    {path: '/objectgroup', name: "objectgroup", component: ObjectGroup},


    {
        path: '/warehouse/balance', name: "warehouse.balance", component: WarehouseBalanceList,
        children: [
            {path: "view/:id", component: WarehouseBalanceView, name: "balance.view", props: true},
            {path: "download", component: WarehouseBalanceDownload, name: "balance.download"},
        ]
    },
    {
        path: '/warehouse/unconfirmedbalance',
        name: "warehouse.unconfirmedbalance",
        component: WarehouseUnconfirmedBalanceList,
        children: [
            {path: "download", component: WarehouseUnconfirmedBalanceDownload, name: "unconfirmedbalance.download"},
        ]
    },

    {
        path: '/warehouse/arrival', name: "arrival.index", component: ArrivalList,
        children: [
            {path: "create", component: ArrivalCreate, name: "arrival.create"},
            {path: ":id", component: ArrivalUpdate, name: "arrival.update", props: true},
            {path: "delete/:id", component: ArrivalDelete, name: "arrival.delete", props: true},
            {path: "view/:id", component: ArrivalView, name: "arrival.view", props: true},
        ]
    },

    {
        path: '/warehouse/moving', name: "moving.index", component: MovingList,
        children: [
            {path: "create", component: MovingCreate, name: "moving.create"},
            {path: ":id", component: MovingUpdate, name: "moving.update", props: true},
            {path: "delete/:id", component: MovingDelete, name: "moving.delete", props: true},
            {path: "view/:id", component: MovingView, name: "moving.view", props: true},
            {path: "confirm/:id", component: MovingConfirm, name: "moving.confirm", props: true},
        ]
    },

    {
        path: '/warehouse/writeoff', name: "writeoff.index", component: WriteoffList,
        children: [
            {path: "create", component: WriteoffCreate, name: "writeoff.create"},
            {path: ":id", component: WriteoffUpdate, name: "writeoff.update", props: true},
            {path: "delete/:id", component: WriteoffDelete, name: "writeoff.delete", props: true},
            {path: "view/:id", component: WriteoffView, name: "writeoff.view", props: true},
            {path: "confirm/:id", component: WriteoffConfirm, name: "writeoff.confirm", props: true},
        ]
    },
    {
        path: '/warehouse/procurement-planning', name: "procurementPlanning.index", component: ProcurementPlanningList,
        children: [
            // {path: "create", component: WriteoffCreate, name: "writeoff.create"},
            // {path: ":id", component: WriteoffUpdate, name: "writeoff.update", props: true},
            // {path: "delete/:id", component: WriteoffDelete, name: "writeoff.delete", props: true},
            // {path: "view/:id", component: WriteoffView, name: "writeoff.view", props: true},
            // {path: "confirm/:id", component: WriteoffConfirm, name: "writeoff.confirm", props: true},
        ]
    },

    {path: '/task/:taskid', name: "task", component: Task},
    {path: '/objectcard/:objectid', name: "objectcard", component: ObjectCard},

    {path: '/profile', name: "profile", component: Profile},
    {path: '/notificationsettings', name: "notificationSettings", component: NotificationSettings},
    {path: '/help', name: "help", component: Help},
    {path: '/worktimetable', name: "worktimetable", component: WorkTimetable},

    {
        path: '/contract', name: "contract.index", component: Contract,
        children: [
            {path: "create", component: ContractCreate, name: "contract.create"},
            {path: "delete/:id", component: ContractDelete, name: "contract.delete", props: true},
            {path: ":id", component: ContractUpdate, name: "contract.update", props: true},
        ]
    },

    {
        path: '/user', name: "user.index", component: Users,
        children: [
            {path: "create", component: UserCreate, name: "user.create"},
            {path: "delete/:id", component: UserDelete, name: "user.delete", props: true},
            {path: "passwd/:id", component: UserChangePassword, name: "user.passwd", props: true},
            {path: ":id", component: UserUpdate, name: "user.update", props: true},
        ]
    },

    {
        path: '/automobile', name: "automobile.index", component: Automobile,
        children: [
            {path: "create", component: AutomobileCreate, name: "automobile.create"},
            {path: "delete/:id", component: AutomobileDelete, name: "automobile.delete", props: true},
            {path: ":id", component: AutomobileUpdate, name: "automobile.update", props: true},
        ]
    },

    {
        path: '/waybill', name: "waybill.index", component: Waybill,
        children: [
            {path: "arrived/:id", component: ArrivedAtObject, name: "arrivedAtObject", props: true},
            {path: "departed/:id", component: DepartedFromObject, name: "departedFromObject", props: true},
            {path: "refueled/:id", component: Refueled, name: "refueled", props: true},
            {path: "create", component: WaybillCreate, name: "waybill.create"},
            {path: "delete/:id", component: WaybillDelete, name: "waybill.delete", props: true},
            {path: ":id", component: WaybillUpdate, name: "waybill.update", props: true},
        ]
    },
    {path: '/transportstat', name: "transportstat.index", component: TransportStat},

    {path: '/dutylist', name: "dutylist.index", component: DutyList},

    {path: '/admin/directory', name: "directorylist", component: Directory},
    {path: '/admin/directory/:dirid', name: "directory", component: DirectoryItem, props: true},

    {path: '/admin/activitytypegroup', name: "activitytypegroup", component: ActivityTypeGroup},
    {path: '/admin/taskstimetable', name: "taskstimetable", component: TasksTimetable},

    {path: '/error', name: "error", component: MainErrors},
]
