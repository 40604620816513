<template>
    <base-system-defects
        v-slot="scope"
        :disabled="disabled"
        :task-id="taskId"
        v-on:need-update="$store.dispatch('TaskData/updateTaskData')"
    >
        <v-col class="px-0 pb-1">
            <v-row class="HeaderTitle-row setMarginBottom" no-gutters>
                <v-col class="HeaderTitle-col pl-2 justify-center pt2" cols="10">Дефекты системы</v-col>
                <v-col class="HeaderTitle-col pl-2 justify-center pt2 text-right pr-3" cols="2">
                    <v-btn
                        :disabled="disabled"
                        icon
                        small
                        @click="scope.openCreateDialog"
                    >
                        <v-icon>{{ $store.state.Icon.svgMdiPlus }}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <template v-for="item in defects">
                <system-defect-item-in-task
                    :disabled="disabled"
                    :task-id="taskId"
                    :value="item"
                    v-on:delete="scope.openDeleteDialog"
                    v-on:edit="scope.openEditDialog"
                    v-on:info="scope.openInfoDialog"
                    v-on:add-file="scope.openUploadDialog"
                    v-on:add-comment="scope.openCommentDialog"
                    v-on:change-status="scope.openStateDialog"
                    v-on:changeIsPrintable="scope.changeIsPrintable"
                />
            </template>
        </v-col>
    </base-system-defects>
</template>

<script>
import SystemDefectItemInTask from "../../../SystemDefect/SystemDefectItemInTask.vue";
import BaseSystemDefects from "../../../SystemDefect/BaseSystemDefects.vue";

export default {
    name: "SystemDefects",
    components: {
        BaseSystemDefects,
        SystemDefectItemInTask
    },
    data: function () {
        return {
            editableDefect: null,
            changeableDefect: null,
            commentedDefect: null,
            daletebleDefect: null,
            infoDefect: null,
            uploadebleDefect: null
        }
    },


    computed: {
        taskId() {
            return this.$store.getters["TaskData/getTaskId"]
        },
        disabled() {
            return this.$store.getters["TaskData/getDisabled"]
        },
        defects() {
            return this.$store.getters["TaskData/getSystemDefects"]
        },
    },
    methods: {},
}
</script>

<style scoped>

.HeaderTitle-row {
    background-color: #cccccc;
    border: 1px solid #285473;
}

.HeaderTitle-col {
    padding: 3px;
}

.SimpleText {
    border: 1px solid #285473;
}

.setMarginBottom {
    margin-bottom: -1px;
}

.setMarginRigh {
    margin-right: -1px;
}

.scrol-div {
    overflow: auto;
    overflow-x: hidden;
    height: 390px;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 6px; /* высота для горизонтального скролла */
    background-color: #ffffff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #3273a7;
}

a.noDecor {
    color: rgba(0, 0, 0, 0.87) !important;

}

a.noDecor:link,
a.noDecor:hover,
a.noDecor:active {
    color: rgba(0, 0, 0, 0.87);
}
</style>
