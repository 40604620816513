<template>
    <v-col
        v-if="data"
        ref="directorydiv"
        class="px-0 pb-1"

    >
        <div class="this-component_title">
            <div class="this-component_title--name">{{ name }}<sup
                v-if="requred">*</sup></div>
            <div class="this-component_title--button">
                <v-btn
                    v-if="withRemove && !read_only" :disabled="disabled" icon small @click="onAddRemovedItem">
                    <v-icon>{{ $store.state.Icon.svgMdiMinus }}</v-icon>
                </v-btn>
            </div>
            <div class="this-component_title--button">
                <v-btn
                    v-if="!read_only" :disabled="disabled" icon small @click="onAddAddedItem">
                    <v-icon>{{ $store.state.Icon.svgMdiPlus }}</v-icon>
                </v-btn>
            </div>
        </div>
        <div v-if="show && blockInLine">
            <div
                :style="styleForVariableColumns"
                class="this-component_section"
            >
                <div v-for="item in fields" class="this-component_header">
                    {{ item }}
                </div>
                <div class="this-component_header">

                </div>

                <template
                    v-for="(field, key) in data"
                >
                    <div v-for="item in fields" class="this-component-field">
                        {{ field ? field[item] : '' }}
                    </div>
                    <div class="this-component-field this-component-field--action">
                        <div>
                            <v-icon
                                v-if="!read_only && field?.type !== 'remove'"
                                :disabled="disabled"
                                small
                                title="Изменить"
                                @click="onEditItem(key, field?.type)"
                            >
                                {{ $store.state.Icon.svgMdiPencil }}
                            </v-icon>
                        </div>
                        <div>
                            <v-icon
                                v-if="!read_only" :disabled="disabled"
                                small
                                title="Удалить"
                                @click="onDeleteItem(key, field?.type)"
                            >
                                {{ $store.state.Icon.svgMdiDelete }}
                            </v-icon>
                        </div>
                    </div>
                </template>


            </div>
        </div>
        <div v-else-if="show && !blockInLine">
            <div class="this-component-columnsection">
                <div v-for="(field, key) in data" class="wrapper">
                    <template v-for="item in fields">
                        <div class="this-component-columnsection-row-header">
                            {{ item }}
                        </div>
                        <div class="this-component-columnsection-row-item">
                            {{ field ? field[item] : '' }}
                        </div>
                    </template>
                    <div class="this-component-columnsection-row-header last">Действия</div>
                    <div class="this-component-columnsection-row-item last">
                        <div class="this-component-field--action">
                            <div>
                                <v-icon
                                    v-if="!read_only && field?.type !== 'remove'"
                                    :disabled="disabled"
                                    small
                                    title="Изменить"
                                    @click="onEditItem(key, field?.type)"
                                >
                                    {{ $store.state.Icon.svgMdiPencil }}
                                </v-icon>
                            </div>
                            <div>
                                <v-icon
                                    v-if="!read_only"
                                    :disabled="disabled"
                                    small
                                    title="Удалить"
                                    @click="onDeleteItem(key, field?.type)"
                                >
                                    {{ $store.state.Icon.svgMdiDelete }}
                                </v-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slot name="add-item-form"/>
        <slot name="remove-item-form" v-if="withRemove"></slot>

    </v-col>
</template>

<script>

import shownWhenIntersectionComponent from "../../../../../mixins/shownWhenIntersectionComponent";

export default {
    name: "DirectoryForm",
    mixins: [shownWhenIntersectionComponent],
    props: {
        name: {
            default: '',
            required: true
        },
        requred: {
            type: Boolean,
            default: false,
        },
        data: {
            default: null,
            required: true
        },
        fields: {
            default: null,
            required: true
        },

        withRemove: {
            type: Boolean,
            default: false
        },
        read_only: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },

    data: function () {
        return {
            blockInLine: true,
            resizeObserver: null,
            observedElement: 'directorydiv'
        }
    },

    computed: {
        styleForVariableColumns() {
            if (this.fields) {
                return 'grid-template-columns: repeat(' + this.fields.length + ', auto) min-content';
            }
            return ''
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(this.calcElementWidth)
        this.resizeObserver.observe(this.$refs.directorydiv)
    },

    beforeDestroy() {
        this.resizeObserver.unobserve(this.$refs.directorydiv)
    },

    methods: {

        /**
         * Метод для расчета вида блока
         */
        calcElementWidth() {
            if (this.$refs.directorydiv) {
                let rect = this.$refs.directorydiv.getBoundingClientRect()
                let width = rect.width
                if (width && this.fields && this.fields.length) {
                    this.blockInLine = (width / (this.fields.length + 1)) > 100
                }
            }
        },

        onAddAddedItem() {
            this.$emit('addAddedItem')
        },

        onAddRemovedItem() {
            this.$emit('addRemovedItem')
        },

        onEditItem(key, type) {
            if (type && type === 'remove') {
                this.$emit('editRemovedItem', key)
            } else {
                this.$emit('editAddedItem', key)
            }
        },


        onDeleteItem(key, type) {
            if (type && type === 'remove') {
                this.$emit('deleteRemovedItem', key)
            } else {
                this.$emit('deleteAddedItem', key)
            }
        },

    }
}
</script>

<style scoped>
.this-component_title {
    display: grid;
    border: 1px solid #285473;
    border-bottom-width: 0;
    min-height: 30px;
    width: 100%;
    font-size: 1rem;
    font-style: italic;
    background-color: #cccccc;
    padding-top: 2px;
    padding-left: 8px;
    padding-right: 8px;
    align-content: space-evenly;
    grid-template-columns: auto 30px 30px;
}

.this-component_title--name {
    text-align: left;
}

.this-component_title--button {
    display: grid;
    justify-content: right;
    //padding-right: 12px;
}

.this-component_section {
    display: grid;
    border: 1px solid #285473;
    /*border-bottom-width: 0;*/
    grid-gap: 1px;
    background-color: #285473;

}

.this-component_header {
    display: grid;
    width: 100%;
    font-size: 1rem;
    font-style: italic;
    background-color: #cccccc;
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: anywhere;
}

.this-component-field {
    display: grid;
    width: 100%;
    font-size: 0.85rem;
    font-style: italic;
    background-color: white;
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: anywhere;
}

.this-component-field.this-component-field--action {
    grid-template-columns: auto auto;
    padding-right: 8px;
}

.this-component-field.this-component-field--action div {
    width: 20px;
    text-align: center;
}

.this-component-columnsection {
    border: 1px solid #285473;
    background-color: #285473;
}

.this-component-columnsection .wrapper {
    display: grid;
    grid-gap: 1px;
    grid-template-columns: max-content auto;
    font-size: 0.80rem;
}


.this-component-columnsection-row-header {
    background-color: white;
    padding-left: 8px;
    padding-right: 3px;
}

.this-component-columnsection-row-item {
    background-color: white;
    padding-left: 8px;
    padding-right: 3px;
}

.this-component-columnsection-row-item .this-component-field--action {
    display: grid;
    grid-template-columns: auto auto;
    width: 30px;
    /*border-bottom-width: 0;*/

}

.this-component-columnsection-row-item.last,
.this-component-columnsection-row-header.last {
    border-bottom: 2px solid #285473;
}

</style>
