<template>
    <v-col v-if="$store.getters['TaskData/getTaskCustomData'].length" class="px-0 pb-1">
        <v-row v-for="(value, index ) in $store.getters['TaskData/getTaskCustomData']" :key="value.id" no-gutters>
            <v-col
                :title="value.name"
                class="taskHeaderTitle setMargin pl-2 justify-center pt2" cols="12" md="3"
                sm="5"
            >
                {{ value.name }}
            </v-col>
            <v-col
                class="taskHeaderSimpleText setMargin pl-2 pt2"
                cols="12" md="9" sm="7"
            >
                <component
                    :is="value.component"
                    v-model="model[index]['data']"
                    :componentDisabled="disabled"
                    :param="model[index]['param']"
                    v-on:blur="onBlurCustomData(model[index])"
                ></component>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>

import TaskCustomDataTextField from "./TaskCustomDataTextField";
import TaskCustomDataTextarea from "./TaskCustomDataTextarea";
import TaskCustomDataInteger from "./TaskCustomDataInteger";
import TaskCustomDataSelect from "./TaskCustomDataSelect";
import TaskCustomDataMultiSelect from "./TaskCustomDataMultiSelect.vue";

export default {
    name: "TaskCustomData",
    components: {
        TaskCustomDataTextField,
        TaskCustomDataTextarea,
        TaskCustomDataInteger,
        TaskCustomDataSelect,
        TaskCustomDataMultiSelect,
    },
    data: function () {
        return {
            model: this.getCustomData(),
        }
    },
    computed: {
        taskId() {
            return this.$store.getters["TaskData/getTaskId"]
        },
        disabled() {
            return this.$store.getters["TaskData/getDisabled"]
        },

    },


    methods: {
        getCustomData() {
            // console.log(this.$store.getters["TaskData/getTaskData"].custom_data)
            return this.$store.getters["TaskData/getTaskCustomData"]
        },

        onBlurCustomData(val) {
            if (val) {
                axios.put('/api/task/customdata/' + val.task_id, {id: val.id, data: val.data})
                    .then(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                    .catch(error => {
                        this.showError(error)
                    });
            }
        }
    }
}
</script>

<style scoped>

</style>
