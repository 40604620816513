<template>
    <v-col class="px-0 pb-1">
        <div class="element-title">
            <div>
                Установленные пломбы
            </div>
            <div class="pr-3">
                <v-btn
                    :disabled="disabled"
                    icon
                    small
                    v-on:click="addNewSeal"
                >
                    <v-icon>{{ $store.state.Icon.svgMdiPlus }}</v-icon>
                </v-btn>
            </div>
        </div>

        <div class="element-body">
            <div v-if="sealsWithoutOperation.length" class="element-body-section-WithoutOperation header">
                <div class="element-body-header">Номер пломбы</div>
                <div class="element-body-header">Место установки</div>
                <div class="element-body-header"></div>

                <template v-for="item in sealsWithoutOperation">
                    <div class="element-body-field">{{ item.number }}</div>
                    <div class="element-body-field">{{ item.location }}</div>
                    <div class="element-body-field action">
                        <div class="px-3">
                            <v-btn
                                :disabled="disabled"
                                icon
                                title="Удалить"
                                x-small
                                @click="deleteSeal(item.id)"
                            >
                                <v-icon>{{ $store.state.Icon.svgMdiDelete }}</v-icon>
                            </v-btn>
                        </div>
                        <div class="px-3">
                            <v-btn
                                :disabled="disabled"
                                icon
                                title="Заменить"
                                x-small
                                @click="replaceSeal(item.id)"
                            >
                                <v-icon>{{ $store.state.Icon.svgMdiReload }}</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </template>
            </div>

            <div v-if="sealsWithOperation.length" class="element-body-section-WithOperation header">
                <div class="element-body-header">Старая пломба</div>
                <div class="element-body-header">Новая пломба</div>
                <div class="element-body-header">Место установки</div>
                <div class="element-body-header"></div>

                <template v-for="item in sealsWithOperation">
                    <div class="element-body-field">{{ item.operation.old_number }}</div>
                    <div class="element-body-field">{{ item.operation.new_number }}</div>
                    <div class="element-body-field">{{ item.location }}</div>
                    <div class="element-body-field action">
                        <v-btn
                            :disabled="disabled"
                            icon
                            title="Удалить"
                            x-small
                            @click="deleteSealOperation(item.operation.id)"
                        >
                            <v-icon>{{ $store.state.Icon.svgMdiDelete }}</v-icon>
                        </v-btn>
                    </div>
                </template>

            </div>
        </div>


        <base-modal
            v-model="newSealDialog"
            max-width="520"
        >
            <v-card>
                <v-container>
                    <v-card-title>
                        <span class="headline">Добавить пломбу</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form
                            ref="newSealForm"
                            v-model="newSealValid"
                        >
                            <v-text-field
                                v-model="newSeal.number"
                                :rules="requiredRules"
                                autocomplete="off"
                                clearable
                                label="Номер пломбы"
                            ></v-text-field>
                            <v-text-field
                                v-model="newSeal.location"
                                :rules="requiredRules"
                                autocomplete="off"
                                clearable
                                label="Место установки"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!newSealValid" color="blue" text v-on:click="saveNewSeal()">Сохранить</v-btn>
                        <v-btn color="blue" text v-on:click="closeNewSealDialog()">Закрыть</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </base-modal>

        <base-modal
            v-model="replaceSealDialog"
            max-width="520"
        >
            <v-card>
                <v-container>
                    <v-card-title>
                        <span class="headline">Добавить пломбу</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form
                            ref="replaceSealForm"
                            v-model="replaceSealValid"
                        >
                            <v-text-field
                                v-model="replacedSeal.new_number"
                                :rules="requiredRules"
                                autocomplete="off"
                                clearable
                                label="Номер новой пломбы"
                            ></v-text-field>
                            <v-text-field
                                v-model="replacedSeal.reason"
                                autocomplete="off"
                                clearable
                                label="Причина замены"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!replaceSealValid" color="blue" text v-on:click="saveReplaceSeal()">
                            Сохранить
                        </v-btn>
                        <v-btn color="blue" text v-on:click="closeReplaceSealDialog()">Закрыть</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </base-modal>

    </v-col>
</template>

<script>
import BaseModal from "../../../BaseComponents/BaseModal";
import requiredRules from "../../../../Rules/requiredRules";

export default {
    name: "EquipmentSeal",
    components: {BaseModal},
    data: function () {
        return {
            newSealDialog: false,
            newSealValid: false,
            newSeal: {
                number: null,
                location: null
            },

            replaceSealDialog: false,
            replaceSealValid: false,
            replacedSeal: {
                id: null,
                new_number: null,
                reason: null,
            }
        }
    },
    computed: {
        taskId() {
            return this.$store.getters["TaskData/getTaskId"]
        },
        disabled() {
            return this.$store.getters["TaskData/getDisabled"]
        },
        seals() {
            return this.$store.getters["TaskData/getSeals"]
        },

        sealsWithoutOperation() {
            return this.seals.filter(item => !item.operation);
        },

        sealsWithOperation() {
            return this.seals.filter(item => item.operation);
        },

        requiredRules() {
            return requiredRules
        },
    },
    methods: {
        addNewSeal() {
            this.newSealDialog = true
        },
        saveNewSeal() {
            if (this.newSeal.number && this.newSeal.location) {
                axios.post('/api/equipment_seal/' + this.taskId, this.newSeal)
                    .then(() => {
                        this.closeNewSealDialog()
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                    .finally(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                ;
            }
        },
        closeNewSealDialog() {
            this.newSealDialog = false
            this.$refs.newSealForm.resetValidation()
            this.$refs.newSealForm.reset()
        },
        deleteSeal(id) {
            if (id && confirm('Вы точно хотите удалить эту пломбу?')) {
                axios.delete('/api/equipment_seal/' + this.taskId + '/' + id)
                    .catch(error => {
                        this.showError(error)
                    })
                    .finally(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                ;
            }
        },
        replaceSeal(id) {
            this.replacedSeal.id = id
            this.replaceSealDialog = true
        },

        saveReplaceSeal() {
            if (this.replacedSeal.id && this.replacedSeal.new_number) {
                axios.put('/api/equipment_seal/' + this.taskId + '/' + this.replacedSeal.id, this.replacedSeal)
                    .then(() => {
                        this.closeReplaceSealDialog()
                    })
                    .catch(error => {
                        this.showError(error)
                    })
                    .finally(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                ;
            }
        },

        closeReplaceSealDialog() {
            this.replaceSealDialog = false
            this.$refs.replaceSealForm.resetValidation()
            this.$refs.replaceSealForm.reset()
        },

        deleteSealOperation(id) {
            if (id && confirm('Вы точно хотите удалить эту замену?')) {
                axios.delete('/api/equipment_seal/operation/' + this.taskId + '/' + id)
                    .catch(error => {
                        this.showError(error)
                    })
                    .finally(() => {
                        this.$store.dispatch('TaskData/updateTaskData')
                    })
                ;
            }
        }
    }
}
</script>

<style scoped>
.element-title {
    display: grid;
    border: 1px solid #285473;
    border-bottom-width: 0;
    height: 28px;
    width: 100%;
    font-size: 1rem;
    font-style: italic;
    background-color: #cccccc;
    text-align: left;
    padding-left: 8px;
    align-content: space-evenly;
    grid-template-columns: auto max-content;
}

.element-body {
    width: 100%;
    display: grid;
    border-bottom: 1px solid #285473;
}

.element-body-section-WithoutOperation {
    display: grid;
    border: 1px solid #285473;
    border-bottom-width: 0;
    grid-gap: 1px;
    background-color: #285473;
    grid-template-columns: max-content auto max-content;
}

.element-body-section-WithOperation {
    display: grid;
    border: 1px solid #285473;
    border-bottom-width: 0;
    grid-gap: 1px;
    background-color: #285473;
    grid-template-columns: max-content max-content auto max-content;
}

.element-body-header {
    display: grid;
    width: 100%;
    font-size: 1rem;
    font-style: italic;
    background-color: #cccccc;
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
}

.element-body-field {
    display: grid;
    align-content: space-around;
    white-space: nowrap;
    background-color: white;
    font-size: .85rem;
    min-height: 24px;
    padding-left: 8px;
    padding-right: 8px;
}

.element-body-field.action {
    grid-gap: 1px;
    grid-template-columns: max-content max-content;
}

</style>