const Performer = () => import(/* webpackChunkName: "Performer" */ '../components/Admin/Performers/Performers.vue')
const PerformerCreate = () => import(/* webpackChunkName: "Performer" */ '../components/Admin/Performers/PerformerCreate.vue')
const PerformerDelete = () => import(/* webpackChunkName: "Performer" */ '../components/Admin/Performers/PerformerDelete.vue')
const PerformerUpdate = () => import(/* webpackChunkName: "Performer" */ '../components/Admin/Performers/PerformerUpdate.vue')
export const performerRoutes = [
    {
        path: '/performer', name: "performer.index", component: Performer,
        children: [
            {path: "create", component: PerformerCreate, name: "performer.create"},
            {path: "delete/:id", component: PerformerDelete, name: "performer.delete", props: true},
            {path: ":id", component: PerformerUpdate, name: "performer.update", props: true},
        ]
    },
]