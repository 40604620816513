const state = {
    confirmDialog: null,
    errors: null,
    info: null,
    infoTimeout: 2000,
    errorTimeout: 2000,
}


const getters = {
    getConfirmDialog: (state) => state.confirmDialog,
    getErrors: (state) => state.errors,
    getInfo: (state) => state.info,
    getInfoTimeout: (state) => state.infoTimeout,
}

const mutations = {
    setConfirmDialog(state, data) {
        state.confirmDialog = data
    },

    setErrors(state, data) {
        state.errors = data
    },
    setNoErrors(state) {
        state.errors = null
    },
    setInfo(state, data) {
        state.info = data
    },

    setInfoTimeout(state, data) {
        state.infoTimeout = data
    },

    setNoInfo(state) {
        state.info = null
    }
}


export default {
    state,
    getters,
    mutations
}
