<template>
    <g transform="matrix(29.787234,0,0,29.88805,22,-0.2988805)">
        <path
            class="fil0"
            d="M 0.94,3.35 H 0 V 3.11 C 0,3.08 0.01,3.07 0.03,3.07 H 0.09 V 2.84 C 0.09,2.46 0.26,2.36 0.41,2.2 0.63,1.99 0.83,1.86 0.84,1.68 0.83,1.49 0.63,1.36 0.41,1.16 0.25,1.01 0.09,0.9 0.09,0.52 V 0.29 H 0.03 C 0.01,0.29 0,0.28 0,0.25 V 0.01 h 0.94 0.94 v 0.24 c 0,0.03 -0.01,0.04 -0.03,0.04 H 1.79 V 0.52 C 1.79,0.9 1.62,1 1.47,1.16 1.25,1.37 1.05,1.5 1.04,1.68 1.05,1.87 1.25,2 1.47,2.2 1.63,2.35 1.79,2.46 1.79,2.84 v 0.23 h 0.06 c 0.02,0 0.03,0.01 0.03,0.04 v 0.24 z m 0,-1.77 C 1,1.38 1.24,1.21 1.39,1.07 1.59,0.88 1.68,0.82 1.68,0.52 V 0.33 H 0.21 v 0.19 c 0,0.3 0.09,0.36 0.29,0.55 0.15,0.14 0.39,0.31 0.45,0.51 z"/>
    </g>
</template>

<script>
export default {
    name: "SuspendedStatus"
}
</script>

<style scoped>
.fil0 {
    fill: #285473
}

.fil1 {
    fill: #fefefe
}
</style>
