<template>
    <v-currency-field
        v-model="localData"
        :clearable="!disabled && !read_only"
        :disabled="disabled"
        :readonly="read_only"
        class="task-text-field pt-0 mt-0"
        hide-details
        no-resize
        suffix="руб."
        value-as-integer
        v-on:change="$emit('change', $event)"
    >
    </v-currency-field>
</template>

<script>
export default {
    name: "Money",
    props: {
        value: null,
        extra_attributes: null,
        read_only: false,
        disabled: false,
    },
    data: function () {
        return {
            localData: null,

        }
    },
    created() {
        this.localData = this.value
    },
    watch: {
        value: function (val) {
            this.localData = val
        },
    }
}
</script>

<style scoped>
.task-text-field {
    font-size: .85rem;
}
</style>
