<template>
    <v-col class="px-0 pb-1">
        <FixedEquipment
            v-if="value.system.contract.settings.equipment === 'from_directory'"
            :disabled="disabled"
            :task-id="taskId"
            :value="model"
            @input="$emit('input')"
        />

        <WarehouseEquipment
            v-else-if="value.system.contract.settings.equipment === 'from_warehouse'"
            :disabled="disabled"
            :task-id="taskId"
            :value="model"
            @input="$emit('input')"
        />

        <FreeEquipment
            v-else-if="value.system.contract.settings.equipment === 'not_control'"
            :disabled="disabled"
            :task-id="taskId"
            :value="model"
            @input="$emit('input')"
        />
    </v-col>
</template>

<script>
import FixedEquipment from "./FixedEquipment";
import FreeEquipment from "./FreeEquipment";
import WarehouseEquipment from "./WarehouseEquipment";

export default {
    name: "TaskEquipment",
    components: {
        FixedEquipment,
        FreeEquipment,
        WarehouseEquipment
    },
    props: {
        value: {
            default: {}
        },

        taskId: {
            type: Number,
            required: true
        },
    },
    data: function () {
        return {
            model: null,
        }
    },
    computed: {
        disabled() {
            return !!this.$store.getters["TaskData/getDisabled"]
        },
    },
    watch: {
        value: {
            handler: function (val) {
                switch (val.system.contract.settings.equipment) {
                    case 'from_directory':
                        this.model = Object.assign({}, val.fixed_equipment)
                        break;
                    case 'from_warehouse':
                        this.model = Object.assign({}, val.warehouse_equipment)
                        break;
                    case 'not_control':
                        this.model = Object.assign({}, val.free_equipment)
                        break;
                }
            },
            deep: true,
            immediate: true
        },
    },
}
</script>

<style scoped>

</style>
