<template>
    <base-modal
        :value="openDialog"
        max-width="400"
    >
        <v-card>
            <v-card-title class="text-h5">
                Изменить состояние
            </v-card-title>
            <v-card-text>
                Вы уверены, что хотите изменить состояние дефекта?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Нет</v-btn>
                <v-btn color="blue darken-1" text @click="save">Да</v-btn>
            </v-card-actions>
        </v-card>
    </base-modal>
</template>
<script>
import BaseModal from "../BaseComponents/BaseModal.vue";
import SystemDefectDefaultItem from "./SystemDefectDefaultItem";

export default {
    name: "SystemDefectStateForm",
    components: {BaseModal},
    props: {
        value: {
            default: SystemDefectDefaultItem(),
        },
        taskId: {
            type: Number,
            nullable: true,
            default: null
        },
        systemId: {
            type: Number,
            nullable: true,
            default: null
        }
    },
    data: function () {
        return {
            openDialog: false,
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if (val) {
                    this.openDialog = true
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        save() {
            if (this.getPath()) {
                axios.post(this.getPath())
                    .then(() => {
                        this.$emit('need-update')
                        this.close()
                    })
                    .catch(error => {
                        this.showError(error)
                    });
            }
        },

        getPath() {
            if (this.taskId && this.value.id) {
                return '/api/system-defect/state/task/' + this.taskId + '/defect/' + this.value.id
            }

            if (this.systemId && this.value.id) {
                return '/api/system-defect/state/system/' + this.systemId + '/defect/' + this.value.id
            }
            return null
        },

        close() {
            this.openDialog = false
            this.$emit('close')
        }
    }
}
</script>

