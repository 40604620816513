<template>
    <g>
        <path :fill="iconColor"
              d="M22.808 8.116c-.694-1.762-1.514-3.746-2.03-5.056l-.002-.006a2.226 2.226 0 0 1-.16-.832c0-1.222.98-2.212 2.192-2.212C24.018.01 25 1 25 2.222c0 .294-.057.575-.16.832l-.003.006c-.516 1.31-1.336 3.294-2.03 5.056Zm0-7.377a1.463 1.463 0 1 1 0 2.926 1.463 1.463 0 0 1 0-2.926z"
              style="stroke-width:.017619"/>
        <path :fill="iconColor"
              d="M5.776 22.407c1.531.111 4.682.294 5.791.145 1.61-.216 4.655-1.853 5.994-2.778 1.747-1.205 1.91-2.628 1.523-4.726-.297-1.611-1.476-1.968-.952-4.456.399-1.895 2.168-2.585 3.87-2.601.396-.004.535.057 1.074.092-1.537.214-1.376.158-2.14.352-1 .255-1.819.703-2.252 1.736-.678 1.616.632 3.006.987 4.442.82 3.31.222 5.243-2.692 6.761-5.997 3.125-4.33 2.703-11.277 2.459-.678-.026-1.393-.166-1.368-.844.026-.677.764-.607 1.442-.582Z"
              style="stroke-width:.017619"/>
        <path :fill="iconColor"
              d="M4.49 23.496C3.07 19.888 1.39 15.824.334 13.14l-.004-.012A4.556 4.556 0 0 1 0 11.424c0-2.502 2.01-4.53 4.49-4.53 2.48 0 4.491 2.028 4.491 4.53 0 .603-.116 1.178-.328 1.704l-.005.012c-1.057 2.684-2.736 6.749-4.157 10.356Zm0-15.11a2.997 2.997 0 1 1 0 5.994 2.997 2.997 0 0 1 0-5.995z"
              style="stroke-width:.017619"/>
    </g>
</template>

<script>
export default {
    name: "WaybillIcon",
    props: {
        iconColor: {
            type: String,
            default: '#285473'
        },
        whiteColor: {
            default: "#FEFEFE"
        },
    }
}
</script>

<style scoped>

</style>
