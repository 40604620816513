import Vue from 'vue'
import store from './store'
import router from './router/index'
import vuetify from './plugins/vuetify';
import VCurrencyField from 'v-currency-field'
import VueGeolocation from 'vue-browser-geolocation';
import VJsf from '@koumoul/vjsf'
import Toast, {POSITION} from "vue-toastification";
import {userRights} from "./mixins/userRights";
import {showMessageMixin} from "./mixins/showMessageMixin";

require('./bootstrap');
// window.Vue = require('vue');


Vue.component('VJsf', VJsf)
Vue.component('app', require('./components/app').default);
Vue.component('v-currency-field', VCurrencyField)

Vue.mixin(userRights)

Vue.mixin(showMessageMixin)


Vue.use(Toast, {
        position: POSITION.BOTTOM_RIGHT
    }
);


Vue.use(
    VCurrencyField, {
        locale: 'ru-RU',
        decimalLength: 2,
        autoDecimalMode: true,
        min: null,
        max: null,
        defaultValue: 0,
        valueAsInteger: false,
        allowNegative: true
    },
)

Vue.use(
    VueGeolocation,
)

const app = new Vue({
    store,
    vuetify,
    router,
}).$mount('#app')

