<template>
    <v-card
        v-if="UserCan.DirectoryItem.Read || UserCan.DirectoryItem.Edit"
        :loading="loading">
        <v-form
            ref="Form"
            v-model="Valid"
        >
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <div class="scrol-div">
                    <template v-for="(field, index ) in directoryFields">
                        <v-col
                            v-if="field.type &&  field.type.key && checkAddonComponent(field.type.key) && field.type.key !== 'Directory' "
                            class="px-1 py-1"
                            cols="12"
                        >
                            <component
                                :is="field.type.key"
                                v-model="editedItem.data[field.name]"
                                :extra_attributes="field.extra_attributes"
                                :label="field.name"
                                :required="field.required"
                            ></component>
                        </v-col>
                    </template>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="inProcess" color="blue" text @click="close">Отмена</v-btn>
                <v-btn v-if="UserCan.DirectoryItem.Edit" :disabled="!Valid || inProcess" color="blue" text
                       @click="save">Сохранить
                </v-btn>
            </v-card-actions>
        </v-form>
        <base-error-snackbar
            v-model="Error"
            v-bind:error-text="ErrorText"
        />
    </v-card>
</template>

<script>
import BaseErrorSnackbar from "../../../BaseComponents/BaseErrorSnackbar";
import String from "../../../BaseCustomDataComponents/CustomDataFieldsType/String";
import TextArea from "../../../BaseCustomDataComponents/CustomDataFieldsType/TextArea";
import Number from "../../../BaseCustomDataComponents/CustomDataFieldsType/Number";
import Money from "../../../BaseCustomDataComponents/CustomDataFieldsType/Money";
import Select from "../../../BaseCustomDataComponents/CustomDataFieldsType/Select";
import MultiSelect from "../../../BaseCustomDataComponents/CustomDataFieldsType/MultiSelect";
import DateTime from "../../../BaseCustomDataComponents/CustomDataFieldsType/DateTime";
import Directory from "../../../BaseCustomDataComponents/CustomDataFieldsType/Directory";
import Time from "../../../BaseCustomDataComponents/CustomDataFieldsType/Time";
import Date from "../../../BaseCustomDataComponents/CustomDataFieldsType/Date";

export default {
    name: "DirectoryItemForm",
    components: {
        BaseErrorSnackbar,
        String,
        TextArea,
        Number,
        Money,
        Select,
        MultiSelect,
        DateTime,
        Directory,
        Time,
        Date
    },
    props: {
        dirid: { //Идентификатор справочника, передается из маршрута
            required: true
        },
        propsEditedIndex: 0, //Индекс редактируемого элемента
        propsEditedItem: null,
        propsFields: {
            required: true,
            type: Array,
        }
    },
    data: function () {
        return {
            componentsList: Object.keys(this.$options.components),
            editedItem: Object, //Переменная с данными редактируемого элемента
            Valid: false, //Переменная для контроля валидности данных формы
            ErrorText: '',
            Error: false,
            loading: false,
            inProcess: false,
            directoryFields: []
        }
    },
    computed: {
        formTitle() {
            return this.propsEditedIndex === -1 ? "Создать элемент" : "Изменить элемент"
        },
    },
    watch: {
        propsEditedIndex: {
            handler: function (val) {
                this.editedItem = Object.assign({}, this.propsEditedItem)
            },
            immediate: true,
        },
        propsFields: {
            handler: function (val) {
                if (val && Array.isArray(val)) {

                    //... - это spread syntax. https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Operators/Spread_syntax

                    this.directoryFields = [...val]

                    this.directoryFields.sort((a, b) => a.following > b.following ? 1 : -1);
                } else {
                    this.directoryFields = []
                }
            },
            immediate: true,
        }
    },

    methods: {
        checkAddonComponent(component) {
            return this.componentsList.includes(component)
        },

        wait: function (act) {
            if (act === "start") {
                this.inProcess = true
                this.loading = true
            }
            if (act === "stop") {
                this.inProcess = false
                this.loading = false
            }
        },

        resetValidation() {
            this.Valid = false
            this.$refs.Form.reset()
            this.$refs.Form.resetValidation()
        },


        close() {
            this.$emit('close')
            // this.Error = false
            // this.ErrorText = ''
            // this.editedItem = Object.assign({}, {})
            // this.resetValidation()
        },


        save() {
            if (this.UserCan.DirectoryItem.Edit && this.editedItem.directory_id) {
                this.wait("start")
                const emitedData = Object.assign({}, this.editedItem)
                if (this.propsEditedIndex === -1) {
                    axios.put("/api/directoryitem/" + emitedData.directory_id + "/new", emitedData)
                        .then(() => {
                            this.close()
                        })
                        .catch(error => {
                            if (error.response) {
                                this.Error = true
                                this.ErrorText = "Не удалось создать новый элемент справочника."
                            }
                        })
                        .finally(() => {
                            this.wait("stop")
                        });
                } else {
                    if (emitedData.id) {
                        axios.put('/api/directoryitem/' + emitedData.directory_id + "/" + emitedData.id, emitedData)
                            .then(() => {
                                this.close()
                            })
                            .catch(error => {
                                if (error.response) {
                                    this.Error = true
                                    this.ErrorText = "Не удалось изменить элемент справочника."
                                }
                            })
                            .finally(() => {
                                this.wait("stop")
                            });
                    }
                }
            }
        },
    },


}
</script>

<style scoped>
.scrol-div {
    overflow: auto;
    overflow-x: hidden;
    min-height: 590px;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 6px; /* высота для горизонтального скролла */
    background-color: #ffffff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #3273a7;
}
</style>
