// Imports
import Vue from 'vue'
import Router from 'vue-router'
import {routes} from "./routes";
import {adminConfigRoutes} from "./AdminConfigRoutes";
import {personRoutes} from "./PersonRoutes";
import {customerRoutes} from "./CustomerRoutes";
import {performerRoutes} from "./PerformerRoutes";
import {rolesRoutes} from "./RolesRoutes";
import {departmentRoutes} from "./DepartmentsRoutes";
import {objectRoutes} from "./ObjectRoutes";

Vue.use(Router)

const allRoutes = [].concat(
    routes,
    adminConfigRoutes,
    personRoutes,
    customerRoutes,
    performerRoutes,
    rolesRoutes,
    departmentRoutes,
    objectRoutes,
)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return {selector: to.hash}
        if (savedPosition) return savedPosition

        return {x: 0, y: 0}
    },
    routes: allRoutes,
})


export default router

