<template>
    <g transform="scale(1.3768415,1.4025245)">
        <path
            class="fil0"
            d="m 7.49,71.3 h 57.66 c 4.11,0 7.48,-3.37 7.48,-7.49 V 7.49 C 72.63,3.37 69.26,0 65.15,0 H 7.49 C 3.37,0 0,3.37 0,7.49 v 56.32 c 0,4.12 3.37,7.49 7.49,7.49 z M 36.32,7.05 c 15.79,0 28.6,12.8 28.6,28.6 0,15.8 -12.81,28.6 -28.6,28.6 -15.8,0 -28.61,-12.8 -28.61,-28.6 0,-15.8 12.81,-28.6 28.61,-28.6 z"/>
        <path
            class="fil0"
            d="m 34.66,46.64 c -1.34,-6.83 -3.31,-13.8 -4.24,-20.69 -0.71,-4.58 0.78,-8.99 5.9,-8.99 5.11,0 6.6,4.41 5.89,8.99 -0.92,6.89 -2.9,13.86 -4.23,20.69 -0.52,-0.18 -1.08,-0.28 -1.66,-0.28 -0.59,0 -1.14,0.1 -1.66,0.28 z"/>
        <circle
            class="fil0"
            cx="36.32"
            cy="51.369999"
            r="2.97"/>
    </g>
</template>

<script>
export default {
    name: "ExpiredPriority"
}
</script>

<style scoped>
.fil0 {
    fill: #E31E24
}
</style>
