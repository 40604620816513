export default {
    data: function () {
        return {
            show: false,
            intersectionObserver: null,
            observedElement: 'observedElement'
        }
    },
    mounted() {
        if (this.$refs[this.observedElement]) {
            this.intersectionObserver = new IntersectionObserver(this.showElementWhenIntersection);
            this.intersectionObserver.observe(this.$refs[this.observedElement])
        }
    },

    beforeDestroy() {
        if (this.$refs[this.observedElement]) {
            this.intersectionObserver.unobserve(this.$refs[this.observedElement])
        }
    },
    methods: {
        showElementWhenIntersection(entries, obsever) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.show = true;
                    obsever.unobserve(entry.target)
                }
            });
        },
    }
}
