const state = {
    focus: null,
}

const getters = {
    getFocus: (state) => state.focus,
}

const mutations = {
    setFocus(state, data) {
        state.focus = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}